import React from 'react';
import { Link } from 'react-router-dom';
import ClubListNumberRollOver from './ClubListNumberRollOver.js';
import { __x } from '../../helpers/GeneralHelper.js';


const Club = ({ clubs, user_role, is_fetched, ...rest }) => {
    let clubsList       = [];
    if ( !clubs || !clubs.length || clubs === 'undefined' ) {
        if ( is_fetched ) {
            clubsList.push(
                <tr key={1}>
                    <td colSpan="7">{ __x( `No record found.` ) }</td>
                </tr>
            );
        }

        return clubsList;
    }

    const manage_icon   = user_role === 'Agent' ? <i className="material-icons text-offWhite font-size20">remove_red_eye</i> : <i className="material-icons text-offWhite font-size20">edit</i>;
        
    if ( !clubs || clubs === 'undefined' || clubs.length <= 0 ) {
        return clubsList;
    }
    
    for ( let i in clubs ) {
        let club = clubs[i];
        let email_phone_restrict = club.is_restricted && club.email === '' && club.phone === '';
        if ( email_phone_restrict ) {
            clubsList.push(
                <tr key={club.id}>
                    <td>
                        <div className="d-flex align-items-center">
                            <span className="table-clubImg"><img src={ club.image_path && club.image_path !== '' ? club.image_path : require( '../../assets/images/dummy-image.png' ) } alt="" /></span> { club.club_name }
                        </div>
                    </td>
                    <td>{ club.club_title }</td>
                    <td>{ `${club.first_name} ${club.last_name}` }</td>
                    <td colSpan="2" className="text-danger">{ __x( `Restricted, please contact the administrator.` ) }</td>
                    <td>
                        <button data-toggle="popover" data-container="body" data-trigger="focus" data-placement="left" data-html="true" to="#" email="toggle-one" className="table-btn btn-transWhite show-rollover position-relative">
                            <span>{ club.cases.length }</span>
                            { club.cases.length > 0 ? <ClubListNumberRollOver club_id={club.id} clubCases = {club.cases} filtered={ rest.filtered } /> : '' }
                        </button>
                    </td>
                    <td><Link to={`/clubs-details/${club.id}`}>{ manage_icon }</Link></td>
                </tr>
            );
        } else {
            clubsList.push(<tr key={club.id}>
                <td>
                    <div className="d-flex align-items-center">
                        <span className="table-clubImg"><img src={ club.image_path && club.image_path !== '' ? club.image_path : require( '../../assets/images/dummy-image.png' ) } alt="" /></span> { club.club_name }
                    </div>
                </td>
                <td>{ club.club_title }</td>
                <td>{ `${club.first_name} ${club.last_name}` }</td>
                <td className="break-email">{club.email}</td>
                <td className="fontBold">{`${club.country_code && club.phone ? `+${club.country_code}` : ''}${club.phone ? club.phone : ''}`}</td>
                <td>
                    <button data-toggle="popover" data-container="body" data-trigger="focus" data-placement="left" data-html="true" to="#" email="toggle-one" className="table-btn btn-transWhite show-rollover position-relative">
                        <span>{ club.cases.length }</span>
                        { club.cases.length > 0 ? <ClubListNumberRollOver club_id={club.id} clubCases = {club.cases} filtered={ rest.filtered } /> : '' }
                    </button>
                </td>
                <td><Link to={`/clubs-details/${club.id}`}>{ manage_icon }</Link></td>
            </tr>);
        }
    }
    return clubsList;
}
export default Club;