import React from 'react';
import Alert from '../../../helpers/Alert';
import IntlTelInput from 'react-intl-tel-input';
import '../../../../node_modules/react-intl-tel-input/dist/main.css';
import { __x } from '../../../helpers/GeneralHelper';

const UpdateUser = ( props ) => {
    const modalActiveClass  = props.modal ? 'show' : '',
    first_name_err_classes  = props.isValid.first_name ? '' : 'form-error',
    last_name_err_classes   = props.isValid.last_name ? '' : 'form-error',
    email_err_classes       = props.isValid.email ? '' : 'form-error',
    phone_err_classes       = props.isValid.phone ? '' : 'form-error',
    status_err_classes      = props.isValid.status ? '' : 'form-error',
    users_role_err_classes  = props.isValid.users_role ? '' : 'form-error',
    file_err_classes        = props.isValid.file ? '' : 'form-error',
    popup_tile              = props.isUpdating ? `Edit User` : `Add User`,
    disableSubmit           = !props.isFormValid() || props.isProcessing ? true : false,
    responseMsg             = props.responseMessage !== '' ? <Alert message={props.responseMessage} success={props.isSuccess} floating="true" onClose={props.resetAlert} /> : '',
    lockErrorMsg            = props.lockErrorMsg ? <Alert message={props.lockErrorMsg} success={props.isSuccess}  floating="true" goBack={props.goBack} isNotFlash /> : '',
    lockscreen              = props.lockscreen ? 'modal-backdrop fade show' : 'modal form-modal fade modal-active';
    
    return (
        <div>
            { responseMsg }
            { lockErrorMsg }
            <div className={`modal form-modal fade modal-active ${modalActiveClass} ${lockscreen}`} style={{ transform: props.modal ? `translate(0, 0)` : 'translate(-125%, -125%)' }}>
                <div className="modal-dialog modal-dialog-centered maxWidth600">
                    <div className="modal-content">
                        <form action="" className="usm-form form-border" onSubmit={ event => {event.preventDefault()} } encType="multipart/form-data">
                            <div className="modal-header form-modal-header d-flex align-items-center">
                                <img src={ require( '../../../assets/images/form-usm-logo.png' ) } alt="" />
                                <h5 className="form-modal-title">{popup_tile}</h5>
                                <button type="button" className="modal-close" aria-label="Close" onClick={ props.onPopupClose }>
                                    <img src={ require( '../../../assets/images/close-modal.png' ) } alt="Close" />
                                </button>
                            </div>
                            <div className="modal-body form-modal-body">
                                <div className="row">
                                    <div className="col-md-6 col-xs-12">
                                        <div className={`form-group ${first_name_err_classes}`}>
                                            <label htmlFor="">First Name</label>
                                            <input value={ props.fields.first_name } onChange={ e => props.handleFieldsChanges( e ) } className="form-control" name="first_name" type="text" placeholder="First Name" autoComplete="off" />
                                            { first_name_err_classes === '' || <div className="display-error">{ __x( `First Name is invalid.` ) }</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xs-12">
                                        <div className={`form-group ${last_name_err_classes}`}>
                                            <label htmlFor="last_name">Last Name</label>
                                            <input value={ props.fields.last_name } onChange={ e => props.handleFieldsChanges( e ) } type="text" className="form-control" name="last_name" placeholder="Last Name" autoComplete="off" />
                                            { last_name_err_classes === '' || <div className="display-error">{ __x( `Last Name is invalid.` ) }</div> }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className={`form-group ${email_err_classes}`}>
                                            <label htmlFor="email">Email</label>
                                            <input value={ props.fields.email } onChange={ e => props.handleFieldsChanges( e ) } type="text" className="form-control" name="email" placeholder="Email" autoComplete="off" />
                                            { email_err_classes === '' || <div className="display-error">{ __x( `Please enter a valid email.` ) }</div> }
                                        </div>
                                    </div>
                                    <div className="col">                                            
                                        <div className={`form-group`}>
                                            <label htmlFor="phone">Phone</label>
                                            <div className={`input-group ${phone_err_classes}`}>
                                                <IntlTelInput 
                                                    onPhoneNumberChange={ props.handlePhoneChange }
                                                    onSelectFlag={ props.countryCodeChange }
                                                    defaultCountry={ 'gb' }
                                                    css={['intl-tel-input w-100', 'form-control']} 
                                                    placeholder="Phone"
                                                    value={props.fields.phone}
                                                    separateDialCode="true"
                                                    ref={ elt => props.set_country( elt ) }
                                                />
                                            </div>
                                            { phone_err_classes === '' || <div className="display-error">{ __x( `Phone is invalid.` ) }</div> }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-xs-12">
                                        <div className={`form-group ${users_role_err_classes}`}>
                                            <label htmlFor="roles">User Role</label>
                                            <select name="users_role" className="form-control" value={ props.fields.users_role } onChange={ e => props.handleFieldsChanges( e ) } >
                                                <option value=""></option>
                                                <option value="Admin">Admin</option>
                                                <option value="Staff">Staff</option>
                                            </select>
                                        </div>					
                                    </div>
                                    <div className="col-md-6 col-xs-12">
                                        <div className={`form-group ${status_err_classes}`}>
                                            <label htmlFor="status">Status</label>
                                            <select name="status" className="form-control" value={ props.fields.status } onChange={ e => props.handleFieldsChanges( e ) } >
                                                <option value=""></option>
                                                <option value="1">Active</option>
                                                <option value="0">Deactivated</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="add-photo-section form-transDivider d-flex align-items-cente">
                                    <div className={`form-group ${file_err_classes}`}>
                                        <label htmlFor="">Add Photo</label>
                                        <div className="custom-file">
                                            <input ref={ props.setFileRef } type="file" className="custom-file-input" id="customFile" name="file" onChange={ e => props.handleFieldsChanges( e ) } />
                                            <label className="custom-file-label" htmlFor="customFile">{ props.fileName }</label>
                                        </div>
                                        { file_err_classes === '' || <div className="display-error"><ul>{props.fieldsValidityError.file}</ul></div> }
                                    </div>
                                    <div className="upload-image ml-auto">
                                        <img src={props.imgPreview} alt="" />
                                    </div>				
                                </div>
                            </div>
                            
                            <div className="modal-footer form-modal-footer d-flex justify-content-between">
                                <button type="button" className="default-btn btn-danger" data-dismiss="modal" onClick={ props.onPopupClose }>Cancel</button>
                                <button type="button" onClick={event => props.updateUser( event )} className="default-btn btn-success" disabled={disableSubmit}>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            { !props.modal || <div className="modal-backdrop fade show"></div> }
        </div>
    );
}
export default UpdateUser;