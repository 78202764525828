import React from 'react';
import { connect } from 'react-redux';
import { is_valid_db_date, isUserImageValid, get_authorization_token, get_display_format_date, get_league_name_by_id, get_img_name_from_url, get_current_user_role, __x } from '../../helpers/GeneralHelper';
import { setClub, setLeagues } from '../../actions/ClubsAction';
import UpdateClub from './UpdateClub';
import UploadPics from './UploadPics';
import APIRoutes from '../../API/Routes';
import { withRouter } from 'react-router-dom';
import Request from '../../helpers/Request';
import Alert from '../../helpers/Alert';
import ConfirmLeagueChange from './club/ConfirmLeagueChange';
import LoadingSpinner from './common/LoadingSpinner';

class ClubRightSidebar extends React.Component {
    constructor( props ) {
        super( props );
        
        this._file              = React.createRef();
        this._uploadComponent   = React.createRef();
        
        this._club_id           = props.match.params.id;
        this._isFetching        = false;
        this._isFetched         = {
            club : false
        };

        this.current_user_role  = get_current_user_role();

        this.state = {
            club            : {},
            updateClubModal : false,
            sidebarToggled  : false,
            badge           : {},
            isbadgeValid    : false,
            badgeError      : '',
            isProcessing    : false,
            badgeUploaded   : false,
            isSubmitted     : false,
            isSuccess       : false,
            responseMsg     : '',
            imgPreview      : '',
            imgUploadHeader : `Upload Team Badge`,
            imgUploadCancel : `Cancel`,
            fileName        : 'Upload Photo',
            changedLeagueId : 0,
            from_league_name: '',
            to_league_name  : '',
            open_league_change_confirmation : false,
            is_league_changes_confirmed     : false
        }
    }

    handleUpdateClubModal = () => {
        let new_state = {
            updateClubModal    : !this.state.updateClubModal
        }
        
        if ( new_state.updateClubModal ) {
            document.body.classList.add( 'modal-open' );
        } else {
            document.body.classList.remove( 'modal-open' );
        }

        this.setState( new_state );
    }

    resetAlert = () => {
        this.setState({
            responseMsg: ''
        });
    }

    handleBadgeChange = ( event, file ) => {
        const isImageValid = isUserImageValid( file );
        if ( !isImageValid ) {
           this.setState({
                fileName: 'Upload Photo',
                isbadgeValid: false,
                badgeError  : "No Image Found!",
            });
        } else {
            if ( !isImageValid.success ) {
                this.setState({
                    fileName: 'Upload Photo',
                    isbadgeValid: false,
                    badgeError  : isImageValid.messages.map( ( error, idx ) => <li key={idx}>{error}</li> )
                });
            } else {
                /**
                 * Get preview file url using file reader
                 */
                const reader = new FileReader();
                reader.readAsDataURL( file );
                reader.onloadend = function ( e ) {
                    this.setState({
                        imgPreview  : [ reader.result ],
                        fileName    : file.name,
                        badge       : file,
                        isbadgeValid: true,
                        badgeError  : ''
                    });
                }.bind( this );
            }
        }
    }

    handleUplodadBadge = ( event ) => {
        event.preventDefault();

        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( !this.state.isbadgeValid ) {
            return false;
        }

        this.setState({
            isProcessing: true
        });

        const badge_updated_path = APIRoutes.club_badge + this._club_id;
        
        let new_state   = {},
        formData        = new FormData();
        formData.append( 'file', this.state.badge );
        
        /**
         * Sending request to add/update clubs.
         */
        const request = new Request( badge_updated_path, formData, 'POST', headers, true ).send();
        request.then( response => {
            new_state = {
                badgeUploaded   : response.success,
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message
            };
            
            if ( response.success ) {
                this.getClub();
                this._uploadComponent.current.closePopup();
            }
            
            this.setState( new_state );
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMsg     : error.message
            };
            this.setState( new_state );
        });
    }

    componentWillReceiveProps = new_props => {
        const club = new_props.clubs.club;
        this.setState({
            club,
            imgPreview      : club ? club.image_path : this.state.imgPreview,
            fileName        : club.image_path && club.image_path !== '' && get_img_name_from_url( club.image_path ) !== 'null' ? club.image_path : __x( `Upload Photo` ),
            imgUploadHeader : club.image_path && club.image_path !== '' && get_img_name_from_url( club.image_path ) !== 'null' ? __x( `Replace Team Badge` ) : __x( `Upload Team Badge` ),
            imgUploadCancel : club.image_path && club.image_path !== '' && get_img_name_from_url( club.image_path ) !== 'null' ? __x( `No, Cancel` ) : __x( `Cancel` )
        });
    }

    onCloseImgUpload = () => {
        const club = this.props.clubs.club ? this.props.clubs.club : this.state.club;
        this.setState({
            badge           : {},
            imgPreview      : !club.image_path ? '' : club.image_path,
            fileName        : !club.image_path || get_img_name_from_url( club.image_path ) === 'null' ? __x( `Upload Photo` ) : club.image_path,
            badgeError      : '',
            imgUploadHeader : club.image_path && club.image_path !== '' && get_img_name_from_url( club.image_path ) !== 'null' ? __x( `Replace Team Badge` ) : __x( `Upload Team Badge` ),
            imgUploadCancel : club.image_path ? __x( `No, Cancel` ) : __x( `Cancel` )
        });
    }

    leagueChangeConfirmtion = ( event ) => {
        if ( this.current_user_role === 'Agent' ) {
            return false;
        }

        const changedLeagueId   = event.target.value;
        const from_league_name  = get_league_name_by_id( this.state.club.master_league_id, this.props.clubs.leagues );
        const to_league_name    = get_league_name_by_id( changedLeagueId, this.props.clubs.leagues );
        const open_league_change_confirmation = this.state.open_league_change_confirmation;
        this.setState({
            changedLeagueId,
            from_league_name,
            to_league_name,
            open_league_change_confirmation: !open_league_change_confirmation
        }, () => {
            if ( this.state.open_league_change_confirmation ) {
                document.body.classList.add( 'modal-open' );
            } else {
                document.body.classList.remove( 'modal-open' );
            }
        });
    }

    onConfirmLeagueChange = () => {
        this.setState({
            is_league_changes_confirmed: true
        }, this.updateClubLeague );
    }
    
    closeLeagueConfirmationPopup = () => {
        this.setState({
            open_league_change_confirmation : false,
            is_league_changes_confirmed     : false
        }, () => {
            if ( this.state.open_league_change_confirmation ) {
                document.body.classList.add( 'modal-open' );
            } else {
                document.body.classList.remove( 'modal-open' );
            }
        });
    }

    updateClubLeague = ( event ) => {
        if ( !this.state.is_league_changes_confirmed ) {
            return false;
        }

        const league_id = this.state.changedLeagueId;
        
        if ( !league_id || league_id === '' ) {
            return false;
        }

        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        this.setState({
            isProcessing: true
        });
        
        const update_path = APIRoutes.update_league;
        
        let new_state   = {},
        formData        = new FormData();
        formData.append( 'club_id', this._club_id );
        formData.append( 'master_league_id', league_id );
        
        /**
         * Sending request to add/update clubs.
         */
        const request = new Request( update_path, formData, 'PUT', headers, true ).send();
        request.then( response => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message
            };
            
            if ( response.success ) {
                this.closeLeagueConfirmationPopup();
                this.getClub();
            }
            
            this.setState( new_state );
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMsg     : error.message
            };
            this.setState( new_state );
        });
    }

    /**
     * go back on lock edit upload badge screen
     */
    goBack = () => {
        document.body.classList.remove( 'modal-open' );
        this.props.history.go(-1);
    }

    render = () => {
        const club  = this.state.club,
        bday        = is_valid_db_date(club.birthday) ? get_display_format_date( club.birthday ) : '',
        leagues     = this.props.clubs.leagues,
        responseMsg = this.state.responseMsg !== '' && this.state.isSubmitted ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} floating="true" onClose={this.resetAlert} /> : '',
        loading     = this.state.isProcessing ? <LoadingSpinner/> : '';
        
        const league_disabled   = this.current_user_role === 'Agent',
        email_phone_restrict    = this.current_user_role !== 'Admin' && club.is_restricted && club.email === '' && club.phone === '';

        return (
            <div>
                {loading}
                { responseMsg }
                <div id="drawerOpen" className="d-flex align-items-stretch">
                    <div className="drawerInner">
                        <div className="dw-user-block border-top-0 border-bottom-0 auto-height">
                            {
                                this.current_user_role === 'Agent' ? '' : 
                                <UploadPics 
                                    ref={ this._uploadComponent }
                                    badgeUploaded={ this.state.badgeUploaded }
                                    onChange={ this.handleBadgeChange }
                                    onSave={ this.handleUplodadBadge }
                                    imgPreview={ this.state.imgPreview }
                                    file={ this._file }
                                    resetBadgeUploaded={ this.resetBadgeUploaded }
                                    onClose={ this.onCloseImgUpload }
                                    error={ this.state.badgeError }
                                    fileName={ this.state.fileName }
                                    required={true}
                                    title={this.state.imgUploadHeader}
                                    cacnelText={ this.state.imgUploadCancel }
                                    badge={ this.state.badge }
                                    identifier={'club'}
                                    club_id = { this._club_id }
                                    goBack = { this.goBack }
                                />
                            }
                            <div className="player-img-wrap">
                              <img src={ club.image_path && club.image_path !=='' ? club.image_path : require('../../assets/images/dummy-image.png') } className="marginT14" alt="" />
                            </div>
                        </div>
                        <div className="dw-user-detail border-bottom-0">
                            <h5>{ club.club_name }</h5>
                            <div className="dw-select-block">
                                <div className="form-group">
                                    <label htmlFor="">League</label>
                                    <select disabled={league_disabled} onChange={ this.leagueChangeConfirmtion } name="master_league_id" className="form-control" value={club.master_league_id}>
                                        {/* <option value="">League</option> */}
                                        { leagues && leagues.length > 0 ?
                                            leagues.map( league => {
                                                return <option key={ league.id } value={ league.id }>{ league.title }</option>
                                            })
                                            : ''
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="dw-user-contact-detail border-top-0 marginT0">
                                
                                <div className="dw-user-heading marginB20">Main Contact Details 
                                    {
                                        this.current_user_role === 'Agent' ? '' : 
                                        <button onClick={ this.handleUpdateClubModal } to="" className="transparent-btn float-right" data-toggle="modal" data-target="#addContactModal">
                                            <i className="material-icons text-offWhite font-size20 align-middle">edit</i>
                                        </button>
                                    }
                                </div>
                                <div className="user-label">Title in Club:</div>
                                <div className="label-content">{club.club_title}</div>
                                <div className="user-label">Name:</div>
                                <div className="label-content">{club.first_name} {club.last_name}</div>
                                <div className="user-label">Phone:</div>
                                { email_phone_restrict ? <div className="label-content text-danger">Restricted, please contact the administrator.</div> : <div className="label-content">{ club.phone ? `+` + club.country_code + club.phone : ''} </div>}
                                <div className="user-label">Email:</div>
                                { email_phone_restrict ? <div className="label-content text-danger">Restricted, please contact the administrator.</div> : <div className="label-content break-all">{club.email}</div> }
                                <div className="user-label">Birthday:</div>
                                <div className="label-content">
                                    { bday } 
                                    { club.show_birthday_notification ? <i className="align-bottom material-icons text-success">notifications</i> : '' }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <UpdateClub
                    getClub                 = { this.getClub }
                    club_id                 = { this._club_id }
                    modal                   = { this.state.updateClubModal }
                    handleUpdateClubModal   = { this.handleUpdateClubModal }
                />
                <ConfirmLeagueChange 
                    modal={ this.state.open_league_change_confirmation }
                    onClose={ this.closeLeagueConfirmationPopup }
                    onConfirm={ this.onConfirmLeagueChange }
                    from_league_name={ this.state.from_league_name }
                    to_league_name={ this.state.to_league_name }
                />
            </div>
        );
    }

    /**
     * This function is being called for all the listings in club details page.
     */
    getClub = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( this._isFetching ) {
            return false;
        }

        this._isFetching = true;
        const path = APIRoutes.club_detail + this._club_id;
        const request = new Request( path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                this._isFetched.club = true;
                const club = response.data.length > 0 ? response.data[0] : response.data;
                /**
                 * Dispatching action to set club details to redux store.
                 */
                this.props.setClub( club );
                this._isFetching = false;
                this.setState({
                    imgPreview      : club ? club.image_path : this.state.imgPreview,
                    imgUploadHeader : club.image_path && club.image_path !== '' && get_img_name_from_url( club.image_path ) !== 'null' ? __x( `Replace Team Badge` ) : __x( `Upload Team Badge` ),
                    imgUploadCancel : club.image_path ? __x( `No, Cancel` ) : __x( `Cancel` )
                });
            }
        }, error => {
            console.log( error );
        });
    }

    getLeagues = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const request = new Request( APIRoutes.league_list, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                this.props.setLeagues( response.data );
            }
        }, error => {
            console.log( error );
        });
    }

    componentDidMount = () => {
        document.title = this._page_title;
        if ( !this.props.clubs.leagues || this.props.clubs.leagues.length <= 0 ) {
            this.getLeagues();
        }

        if ( !this._isFetched.club ) {
            this.getClub();
        }
    }
}

const mapStateToProps = ( state ) => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setLeagues  : ( leagues ) => dispatch( setLeagues( leagues ) ),
    setClub     : ( club ) => dispatch( setClub( club ) )
});

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( ClubRightSidebar ) );