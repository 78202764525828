import React from 'react';
import { NavLink  } from 'react-router-dom';
import { get_current_user_role } from '../helpers/GeneralHelper';
import *  as Routes from '../helpers/InternalRoutes';

const Nav = () => {
    let current_user_role = get_current_user_role();
    /**
     * Checks if current user is not admin.
     */
    const is_not_admin = current_user_role !== 'Admin';
    
    const dashboard_route_role = current_user_role ? current_user_role.toLowerCase() : '';
    current_user_role = current_user_role === 'Agent' ? 'agent' : 'admin';
    return (
        <ul className="navbar-nav mr-auto usm-navbar">
            <li className="nav-item">
                <NavLink className="nav-link text-white" activeClassName="active" isActive={ ( match, location ) => isMatched( match, location, '' ) } to={`/${dashboard_route_role}/dashboard`}>Dashboard</NavLink>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link text-white" activeClassName="active" isActive={ ( match, location ) => isMatched( match, location, 'PlayerRoutes' ) } to={`/${current_user_role}/players`}>Players</NavLink>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link text-white" activeClassName="active" isActive={ ( match, location ) => isMatched( match, location, 'ClubsRoutes' ) } to={`/clubs`}>Clubs</NavLink>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link text-white" activeClassName="active" isActive={ ( match, location ) => isMatched( match, location, 'CasesRoutes' ) } to={`/${current_user_role}/cases`}>Cases</NavLink>
            </li>
            { is_not_admin ? '' : 
                <li className="nav-item">
                    <NavLink className="nav-link text-white" activeClassName="active" isActive={ ( match, location ) => isMatched( match, location, 'AgentsRoutes' ) } to={`/${current_user_role}/agents`}>Agents</NavLink>
                </li>
            }
            { is_not_admin ? '' :
                <li className="nav-item">
                    <NavLink className="nav-link text-white" activeClassName="active" isActive={ ( match, location ) => isMatched( match, location, '' ) } to={`/${current_user_role}/users`}>Users</NavLink>
                </li>
            }
            {
                current_user_role === 'agent' ? '' : 
                <li className="nav-item min-width160">
                    <NavLink className="nav-link text-white" activeClassName="active" isActive={ ( match, location ) => isMatched( match, location, 'OrganisationsRoutes' ) } to={`/organisations`}>Organisations & People</NavLink>
                </li>
            }
            {
                get_current_user_role() === 'Admin' ? 
                <li className="nav-item min-width155">
                    <NavLink className="nav-link text-white" activeClassName="active" isActive={ ( match, location ) => isMatched( match, location, '' ) } to={`/admin/notes`}>Notes</NavLink>
                </li>
                :
                ''
            }
        </ul>
    );
}

export default Nav;

/**
 * Checks if current route is matched with navlink pathname and internal routes as well.
 * @param {*} match     // Returns route details if matched with current route.
 */
const isMatched = ( match, location, path ) => {
    if ( match ) {
        return true;
    }
    
    if ( !path || path === '' ) {
        return false;
    }

    let role = get_current_user_role();
    if ( role && role !== '' ) {
        role = role.toLowerCase();
    }

    const current_path = location.pathname;
    let path_segments = current_path.split( '/' );
    if ( path_segments.length > 0 ) {
        path_segments = path_segments.filter( segment => segment && isNaN( segment ) && segment !== role );
    }

    if ( !path_segments || path_segments.length <= 0 ) {
        return false;
    }

    const path_without_params = '/' + path_segments.join( '/' );
    if ( Routes[ path ].indexOf( path_without_params ) >= 0 ) {
        return true;
    }

    return false;
}