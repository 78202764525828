import React from 'react';

const PlayerGrid = ({ player }) => {
    if (!player || Object.keys(player).length <= 0) {
        return false;
    }

    return (
        <div className="col-md-3 col-xs-12">
            <div className="card">
                <div className="card-body">
                    <div className="cardImg-section">
                        <div className="card-image paddingT5">
                            {player.profile_image && player.profile_image !== null ? <img className="club" src={player.profile_image} alt="Card cap" /> : ''}
                        </div>

                        <div className="cardTitle-section">
                            <div className="card-title">{player.name && player.name !== null ? player.name : ''}</div>
                            <div className="card-subtitle">{player.position && player.position !== null ? player.position : ''}</div>
                        </div>
                    </div>
                    <div className="cardContent-section">
                        <div className="d-flex align-items-center justify-content-center marginB20 marginT10 text-white">
                            <span className="marginR5 circular-img">
                                {
                                    player.club_image && player.club_image !== null ? 
                                    <img className="club" src={player.club_image} alt="Club" />
                                    :
                                    <img className="club" src={ require( '../../../assets/images/add-clubs.png' ) } alt="Club" />
                                }
                            </span>
                            <span> {player.club && player.club !== null ? player.club : ''}</span>
                        </div>
                    </div>
                    <div className="card-footer text-center">
                        <a href={`/players-details/${player.id}`} target="_blank" rel="noopener noreferrer" className="btn btn-usmPrimary">View</a>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PlayerGrid;