import React from 'react';
import { get_display_format_date } from '../../../helpers/GeneralHelper';


const PlayerNotesConciergeViewBox = props => {
    let player_note = props.playerNote;
    let attachments = [];
    if ( player_note.player_note_files && player_note.player_note_files.length > 0 ) {
        for ( let i in player_note.player_note_files ) {
            attachments.push(<div key={i} className="text-success font-size14 marginB10 word-break-all">{player_note.player_note_files[i].original_filename ? player_note.player_note_files[i].original_filename : ''}</div>)
        }
    } else if( player_note.player_concierge_files && player_note.player_concierge_files.length > 0 ) {
        for ( let i in player_note.player_concierge_files ) {
            attachments.push(<div key={i} className="text-success font-size14 marginB10 word-break-all">{player_note.player_concierge_files[i].original_filename ? player_note.player_concierge_files[i].original_filename : ''}</div>)
        }
    }

    const id = `popover-content-toggle-one-${player_note.id}`;
    return (
        <div id={id} className="dropdown-menu purpleBg-dropdown note-popup-drodown purpleBg-right dw-note-detail-hover-block" aria-labelledby="dropdownMenuNote">
            <div className="notes-block">
                <ul className="list-unstyled">
                    <li className="media padding0 border-bottom-0">
                        <div className="media-body clearfix">
                            <div className="note-add-modify">
                                <div className="row marginB15">
                                    <div className="col-md-3 col-sm-12">{ player_note.note ? 'Note' : 'Concierge' }:</div>
                                    <div className="col-md-9 col-sm-12 fontBold word-break">{ player_note.note ? player_note.note : ( player_note.detail ? player_note.detail : '' ) }</div>
                                </div>

                                <div className="row marginB15">
                                    <div className="col-md-3 col-sm-12">Added by:</div>
                                    <div className="col-md-9 col-sm-12 fontBold">{player_note.added_by}</div>
                                </div>
                                <div className="row marginB15">
                                    <div className="col-md-3 col-sm-12">Last modified:</div>
                                    <div className="col-md-9 col-sm-12 fontBold">{get_display_format_date(player_note.updated_at)}</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-12">Attachments: </div>
                                    <div className="col-md-9 col-sm-12">
                                        {attachments}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default PlayerNotesConciergeViewBox;

