import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import Request from '../../helpers/Request';
import { set_user_data, get_user_data, delete_user_data, get_user_dashboard_url, __x } from '../../helpers/GeneralHelper';
import Alert from '../../helpers/Alert';
import APIRoutes from '../../API/Routes';

export default class TermsNConditions extends Component {

    _page_title     = "Terms and conditions - USM";

    constructor( props ) {
        super( props );
        
        this.state = {
            fields  : {
                tandc   : false
            },
            isValid : {
                tandc   : false
            },
            isUntouched : {
                tandc   : true
            },
            tncContent  : '',
            loggedIn    : false,
            tncErrorMsg : '',
            isSubmitted : false,
            isSuccess   : false
        };
        
        this.handleSubmit   = this.handleSubmit.bind( this );
        this.handleChange   = this.handleChange.bind( this );
        this.isFormValid    = this.isFormValid.bind( this );
        this.resetAlert     = this.resetAlert.bind( this );
    }
    
    /**
     * Handles fomr submission
     * @param {*} event 
     */
    handleSubmit( event ) {
        if( !this.isFormValid() ) {
            return;
        }
        
        const resetPassUrl  = APIRoutes.tnc;
        const fields        = {
            tnc_accepted: this.state.fields.tandc
        };
        
        let headers         = {
            "Authorization": get_user_data( 'temp_token' )
        }
        
        const response      = new Request( resetPassUrl, fields, "POST", headers ).send();        
        response.then( result => {
            if( result.success ) {

                /**
                 * Set user information in local storage
                 */
                set_user_data( 'token', get_user_data( 'temp_token' ) );
                set_user_data( 'user', get_user_data( 'temp_user' ) );

                delete_user_data( 'temp_token' );
                delete_user_data( 'temp_user' );

                this.setState({
                    loggedIn: true
                }, () => {
                    this.props.history.push( get_user_dashboard_url() );
                });
            } else {
                this.setState({
                    tncErrorMsg: result.message
                });
            }
        }, error => {
            this.setState({
                isSubmitted : true,
                isSuccess   : error.success,
                tncErrorMsg : error.message
            });
        });
    }

    /**
     * Handles the input fields changes
     * @param {*} event 
     */
    handleChange( event ) {
        const target    = event.target,
        value           = target.checked,
        id              = target.id;
        
        let fields      = this.state.fields,
        isValid         = this.state.isValid,
        isUntouched     = this.state.isUntouched;
        
        /**
         * Set fields values
         */
        fields[ id ]        = value;

        /**
         * Set touched fields
         */
        isUntouched[ id ]   = false;

        this.setState({
            fields,
            isValid,
            isUntouched
        });
    }

    /**
     * Checks if form is valid
     */
    isFormValid = () => {
        return this.state.fields.tandc;
    }

    /**
     * Crates jsx markup from html markups
     */
    createMarkup = ( text ) => { 
        return {
            __html: text
        };
    };

    /**
     * Fetchs tnc content
     */
    componentDidMount = () => {
        document.title = this._page_title;
        const tncPath  = APIRoutes.tnc;
        
        let headers    = {
            "Authorization": get_user_data( 'temp_token' )
        }

        const request = new Request( tncPath, {}, "GET", headers ).send();

        request.then( result => {
                this.setState({ 
                    tncContent: result.data
                })
            },
            resposneError => {
                console.log( resposneError );
            },
        );
    }

    /**
     * Handles cancel event and clear all fields
     */
    onCancel = () => {
        delete_user_data( 'token' );
        delete_user_data( 'user' );
        this.props.history.push( '/login' );
    }

    resetAlert = () => {
        this.setState({
            tncErrorMsg: ''
        });
    }

    render() {
        if ( this.state.loggedIn ) {
            return <Redirect to={get_user_dashboard_url()} />
        }

        const getStartedDisabled = !this.isFormValid();
        const tncContent    = this.state.tncContent,
        tncErrorMsg         = ( this.state.isSubmitted && !this.state.isSuccess ) ? <Alert message={this.state.tncErrorMsg} success={this.state.isSuccess} onClose={this.resetAlert} /> : '';
        return (
            <div>
                { !tncContent && tncContent === '' ? '' : 
            <div className="modal-body usm-modal-body ResetPassword">
                { tncErrorMsg }
                
                <h2>Terms & Conditions</h2>

                <div dangerouslySetInnerHTML={ this.createMarkup( tncContent ) } ></div> 

                <div className="row justify-content-md-center usm-form marginT25">
                    <div className="usm-check">
                        <input type="checkbox" id="tandc" onChange={ event => this.handleChange( event ) } value={ this.state.fields.tandc } />
                        <label htmlFor="tandc">{ __x( `I have read and agree to the Terms & Conditions.` ) }</label>
                    </div>							
                </div>

                <div className="row justify-content-md-center">							
                    <button className="btn btn-danger" onClick={ event => this.onCancel( event ) }>Cancel</button> 
                    <button className="btn btn-success" onClick={ event => this.handleSubmit( event ) } disabled={getStartedDisabled}>Get started</button>
                </div>
            </div>
                }
            </div>
        );
    }
}
