/**
 * @Note: Please don't add the parent root itself, that's unnecessary.
 */

/**
 * All the internal paths for clubs without params and roles.
 */
export const ClubsRoutes = [
    '/clubs',
    '/clubs-details'
];

/**
 * All the internal paths for players without params and roles.
 */
export const PlayerRoutes = [
    '/add-player',
    '/players-details',
    '/player-cases',
    '/player-contacts',
    '/player-highlight-list',
    '/player-videos-and-reports',
    '/player-notes-and-concierge'
];

/**
 * All the internal paths for cases without params and roles.
 */
export const CasesRoutes = [
    '/add-club-case',
    '/edit-club-case',
    '/club-case',
    '/add-player-case',
    '/edit-player-case',
    '/view-player-case'
];

/**
 * All the internal links for agents without params and roles.
 */
export const AgentsRoutes = [
    '/agent-details'
];

/**
 * All the internal paths for organisations without params and roles.
 */
export const OrganisationsRoutes = [
    '/organisations-details'
];
