export const addChangeLogs = ( changelogs, old_logs = [], update = false ) => dispatch => {
    if ( !update ) {
        changelogs = [
            ...old_logs,
            ...changelogs
        ];
    }
    
    dispatch({
        type: 'ADD_CHANGELOGS',
        payload: {
            changelogs
        }
    });
}