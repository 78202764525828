import React from 'react';
import { Link } from 'react-router-dom';
import AgentListCaseRollOver from '../AgentListCaseRollOver';
import { __x } from '../../../helpers/GeneralHelper';

const Agent = ({ agents, is_fetched, ...rest }) => {
    let usersList = [];
    
    if ( !agents || !agents.length || agents === 'undefined' ) {
        if ( is_fetched ) {
            usersList.push(
                <tr className="text-center" key={1}>
                    <td colSpan="9">{ __x( `No record found.` ) }</td>
                </tr>
            );
        }
        return usersList;
    }

    for ( let i in agents ) {
        let id      = agents[i].id;
        let status  = agents[i].status;
        const status_classes = status ? 'btn-success' : 'btn-danger';
        usersList.push(
            <tr key={id}>
                <td className="fontBold">{ agents[i].emp_id }</td>
                <td>{ agents[i].first_name }</td>
                <td>{ agents[i].last_name }</td>
                <td>{agents[i].email}</td>
                <td>+{ agents[i].country_code }{ agents[i].phone }</td>
                <td><button type="button" className="table-btn btn-transWhite position-relative show-rollover">{agents[i].case_assign ? agents[i].case_assign.length : 0}
                    { agents[i].case_assign && agents[i].case_assign.length > 0 ? <AgentListCaseRollOver activeCases = {agents[i].case_assign} /> : '' }
                    </button>
                </td>
                <td><button type="button" className="table-btn btn-transWhite">{ agents[i].contract_signed ? agents[i].contract_signed.length : 0 }</button></td>
                <td><button className={`table-btn ${status_classes}`}>{ status ? 'Active' : 'Deactivated' }</button></td>
                <td><Link to={`/admin/agent-details/${id}`}><i className="material-icons text-offWhite font-size20">edit</i></Link></td>
            </tr>
        );
    }

    return usersList;
}
export default Agent;