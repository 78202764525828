import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import { connect } from 'react-redux';
import { get_current_user_role, get_authorization_token, __x } from '../../../helpers/GeneralHelper';
import { setHighlights, setHistory } from '../../../actions/PlayersAction';
import UpdateCareerHighlights from './UpdateCareerHighlights';
import CareerHistory from './CareerHistory';

class HighlightList extends Component {

    _page_title = 'Career Highlights - USM';

    constructor( props ) {
        super( props );

        this._player_id = props.match.params.player_id;
        this.current_user_role = get_current_user_role();

        this._fetched = {
            history     : false,
            highlights  : false
        };

        this.state = {
            popupOpen   : false,
            highlights  : [],
            history     : []
        }
    }

    componentWillReceiveProps = props => {
        this.setState({
            history     : props.players.history,
            highlights  : props.players.highlights
        });
    }

    togglePoppup = () => {
        this.setState({
            popupOpen: !this.state.popupOpen
        }, () => {
            if ( this.state.popupOpen ) {
                document.body.classList.add( 'modal-open' );
            } else {
                document.body.classList.remove( 'modal-open' );
            }
        });
    }

    getHeighlights = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const highlight_path = APIRoutes.player_hightlight + this._player_id;
        const request = new Request( highlight_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                this.props.setHighlights( response.data );
                this._fetched.highlights = true;
            }
        }, error => {
            console.log( error );
        });
    } 
    
    getCareerHistory = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const hisgtory_path = APIRoutes.players_history + this._player_id;
        const request = new Request( hisgtory_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                this.props.setHistory( response.data );
                this._fetched.history = true;
            }
        }, error => {
            console.log( error );
        });
    }
    
    componentDidMount = () => {
        document.title = this._page_title;

        if ( !this._fetched.highlights ) {
            this.getHeighlights();
        }

        if ( !this._fetched.history ) {
            this.getCareerHistory();
        }
    }

    highlightList = () => {
        const highlights = this.state.highlights;
        return highlights && highlights.length > 0 ? highlights.map( highlight => {
            return (
                <li className="career-ul d-flex" key={ highlight.id }>
                    <div className="paddingL10 paddingR10">
                        <img src={ require( "../../../assets/images/career-heighlight-white.png" ) } alt="" />
                    </div>
                    <div className="paddingL10">
                        { highlight.highlight }
                    </div>                 
                </li>
            );
        }) : [];
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="player-detail-block">
                            <div className="player-detail-heading d-flex align-items-center">
                                Career Highlights
                                {
                                    this.current_user_role === 'Agent' ? '' :
                                    <button type="button" onClick={this.togglePoppup} className="transparent-btn float-right ml-auto" data-toggle="modal" data-target="#careerModal" >
                                        <img src={ require( "../../../assets/images/edit-icon.png" ) } alt="" />
                                    </button>
                                }
                            </div>
                            <div className="player-detail-content">
                                <ul className="list-unstyled">
                                    { this.highlightList() }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="player-detail-block">
                            <div className="player-detail-heading d-flex align-items-center">Career History</div>
                            <CareerHistory 
                                player_id={ this._player_id }
                                history={ this.state.history }
                            />
                        </div>
                    </div>
                </div>

                {/* Add career Modal bg overlay */}
                <UpdateCareerHighlights 
                    player_id={ this._player_id }
                    popupOpen={ this.state.popupOpen }
                    togglePoppup={ this.togglePoppup }
                    highlights={ this.state.highlights }
                    refresh={ this.getHeighlights }
                />
                {!this.state.popupOpen || <div className="modal-backdrop fade show"></div>}                
            </div>
        );
    }

}

const mapStateToProps = ( state ) => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setHighlights: highlights => dispatch( setHighlights( highlights ) ),
    setHistory: history => dispatch( setHistory( history ) )
});

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( HighlightList ) );