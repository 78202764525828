import React from 'react';
import Alert from '../../helpers/Alert';
import IntlTelInput from 'react-intl-tel-input';
import '../../../node_modules/react-intl-tel-input/dist/main.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Request from '../../helpers/Request';
import { is_valid_db_date, get_authorization_token, get_current_user_role, is_bday_valid, __x, get_db_date } from '../../helpers/GeneralHelper';
import * as EmailValidator from 'email-validator';
import APIRoutes from '../../API/Routes';
import { setLeagues, setClub } from '../../actions/ClubsAction';
import { connect } from 'react-redux';
import NotesHistory from './club/NotesHistory';
import { withRouter } from 'react-router-dom';

class UpdateClub extends React.Component {

    constructor( props ) {
        super( props );

        this._country   = React.createRef();
        this._club_id   = props.club_id;

        this.state = {
            addClubModal    : false,
            clubFetched     : false,
            isFetching      : false, 
            club            : {},
            is_locked       : true,
            fields          : {
                club_name   : '', 
                club_title  : '', 
                first_name  : '',
                last_name     : '',
                email       : '',
                country_code: '44', 
                phone       : '', 
                birthday    : null,
                club_note   : '',
                show_birthday_notification : false,
                is_restricted : false
            },
            isValid : {
                club_name   : true,
                club_title  : true,
                first_name  : true,
                last_name    : true,
                email       : true,
                country_code: true,
                phone       : true,
                birthday    : true,
                club_note   : true
            },
            fieldsValidityError: {
                club_name   : '', 
                club_title  : '', 
                first_name  : '',
                last_name    : '',
                email       : '',
                country_code: '', 
                phone       : '', 
                birthday    : '',
                club_note   : ''
            },
            country_iso     : 'gb',
            fileName        : 'Add Photo',
            imgPreview      : '',
            isProcessing    : false,
            isSubmitted     : false,
            isSuccess       : false,        // Used for api success/failure.
            isError         : false,        // Used for only local validation errors to show.
            responseMessage : '',
            tokenExpired    : false,
            lockscreen      : false,        // Used to lock screen if same club is being edited by another user
            lockErrorMsg    : '',
        }
    }

    componentWillReceiveProps = props => {
        if ( props.modal ) {
            this.getClub();
            return;
        }

        /* const club = this.props.clubs.club;
        let fields = {
            ...this.state.fields,
            club_name   : club ? club.club_name : '', 
            club_title  : club ? club.club_title : '', 
            first_name  : club ? club.first_name : '',
            last_name   : club ? club.last_name : '',
            email       : club ? club.email : '',
            country_code: club.country_code ? club.country_code : '44', 
            phone       : club ? club.phone : '', 
            birthday    : club && is_valid_db_date(club.birthday) ? new Date( club.birthday ) : null,
            // club_note   : club ? club.club_note : '',
            club_note   : '',
            show_birthday_notification : club ? club.show_birthday_notification : false,
            is_restricted : club ? club.is_restricted : false
        };

        this.setState({
            fields
        }); */
    }

    goBack = () => {
        document.body.classList.remove( 'modal-open' );
        this.props.history.go(-1);
    }

    render = () => {
        const club          = this.props.clubs.club,
        modalActiveClass    = this.props.modal ? 'show' : '',
        /**
         * Check if the Email, Phone and Restrict personal contact details field will not be shown.
         */
        is_restricted       = get_current_user_role() !== 'Admin' && club.is_restricted,
        disableSubmit       = !this.isFormValid() || this.state.isProcessing ? true : false,
        responseMsg         = this.state.responseMessage !== '' && this.state.isSubmitted ? <Alert message={this.state.responseMessage} success={this.state.isSuccess} floating="true" onClose={this.resetAlert} /> : '',
        lockErrorMsg        = this.state.lockErrorMsg ? <Alert message={this.state.lockErrorMsg} success={this.state.isSuccess} floating="true" goBack={this.goBack} isNotFlash /> : '',
        lockscreen          = this.state.lockscreen ? 'modal-backdrop fade show' : 'modal form-modal fade modal-active';
        // not_editable        = !this.state.is_locked && this.props.modal ? <Alert message="This club is being edited by some other user." success={false} floating="true" /> : '';
        const today = new Date();
        return (
            <div>
                {/* { not_editable } */}
                {responseMsg}
                {lockErrorMsg}
                <div className={`${modalActiveClass} ${lockscreen} `} style={{ transform: this.props.modal ? `translate(0, 0)` : 'translate(-125%, -125%)' }}>
                    <div className="modal-dialog modal-dialog-centered maxWidth600">
                        <div className="modal-content">
                            <div className="modal-header form-modal-header d-flex align-items-center">
                                <img src={ require( '../../assets/images/form-usm-logo.png' ) } alt="" />
                                <h5 className="form-modal-title">Edit Club</h5>
                                <button className="modal-close transparent-btn" data-dismiss="modal" aria-label="Close" onClick={ this.onPopupClose }>
                                    <img src={ require( '../../assets/images/close-modal.png' ) } alt="" />
                                </button>
                            </div>
                            <form action="" className="usm-form form-border" onSubmit={ e => {e.preventDefault()} }>
                                <div className="modal-body form-modal-body">
                                    <div className="row">
                                        <div className="col">
                                            <div className={ this.state.fieldsValidityError.club_name === '' ? `form-group marginB0` : `form-group marginB0 form-error`}>
                                                <label htmlFor="">Club Name</label>
                                                <input onChange={ this.handleFieldsChanges } value={ this.state.fields.club_name } name="club_name" type="text" className="form-control" placeholder="Club Name" autoComplete="off" />
                                                { this.state.fieldsValidityError.club_name === '' || <div className="display-error">{ this.state.fieldsValidityError.club_name }</div> }
                                            </div>
                                        </div>
                                    </div>
    
                                    <div className="row form-transDivider marginT15">
                                        <div className="col">
                                            <div className="form-subheading">Main Contact</div>	
                                        </div>			
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className={ this.state.fieldsValidityError.club_title === '' ? `form-group` : `form-group form-error`}>
                                                <label htmlFor="">Title in Club</label>
                                                <input onChange={ this.handleFieldsChanges } value={ this.state.fields.club_title } name="club_title" className="form-control" autoComplete="off"/>
                                                { this.state.fieldsValidityError.club_title === '' || <div className="display-error">{ this.state.fieldsValidityError.club_title }</div> }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-xs-12">
                                            <div className={ this.state.fieldsValidityError.first_name === '' ? `form-group` : `form-group form-error`}>
                                                <label htmlFor="">First Name</label>
                                                <input onChange={ this.handleFieldsChanges } value={ this.state.fields.first_name } name="first_name" type="text" className="form-control" placeholder="First Name" autoComplete="off" />
                                                { this.state.fieldsValidityError.first_name === '' || <div className="display-error">{ this.state.fieldsValidityError.first_name }</div> }
                                            </div>					
                                        </div>
                                        <div className="col-md-6 col-xs-12">
                                            <div className={ this.state.fieldsValidityError.last_name === '' ? `form-group` : `form-group form-error`}>
                                                <label htmlFor="">Last Name</label>
                                                <input onChange={ this.handleFieldsChanges } value={ this.state.fields.last_name } name="last_name" type="text" className="form-control" placeholder="Last Name" autoComplete="off" />
                                                { this.state.fieldsValidityError.last_name === '' || <div className="display-error">{ this.state.fieldsValidityError.last_name }</div> }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                    { 
                                        is_restricted ? 
                                        <div className="col">
                                            <div className="form-group"> 
                                                <label htmlFor="">Email</label>
                                                <p className="text-danger font-size13">{ __x( `Restricted, please contact the administrator.` ) }</p>
                                            </div>
                                        </div>
                                        :
                                        <div className="col">
                                            <div className={ this.state.fieldsValidityError.email === '' ? `form-group` : `form-group form-error`}>
                                                <label htmlFor="">Email</label>
                                                <input onChange={ this.handleFieldsChanges } value={ this.state.fields.email } name="email" type="text" className="form-control" placeholder="Email" autoComplete="off" />
                                                { this.state.fieldsValidityError.email === '' || <div className="display-error">{ this.state.fieldsValidityError.email }</div> }
                                            </div>
                                        </div>                                       
                                    }
                                     </div>
    

                                    <div className="row">
                                        { is_restricted ? 
                                            <div className="col">
                                                <div className="form-group"> 
                                                    <label htmlFor="">Phone</label>
                                                    <p className="text-danger font-size13">{ __x( `Restricted, please contact the administrator.` ) }</p>
                                                </div>
                                            </div>
                                            :
                                            <div className="col">
                                                <div className="form-group">
                                                    <label htmlFor="">Phone</label>
                                                    <div className={ this.state.fieldsValidityError.phone === '' ? `input-group` : `input-group form-error`}>
                                                        <IntlTelInput 
                                                            onPhoneNumberChange={ this.handlePhoneChange }
                                                            defaultCountry={ 'gb' }
                                                            css={['intl-tel-input w-100', 'form-control']} 
                                                            placeholder="Phone"
                                                            value={ this.state.fields.phone }
                                                            separateDialCode="true"
                                                            onSelectFlag={ this.handleCountryCodeChange }
                                                            ref={ elt => this._country = elt }
                                                        />
                                                    </div>
                                                    { this.state.fieldsValidityError.phone === '' || <div className="display-error">{ this.state.fieldsValidityError.phone }</div> }
                                                </div>
                                            </div>
                                        }
    
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="">Birthday</label>
                                                <div className={ this.state.fieldsValidityError.birthday === '' ? `input-group w-100` : `input-group w-100 form-error`}>
                                                    <DatePicker
                                                        className="form-control"
                                                        selected={ this.state.fields.birthday }
                                                        onChange={ this.handleBirthdayChanges }
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={ new Date( today.getFullYear(), today.getMonth(), today.getDate() - 1 ) }
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        scrollableYearDropdown
                                                        yearDropdownItemNumber={30}
                                                    />
                                                </div>
                                                { this.state.fieldsValidityError.birthday === '' || <div className="display-error">{ this.state.fieldsValidityError.birthday }</div> }
                                            </div>					
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="">Note</label>
                                                <textarea onChange={ this.handleFieldsChanges } name="club_note" className="form-control textarea76" cols="" rows="" value={ this.state.fields.club_note }></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <div className="note-header">
                                                    <label>Contact Notes</label>
                                                </div>
                                                <div className="notes-block">
                                                    <NotesHistory 
                                                        notes={ club.club_notes }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group usm-check">
                                                <input onChange={ this.handleFieldsChanges } value={ this.state.fields.show_birthday_notification } checked={ this.state.fields.show_birthday_notification ? true : false } name="show_birthday_notification" type="checkbox" id="bdayNotification" />
                                                <label htmlFor="bdayNotification">Show Birthday Notification</label>	
                                            </div>
                                        </div>
                                    </div>
                                    { 
                                        is_restricted ? 
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group usm-check">
                                                    <input checked={ true } name="show_birthday_notification" className="" type="checkbox" id="restrictUser" readOnly />
                                                    <label htmlFor="restrictUser" className="text-danger disabled">{ __x( `Restricted, please contact the administrator.` ) }</label>	
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group usm-check">
                                                    <input onChange={ this.handleFieldsChanges } value={ this.state.fields.is_restricted } checked={ this.state.fields.is_restricted ? true : false } name="is_restricted" type="checkbox" id="restrictUser" />
                                                    <label htmlFor="restrictUser">{ __x( `Restrict Personal Contact Details to Admin View Only` ) }</label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="modal-footer form-modal-footer d-flex justify-content-between">
                                    <button type="button" className="default-btn btn-danger min-width157" data-dismiss="modal" onClick={ this.onPopupClose }>Cancel</button>
                                    <button onClick={ this.updateClub } type="button" className="default-btn btn-success min-width157" disabled={disableSubmit}>Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                { !this.props.modal || <div className="modal-backdrop fade show"></div> }
                {/* { !this.state.lockscreen || <div className="modal-backdrop fade show zIndex999"></div> } */}
            </div>
        );
    }

    /**
     * Get current club's details.
     */
    getClub = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( this.state.isFetching ) {
            return false;
        }

        this.setState({
            isFetching: true
        })
        const path = APIRoutes.club + this._club_id;
        const request = new Request( path, {}, 'GET', headers ).send();
        request.then( response => {
            let new_state = {};
            if ( response.success ) {
                const club = response.data.length > 0 ? response.data[0] : response.data;
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                // this.props.setClub( club );

                if ( !club || Object.keys( club ).length <= 0 ) {
                    return false;
                }
        
                /**
                 * Update current club details data to local state to be mapped with input fields.
                 */
                new_state = {
                    isFetching  : false,
                    clubFetched : true,
                    is_locked   : response.lock_status ? response.lock_status : false,
                    fields      : {
                        ...this.state.fields,
                        club_name   : club ? club.club_name : '', 
                        club_title  : club ? club.club_title : '', 
                        first_name  : club ? club.first_name : '',
                        last_name   : club ? club.last_name : '',
                        email       : club ? club.email : '',
                        country_code: club.country_code ? club.country_code : '44', 
                        phone       : club ? club.phone : '', 
                        birthday    : club && is_valid_db_date(club.birthday) ? new Date( club.birthday ) : null,
                        // club_note   : club ? club.club_note : '',
                        club_note   : '',
                        show_birthday_notification : club ? club.show_birthday_notification : false,
                        is_restricted : club ? club.is_restricted : false
                    }
                }
                
                 /**
                 * Set current club details to local state to be mapped with input fields.
                 */
                this.setState( new_state, () => {
                    /**
                     * If Staff and personal contact details for main contact is restricted, 
                     * don't select the country flag as it'd trhow an error due to absence of phone field.
                     */
                    if ( get_current_user_role() === 'Admin' || !club.is_restricted ) {
                        return this._country.setFlag( club.country_iso );
                    }
                });
            }
        }, error => {
            console.log( error );
            this.setState({
                lockErrorMsg : error.message,
                isSuccess : false,
                lockscreen : error.lockscreen ? true : false
            })
        });
    }

    componentDidMount = () => {
        if ( !this.state.clubFetched ) {
            // this.getClub();
        }
    }

    updateClub = ( event ) => {
        event.preventDefault();
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( !this.isFormValid() ) {
            return false;
        }

        this.setState({
            isProcessing: true
        });

        const club_path = APIRoutes.club + this._club_id;

        const fields    =  this.state.fields;
        let new_state   = {},
        formData        = new FormData();
        const email_phone_restrict = get_current_user_role() !== 'Admin' && fields.is_restricted && fields.email === '' && fields.phone === '';
        for ( let i in fields ) {
            /**
             * Exlude email and phone if is_restricted is checked and curent user is staff.
             */
            if ( email_phone_restrict && [ 'email', 'phone' ].indexOf( i ) >= 0 ) 
                continue;

            if ( i === 'birthday' ) {
                if ( fields[i] !== '' && fields[i] instanceof Date ) {
                    formData.append(i, get_db_date(fields[i]));
                    // formData.append( i, `${fields[i].getDate()}/${fields[i].getMonth() + 1}/${fields[i].getFullYear()}` );
                } else {
                    formData.append( i, `` );
                }

                continue;
            }
            
            formData.append( i, fields[i] );
        }        
        formData.append( 'country_iso', this.state.country_iso );
        
        /**
         * Sending request to add/update clubs.
         */
        const request = new Request( club_path, formData, 'PUT', headers, true ).send();
        request.then( response => {
            if ( response.success ) {
                this.onPopupClose( true );
            }
            
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMessage : response.message
            }
            this.setState( new_state );
            this.props.getClub();
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMessage : error.message
            };
            this.setState( new_state );
        });

    }

    handleFieldsChanges = ( event ) => {
        const target    = event.target,
        elt_name        = target.name,
        elt_value       = target.type === 'checkbox' ? target.checked : target.value,
        fields          = this.state.fields;

        let isValid         = this.state.isValid,
        fieldsValidityError = this.state.fieldsValidityError;
        
        if ( [ 'club_name', 'club_title', 'first_name', 'last_name' ].indexOf( elt_name ) >= 0 ) {
            const label = elt_name.replace( '_', ' ' );

            fields[ elt_name ]              = elt_value;
            isValid[ elt_name ]             = fields[ elt_name ] !== '' ? fields[ elt_name ].length >= 1 && fields[ elt_name ].length <= 255  : true;
            fieldsValidityError[ elt_name ] = !isValid[ elt_name ] ? __x( `{{label}} is invalid.`, `{{label}}`, label ) : '';
        } else if ( elt_name === 'email' ) {
            fields[ elt_name ]              = elt_value;
            isValid[ elt_name ]             = fields[ elt_name ] !== '' ? fields[ elt_name ].length >= 1 && fields[ elt_name ].length <= 255 && EmailValidator.validate( fields[ elt_name ] ) : true;
            fieldsValidityError[ elt_name ] = !isValid[ elt_name ]  ? __x( `Please enter a valid email.` )  : '';
        } else {
            fields[ elt_name ] = elt_value;
        }
        
        this.setState({
            fields,
            isValid,
            fieldsValidityError
        });
    }

    /**
     * Check if form is valid or not to submit
     */
    isFormValid = () => {
        let validityErr = [];
        const fields    = this.state.fields,
        isValid         = this.state.isValid;
        const email_phone_restrict = get_current_user_role() !== 'Admin' && fields.is_restricted && fields.email === '' && fields.phone === '';
        
        for ( let elt in fields ) {
            if ( [ 'is_restricted', 'show_birthday_notification', 'club_note' ].indexOf( elt ) >= 0 ) {
                continue;
            }

            if ( email_phone_restrict && [ 'email', 'phone' ].indexOf( elt ) >= 0 ) {
                continue;
            }

            if ( elt === 'birthday' || elt === 'phone' || elt === 'email' ) {
                if ( isValid[ elt ] || fields[ elt ] === '' ) {
                    continue;
                }
            }
            
            if ( fields[ elt ] === '' || !isValid[ elt ] ) {
                validityErr.push( elt );
            }
        }
        return validityErr.length <= 0;
    }

     /**
     * Handles country code changes.
     */
    handleCountryCodeChange = ( value, countryData ) => {
        const fields    = this.state.fields,
        isValid         = this.state.isValid,
        country_code    = countryData? countryData.dialCode : '44';
        
        fields[ 'country_code' ]    = country_code;
        isValid[ 'country_code' ]   = fields.country_code !== '' ? fields.country_code.toString().length >= 0 && fields.country_code.toString().length <= 255 : true;
        
        this.setState({
            fields,
            isValid,
            country_iso: countryData.iso2
        });
    }

    /**
     * Handles phone changes.
     */
    handlePhoneChange = ( status, value ) => {
        const fields        = this.state.fields,
        isValid             = this.state.isValid,
        fieldsValidityError = this.state.fieldsValidityError,
        phone               = value ? value : '';
        
        fields[ 'phone' ]   = phone;
        isValid[ 'phone' ]  = fields.phone !== '' ? fields.phone.length >= 0 && fields.phone.length <= 255 && !isNaN( fields.phone ) : true;
        fieldsValidityError[ 'phone' ] = !isValid.phone ? __x( `Phone is invalid.` ) : '';
        
        this.setState({
            fields,
            isValid,
            fieldsValidityError
        });
    }

    /**
     * Handles birthday fields changes.
     */
    handleBirthdayChanges = ( date ) => {
        const fields        = this.state.fields,
        isValid             = this.state.isValid,
        fieldsValidityError = this.state.fieldsValidityError;

        fields[ 'birthday' ]                = date;
        isValid[ 'birthday' ]               = date === '' || date === null || !date ? true : is_bday_valid( date );
        fieldsValidityError[ 'birthday' ]   = !isValid[ 'birthday' ] ? __x( `Birthday is invalid.` ) : '';
        
        this.setState({
            fields,
            isValid,
            fieldsValidityError
        });
    }
    
    onPopupClose = () => {
        this.props.getClub();
        this.props.handleUpdateClubModal( true );
    }

    resetAlert = () => {
        this.setState({
            isSubmitted     : false,
            responseMessage : ''
        });
    }
}
const mapStateToProps = ( state ) => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setLeagues  : ( leagues ) => dispatch( setLeagues( leagues ) ),
    setClub     : ( club ) => dispatch( setClub( club ) )
});

export default withRouter(connect( mapStateToProps, mapDispatchToProps )( UpdateClub ));