import React from 'react';
import { get_current_user_full_name, is_note_attachment_valid, get_authorization_token, get_display_format_date, get_current_user_role, __x, is_size_exceeded } from '../../../helpers/GeneralHelper';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import Alert from '../../../helpers/Alert';
import LoadingSpinner from './LoadingSpinner';
import DeletePlayerInfoConfirm from '../players/DeletePlayerInfoConfirm';
import IntelNotesConfirmationPopup from '../../common/IntelNotesConfirmationPopup';

class UpdateNote extends React.Component {

    constructor( props ) {
        super( props );

        this._player_id = props.player_id ? props.player_id : 0;
        this.files = {};

        this._organisation_id   = props.organisation_id ? props.organisation_id : 0; // organisation id

        this.current_user_role  = get_current_user_role();

        this.state = {
            modal       : false,
            files       : ['note-0'],
            fileNames   : {
                'note-0': 'File Name'
            },
            is_editing  : false,
            note_id     : 0,
            added_by    : get_current_user_full_name(),
            attachments : {},
            deleted_file_ids : [],
            fields  : {
                note            : '',
                files           : {},
                is_restricted   : false,
                show_to_all        : false //used to show checkbox of intel note showing to everyone when intel note is managed by admin 
            },
            isValid : {
                note            : true,
                files     : {},
                is_restricted   : true
            },
            error: {
                note            : '',
                files     : {},
                is_restricted   : ''
            },
            isProcessing :   false,
            isSubmitted     : true,
            isSuccess       : false,
            responseMsg     : '',
            openDeleteConfirmationPoppup : false,
            userRole        : '',
            is_organisation : false, //for organisation add/edit note popup 
            last_index      : 0, //used for managing add files/attachments
            //disable_is_restricted : false //used to disable is_restricted button when checkbox of view everyone is checked by admin
        }
    }

    setFileRefernces = ( elt, file ) => {
        this.files = {
            [file]: elt
        };
    }

    componentWillReceiveProps = props => {
        const files = props.files ? props.files : 'player_note_files';
        this.setState({
            fields  : {
                ...this.state.fields,
                note            : props.note && props.note.note ? props.note.note : '',
                is_restricted   : props.note && props.note.is_restricted ? props.note.is_restricted : false,
            },
            is_editing  : props.is_editing,
            note_id     : props.note && props.note.id ? props.note.id : 0,
            added_by    : props.note && props.note.added_by ? props.note.added_by : get_current_user_full_name(),
            attachments : props.note && props.note[ files ] ? props.note[ files ] : {},
            userRole    : props.userRole ? props.userRole : '',
            is_organisation : props.is_organisation ? props.is_organisation : false,
        });
    }

    /**
     * Toggle notes popup.
     */
    toggleNotesPopup = ( clear_fields = false ) => {
        this.setState({
            modal: !this.state.modal
        }, () => {
            if ( this.state.modal ) {
                document.body.classList.add( 'modal-open' );
            } else {
                this.clearFields();
                document.body.classList.remove( 'modal-open' );
            }
        });

        if ( clear_fields ) {
            this.clearFields();
        }
    }

    /**
     * Add new file input.
     */
    addFile = () => {
        const files = this.state.files,
        fileNames   = this.state.fileNames;
        let last_index  = this.state.last_index;
        last_index  = last_index + 1;
        const new_file    = `note-${last_index}`;

        files.push( new_file );
        this.setState({
            files,
            fileNames: {
                ...fileNames,
                [ new_file ] : `File Name`
            },
            last_index
        });
    }

    handleChanges = ( events ) => {
        const elt   = events.target,
        type        = elt.type,
        name        = elt.name,
        value       = type === 'checkbox' ? elt.checked : elt.value,
        fileNames   = this.state.fileNames,
        fields      = this.state.fields,
        isValid     = this.state.isValid,
        error       = this.state.error;

        if ( type === 'file' ) {
            const file = elt.files.length > 0 ? elt.files[0] : false;
            if ( !file ) {
                return false;
            }

            /**
             * Validting file
             */
            const is_attachment_valid = is_note_attachment_valid( file );
            if ( !is_attachment_valid || !is_attachment_valid.success ) {
                /**
                 * Set files fields according to error.
                 */
                isValid.files[ name ] = false;
                error.files[name]     = is_attachment_valid.messages && is_attachment_valid.messages.length > 0 ? is_attachment_valid.messages.map( ( msg, idx ) => <li key={idx}>{msg}</li> ) : __x( `Something went wrong, please upload a valid PDF file` );
                fileNames[ name ]     = 'File Name';
                fields.files[ name ]    = {};
            } else {
                /**
                 * Set files fields according to valid files.
                 */
                fileNames[ name ]    = file.name;
                fields.files[ name ]  = file;
                isValid.files[ name ] = true;
                error.files[ name ]   = '';
                const exceeded = is_size_exceeded( fields.files );
                if ( exceeded ) {
                    isValid.files[ name ] = false;
                    error.files[ name ]   = __x( `Total size exceeded from 5mb.` );
                }
            }
        }
        
        if ( name === 'note' ) {
            fields[ name ]  = value;
            isValid[ name ] = fields[ name ] === '' ? true : fields[ name ].length > 0 && fields[ name ].length <= 255;
            error[ name ]   = isValid[ name ] ? '' : __x( `Maximum 255 characters allowed.` );
        }
        
        if ( name === 'is_restricted' ) {
            fields[ name ]  = value;
            fields[ 'show_to_all' ] = false;
        }

        if ( name === 'show_to_all' ) {
            // this.setState({
            //     disable_is_restricted : !this.state.disable_is_restricted
            // });
            fields[ name ]  = value;
            fields[ 'is_restricted' ] = false;
        }

        this.setState({
            fileNames,
            fields, 
            isValid, 
            error
        });
    }

    /**
     * Delete attachments from editing note.
     */
    deleteAttachment = ( event, file_id ) => {
        if ( !file_id || file_id === '' || file_id <= 0 ) {
            return false;
        }

        const deleted_file_ids = this.state.deleted_file_ids;

        deleted_file_ids.push( file_id );
        this.setState({
            deleted_file_ids 
        });
    }

    handleSubmit = event => {
        event.preventDefault();

        /**
         * Gives control to the components which are hooking for submit form control.
         * And return back from here ir true.
         */
        if ( this.props.onSubmit ) {
            let note_name = this.props.note_name ? this.props.note_name : ''
            this.props.onSubmit( this.state.fields, this.state.deleted_file_ids, note_name );
            return;
        }

        /**
         * Check if user is logged in.
         */
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( !this.isFormValid() ) {
            return false;
        }

        this.setState({
            isProcessing: true
        });
        
        console.log(this._organisation_id);
        const fields        = this.state.fields,
        //organisation api needs to be chnaged once api is developed
        player_note_path    = this.state.is_organisation && !this.state.is_editing ? APIRoutes.organization_note + this._organisation_id : this.state.is_organisation && this.state.is_editing ? APIRoutes.organization_note + this.state.note_id : this.state.is_editing ? APIRoutes.update_player_note : APIRoutes.add_player_note + this._player_id,
        // player_note_path    = this.state.is_editing ? APIRoutes.update_player_note : APIRoutes.add_player_note + this._player_id,
        mothod              = this.state.is_editing ? "PUT" : "POST";

        let new_state   = {},
        formData        = new FormData();
        
        if ( this.state.is_editing ) {
            formData.append( 'note_id', this.state.note_id );
            formData.append( 'deleted_file_id', JSON.stringify( this.state.deleted_file_ids ) );
        }

        formData.append( 'note', fields.note );
        for( let i in fields.files ) {
            formData.append( 'files', fields.files[i] );
        }

        formData.append( 'is_restricted', fields.is_restricted );
        
        /**
         * Sending request to add plyaer notes.
         */
        const request = new Request( player_note_path, formData, mothod, headers, true ).send();
        request.then( response => {
            if ( response.success ) {
                this.toggleNotesPopup();
                this.clearFields();

                if(this.props.userRole === 'Agent') {
                    this.setState({
                        openIntelNotesConfirmationPoppup : true
                    });
                }

                if ( this.props.refreshNotes ) {
                    this.props.refreshNotes();
                }
            }

            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message,
                
            }
            this.setState( new_state );
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMsg : error.message
            };
            this.setState( new_state );
        });
    }

    /**
     * close confirmation popup when agent added note for player
     */
    closeConfirmationPopUp = () => {
        this.setState({
            openIntelNotesConfirmationPoppup : false
        });
    }

    /**
     * Open confirmation poppup.
     */
    toggleDeleteConfirmation = () => {
        this.setState({
            openDeleteConfirmationPoppup : !this.state.openDeleteConfirmationPoppup
        });
    }

    /**
     * Delete notes.
     */
    deleteNotes = () => {
        /**
         * Call handler, if provided.
         */
        if ( this.props.onDelete ) {
            const note_name = this.props.note_name ? this.props.note_name : '';
            this.props.onDelete(note_name);
            this.toggleNotesPopup();
            this.toggleDeleteConfirmation();
            return;
        }

        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }
        
        let new_state = {};
        const note_id = this.state.note_id;
        const note_path = this.state.is_organisation ? APIRoutes.organization_note + note_id : APIRoutes.delete_player_note + note_id;
        const request = new Request( note_path, {}, 'DELETE', headers ).send();
        request.then( response => {
            if ( response.success ) {
                this.toggleNotesPopup();
                this.toggleDeleteConfirmation();
                
                if ( this.props.refreshNotes ) {
                    this.props.refreshNotes();
                }
            }

            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message
            }
            this.setState( new_state );
        }, error => {
            console.log( error );
        });
    }

    onCancelDelete = () => {
        this.toggleDeleteConfirmation();
        if(!this.props.note_manage_by_staff)
        {
            this.toggleNotesPopup();
            this.clearFields();
        }
        
    }

    isFormValid = () => {
        const fields = this.state.fields,
        isValid = this.state.isValid;

        const errors = [];
        if ( fields.note === '' || !isValid.note ) {
            errors.push( 'note' );
        }

        for ( let i in fields.files ) {
            if ( !isValid.files[i] ) {
                errors.push( `file-${i}` );
            }
        }

        return errors.length <= 0;
    }

    resetAlert = () => {
        this.setState({
            responseMsg: ''
        });
    }

    /**
     * List all input files data.
     */
    listFiles = () => {
        const files = this.state.files;
        return files.map( ( file, idx ) => {
            return (
                // <div key={idx} className="custom-file">
                //     <input ref={ elt => this.setFileRefernces( elt, file ) } onChange={ this.handleChanges } type="file" className="custom-file-input" name={file} />
                //     <label className="custom-file-label add-file-input" htmlFor="customFile">{this.state.fileNames[ file ]}</label>
                //     { this.state.error.files[ file ] && this.state.error.files[ file ] !== '' ? <div className="display-error custome-error-top44">{ this.state.error.files[ file ] }</div> : '' }
                // </div>
                <div className="row marginB15 form-group" key={idx}>

                    <div className="col-md-3">
                        <div className="browse-custom-file">
                            <input ref={elt => this.setFileRefernces(elt, file)} onChange={this.handleChanges} type="file" className="custom-file-browse" name={file} id={file} />
                            {/* <input type="file" className="custom-file-browse" id="browseFile" /> */}
                            <label className="browse-label" htmlFor={file}></label>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <label className="marginB0 paddingT5">{this.state.fileNames[file]}</label>
                        <button onClick={this.clearAttachment.bind(this, idx, file)} type="button" className="transparent-btn d-inline">
                            <i className="material-icons text-danger font-size16 align-middle">close</i>
                        </button>
                    </div>
                    {this.state.error.files[file] && this.state.error.files[file] !== '' ? <div className="display-error custome-error-top44">{this.state.error.files[file]}</div> : ''}
                </div>
            );
        });
    }


    clearAttachment = (index, file_name) => {
        const files = this.state.files,
              fields = this.state.fields;

        files.splice( index, 1 );

        delete fields.files[ file_name ];

        this.setState({
            files,
            fields: {
                ...fields,
                fields
            }
        });
    }

    render = () => {
        const showModal = this.state.modal ? 'show' : '',
        deleted_file_ids= this.state.deleted_file_ids,
        submit_disabled = !this.isFormValid(),
        alert_messages  = this.state.responseMsg !== '' && this.current_user_role !== 'Agent' ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} floating="true" onClose={this.resetAlert} /> : '',
        loading         = this.state.isProcessing ? <LoadingSpinner/> : '';
        return (
            <div>
                {loading}
                {alert_messages}
                <div className={`modal form-modal fade modal-active ${showModal}`} style={{ transform: this.state.modal ? `translate(0, 0)` : 'translate(-125%, -125%)' }}>
                    <div className="modal-dialog modal-dialog-centered maxWidth600">
                        <div className="modal-content">
                            <div className="modal-header form-modal-header d-flex align-items-center">
                                <img src={require('../../../assets/images/form-usm-logo.png')} alt="" />
                                {
                                    this.props.note_manage_by_staff && this.props.note_manage_by_staff === true ? 
                                    <h5 className="form-modal-title">Manage Note</h5> :
                                    <h5 className="form-modal-title">{ this.state.is_editing ? `Edit` : `Add` } Note</h5>
                                }
                                
                                <button type="button" className="modal-close transparent-btn" data-dismiss="modal" aria-label="Close" onClick={this.toggleNotesPopup}>
                                    <img src={require('../../../assets/images/close-modal.png')} alt="" />
                                </button>
                            </div>
                            <div className="modal-body form-modal-body">
                                <form onSubmit={ this.handleSubmit } action="" className="usm-form form-border">
                                    <div className="row form-top-buttons">

                                    { this.props.note_manage_by_staff && this.props.note_manage_by_staff === true ? 
                                       <div className="col-md-6 added-by-text">
                                       Sent by: <span>{ this.state.added_by }</span>
                                       </div> :
                                       <div className="col-md-6 added-by-text">
                                            Added by: <span>{ this.state.added_by }</span>
                                        </div>
                                       }

                                        {this.props.note_manage_by_staff && this.props.note_manage_by_staff === true ?
                                            <div className="col-md-6 added-by-text testAlignR">
                                                {this.props.note.created_at ? get_display_format_date(this.props.note.created_at) : ''}
                                            </div>
                                            : ''
                                        }

                                        
                                        {this.props.note_manage_by_staff && this.props.note_manage_by_staff === true ?
                                            <div className="col-md-6 added-by-text">
                                                {(this.props.note.case && this.props.note.case.case_number) ? [`Case :` ,<span key={this.props.note.id}>{this.props.note.case.case_number}</span>] : (this.props.note.player) ? [`Player :` , <span key={this.props.note.id}>{this.props.note.player.first_name +  " "+ this.props.note.player.last_name}</span>] :
                                                    'Intel Note'}
                                            </div>
                                            : ''
                                        }

                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className={ this.state.error.note !== '' ? `form-group form-error` : `form-group` }>
                                                <label>Note*</label>
                                                <textarea onChange={ this.handleChanges } name="note" className="form-control textarea76" cols="" rows="" value={ this.state.fields.note }></textarea>
                                                { this.state.error.note !== '' ? <div className="display-error custome-error-top108 text-white">{ this.state.error.note }</div> : '' }
                                            </div>
                                        </div>
                                    </div>
                                    
                                    { this.state.is_editing && this.state.attachments && this.state.attachments.length > 0 ? 
                                        (<div className="row">
                                                <div className="col">
                                                    <label>Attachments</label>
                                                    <div className="form-group">
                                                        {
                                                            this.state.attachments.map( attachment => {
                                                                if ( deleted_file_ids.indexOf( attachment.id ) >= 0 ) {
                                                                    return '';
                                                                }

                                                                return (<div key={attachment.id} className="text-success font-size14 word-break-all">
                                                                    <label className="text-success">
                                                                    <a href={attachment.filename} target="_blank" rel="noopener noreferrer">{ attachment.original_filename }</a> 

                                                                        { this.props.note_manage_by_staff && this.props.note_manage_by_staff === true ? '' :
                                                                    
                                                                        <button onClick={ e => this.deleteAttachment( e, attachment.id ) } type="button" className="transparent-btn d-inline">
                                                                            <i className="material-icons text-danger font-size16 align-middle">close</i>
                                                                        </button>
                                                                        }

                                                                    </label>
                                                                </div>)
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>)
                                        : ''
                                    }
                                    <div className="row">
                                    { this.props.note_manage_by_staff && this.props.note_manage_by_staff === true ? '' : 
                                            <div className="col">
                                                <div className="">
                                                    <label>Add Files</label>        
                                                </div>
                                            </div>
                                    }                                       
                                        
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                        <div className="">
                                            { this.props.note_manage_by_staff && this.props.note_manage_by_staff === true ? '' : this.listFiles() }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row marginB15">                                     
                                            {this.props.note_manage_by_staff && this.props.note_manage_by_staff === true ?
                                                '' :
                                                <div className="col-md-12 testAlignR">
                                                    <button onClick={this.addFile} type="button" className="top-browse-btn transparent-btn paddingL0">
                                                        <img src={require('../../../assets/images/add-file-icon.png')} alt="" />
                                                        Add Another File
                                                </button>
                                                </div>
                                            }                                     
                                    </div>
                                    { this.state.userRole && this.state.userRole === 'Agent' ? '' : 
                                    <div className="row marginT10">
                                        <div className="col">
                                            <div className="form-group usm-check">
                                                <input onChange={ this.handleChanges } name="is_restricted" type="checkbox" id="restrictNote" checked={ this.state.fields.is_restricted } />
                                                <label htmlFor="restrictNote"> Restrict Note to Admin View Only.</label>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {/* added checkbox to show intel note to everyone */}
                                    { ((this.props.note_manage_by_admin && this.props.note_manage_by_admin === true) || ( this.props.note_manage_by_staff && this.props.note_manage_by_staff === true )) && ( this.props.note_name && this.props.note_name === 'intel' ) ?
                                        <div className="row marginT10">
                                            <div className="col">
                                                <div className="form-group usm-check">
                                                    <input onChange={this.handleChanges} name="show_to_all" type="checkbox" id="show_to_all" checked={this.state.fields.show_to_all} />
                                                    <label htmlFor="show_to_all"> View for Everyone.</label>
                                                </div>
                                            </div>
                                        </div> :
                                    ''}

                                </form>
                            </div>

                            { this.props.note_manage_by_staff && this.props.note_manage_by_staff === true ? 
                                <div className="modal-footer form-modal-footer d-flex justify-content-between">
                                    <button type="button" className="default-btn btn-danger min-width157" onClick={this.toggleNotesPopup}>Cancel</button>
                                    {this.state.is_editing ? <button type="button" className="default-btn btn-danger min-width157" onClick={this.toggleDeleteConfirmation}>Delete</button> : ''}
                                    <button onClick={this.handleSubmit} disabled={submit_disabled} type="submit" className="default-btn btn-success min-width157">
                                        {this.props.note_name === 'case' ? `Add to Case` : this.props.note_name === 'player' ? 'Add to Player Profile' : this.props.note_name === 'intel' ? 'Add to Agent Profile' : '' }</button>
                                </div> :
                            <div className="modal-footer form-modal-footer d-flex justify-content-between">
                                <button type="button" className="default-btn btn-danger min-width157" onClick={ this.toggleNotesPopup }>Cancel</button>
                                { this.state.is_editing ? <button type="button" className="default-btn btn-danger min-width157" onClick={ this.toggleDeleteConfirmation }>Delete</button> : '' }
                                <button onClick={ this.handleSubmit } disabled={submit_disabled} type="submit" className="default-btn btn-success min-width157">
                                { this.state.userRole && this.state.userRole === 'Agent' ? `Send` : `Save` }</button>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                {!this.state.modal || <div className="modal-backdrop fade show"></div>}

                <DeletePlayerInfoConfirm 
                    modal={ this.state.openDeleteConfirmationPoppup }
                    onClose={ this.onCancelDelete }
                    onConfirm={ this.deleteNotes }
                    type="note"
                />
                <IntelNotesConfirmationPopup
                    modal={this.state.openIntelNotesConfirmationPoppup}
                    onConfirm={this.closeConfirmationPopUp}
                />
            </div>
        );
    };

    clearFields = () => {
        this.setState({
            files       : ['note-0'],
            fileNames   : {
                'note-0': 'File Name'
            },
            is_editing  : false,
            note_id     : 0,
            added_by    : get_current_user_full_name(),
            attachments : {},
            deleted_file_ids : [],
            fields  : {
                note    : '',
                files   : {},
                is_restricted   : false,
                show_to_all        : false,
            },
            isValid : {
                note            : true,
                files     : {},
                is_restricted   : true
            },
            error: {
                note            : '',
                files     : {},
                is_restricted   : ''
            },
            isProcessing :   false,
            isSubmitted     : true,
            isSuccess       : false,
            responseMsg     : '',
            last_index : 0
        });
        
        if(!this.props.note_manage_by_staff) {
            for( let f in this.files ) {
                if ( this.state.files.indexOf( f ) < 0 ) {
                    continue;
                }
    
                this.files[ f ].value = null;
            }
        }
        
    }
}
export default UpdateNote;