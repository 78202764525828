const initialState = {
    players : [],
    player_positions : [],
    player_professional_level: [],
    player_contract_type: [],
    highlights: [],
    history: [],
    reports: [],
    videos: [],
    contacts : [],
    contact: {}
}

export default ( state = initialState, action ) => {
	switch ( action.type ) {
        case 'SET_PLAYERS':
            return {
                ...state,
                players: action.payload.players
            };
        case 'APPEND_PLAYERS':
            return {
                ...state,
                players : action.payload.players
            };    
        case 'SET_PLAYER_POSITIONS':
            return {
                ...state,
                player_positions: action.payload.player_postions
            };
        case 'SET_PLAYER_PROFESSIONAL_LEVEL':
            return {
                ...state,
                player_professional_level: action.payload.player_professional_level
            };
        case 'SET_PLAYER_CONTRACT_TYPE':
            return {
                ...state,
                player_contract_type: action.payload.player_contract_type
            };
        case 'SET_PLAYER_CAREER_HIGHLIGHTS':
            return {
                ...state,
                highlights: action.payload.highlights
            }; 
        case 'SET_PLAYER_CAREER_HISTORY':
            return {
                ...state,
                history: action.payload.history
            }; 
        case 'SET_PLAYER_REPORTS':
            return {
                ...state,
                reports: action.payload.reports
            }; 
        case 'SET_PLAYER_VIDEOS':
            return {
                ...state,
                videos: action.payload.videos
            }; 
        case 'SET_PLAYER_CONTACTS':
            return {
                ...state,
                contacts: action.payload.contacts
            }; 
        case 'SET_PLAYER_CONTACT':
            return {
                ...state,
                contact: action.payload.contact
            }; 
		default:
			return state;
	}
}