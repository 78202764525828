import { __x } from '../helpers/GeneralHelper';

const PasswordValidator = ( password ) => {
    let failedList = [];

    if ( password === '' || !password ) {
        return failedList;
    }

    /**
     * Defining regular expression rules for password validation.
     */
    const passValidationRules = {
        // missing     : "^(?!\s*$).+",
        startsWith  : "^([a-zA-Z])",
        digit       : "(?=.*[0-9])",
        lower       : "(?=.*[a-z])",
        upper       : "(?=.*[A-Z])",
        min8        : "(?=.{8,})",
        // lastName    : `^((?!${lastName}).)*$`,
        // empId       : `^((?!${empId}).)*$`
    }
    
    
    let failedListMsgs = {
        startsWith  : __x( `Password must start with an alphabetic character.` ),
        digit       : __x( `Password must contain at least 1 numeric characters.` ),
        lower       : __x( `Password must contain at least 1 lowercase letters.` ),
        upper       : __x( `Password must contain at least 1 uppercase letters.` ),
        min8        : __x( `The password must be at least 8 characters long.` ),
        max255      : __x( `The password must be maximum 255 characters long.` )
    };


    for( let r in passValidationRules ) {
        let rule = passValidationRules[r];
        
        /**
         * Defining the modifiers
         */
        const modifier = ( r === 'lastName' ) ? 'i' : 'g';

        /**
         * Creating regular expression instance to match the rules.
         */
        let reg = new RegExp( rule, modifier );

        /**
         * Testing rules with password
         */
        if( !reg.test( password ) ) {
            failedList.push( failedListMsgs[r] );
        }
    }
    
    /**
     * Password must me be of max 255 characters
     */
    if ( password.length > 255 ) {
        failedList.push( failedListMsgs[ 'max255' ] );
    }
    
    return failedList;
}

export default PasswordValidator;