import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';


const AgentListCaseRollOver = props => {
    
    let active_cases  = [];

    for ( let i in props.activeCases ) {
        let active_case = props.activeCases[i];
        
        active_cases.push(<tr key={i}>
            <td title={active_case.name ? active_case.name : ''} className="text-left usm-roll-name paddingL0">{ active_case.name ? active_case.name : ''}</td>
            <td className="text-right paddingR0">{ active_case.position ? active_case.position : '' }</td>
        </tr>);
    }

    const id = `popover-content-toggle-one-${props.club_id}`;
    return (
        <div id={id} className="popover-content-toggle-one">

            <div className="arrow-right"></div>
            <div className="usm-table-popover table-responsive">
                <div className="table-popover-heading"><span>{props.activeCases ? props.activeCases.length : 0}</span> Active Cases</div>
                <Scrollbars autoHeightMin={100} autoHeightMax={270} autoHide autoHeight>
                    <div className="usm-table-popover-scroll">
                        <table className="">
                            <tbody>
                                {active_cases}
                            </tbody>
                        </table>
                    </div>
                </Scrollbars>
            </div>
        </div>
    )
}

export default AgentListCaseRollOver;

