import React from 'react';
import { Link } from 'react-router-dom';
import { __x } from '../../../helpers/GeneralHelper';


const OrganisationsList = ({ organisations, is_fetched }) => {
    let OrganisationsList = [];
    if ( !organisations || organisations === 'undefined' || organisations.length <= 0 ) {
        if ( is_fetched ) {
            OrganisationsList.push(
                <tr key={1}>
                    <td colSpan="7">{ __x( `No record found.` ) }</td>
                </tr>
            );
        }

        return OrganisationsList;
    }

    for ( let i in organisations ) {
        let organisation = organisations[i];
        OrganisationsList.push(
            <tr key={organisation.id}>
                <td>{ organisation.organization_name ? organisation.organization_name : ''}</td>
                <td>{ organisation.web_address ? organisation.web_address : '' }</td>
                <td>{ organisation.email ? organisation.email : '' }</td>
                <td>{`${organisation.country_code ? `+${organisation.country_code}` : ''}${organisation.phone ? organisation.phone : ''}`}</td>
                <td>{ organisation.organization_type ? organisation.organization_type : '' }</td>
                <td><button className="table-btn btn-transWhite">{ organisation.organization_notes && organisation.organization_notes.length > 0 ? organisation.organization_notes.length : 0 }</button></td>
                
                <td>
                    <Link to={`/organisations-details/${organisation.id}`}><i className="material-icons text-offWhite font-size20">edit</i></Link>
                </td>
            </tr>
        );
    }
    return OrganisationsList;
}
export default OrganisationsList;

