import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../Auth/ProtectedRoutes';
import AgentDashboard from './Dashboard';
import Header from '../Header';
import Players from '../admin-staff/players/Players';
import PlayerDetails from '../admin-staff/players/PlayerDetails';
import Cases from '../admin-staff/case/Cases';
import ClubCaseView from '../common/ClubCaseView';
import PlayercaseView from '../common/PlayerCaseView';
import NotesAndConcierge from '../admin-staff/players/NotesAndConcierge';

/**
 * Wrapper component for wrapping up the agent sections by defining routes
 */
const Wrapper = () => {
    return (
        <div>
            <Header />
            <Switch>
                <ProtectedRoute exact path="/agent/dashboard" component={AgentDashboard} accessibleTo="Agent" />
                <ProtectedRoute exact path="/agent/players" component={Players} />
                <ProtectedRoute exact path="/players-details/:player_id" component={PlayerDetails} />
                <ProtectedRoute exact path="/agent/player-notes-and-concierge/:player_id" component={NotesAndConcierge} /> 
                <ProtectedRoute exact path="/agent/player-highlight-list/:player_id" component={PlayerDetails} /> 
                <ProtectedRoute exact path="/agent/player-videos-and-reports/:player_id" component={PlayerDetails} />
                <ProtectedRoute exact path="/agent/player-contacts/:player_id" component={PlayerDetails} />                
                <ProtectedRoute exact path="/agent/player-cases/:player_id" component={PlayerDetails} />
                <ProtectedRoute exact path="/agent/cases" component={Cases} />
                <ProtectedRoute exact path="/agent/club-case/:case_id" component={ClubCaseView} />
                <ProtectedRoute exact path="/agent/view-player-case/:case_id" component={PlayercaseView} />
            </Switch>
        </div>
    );
}

export default Wrapper;