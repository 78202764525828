import React from 'react';
import { get_display_format_date, __x } from '../../../helpers/GeneralHelper';

const ConfirmLeagueChange = props => {
    const showModal = props.modal ? 'show' : '';
    return (
        <div>
            <div className={`modal form-modal fade modal-active ${showModal}`} style={{ transform: props.modal ? `translate(0, 0)` : 'translate(-125%, -125%)' }}>
                <div className="modal-dialog modal-dialog-centered maxWidth400">
                    <div className="modal-content">
                        <div className="modal-body form-modal-body">
                            <h5 className="form-modal-content text-white fontBold text-center">
                                { 
                                    __x( 
                                        `Please confirm that you wish to reassign this team from {{from_league_name}} to {{to_league_name}} as of {{date}}.`,
                                        [ `{{from_league_name}}`, `{{to_league_name}}`, `{{date}}` ],
                                        [ props.from_league_name, props.to_league_name, get_display_format_date( '', true ) ]
                                    ) 
                                }
                            </h5>
                        </div>
                        <div className="modal-footer form-modal-footer d-flex justify-content-between">
                            <button type="button" className="default-btn btn-danger" data-dismiss="modal" onClick={ props.onClose }>{ __x( `No, Cancel` ) }</button>
                            <button type="button" className="default-btn btn-success" onClick={ props.onConfirm }>{ __x( `Yes, Reassign` ) }</button>
                        </div>
                    </div>
                </div>
            </div>
            { !props.modal || <div className="modal-backdrop fade show"></div> }
        </div>
    );
};

export default ConfirmLeagueChange;