import React from 'react';
import Alert from '../../../helpers/Alert';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Case from './Case';
import { get_authorization_token, get_current_user_role, __x } from '../../../helpers/GeneralHelper';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import { setCases, appendCases } from '../../../actions/CasesActions';
import InfiniteScroll from 'react-infinite-scroll-component';

class Cases extends React.Component {
    
    _page_title = 'Cases - USM';

    constructor(props) {
        super(props);

        this.current_user_role = get_current_user_role();
        this.fetched = false;

        this.state = {
            modal_add_case: '',
            isDisabled: true,
            redirect_to_case: '',
            addCaseModal: false,
            resetAlertState: false,
            agentOptions: [],
            leagueOptions: [],
            primaryPositionOptions: [],
            clubOptions: [],
            cases: [],
            fields: {
                type: '',
                status: '',
                agent: '',
                position: '',
                league: '',
                name: ''
            },
            names : {
                type        : '',
                status      :'',
                agent       : '',
                position    : '',
                league      : '',
                name        : '',
            },
            filtered            : false,
            hasMoreItems        : false,
            maxPages            : null,
            nextPage            : 1,
        }
    }

    handleAddCaseModal = () => {
        let new_state = {
            addCaseModal: !this.state.addCaseModal
        }

        if (new_state.addCaseModal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        this.setState(new_state);
    }

    onPopupClose = () => {
        this.handleAddCaseModal(true);
        this.setState({
            isDisabled: true,
            modal_add_case: ''
        });
    }

    handleAddCaseModalChange = (event) => {
        const target = event.target,
            value = target.value,
            isDisabled = value === '' ? true : false;

        this.setState({
            modal_add_case: value,
            isDisabled: isDisabled
        });
    }

    proceedAddCase = () => {
        const modal_add_case = this.state.modal_add_case;
        let redirect_to_case = this.state.redirect_to_case;
        if (modal_add_case === '1') {
            redirect_to_case = '1';
        }
        else if (modal_add_case === '2') {
            redirect_to_case = '2';
        }
        this.setState({
            redirect_to_case
        })
    }

    componentWillUnmount = () => {
        document.body.classList.remove('modal-open');
    }

    render = () => {
        if (this.state.redirect_to_case === '1') {
            return <Redirect to="/admin/add-player-case" />
        }

        if (this.state.redirect_to_case === '2') {
            return <Redirect to="/admin/add-club-case" />
        }

        const flashMessage = this.state.resetAlertState || !this.props.location.state || this.props.location.state.message === '' ? '' : <Alert message={this.props.location.state.message} success={this.props.location.state.success} floating="true" onClose={this.closeAlert} />;

        const modalActiveClass = this.state.addCaseModal ? 'show' : '';

        /* agent dropdown data */
        const agents = this.state.agentOptions && this.state.agentOptions.length > 0 ? this.state.agentOptions.map(agent => {
            return <option key={agent.value} value={agent.value}>{agent.label}</option>;
        }) : '';

        /* League dropdown data */
        const leagues = this.state.leagueOptions && this.state.leagueOptions.length > 0 ? this.state.leagueOptions.map(agent => {
            return <option key={agent.value} value={agent.value}>{agent.label}</option>;
        }) : '';

        /* position dropdown data */
        const requirements = this.state.primaryPositionOptions && this.state.primaryPositionOptions.length > 0 ? this.state.primaryPositionOptions.map(position => {
            return <option key={position.value} value={position.value}>{position.label}</option>;
        }) : '';

        /* position dropdown data */
        const clubs = this.state.clubOptions && this.state.clubOptions.length > 0 ? this.state.clubOptions.map(club => {
            return <option key={club.value} value={club.value}>{club.label}</option>;
        }) : '';
        return (
            <div id="mainContainer">
                {flashMessage}
                <div className="heading-section d-flex align-items-center">
                    <h2>Cases</h2> 
                    {
                        this.current_user_role === 'Agent' ? '' :
                        <h6 className="ml-auto float-right">
                        <img src={require(`../../../assets/images/add-cases.png`)} alt="" className="align-baseline" /> 
                        <button onClick={this.handleAddCaseModal} className="text-white marginL10 transparent-btn" data-toggle="modal">Add Case</button>
                    </h6>
                    }
                    
                </div>
                <div className="filtter-section position-relative">
                    <div className="d-flex">
                        <div className="w-100">
                            <form className="usm-form form-border">
                                <div className="row all-filter-block">
                                    <div className="form-group col-md-2 col-sm-12 marginB0">
                                        <label htmlFor="">Case Type</label>
                                        <select name="type" id="" className="form-control input-height30" placeholder="" onChange={this.handleFieldChange} value={this.state.fields.type} title={this.state.names.type}>
                                            <option value="">All</option>
                                            <option value="1">Player Recruitment</option>
                                            <option value="2">Club Requirement</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-2 col-sm-12 marginB0">
                                        <label htmlFor="">Case Status</label>
                                        <select name="status" id="" className="form-control input-height30" placeholder="" onChange={this.handleFieldChange} value={this.state.fields.status} title={this.state.names.status}>
                                            <option value="">All</option>
                                            <option value="1">Open</option>
                                            <option value="0">Closed</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-2 col-sm-12 marginB0">
                                        <label htmlFor="">Agent</label>
                                        <select name="agent" id="" className="form-control input-height30" placeholder="" onChange={this.handleFieldChange} value={this.state.fields.agent} title={this.state.names.agent}>
                                            <option value="">All</option>
                                            {agents}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-2 col-sm-12 marginB0">
                                        <label htmlFor="">Requirement</label>
                                        <select name="position" id="" className="form-control input-height30" placeholder="" onChange={this.handleFieldChange} value={this.state.fields.position} title={this.state.names.position}>
                                            <option value="">All</option>
                                            {requirements}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-2 col-sm-12 marginB0">
                                        <label htmlFor="">League</label>
                                        <select name="league" id="" className="form-control input-height30" placeholder="" onChange={this.handleFieldChange} value={this.state.fields.league} title={this.state.names.league}>
                                            <option value="">All</option>
                                            {leagues}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-2 col-sm-12 marginB0">
                                        <label htmlFor="">Club Name</label>
                                        <select name="name" id="" className="form-control input-height30" placeholder="" onChange={this.handleFieldChange} value={this.state.fields.name} title={this.state.names.name}>
                                            <option value="">All</option>
                                            {clubs}
                                        </select>
                                    </div>
                                </div>

                            </form>
                        </div>
                        <div className="flex-shrink-1 marginL18">
                            <div className="apply-filtter float-right d-flex align-items-center flex-column">
                                <i className="material-icons marginB5"><img src={ require( '../../../assets/images/filter-icon.png' ) } alt="" /></i>
                                <button onClick={this.filterCases} className="btn btn-success borderRadius15 text-uppercase small-button">Apply Filters</button>
                            </div>
                        </div>
                    </div>
                    {/* 
                        !this.state.filtered ? '' : 
                        <button onClick={ this.clearFilter } className="transparent-btn text-danger font-size30 clear-filter-btn">&times;</button>
                     */}
                </div>
                <div className="content-section">

                    <div className="usm-table marginT82 table-responsive">

                    <InfiniteScroll
                            dataLength={this.props.cases.cases && this.props.cases.cases.length > 0 ? this.props.cases.cases.length : 0 } //This is important field to render the next data
                            next={this.loadMoreCases}
                            hasMore={this.state.hasMoreItems}
                        // loader={<h4>Loading...</h4>}
                        >
                            <table className="table table-hover lastFour-center">
                            <thead className="usm-thead">
                                <tr>
                                    <th>Case No</th>
                                    <th>Case Type</th>
                                    <th>Requirement</th>
                                    <th>Fulfilment Date</th>
                                    <th>No of Agents</th>
                                    <th>Last Updated</th>
                                    <th>Case Status</th>
                                    <th>{ this.current_user_role === 'Agent' ? 'View' : 'Manage'}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <Case
                                    is_fetched={ this.fetched }
                                    cases={this.props.cases.cases}
                                    user_role={ this.current_user_role }
                                />
                            </tbody>
                        </table>

                        </InfiniteScroll>
                    </div>
                </div>
                <div className={`modal form-modal fade modal-active ${modalActiveClass}`} id="addCaseModal" tabIndex="-1" role="dialog" aria-hidden="true" style={{ transform: this.state.addCaseModal ? `translate(0, 0)` : 'translate(-125%, -125%)' }}>
                    <div className="modal-dialog modal-dialog-centered maxWidth600" role="document">
                        <div className="modal-content">
                            <div className="modal-header form-modal-header d-flex align-items-center">
                                <img src={require('../../../assets/images/form-usm-logo.png')} alt="" />
                                <h5 className="form-modal-title">Add Case</h5>
                                <button className="modal-close transparent-btn" data-dismiss="modal" aria-label="Close" onClick={this.onPopupClose}>
                                    <img src={require('../../../assets/images/close-modal.png')} alt="" />
                                </button>
                            </div>
                            <div className="modal-body form-modal-body">
                                <form action="" className="usm-form form-border">
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="">Case Type</label>
                                                <select name="modal_add_case" id="" className="form-control" value={this.state.modal_add_case} onChange={this.handleAddCaseModalChange}>
                                                    <option value=""></option>
                                                    <option value="1">Player Recruitment</option>
                                                    <option value="2">Club Requirement</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer form-modal-footer d-flex justify-content-between">
                                <button type="button" className="default-btn btn-danger" data-dismiss="modal" onClick={this.onPopupClose}>Cancel</button>
                                <button type="button" className="default-btn btn-success" disabled={this.state.isDisabled} onClick={this.proceedAddCase}>Proceed</button>
                            </div>
                        </div>
                    </div>

                </div>
                {!this.state.addCaseModal || <div className="modal-backdrop fade show"></div>}
            </div>
        );
    }

    closeAlert = () => {
        this.setState({
            resetAlertState: true
        });
    }

    componentDidMount = () => {
        document.title = this._page_title;
        this.getCases();
        this.getAgentsOptions();
        this.getLeagueOptions();
        this.getPrimaryPostionOptions();
        this.getClubs();
    }

    /**
     * Get all cases
     */
    getCases = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }
        this.setState({
            nextPage : 1,
            maxPages : null,
        });

        let cases = this.state.cases;
        const request = new Request(APIRoutes.all_cases, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                this.fetched = true;
                /**
                 * set cases list in redux store
                 */
                cases = response.data && response.data.length > 0 ? response.data : [];
                this.props.setCases( cases );

                this.setState({
                    hasMoreItems    : response.pages && response.pages === 1 ? false : true,
                    maxPages        : response.pages ? response.pages : null,
                    nextPage        : this.state.nextPage + 1,
                });
            }
        }, error => {
            console.log(error);
        });
    }

    /**
	 * load more cases on page scroll
	 */
	loadMoreCases = () => {
		const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

		this.setState({
			hasMoreItems : false,
		});

		let maxPages = this.state.maxPages,
		nextPage     = this.state.nextPage;


		if(maxPages < nextPage) {
			this.setState({
				hasMoreItems : false,
			});
			return false;
        }
        
        let query_string = '';
        const fields = this.state.fields;
        if (fields.type && fields.type !== '') {
            query_string += (`type=` + fields.type + `&`);
        }
        if (fields.position && fields.position !== '') {
            query_string += (`position=` + fields.position + `&`);
        }
        if (fields.agent && fields.agent !== '') {
            query_string += (`agent=` + fields.agent + `&`);
        }
        if (fields.status && fields.status !== '') {
            query_string += (`status=` + fields.status + `&`);
        }

        if (fields.name && fields.name !== '') {
            query_string += (`name=` + fields.name + `&`);
        }
        if (fields.league && fields.league !== '') {
            query_string += (`league=` + fields.league + `&`);
        }
        
        const agent_path = query_string !== '' ?  APIRoutes.all_cases + nextPage + '?' + query_string : APIRoutes.all_cases + nextPage ;
		const request = new Request(agent_path, {}, 'GET', headers).send();
		
		request.then(response => {
			if (response.success) {
				this.setState({
					hasMoreItems : true,
					nextPage : nextPage + 1,
                });

                /**
					 * Dispatching action to set cases to redux store.
					 */
                this.props.appendCases(this.props.cases.cases, response.data);
				
			}
		}, error => {
			console.log(error);
		});
    }

    /**
     * get data for agent list dropdown
     */
    getAgentsOptions = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        const request = new Request(APIRoutes.get_agent_list, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                let agentOptions = this.state.agentOptions;

                agentOptions = response.data;
                this.setState({
                    isError: false,
                    responseMessage: '',
                    isSuccess: true,
                    agentOptions
                });
            }
        }, error => {
            console.log(error);
        });
    }

    /**
     * get data for league dropdown
     */
    getLeagueOptions = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        const request = new Request(APIRoutes.league_list, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                let leagueOptions = this.state.leagueOptions;
                let league_list = [];
                league_list = response.data.map(function (data, i) {
                    return { label: data.title, value: data.id };
                });
                leagueOptions = league_list;
                this.setState({
                    isError: false,
                    responseMessage: '',
                    isSuccess: true,
                    leagueOptions
                });
            }
        }, error => {
            console.log(error);
        });
    }

    getClubs = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const club_path = APIRoutes.get_club_list;
        const request = new Request(club_path, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                let clubOptions = this.state.clubOptions;
                clubOptions = response.data;
                this.setState({
                    isError: false,
                    responseMessage: '',
                    isSuccess: true,
                    clubOptions
                });

            }
        }, error => {
            console.log(error);
        });
    }

    /**
     * get data for primary position
     */
    getPrimaryPostionOptions = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        const request = new Request(APIRoutes.player_positions, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                let primaryPositionOptions = this.state.primaryPositionOptions;

                primaryPositionOptions = response.data;
                this.setState({
                    isError: false,
                    responseMessage: '',
                    isSuccess: true,
                    primaryPositionOptions
                });
            }
        }, error => {
            console.log(error);
        });
    }

    /**
     * handle field changes
     */
    handleFieldChange = (event) => {
        const target = event.target,
            name = target.name,
            value = target.value;
        const fields = this.state.fields;
        const names = this.state.names;
        names[name] = event.target.options[event.target.selectedIndex].text
        fields[name] = value;
        this.setState({
            ...fields,
            fields,
            ...names,
            names,
        });
    }

    /**
     * filter cases
     */
    filterCases = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }
        let query_string = '';
        const fields = this.state.fields;
        if (fields.type && fields.type !== '') {
            query_string += (`type=` + fields.type + `&`);
        }
        if (fields.position && fields.position !== '') {
            query_string += (`position=` + fields.position + `&`);
        }
        if (fields.agent && fields.agent !== '') {
            query_string += (`agent=` + fields.agent + `&`);
        }
        if (fields.status && fields.status !== '') {
            query_string += (`status=` + fields.status + `&`);
        }

        if (fields.name && fields.name !== '') {
            query_string += (`name=` + fields.name + `&`);
        }
        if (fields.league && fields.league !== '') {
            query_string += (`league=` + fields.league + `&`);
        }
        const request = new Request(APIRoutes.all_cases + '?' + query_string, '', 'GET', headers, true).send();
        request.then(response => {
            if (response.success) {
                this.setState({
                    isError: false,
                    responseMessage: '',
                    isSuccess: true,
                    fields: {
                        ...this.state.fields,
                    },
                    filtered     : true,
                    hasMoreItems : response.pages && response.pages === 1 ? false : true,
					maxPages     : response.pages ? response.pages : null,
					nextPage     : 2,
                });

                /** store cases list in redux store */
                this.props.setCases( response.data );

            }
        }, error => {
            console.log(error);
        });
    }

    clearFilter = () => {
        this.setState({
            fields: {
                cases: {},
                type: '',
                status: '',
                agent: '',
                position: '',
                league: '',
                name: ''
            },
            filtered : false
        });
        this.getCases();
    }
}
const mapStateToProps = (state) => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setCases: (cases) => dispatch(setCases(cases)),
    appendCases : (all_cases, load_cases) => dispatch(appendCases(all_cases, load_cases)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cases));