import React from 'react';
import { NavLink  } from 'react-router-dom';
import { get_current_user_role } from '../../../helpers/GeneralHelper';

const PlayerNavTabs = ({ player_id }) => {
    let current_user_route = get_current_user_role() === 'Agent' ? 'agent' : 'admin';
    return (
        <div className="usm-tabs">
            <ul className="row nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="col nav-item">
                    <NavLink isActive={isMatched} to={ `/players-details/${player_id}` } className="nav-link" id="profile-tab" data-toggle="pill" role="tab" aria-controls="pills-profile" aria-selected="true">Profile</NavLink>
                </li>
                <li className="col nav-item">
                    <NavLink isActive={isMatched} to={ `/${current_user_route}/player-cases/${player_id}` } className="nav-link" id="cases-tab" data-toggle="pill" role="tab" aria-controls="pills-cases" aria-selected="false">Cases</NavLink>
                </li>
                <li className="col nav-item">
                    <NavLink isActive={isMatched} to={ `/${current_user_route}/player-highlight-list/${player_id}` } className="nav-link" id="career-tab" data-toggle="pill" role="tab" aria-controls="pills-career" aria-selected="false">Career Highlights</NavLink>
                </li>
                <li className="col nav-item">
                    <NavLink isActive={isMatched} to={ `/${current_user_route}/player-videos-and-reports/${player_id}` } className="nav-link" id="videos-tab" data-toggle="pill" role="tab" aria-controls="pills-videos" aria-selected="false">Videos & Reports</NavLink>
                </li>
                <li className="col nav-item">
                    <NavLink isActive={isMatched} to={ `/${current_user_route}/player-contacts/${player_id}` } className="nav-link" id="contacts-tab" data-toggle="pill" role="tab" aria-controls="pills-contacts" aria-selected="false">Contacts</NavLink>
                </li>
            </ul>
        </div>
    );
}

export default PlayerNavTabs;

/**
 * Checks if current route is matched with navlink pathname.
 * @param {*} match     // Returns route details if matched with current route.
 */
const isMatched = ( match ) => {
    return match;
}