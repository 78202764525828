import React from 'react';
import { Link } from 'react-router-dom';


const ClosedPlayerCases = ({ closed_player_cases }) => {

    let closedPlayerCasesList = [];

    if (!closed_player_cases || closed_player_cases === 'undefined' || closed_player_cases.length <= 0) {
        return closedPlayerCasesList;
    }

    for (let i in closed_player_cases) {
        let closed_player_case = closed_player_cases[i];
        closedPlayerCasesList.push(
            <tr key={closed_player_case.id}>
                <td className="fontBold"><Link to={`/admin/view-player-case/${closed_player_case.id}`}>{closed_player_case.case_number && closed_player_case.case_number !== '' ? closed_player_case.case_number : ''}</Link></td>

                <td>{closed_player_case.player && Object.keys(closed_player_case.player).length > 0 ? closed_player_case.player.first_name + " " + closed_player_case.player.last_name : ''}</td>

                <td>{closed_player_case.master_position && Object.keys(closed_player_case.master_position).length > 0 ? closed_player_case.master_position.title : ''}</td>

                <td><div className="d-flex align-items-center"><span className="table-clubImg"><img src={closed_player_case.player && closed_player_case.player.image_path ? closed_player_case.player.image_path : require('../../../assets/images/dummy-image.png')} alt="" /></span> 
                {closed_player_case.player && closed_player_case.player.current_club ? closed_player_case.player.current_club : ''}</div></td>

                <td className={closed_player_case.is_contract_signed && closed_player_case.is_contract_signed === true ? `text-success fontBold` : `text-danger fontBold`}>{closed_player_case.is_contract_signed && closed_player_case.is_contract_signed === true ? `Yes` : `No`}</td>
            </tr>
        );
    }
    return closedPlayerCasesList;
}
export default ClosedPlayerCases;