import React from 'react';
import { Link } from 'react-router-dom';
import AgentListCaseRollOver from '../admin-staff/AgentListCaseRollOver';

const PlayerCase = ({ active_agent_case, active_agent_widgets, idx, handle_alert }) => {

    let visible = '',
        activeCases = [],
        disableAlert = active_agent_case.show_button && active_agent_case.show_button === 1 ? '' : 'disabled';

    if (active_agent_widgets.length > 0) {
        for (let i in active_agent_widgets) {
            if (active_agent_widgets[i].id === active_agent_case.id) {
                visible = `visible`;
            }
        }
    } else {
        visible = idx > 3 ? '' : `visible`;
    }

    activeCases.push(<div key={active_agent_case.id} className={`col-md-3 col-sm-6 col-xs-12 ${visible}`}>
        <div className="card">
            <div className="card-body">
            <Link to={`/admin/agent-details/${active_agent_case.id}`}>
                <div className="cardImg-section">
                    <div className="card-image" style={ { background: active_agent_case.image_path ? `url(${active_agent_case.image_path})` :  `url(require("../../assets/images/dummy-image.png"))` } }>
                        {/* <img className="club" src={active_agent_case.image_path ? active_agent_case.image_path : require('../../assets/images/dummy-image.png')} alt=" " /> */}
                    </div>
                    <div className="cardTitle-section" title={active_agent_case.name ? active_agent_case.name : ''}>
                        <div className="card-title">{active_agent_case.name ? active_agent_case.name : ''}</div>
                    </div>
                </div>
                </Link>

                <div className="cardContent-section">
                <Link to={`/admin/agent-details/${active_agent_case.id}`}>
                    <div className="d-flex card-text marginB18">
                        <div className="mr-auto text-success fontBold font-size14">Cases</div>
                        <div className="fontBold text-success font-size14 show-rollover position-relative">{active_agent_case.case_count && active_agent_case.case_count.length > 0 ? active_agent_case.case_count.length : 0}
                            {active_agent_case.case_count.length > 0 ? <AgentListCaseRollOver activeCases = {active_agent_case.case_count} /> : ''}
                        </div>
                    </div>
                    
                    <div className="d-flex card-text marginB20">
                        <div className="mr-auto font-size14 text-danger">Days Inactive</div>
                        <div className="fontBold font-size14 text-danger">{active_agent_case.last_login ? active_agent_case.last_login : 0}</div>
                    </div>
                    </Link>
                    <button type="button" disabled={disableAlert} onClick={ () => handle_alert(active_agent_case,'agent') } className="btn btn-usmPrimary">Alert</button>
                </div>
            </div>
        </div>
    </div>);

    return activeCases;

}
export default PlayerCase;

