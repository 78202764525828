import base64url from "base64url";
import Request from './Request';
import APIRoutes from '../API/Routes';
import 'url-search-params-polyfill';

const Messages = require( '../lang/en.json' );

export const MonthsName = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];

/**
 * Retrieves the userdata from localstorage accrodign to the given key
 * @param {*} key 
 */
export const get_user_data = ( key ) => {
    if ( localStorage.hasOwnProperty( key ) ) {
        let value = localStorage.getItem( key );

        try {
            value = key === 'user' ? JSON.parse( value ) : value;
        } catch ( e ) {
            console.log( e );
        }

        return value;
    }

    return false;
}

/**
 * Set user data to local storage according to given keys
 * @param {*} key 
 * @param {*} value 
 */
export const set_user_data = ( key, value ) => {
    const data = typeof value === "string" ? value : JSON.stringify( value );
    localStorage.setItem( key, data );
}

/**
 * Delete user data from local storage
 * @param {*} key 
 */
export const delete_user_data = ( key ) => {
    if ( localStorage.hasOwnProperty( key ) ) {
        localStorage.removeItem( key );
    }
}

/**
 * Checks if token is set in local storage 
 */
export const isAuthenticated = ( is_tnc = false ) => {
    const key = is_tnc ? 'temp_token' : 'token',
        token = get_user_data( key );
    
    return !token ? false : true;
}

/**
 * Checks if current user has accepted tnc
 */
export const is_tnc_accepted = () => {
    const current_user = get_user_data( 'user' );
    if ( !current_user )
        return false;
    
        return current_user.tnc_accepted;
}

/**
 * Gets current user's role
 */
export const get_current_user_role = () => {
    if ( !localStorage.hasOwnProperty( 'user' ) ) {
        return false;
    }

    const current_user = get_user_data( 'user' );
    return current_user.users_role;
}

/**
 * Gets current user's dashbaord url
 */
export const get_user_dashboard_url = () => {
    const user_role = get_current_user_role();

    if ( !user_role ) {
        return false;
    }
    
    return `/${user_role.toLowerCase()}/dashboard`;
}

/**
 * Retrieves token from url's query string
 * @param {*} search_str 
 */
export const get_token_from_search_string = ( search_str ) => {
    if ( search_str === '' || !search_str || typeof search_str === "undefined" || search_str === "undefined" )
        return false;
    
    let search_arr = search_str.split( 'token=' );
    
    if ( search_arr.length <= 0 )
        return false;

    return search_arr[1];
}

/**
 * Decodes information from the given base 64 format string to normal string.
 * @param {*} string 
 */
export const base64_decode = ( string ) => {
    if ( !string || string === '' || string === 'undefined' || typeof string === 'undefined' )
        return false;
    
    const informationStr = base64url.decode( decodeURIComponent( get_token_from_search_string( string ) ), "utf8" );
    
    const info_arr = informationStr.split( '**' );
    return info_arr;
}

/**
 * Authenticates token's validity.
 * 
 * @param {*} callback 
 */
export const tokenAuthentication = ( callback ) => {
    /**
     * Retrieves current token from local storage.
     */
    const token = get_user_data( 'token' );

    /**
     * Returns false if not token found
     */
    if ( !token ) {
        return false;
    }

    /**
     * Setting token authentication api path.
     */
    const dashboard_url = APIRoutes.validate_token;
    
    /**
     * Setting current token as header object.
     */
    let headers         = {
        "Authorization": token
    }

    /**
     * Requesting api to authenticate token's validity.
     */
    const request = new Request( dashboard_url, {}, "GET", headers ).send();
    request.then( response => {
        /**
         * If token is not valid delete the current userdata with token.
         */
        if ( !response.success ) {
            delete_user_data( 'token' );
            delete_user_data( 'user' );
        }

        /**
         * Set callback to handle response.
         */
        callback( response );
    }, error => {
        /**
         * If token is not valid delete the current userdata with token.
         */
        delete_user_data( 'token' );
        delete_user_data( 'user' );

        /**
         * Set callback to handle fetching error.
         */
        callback( error );
    });
}

export const isUserImageValid = ( file ) => {
    let errors = [];
    if ( !file ) {
        return false;
    }
    
    if ( file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif' ) {
        errors.push( __x( `Only JPG/JPEG, PNG and GIF files are allowed.` ) );
    }

    /**
     * Calculate file size in bytes. 
     */
    const file_size_in_kb = file.size;
    
    /**
     * Max allowed file size is 5mb in bytes.
     */
    const max_allowed_file_size = 1024 * 1024 * 5;
    
    if ( file_size_in_kb > max_allowed_file_size ) {
        errors.push( __x( `File size should not be more than 5mb.` ) );
    }

    return {
        success: errors.length <= 0,
        messages: errors
    };
}

export const get_user_by_id = ( users, id ) => {
    let user = users.filter( user => {
        return user.id === id;
    });
    
    return user.length > 0 ? user[0] : false;
}

export const get_new_key = () => {
    const key_store = [],
    new_key         = Math.random().toString().replace( '0.', '' );

    if ( key_store.length > 0 && key_store.indexOf( new_key ) ) {
        get_new_key();
    }

    return new_key;
}

export const get_authorization_token = () => {
    const token = get_user_data( 'token' );
    if ( !token ) {
        return false;
    }

    return {
        Authorization   : token,
        credentials     : "include"
    }
}

/**
 * Checks if given date(as first param) is less than equals to matching date.
 * @param {*} date 
 * @param {*} today 
 */
export const is_bday_valid = ( date, player_bday = '' ) => {
    if ( !date || date === '' || date === 'undefined' || typeof date === 'undefined' ) {
        return false;
    }
    
    /**
     * Checks if date is in Date object format.
     */
    if ( !( date instanceof Date ) ) {
        return false;
    }

    const today     = new Date();
    let current_year= (player_bday && player_bday === 'player_bday') ? date.getFullYear() === today.getFullYear() - 11 : date.getFullYear() === today.getFullYear(),
    current_month   = date.getMonth() === today.getMonth();

    /**
     * Checks if year is current or earlier, if not returns false.
     * @Control is here, that means date is an invalid instance of date.
     */
    if ( date.getFullYear() > today.getFullYear() ) {
        return false;
    }
    
    /**
     * Checks if month is current or earlier in the current year.
     * @Control is here, that means date is of current year.
     */
    if ( current_year && date.getMonth() > today.getMonth() ) {
        return false;
    }
    
    /**
     * Checks if date is yesterday's or earlier in the current month and year.
     * @Control is here, that means date is of current month and year.
     */
    if ( current_year && current_month && date.getDate() >= today.getDate() ) {
        return false;
    }
    
    return true;
}

/**
 * Checks if given date(as first param) is less than equals to matching date.
 * @param {*} date 
 * @param {*} today 
 */
export const is_representation_enddate_valid = ( date ) => {
    if ( !date || date === '' || date === 'undefined' || typeof date === 'undefined' ) {
        return false;
    }
    
    /**
     * Checks if date is in Date object format.
     */
    if ( !( date instanceof Date ) ) {
        return false;
    }

    const today     = new Date();
    let current_year= date.getFullYear() === today.getFullYear(),
    current_month   = date.getMonth() === today.getMonth();

    /**
     * Checks if year is current or earlier, if not returns false.
     * @Control is here, that means date is an invalid instance of date.
     */
    if ( date.getFullYear() < today.getFullYear() ) {
        return false;
    }
    
    /**
     * Checks if month is current or earlier in the current year.
     * @Control is here, that means date is of current year.
     */
    if ( current_year && date.getMonth() < today.getMonth() ) {
        return false;
    }
    
    /**
     * Checks if date is yesterday's or earlier in the current month and year.
     * @Control is here, that means date is of current month and year.
     */
    if ( current_year && current_month && date.getDate() < today.getDate() ) {
        return false;
    }
    
    return true;
}

/**
 * Shows date in display format
 * @param {*} date 
 */
export const get_display_format_date = ( date = '', today = false ) => {
    
    let dateTimeFormat;
    if ( !date || date === '' || date === 'undefined' || typeof date === 'undefined' ) {
        if ( !today ) {
            return '';
        }
        
        dateTimeFormat = new Date();
    }

    if ( !today || !dateTimeFormat ) {
        dateTimeFormat = new Date( date );
    }

    if ( !( dateTimeFormat instanceof Date ) ) {
        return '';
    }
    
    let month   = dateTimeFormat.getMonth() === "" ? '' : MonthsName[ dateTimeFormat.getMonth() ];
    month       = month ? month.substring( 0, 3 ) : '';
    
    return `${dateTimeFormat.getDate()} ${month} ${dateTimeFormat.getFullYear()}`;
}

export const get__format_date = ( date = '', today = false ) => {
    
    let dateTimeFormat;
    if ( !date || date === '' || date === 'undefined' || typeof date === 'undefined' ) {
        if ( !today ) {
            return '';
        }
        
        dateTimeFormat = new Date();
    }

    if ( !today || !dateTimeFormat ) {
        dateTimeFormat = new Date( date );
    }
    return `${dateTimeFormat.getFullYear()+'/'+ (dateTimeFormat.getMonth() + 1) + '/'+ dateTimeFormat.getDate()}`;
}

/**
 * Fetches league name by procided league id.
 * @param {*} id 
 * @param {*} leagues 
 */
export const get_league_name_by_id = ( id, leagues ) => {
    let league_name = [];
    if ( !leagues || leagues.length <= 0 ) {
        return '';
    }
    
    league_name = leagues.filter( league => {
        return parseInt( league.id ) === parseInt( id )
    });
    return league_name.length > 0 ? league_name[0].title : '';
}

/**
 * Extracts image name from url.
 * @param {*} url 
 */
export const get_img_name_from_url = url => {
    let file_name = '';
    if ( !url || url === '' ) {
        return file_name;
    }
    
    url = url.split( '/' );
    if ( url.length <= 0 ) {
        return file_name;        
    }
    
    file_name = url[ url.length - 1 ];
    return file_name;        
}

/**
 * Get days by given date.
 * @param {*} date 
 */
export const get_days_by_date = date => {
    if ( !date || date === '' )
        return 0;
    
    const one_day       = 24*60*60*1000; // hours*minutes*seconds*milliseconds
    const from_today    = new Date();
    const till_date     = new Date( date );
    
    if ( !(till_date instanceof Date) )
        return 0;    
        
    const diffDays = Math.round( Math.abs( ( from_today.getTime() - till_date.getTime() ) / ( one_day ) ) );
    return diffDays;
}

/**
 * Get months by given date.
 * @param {*} date 
 */
export const get_month_by_date = date => {
    const days = get_days_by_date( date );
    if ( !days || days === '' || days <= 0 )
        return '';
    
    const month = Math.round( Math.abs( days / 30 ) );
    return month;
}

/**
 * Converts date into dd/mm/yyyy format.
 * @param {*} date 
 */
export const get_db_date = date => {
    if ( !date || !( date instanceof Date ) )
        return '';

        let day = (`0${date.getDate()}`).slice(-2);
        let month = (`0${date.getMonth() + 1}`).slice(-2);

    return `${ day }/${ month }/${ date.getFullYear() }`;
}

/**
 * Get current user's full name.
 */
export const get_current_user_full_name = () => {
    const user = get_user_data( 'user' );
    if ( !user ) {
        return '';
    }

    return `${user.first_name} ${user.last_name}`;
}

/**
 * Handles notes attachment validation.
 * @param {*} file 
 */
export const is_note_attachment_valid = ( file ) => {
    let errors = [];
    if ( !file ) {
        return false;
    }
    
    if ( file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif' && file.type !== 'application/pdf' ) {
        errors.push( __x( `Only PDF, JPG/JPEG, PNG and GIF files are allowed.` ) );
    }

    /**
     * Calculate file size in bytes. 
     */
    const file_size_in_kb = file.size;
    
    /**
     * Max allowed file size is 5mb in bytes.
     */
    const max_allowed_file_size = 1024 * 1024 * 5;
    
    if ( file_size_in_kb > max_allowed_file_size ) {
        errors.push( __x( `File size should not be more than 5mb.` ) );
    }

    return {
        success: errors.length <= 0,
        messages: errors
    };
}

/**
 * Checks if date of services is valid or not. 
 * Must be greater than or equals to today.
 * @param {*} date 
 */
export const is_date_of_services_valid = date => {
    if ( !date || date === '' || date === 'undefined' || typeof date === 'undefined' ) {
        return false;
    }
    
    /**
     * Checks if date is in Date object format.
     */
    if ( !( date instanceof Date ) ) {
        return false;
    }

    const today = new Date();
    let return_obj = [];
    today.setHours(0,0,0,0);
    const date_of_services  = new Date( date );

    if(date_of_services > new Date(9999,12,31)) {
        return_obj.push({'success': false, 'message': __x( `Date of service is invalid.` ) });
    } else {
        return_obj.push({'success': true, 'message': ''});
    }
    
    return return_obj;
}

/**
 * Fetches limited text with 30 charcters.
 */
export const get_limited_player_note = ( note, limit = 64 ) => {
    if ( !note || note === '' ) {
        return false;
    }
    
    // return note.length > 30 ? note.substring( 0, 30 ) + '...' : note;
    return note.length > 64 ? note.substring( 0, limit ) + '...' : note;
}

/**
 * Handles notes attachment validation.
 * @param {*} file 
 */
export const is_a_valid_pdf = ( file ) => {
    let errors = [];
    if ( !file ) {
        return false;
    }
    
    if ( file.type !== 'application/pdf' ) {
        errors.push( `Only PDF files are allowed.` );
    }

    /**
     * Calculate file size in bytes. 
     */
    const file_size_in_kb = file.size;
    
    /**
     * Max allowed file size is 5mb in bytes.
     */
    const max_allowed_file_size = 1024 * 1024 * 5;
    
    if ( file_size_in_kb > max_allowed_file_size ) {
        errors.push( __x( `File size should not be more than 5mb.` ) );
    }

    return {
        success: errors.length <= 0,
        messages: errors
    };
}

/**
 * Checks if a stirng is valid url.
 * @param {*} url_str 
 */
export const is_url_valid = url_str => {
    const url_regex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

    if( !url_regex.test( url_str ) ) {
        return false;
    }

    return true;
}

/**
 * Adds http in url if there's no scheme(http|https) found.
 * Created specially for iframes to exclude these links by not considering them as an application path by react.
 * @param {*} url 
 */
export const schemize_url = url => {
    if ( !url || url === '' ) {
        return url;
    }
    
    if ( url.includes( 'http://' ) || url.includes( 'https://' ) ) {
        return url;
    }

    return `http://${url}`;
}

/**
 * Get query string values by keys.
 * @param {*} name 
 * @param {*} url 
 */
export const get_parameter_by_name = ( name, url ) => {
    if ( !url ) {
        url = window.location.search;
    }

    var urlParams = new URLSearchParams( url );

    if ( !urlParams.has( name ) ) {
        return '';
    }

    const values = urlParams.getAll( name );
    return values.length > 1 ? values : values[0];
}

export const is_protocol_exists = url => {
    return url.includes( 'http://' ) || url.includes( 'https://' );
}

export const __x = ( key = '', replaceTo = '', replaceWith = '' ) => {
    if ( !key || key === '' ) {
        return key;
    }

    let message = '';
    
    message = Messages[ key ] ? Messages[ key ] : '';
    if ( !message || message === '' ) {
        return message;
    }

    if ( Array.isArray( replaceTo ) && Array.isArray( replaceTo ) ) {
        for( let i in replaceTo ) {
            message = message.replace( replaceTo[i], replaceWith[i] );
        }
    } else if ( replaceTo !== '' ) {
        message = message.replace( replaceTo, replaceWith );
    }

    return message;
}

/**
 * Checks if the date string is 
 * @param {*} date 
 */
export const is_valid_db_date = ( date ) => {
    return date && date !== null && date !== '' && Date.parse( date );
}

/**
 * Gives current browsers' name.
 */
export const get_current_browser = () => {
    let objAgent    = navigator.userAgent,
    objbrowserName  = navigator.appName,
    objOffsetName, objOffsetVersion;
    
    /**
     * In Opera.
     */
    if ( objAgent.indexOf( "OPR/" ) !== -1 || objAgent.indexOf( 'Opera/' ) !== -1 ) {
        objbrowserName = "Opera"; 
    }

    /**
     * In Safari.
     */
    else if ( objAgent.indexOf( "Safari/" ) !== -1 && objAgent.indexOf( "Chrome/" ) === -1 && objAgent.indexOf( "Chromium/" ) === -1  ) {
        objbrowserName = "Safari"; 
    }
    
    /**
     * In Chrome.
     */
    else if ( objAgent.indexOf( "Chrome" ) !== -1 && objAgent.indexOf( 'Chromium/' ) === -1 ) { 
        objbrowserName = "Chrome"; 
    }

    /**
     * In Microsoft internet explorer.
     */
    else if ( objAgent.indexOf( "MSIE" ) !== -1 ) { 
        objbrowserName = "Microsoft Internet Explorer"; 
    }

    /**
     * In Microsoft internet edge.
     */
    else if ( objAgent.indexOf( "Trident/" ) !== -1 ) { 
        objbrowserName = "Microsoft Edge"; 
    }

    /**
     * In Firefox.
     */
    else if ( objAgent.indexOf( "Firefox" ) !== -1 && objAgent.indexOf( "Seamonkey/" ) === -1 ) { 
        objbrowserName = "Firefox"; 
    }

    /**
     * For other browser "name/version" is at the end of userAgent.
     */
    else if ( ( objOffsetName = objAgent.lastIndexOf(' ') + 1 ) < ( objOffsetVersion = objAgent.lastIndexOf( '/' ) ) ) {
        objbrowserName = objAgent.substring(objOffsetName, objOffsetVersion); 
        if ( objbrowserName.toLowerCase() === objbrowserName.toUpperCase() ) { 
            objbrowserName = navigator.appName; 
        }
    }
    
    // console.log( objbrowserName, ' >>>>>>>>>> ', objAgent, ' >>>>>>>>> ', navigator );
    return objbrowserName;
}

/**
 * Checks if current agent is mobile.
 */
export const is_mobile = () => { 
    if( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    ) {
       return true;
    } else {
       return false;
    }
}

/**
 * Get dd-mm-yyyy hh:mm date format from a timestamp
 */
export const get_date = timestamp => {
    if ( !timestamp || timestamp === '' || timestamp === 'undefined' || typeof timestamp === 'undefined' ) {
        return false;
    }

    const dateTimeFormat = new Date(timestamp * 1000),
    minute = dateTimeFormat.getMinutes() ? dateTimeFormat.getMinutes() <= 9 ? `0${dateTimeFormat.getMinutes()}` : dateTimeFormat.getMinutes() : '00';
    
    let month   = dateTimeFormat.getMonth() === "" ? '' : MonthsName[ dateTimeFormat.getMonth() ];
    month       = month ? month.substring( 0, 3 ) : '';
    
    return `${dateTimeFormat.getDate()} ${month} ${dateTimeFormat.getFullYear()} ${dateTimeFormat.getHours()}:${minute}`;

}

/**
 * Get dd-mm-yyyy hh:mm date format from a timestamp
 */
export const get_date_timestamp = ( timestamp, millisecond = true ) => {
    if ( !timestamp || timestamp === '' || timestamp === 'undefined' || typeof timestamp === 'undefined' ) {
        return false;
    }

    const dateTimeFormat = new Date( millisecond ? timestamp * 1000 : timestamp ),
    minute = dateTimeFormat.getMinutes() ? dateTimeFormat.getMinutes() <= 9 ? `0${dateTimeFormat.getMinutes()}` : dateTimeFormat.getMinutes() : '00';
    
    let month   = dateTimeFormat.getMonth() === "" ? '' : MonthsName[ dateTimeFormat.getMonth() ];
    month       = month ? month.substring( 0, 3 ) : '';
    
    return `${dateTimeFormat.getHours()}:${minute}, ${dateTimeFormat.getDate()} ${month} ${dateTimeFormat.getFullYear()}`;
}

/**
 * Set right drawer's min height property.
 * @param {*} height 
 */
export const set_drawer_min_height = height => {
    if ( !height ) {
        return false;
    }

    document.getElementById( 'drawerOpen' ).style.minHeight = `${height}px`;
}

/**
 * Display date time format from a db date.
 * @param {*} date 
 */
export const get_display_timestamp_from_date = date => {
    if ( !date || !is_valid_db_date( date ) ) {
        return '';
    }

    date = new Date( date );
    
    return get_date_timestamp( date.getTime(), false );
}

/**
 * Checks if maximum limits exceeded any files.
 * @param {*} files 
 */
export const is_size_exceeded = ( files ) => {
    if ( !files ) {
        return false;
    }

    const max_size  = 1024 * 1024 * 5;
    let total_size  = 0;
    for( let i in files ) {
        if ( !files[i] ) {
            continue;
        }

        const file = files[i];
        total_size += file.size
    }

    return total_size > max_size;
}

/**
 * Converts watch/video url to embed url.
 * @param {*} video_url 
 */
export const get_embedded_url = video_url => {
    if ( !video_url || video_url === '' ) {
        return video_url;
    }

    const supported_sites = {
        'vimeo.com'     : 'https://player.vimeo.com/video/{{key}}',
        'youtube.com'   : 'https://www.youtube.com/embed/{{key}}',
        'youtu.be'      : 'https://www.youtube.com/embed/{{key}}',
    };

    let current_domain = '';
    for( let i in supported_sites ) {
        const domain = i;
        if ( !video_url.includes( domain ) ) {
            continue;
        }

        current_domain = domain;
    }

    let key         = '',
    embed_url       = '',
    url_segments    = video_url.split( '/' );
    
    /**
     * Calculate key from watch url or video url.
     */
    switch ( current_domain ) {
        case 'youtu.be':
        case 'vimeo.com':
            key = url_segments ? url_segments[ url_segments.length - 1 ] : '';
            break;
        case 'youtube.com':
            url_segments = video_url.includes( 'watch?' ) ? video_url.split( '?' ) : [];
            key = url_segments.length ? get_parameter_by_name( 'v', url_segments[1] ) : '';
            break;
        default :
            key = '';
    }

    embed_url = key !== '' && supported_sites[ current_domain ] ? supported_sites[ current_domain ].replace( '{{key}}', key ) : video_url;
    return embed_url;
}

/**
 * fetches my ip.
 */
(() => {
    const ip = get_user_data( 'ip' );
    if ( ip && ip !== '' ){
        return false;
    }

    try {
        fetch( 'https://api.ipify.org/?format=json' )
        .then( response => response.ok ? response.json() : {} )
        .then( response => {
            if ( response.ip ) {
                set_user_data( 'ip', response.ip );
            }
        });
    } catch ( error ) {
        console.log( error );
    }
}
)();