import React from 'react';
import { get_date } from '../../../helpers/GeneralHelper';

const LoginHistoryList = ({ login_history_results }) => {
    let loginHistoryList = [];
        
    if ( !login_history_results || login_history_results === 'undefined' ) {
        return loginHistoryList;
    }

    for ( let i in login_history_results ) {
        let login_history_result = login_history_results[i];
        
        loginHistoryList.push(
            <tr key={i}>
                <td>{ login_history_result.email ? login_history_result.email : '' }</td>
                <td>{ login_history_result.timestamp ? get_date(login_history_result.timestamp) : '' }</td>
                <td>{ login_history_result.browser ? login_history_result.browser : '' }</td>
                <td>{ login_history_result.ip_address ? login_history_result.ip_address : '' }</td>
            </tr>
        );
    }

    return loginHistoryList;
}
export default LoginHistoryList;