import React from 'react';

const CareerHistory = ({ history }) => {
    if ( !history || !history.length ) {
        return null;
    }
    
    return (
        <div className="player-detail-content">
            <div className="usm-table table-responsive">
                <table className="table table-hover">
                    <thead className="usm-thead">
                        <tr>
                            <th scope="col">Year</th>
                            <th scope="col">Team</th>
                            <th scope="col">League</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            history.map( single_history => (
                                <tr key={ single_history.id }>
                                    <td className="fontBold">{ single_history.year }</td>
                                    <td>{ single_history.team }</td>
                                    <td>{ single_history.league }</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default CareerHistory;