import React from 'react';
import { Link } from 'react-router-dom';
import { is_valid_db_date, get_display_format_date } from '../../../helpers/GeneralHelper';


const ClosedClubCases = ({ closed_club_cases }) => {

    let closedClubCasesList = [];

    if (!closed_club_cases || closed_club_cases === 'undefined' || closed_club_cases.length <= 0) {
        return closedClubCasesList;
    }

    for (let i in closed_club_cases) {
        let closed_club_case = closed_club_cases[i];
        closedClubCasesList.push(
            <tr key={closed_club_case.id}>
                <td className="fontBold"><Link to={`/admin/club-case/${closed_club_case.id}`}>{closed_club_case.case_number && closed_club_case.case_number !== '' ? closed_club_case.case_number : ''}</Link></td>

                <td>{closed_club_case.master_position && Object.keys(closed_club_case.master_position).length > 0 ? closed_club_case.master_position.title : ''}</td>

                <td className="fontBold">{closed_club_case.fulfillment_date && closed_club_case.fulfillment_date !== '' && is_valid_db_date(closed_club_case.fulfillment_date) ? get_display_format_date(closed_club_case.fulfillment_date) : ''}</td>

                <td><div className="d-flex align-items-center"><span className="table-clubImg"><img src={closed_club_case.club && closed_club_case.club.image_path && closed_club_case.club.image_path !== '' ? closed_club_case.club.image_path : require('../../../assets/images/dummy-image.png')} alt="" /></span> 
                {closed_club_case.club && closed_club_case.club.club_name !== '' ? closed_club_case.club.club_name : ''}</div></td>

                <td><span className="table-btn btn-transWhite">{closed_club_case.case_player_considereds ? Object.keys(closed_club_case.case_player_considereds).length : 0}</span></td>

                <td className={closed_club_case.is_contract_signed && closed_club_case.is_contract_signed === true ? `text-success fontBold` : `text-danger fontBold`}>{closed_club_case.is_contract_signed && closed_club_case.is_contract_signed === true ? `Yes` : `No`}</td>
            </tr>
        );
    }
    return closedClubCasesList;
}
export default ClosedClubCases;