import React from 'react';
import { get_authorization_token, __x } from '../../../helpers/GeneralHelper';
import Request from '../../../helpers/Request';
import APIRoutes from '../../../API/Routes';
import { connect } from 'react-redux';
import { setOrganisation } from '../../../actions/OrganisationsAction';
import UpdateOrganisation from './UpdateOrganisation';
import Notes from '../players/Notes';
import Alert from '../../../helpers/Alert';

class organisationDetails extends React.Component {

    _page_title = 'Organisations And People - USM';

    constructor(props) {
        super(props)

        this._organisation_id = props.match.params.id;

        this._is_fetched = {
            get_organisation: false,
        }

        this.state = {
            organisation_name : '',
            lockscreen        : false,
            lockErrorMsg      : '',
        };
    }

    componentDidMount = () => {
        document.title = this._page_title;
        if (!this._is_fetched.get_organisation) {
            this.getOrganisation();
        }
    }

    componentWillReceiveProps = props => {
        this.setState({
            organisation_name: props.organisation.organisation && props.organisation.organisation.organization_name ? props.organisation.organisation.organization_name : ''
        })
    }

    getOrganisation = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x(`Session has been expired. Please reload the page to login!`)
            });
            return false;
        }

        const organisation_path = APIRoutes.organization;
        const request = new Request(organisation_path + this._organisation_id, {}, 'GET', headers).send();
        request.then(response => {

            if (response.success) {
                /**
                 * Dispatching action to set organisation to redux store.
                 */
                this.props.setOrganisation(response.data);
            }
        }, error => {
            console.log(error);
            this.setState({
                isSuccess : false,
                lockErrorMsg : error.message,
                lockscreen : error.lockscreen ? error.lockscreen : false,
            })
        });
    }

    goBack = () => {
        this.props.history.go(-1);
    }


    render = () => {
        const lockErrorMsg = this.state.lockErrorMsg ? <Alert message={this.state.lockErrorMsg} success={this.state.isSuccess}  floating="true" goBack={this.goBack} isNotFlash /> : '';

        return (

            <div id="mainContainer">
            {lockErrorMsg}
                <div className="heading-section auto-height marginTB34">
                    <h2 className="text-capitalize d-inline-block">View Details: {this.state.organisation_name}</h2> 
                    {/* <span className="font-size18 text-transform-normal"> {this.state.organisation_name}</span> */}

                </div>
                <div className="content-section">
                    <div className="row org-people-mobile">
                        <UpdateOrganisation
                            organisation_id={this._organisation_id} />

                        <Notes
                            is_organisation={true} />
                    </div>
                </div>
                { !this.state.lockscreen || <div className="modal-backdrop fade show"></div> }
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setOrganisation: (organisation) => dispatch(setOrganisation(organisation))
});

export default connect(mapStateToProps, mapDispatchToProps)(organisationDetails);