import React from 'react';
import { __x } from '../../../helpers/GeneralHelper';

const DeletePlayerInfoConfirm = props => {
    const showModal = props.modal ? 'show' : '';
    return (
        <div>
            <div className={`modal form-modal z-index99999 fade modal-active ${showModal}`} style={{ transform: props.modal ? `translate(0, 0)` : 'translate(-125%, -125%)' }}>
                <div className="modal-dialog modal-dialog-centered maxWidth400">
                    <div className="modal-content">
                        <div className="modal-body form-modal-body">
                            <h5 className="form-modal-content text-white fontBold text-center">
                                { __x( `Deleting a {{type}} is permanent, and cannot be undone.`, `{{type}}`, props.type ) } { __x( `Do you wish to proceed with deleting?` ) }
                            </h5>
                        </div>
                        <div className="modal-footer form-modal-footer d-flex justify-content-between">
                            <button type="button" className="default-btn btn-danger" onClick={ props.onClose }>{ __x( `No, Cancel` ) }</button>
                            <button type="button" className="default-btn btn-success" onClick={ props.onConfirm }>{ __x( `Yes, Delete` ) }</button>
                        </div>
                    </div>
                </div>
            </div>
            { !props.modal || <div className="modal-backdrop fade show z-index9999"></div> }
        </div>
    );
}
export default DeletePlayerInfoConfirm;