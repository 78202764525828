import React from 'react';

const Logo = ({ siteLogo, tnc }) => {
    if ( siteLogo ) {
        return <img src={require( '../assets/images/usm-site-logo.png' )} alt="USM Site Logo" />
    }

    const header_class = tnc ? `usm-modal-header-tc` : `usm-modal-header`;
    return (
        <div className={`Logo modal-header ${header_class}`}>
            <img src={require( '../assets/images/usm-logo.png' )} alt="Logo" />
        </div>
    );
}

export default Logo;