import React from 'react';
import { get_authorization_token, __x } from '../../../helpers/GeneralHelper';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import { withRouter, Link } from 'react-router-dom';
import { is_valid_db_date, get_current_user_role, get_limited_player_note, get_display_format_date , isUserImageValid } from '../../../helpers/GeneralHelper';
import PlayerNotesConciergeViewBox from './PlayerNotesConciergeViewBox';
import UpdateNote from '../common/UpdateNote';
import UploadPics from '../UploadPics';
import Alert from '../../../helpers/Alert';
import UpdateConcierge from '../common/UpdateConcierge';
import LoadingSpinner from '../common/LoadingSpinner';

class PlayerRightSidebar extends React.Component {

    constructor(props) {
        super(props);

        this._notes             = React.createRef();
        this._concierges        = React.createRef();
        this._uploadComponent   = React.createRef();
        this.player_id          = props.match.params.player_id;
        this.current_user_role  = get_current_user_role();

        this.state = {
            player_notes: [],
            openStatus: false,
            imgPreview: '',
            player_name:'',
            fileName        : __x( `Upload Photo` ),
            isbadgeValid    : false,
            badgeError      : '',
            responseMsg     : '',
            badge           : {},
            isProcessing    : false,
            badgeUploaded   : false,
            isSubmitted     : false,
            isSuccess       : false,
            imgUploadCancel : __x( `Cancel` ),
            imgUploadHeader : __x( `Replace Photo` ),
            imgPreviewInProfile : '',
            fileNameInProfile : ''

        }
    }

    componentDidMount = () => {
        this.getPlayerNoteConcierges();
        this.getPlayerDetail();
    }

    /**
     * get single player details based on id
     */
    getPlayerDetail = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }
        const request = new Request(APIRoutes.player + this.player_id, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                let response_data = response.data,
                    imgPreview = this.state.imgPreview,
                    player_name = this.state.player_name,
                    fileName = this.state.fileName,
                    imgPreviewInProfile = this.state.imgPreviewInProfile,
                    fileNameInProfile = this.state.fileNameInProfile ;
                imgPreview =(response_data.image_path !== null ? response.file_path + response_data.image_path : ""); 
                player_name = response_data.first_name + " " + response_data.last_name;
                fileName = response_data.image_path ? response_data.image_path : __x( `Upload Photo` );
                imgPreviewInProfile = (response_data.image_path !== null ? response.file_path + response_data.image_path : "");
                fileNameInProfile = response_data.image_path ? response_data.image_path : '';
                this.setState({
                    fileName,
                    imgPreview,
                    player_name,
                    imgPreviewInProfile,
                    fileNameInProfile
                })

            }
        }, error => {
            console.log(error);
        });
    }

    getPlayerNoteConcierges = () => {
        const headers = get_authorization_token();
        if (!headers) {
            return false;
        }

        const request = new Request(APIRoutes.get_player_notes_concierges + this.player_id, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                let player_notes = this.state.player_notes;
                player_notes = response.data;
                this.setState({
                    player_notes
                });
            }
        }, error => {
            console.log(error);
        });
    }

    getPlayerNoteConciergesHidePopup = () => {
        this.setState({
            openStatus : !this.state.openStatus
        });
        this.getPlayerNoteConcierges();
    }

    /**
     * Toggle status dropdown
     */
    toggleStatusDropdown = (event) => {
        this.setState({
            openStatus: !this.state.openStatus
        });
    }

    handleBadgeChange = ( event, file ) => {        
        const isImageValid = isUserImageValid( file );
        if ( !isImageValid ) {
           this.setState({
                fileName: __x( `Upload Photo` ),
                isbadgeValid: false,
                badgeError  : "No Image Found!",
            });
        } else {
            if ( !isImageValid.success ) {
                this.setState({
                    fileName: __x( `Upload Photo` ),
                    isbadgeValid: false,
                    badgeError  : isImageValid.messages.map( ( error, idx ) => <li key={idx}>{error}</li> )
                });
            } else {
                /**
                 * Get preview file url using file reader
                 */
                const reader = new FileReader();
                reader.readAsDataURL( file );
                reader.onloadend = function ( e ) {
                    this.setState({
                        imgPreview  : [ reader.result ],
                        fileName    : file.name,
                        badge       : file,
                        isbadgeValid: true,
                        badgeError  : ''
                    });
                }.bind( this );
            }
        }
    }

    handleUplodadBadge = ( event ) => {
        event.preventDefault();

        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( !this.state.isbadgeValid ) {
            return false;
        }

        this.setState({
            isProcessing: true
        });

        const badge_updated_path = APIRoutes.player_profile_image + this.player_id;
        
        let new_state   = {},
        formData        = new FormData();
        formData.append( 'file', this.state.badge );
        
        /**
         * Sending request to add/update player image.
         */
        const request = new Request( badge_updated_path, formData, 'POST', headers, true ).send();
        request.then( response => {
            new_state = {
                badgeUploaded   : response.success,
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message
            };
            
            if ( response.success ) {
                this.getPlayerDetail();
                this._uploadComponent.current.closePopup();
            }
            
            this.setState( new_state );
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMsg     : error.message
            };
            this.setState( new_state );
        });
    }

    onCloseImgUpload = () => {
        this.setState({
            badge           : {},
            imgPreview : this.state.imgPreviewInProfile ? this.state.imgPreviewInProfile : '',
            fileName : this.state.fileNameInProfile && this.state.fileNameInProfile !== '' ? this.state.imgPreviewInProfile : __x( `Upload Photo` )
        })
        
    }

    resetAlert = () => {
        this.setState({
            responseMsg: ''
        });
    }

    render() {
        const notes = this.state.player_notes,
        navigableRole = this.current_user_role === 'Agent' ? 'agent' : 'admin';
        const player_notes_list = notes && notes.length > 0 ? notes.map( note => {
            const is_restricted = note.is_restricted && this.current_user_role !== 'Admin';

            /**
             * If current note is restricted for current user.
             */
            if ( is_restricted ) {
                console.log(note.detail === "");
                return (
                    <li key={ note.id } className="media">
                        <div className="media-body clearfix dw-note-detail">
                            <h5>{(note.note === "") ? 'Note' : (note.detail === "") ? 'Concierge' : ''}</h5>
                            <div className="note-add-modify">
                                <div className="row">
                                    <div className="col-md-12">
                                        <img src={ require("../../../assets/images/Restricted.png") } alt="" />
                                        <p className="fontBold text-danger font-size14 restricted-css">Restricted
                                        <span className="fontBold text-white d-block">Please contact administrator.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </li>
                );
            }
            
            /**
             * List for non restricted notes
             */
            return (
                <div key={note.id} className="dw-note-detail dw-note-detail-hover position-relative">
                     <PlayerNotesConciergeViewBox playerNote={note} />
                     <h5>{note.note ? 'Note' : note.detail ? 'Concierge' : ''}</h5>
                     <h6>{ is_valid_db_date(note.updated_at) ? get_display_format_date(note.updated_at) : ''}, {note.added_by} <span>({(note.player_note_files && note.player_note_files.length) > 0 ? note.player_note_files.length : (note.player_concierge_files && note.player_concierge_files.length) > 0 ? note.player_concierge_files.length : 0}  Attachments)</span></h6>
                     <p>{note.note ? get_limited_player_note( note.note ) : note.detail ? get_limited_player_note( note.detail ) : ''}</p>
                     <Link to={`/${navigableRole}/player-notes-and-concierge/${this.player_id}`}>View</Link>
                    
                 </div>
            );
        }) : '';

        const openStatus = this.state.openStatus ? 'show' : '',
        responseMsg = this.state.responseMsg !== '' && this.state.isSubmitted && this.current_user_role !== 'Agent' ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} floating="true" onClose={this.resetAlert} /> : '';

        const playerAddConcierge = this.current_user_role === 'Agent' ? 'player-add-concierge' : '';

        return (
            <div>
                {responseMsg} 
                {this.state.isProcessing ? <LoadingSpinner/> : ''}
            
                <div id="drawerOpen" className="d-flex align-items-stretch">
                    <div className="drawerInner">
                        <div className="dw-user-block border-top-0 auto-height">
                            {
                                this.current_user_role === 'Agent' ? '' : 
                                <UploadPics 
                                    ref={ this._uploadComponent }
                                    badgeUploaded={ this.state.badgeUploaded }
                                    onChange={ this.handleBadgeChange }
                                    onSave={ this.handleUplodadBadge }
                                    imgPreview={ this.state.imgPreview }
                                    file={ this._file }
                                    resetBadgeUploaded={ this.resetBadgeUploaded }
                                    onClose={ this.onCloseImgUpload }
                                    error={ this.state.badgeError }
                                    fileName={ this.state.fileName }
                                    required={true}
                                    title={this.state.imgUploadHeader}
                                    cacnelText={ this.state.imgUploadCancel }
                                    badge={ this.state.badge }
                                />
                            }
                            <div className="player-img-wrap">
                                <img src={ this.state.imgPreviewInProfile && this.state.imgPreviewInProfile !== '' ? this.state.imgPreviewInProfile : require('../../../assets/images/dummy-image.png') } className="marginT14" alt="" />
                            </div>                        
                        </div>

                        <div className="border-bottom3x text-center paddingTB12">
                            <h5>{this.state.player_name}</h5>
                        </div>
                        <div className="dw-user-detail border-bottom-0">
                            <div className="dw-user-contact-detail border-top-0 marginT0">
                                <div className="dw-user-heading marginB20 position-relative">Notes & Concierge
                                <button onClick={this.toggleStatusDropdown} className="float-right transparent-btn padding0" ><img src={require('../../../assets/images/add-icon.png')} alt="" /></button>

                                <div className={`dropdown-menu purpleBg-dropdown ai-dropdown purpleBg-right player-add-note ${playerAddConcierge} ${openStatus}`} aria-labelledby="dropdownMenuCard" onClick={e => e.stopPropagation()}>
                                        <button onClick={ this._notes.toggleNotesPopup } className={`transparent-btn dropdown-item`}>Note</button>
                                    { this.current_user_role === 'Agent' ? '' : <button onClick={this._concierges.toggleConciergesPopup} className={`transparent-btn dropdown-item`}>Concierge</button> } 
                                    </div>
                                </div>
                            </div>
                            {player_notes_list}
                        </div>
                        <div className="dw-user-action text-left">
                            <Link to={`/${navigableRole}/player-notes-and-concierge/${this.player_id}`}>View All</Link>
                        </div>
                    </div>
                    <UpdateNote 
                        ref={ elt => this._notes = elt }
                        player_id={this.player_id}
                        refreshNotes={ this.getPlayerNoteConciergesHidePopup }
                        userRole={ this.current_user_role }
                    />

                    <UpdateConcierge
                    ref={ elt => this._concierges = elt }
                    player_id={this.player_id}
                    refreshConcierges={ this.getPlayerNoteConciergesHidePopup }
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(PlayerRightSidebar);