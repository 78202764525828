
export const setClubs = ( clubs ) => dispatch => {
    dispatch({
        type: 'SET_CLUBS',
        payload: {
            clubs: clubs
        }
    });
}

export const appendClubs = (all_clubs, loaded_clubs) => dispatch => {
    dispatch({
        type: 'APPEND_CLUBS',
        payload: {
            clubs: [
                ...all_clubs,
                ...loaded_clubs
            ]
        }
    });
}

export const setClub = ( club ) => dispatch => {
    dispatch({
        type: 'SET_CLUB',
        payload: {
            club: club
        }
    });
}

export const setLeagues = ( leagues ) => dispatch => {
    dispatch({
        type: 'SET_LEAGUES',
        payload: {
            leagues: leagues
        }
    });
}

export const setActiveCases = ( cases ) => dispatch => {
    dispatch({
        type: 'SET_ACTIVE_CASES',
        payload: {
            cases
        }
    })
}

export const setClosedCases = ( cases ) => dispatch => {
    dispatch({
        type: 'SET_CLOSED_CASES',
        payload: {
            cases
        }
    })
}

export const setClubContact = contact => dispatch => {
    dispatch({
        type: 'SET_CLUB_CONTACT',
        payload: {
            contact
        }
    })
}