import React from 'react';
import Request from '../../../helpers/Request';
import APIRoutes from '../../../API/Routes';
import { get_current_user_role, get_authorization_token, __x } from '../../../helpers/GeneralHelper';
import { setActiveCases } from '../../../actions/ClubsAction';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import ActiveCase from './ActiveCase';

class ActiveCases extends React.Component {
    constructor( props ) {
        super( props );

        this._club_id           = props.match.params.id;
        this._fetched           = false;
        this.current_user_role  = get_current_user_role();

        this.state = {
            cases           : [],
            active_widgets  : [],
            minIndex        : 0,
            maxIndex        : 3,
            animatedClass   : '',
        }
    }

    componentWillReceiveProps = new_props => {
        this.setState({
            cases: new_props.clubs.activeCases
        })
    }

    listCases = () => {
        const cases = this.state.cases;
        if ( cases.length <= 0 ) {
            return false;
        }
        
        const active_widgets = this.state.active_widgets;
        return cases.map( ( res, idx ) => {
            return res && res !== null ? 
                <ActiveCase 
                    key={res.id} 
                    case={res} 
                    idx={idx}
                    active_widgets={ active_widgets }
                /> : '';
        });
    }
    
    render = () => {
        const cases = this.state.cases;
        return (
            <div className="active-cases">
                <div className="heading-section d-flex align-items-center auto-height">
                    Active Cases <span className="badge badge-pill badge-success">{ cases.length > 0 ? cases[0] === null ? 0 : cases.length : 0 }</span> 
                    
                    {
                        this.current_user_role === 'Agent' ? '' : 
                        <Link to="/admin/add-club-case/" className="ml-auto">
                            <img src={ require( '../../../assets/images/add-icon.png' ) } alt="" />
                        </Link>
                    }
                </div>
                <div className="position-relative">
                <div className="row usm-cards club-slider-block widget-slider marginB35">
                    { this.listCases() }    
                </div>
                <div className="widget-arrow">
                       
                            { cases.length <= 4 ? '' : 
                                <button onClick={ this.slidePrevWidget } className="club-slider-btn float-left" data-toggle="offcanvas">
                                    <i className="material-icons text-success">keyboard_arrow_left</i>
                                </button>
                            }
                            { cases.length <= 4 ? '' : 
                                <button onClick={ this.slideNextWidget } className="club-slider-btn float-right" data-toggle="offcanvas">
                                    <i className="material-icons text-success">keyboard_arrow_right</i>
                                </button>
                            }
                        
                    </div>
                </div>
                
            </div>
        );
    }

    /**
     * Navigate to previous slide.
     */
    slidePrevWidget = () => {
        const cases = this.state.cases;
        if ( !cases || cases.length <= 0 ) {
            return false;
        }

        let minIndex = this.state.minIndex,
        maxIndex = this.state.maxIndex;

        if ( minIndex <= 0 ) {
            return false;
        }

        minIndex -= 1;
        maxIndex -= 1;
        
        const new_cases = cases.filter( ( contact, idx ) => {
            return idx >= minIndex && idx <= maxIndex;
        });
        
        this.setState({
            active_widgets: new_cases,
            minIndex,
            maxIndex,
            animatedClass : `animated bounceInLeft`
        });
    }
    
    /**
     * Navigate to nex slide.
     */
    slideNextWidget = () => {
        const cases = this.state.cases;
        if ( !cases || cases.length <= 0 ) {
            return false;
        }
        
        let minIndex = this.state.minIndex,
        maxIndex = this.state.maxIndex;
        
        if ( maxIndex > ( cases.length - 2 ) ) {
            return false;
        }
        
        minIndex = minIndex + 1;
        maxIndex = maxIndex + 1;
        const new_cases = cases.filter( ( contact, idx ) => {
            return idx >= minIndex && idx <= maxIndex;
        });
        
        this.setState({
            active_widgets: new_cases,
            minIndex,
            maxIndex,
            animatedClass : `animated bounceInRight`
        });
    }

    getCases = ( reset_active_widgets = false ) => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const cases_path = APIRoutes.getcasewidget + this._club_id;
        const request = new Request( cases_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                const cases = response.data && response.data.active ? response.data.active : [];
                
                let active_widgets = this.state.active_widgets;
                if ( reset_active_widgets ) {
                    active_widgets = cases ? cases.filter( ( contact, idx ) => {
                        return idx <= 3;
                    }) : [];
                    this.setState({
                        active_widgets,
                        minIndex : 0,
                        maxIndex : 3,
                    })
                }
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                this.props.setActiveCases( cases );
                this._fetched = true;
            }
        }, error => {
            console.log( error );
        });
    
    }

    componentDidMount = () => {
        if ( !this._fetched ) {
            this.getCases();
        }
    }
}

const mapStateToProps = ( state ) => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setActiveCases : ( cases ) => dispatch( setActiveCases( cases ) )
});

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( ActiveCases ) );