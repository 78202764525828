import React, { Component } from 'react';
import Request from '../../../helpers/Request';
import APIRoutes from '../../../API/Routes';
import { get_current_user_role, get_authorization_token, __x } from '../../../helpers/GeneralHelper';
import ClosedCases from './ClosedCases';
import ActiveCases from './ActiveCases';
import Alert from '../../../helpers/Alert';
import { Scrollbars } from 'react-custom-scrollbars';

export default class Cases extends Component {
    
    _page_title = 'Cases - USM';
    countdown;
    
    /**
     * Time in miliseconds to wait until typing has done.
     */
    wait_for_search = 800;

    constructor(props) {
        super(props);

        this._player_id = props.match.params.player_id;
        this.current_user_role = get_current_user_role();
        
        this.state = {
            closedCasesOpen      : false,
            closed_cases         : [],
            active_cases         : [],
            active_widgets       : [],
            predictive_case_list : [],
            case_club_name       : '',
            case_id              : '',
            _fetched             : {
                get_player_cases : false
            },
            minIndex             : 0,
            maxIndex             : 3,
            animatedClass        : '',
            responseMessage      : '',
            success              : false,
            isSuccess            : false,
            isError              : true,
            isProcessing         : false
        }
    }

    componentDidMount = () => {
        document.title = this._page_title;
        if (!this.state._fetched.get_player_cases) {
            this.getPlayerCases();
        }
    }

    /**
     * get all active/closed cases of a particular player
     */
    getPlayerCases = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError: true,
                responseMessage: __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const player_cases_path = APIRoutes.get_player_case_widget + this._player_id;
        const request = new Request(player_cases_path, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                const closed_cases = response.data && response.data.closed ? response.data.closed : [];
                const active_cases = response.data && response.data.active ? response.data.active : [];
                let active_widgets = this.state.active_widgets;
                active_widgets = active_cases ? active_cases.filter((contact, idx) => {
                    return idx <= 3;
                }) : [];
                this.setState({
                    closed_cases,
                    active_cases,
                    active_widgets,
                    _fetched: {
                        get_player_cases: true
                    }
                })
            }
        }, error => {
            console.log(error);
        });
    }

    /**
        * Navigate to previous slide.
        */
    slidePrevWidget = () => {
        const active_cases = this.state.active_cases;
        if (!active_cases || active_cases.length <= 0) {
            return false;
        }

        let minIndex = this.state.minIndex,
            maxIndex = this.state.maxIndex;

        if (minIndex <= 0) {
            return false;
        }

        minIndex -= 1;
        maxIndex -= 1;

        const new_active_cases = active_cases.filter((contact, idx) => {
            return idx >= minIndex && idx <= maxIndex;
        });

        this.setState({
            active_widgets: new_active_cases,
            minIndex,
            maxIndex,
            animatedClass: `animated bounceInLeft`
        });
    }

	/**
	 * Navigate to nex slide.
	 */
    slideNextWidget = () => {
        const active_cases = this.state.active_cases;
        if (!active_cases || active_cases.length <= 0) {
            return false;
        }
        let minIndex = this.state.minIndex,
            maxIndex = this.state.maxIndex;

        if (maxIndex > (active_cases.length - 2)) {
            return false;
        }

        minIndex = minIndex + 1;
        maxIndex = maxIndex + 1;
        const new_active_cases = active_cases.filter((contact, idx) => {
            return idx >= minIndex && idx <= maxIndex;
        });
        this.setState({
            active_widgets: new_active_cases,
            minIndex,
            maxIndex,
            animatedClass: `animated bounceInRight`
        });
    }

	/**
	 * Handles input field change on searching a case for assigning to player
	 */
    handleChange = event => {
        const target    = event.target,
        value           = target.value;

        if ( value.length === 0 || value === '' ) {
            this.setState({
                predictive_case_list : []
            });
        }

        if ( value.length < 3 ) {
            this.setState({ case_club_name: value });
            return false;
        }
        
        this.setState({
            case_club_name: value
        });

        clearTimeout( this.countdown );
        this.countdown = setTimeout( this.searchCase, this.wait_for_search );
    }

    /**
     * Search after {`this.wait_for_search`} miliseconds case when typing is done and text is more than 3 characters.
     */
    searchCase = () => {
        const query = this.state.case_club_name;
        if ( query.length < 3 ) {
            return false;
        }

        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError: true,
                responseMessage: __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }
        
        this.setState({ isProcessing : true });
        let predictive_case_list    = [];
        const player_cases_path     = APIRoutes.active_case_list + this._player_id + "/" + query;
        const request = new Request( player_cases_path, {}, 'GET', headers ).send();
        
        request.then( response => {
            if ( response.success ) {
                predictive_case_list = response.data && response.data.length > 0 ? response.data : [];
                this.setState({
                    predictive_case_list
                });
            }
            this.setState({ isProcessing : false });
        }, error => {
            console.log(error);
        });

        this.setState({
            case_id: '',
            predictive_case_list
        });
    }

	/**
	 * select case from dropdown
	 */
    selectCase = ( case_id, case_number, club_name ) => {
        this.setState({
            predictive_case_list: [],
            case_club_name: `Case Number: ${case_number} Club Name: ${club_name}`,
            case_id: case_id

        });
    }

	/**
	 * Assign case to player
	 */
    assignCaseToPlayer = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const player_cases_path = APIRoutes.add_player_existing_case;
        const formData = new FormData();
        formData.append('case_id', this.state.case_id);
        formData.append('player_id', this._player_id);
        const request = new Request(player_cases_path, formData, 'POST', headers, true).send();
        request.then(response => {
            if (response.success) {
                this.getPlayerCases();
            }
            this.setState({
                _fetched: {
                    get_player_cases: false
                },
                case_club_name: '',
                case_id: '',
                responseMessage: response.message,
                success: response.success,
                isSuccess: response.success
            });
        }, error => {
            console.log(error);
            this.setState({
                case_club_name: '',
                responseMessage: error.message,
                success: error.success,
                isSuccess: error.success,
                case_id: ''
            });
        });
    }

    resetAlert = () => {
        this.setState({
            isSuccess: false,
            responseMessage: ''
        });
    }
    /**
     * toggle closed case display
     */
    toggleClosedCases = () => {
        this.setState({
            closedCasesOpen: !this.state.closedCasesOpen
        });
    }
    render() {
        const responseMsg = this.state.responseMessage !== '' ? <Alert message={this.state.responseMessage} success={this.state.isSuccess} duration={5000} floating="true" onClose={this.resetAlert} /> : '';
        /* Predictive case list */
        const predictive_cases_exists = this.state.predictive_case_list && this.state.predictive_case_list.length > 0,
        predictive_case_list = predictive_cases_exists ?
            this.state.predictive_case_list.map(case_detail => {
                return <li key={case_detail.case_id} onClick={this.selectCase.bind(this, case_detail.case_id, case_detail.case_number, case_detail.club_name)}>Case Number: {case_detail.case_number} Club Name: {case_detail.club_name}</li>
            }) : '';

		/**
		 * disable assign case to player button
		 */
        const disableButton = this.state.case_id === '' ? true : false;
        
        const show_loader = this.state.isProcessing ? <img className="search-loader" src={require(`../../../assets/images/loader-usm.svg`)} alt="" /> : '';

        return (
            <div>
                {responseMsg}
                <div className="active-cases">
                    <div className="heading-section d-flex align-items-center auto-height">
                        Active Cases <span className="badge badge-pill badge-success">{this.state.active_cases ? this.state.active_cases.length : ''}</span>
                    </div>
                    <div className="position-relative">
                    <div className="row usm-cards club-slider-block widget-slider marginB35">
                        {this.state.active_cases && this.state.active_cases.length > 0 ? this.state.active_cases.map((active_case, idx) => {
                            return <ActiveCases key={active_case.id}
                                active_case={active_case}
                                active_widgets={this.state.active_widgets}
                                idx={idx} />
                        }) : ''
                        }
                    </div>
                    <div className="widget-arrow">
                            
                                {this.state.active_cases.length <= 4 ? '' :
                                    <button onClick={this.slidePrevWidget} className="club-slider-btn float-left" data-toggle="offcanvas">
                                        <i className="material-icons text-success">keyboard_arrow_left</i>
                                    </button>
                                }
                                {this.state.active_cases.length <= 4 ? '' :
                                    <button onClick={this.slideNextWidget} className="club-slider-btn float-right" data-toggle="offcanvas">
                                        <i className="material-icons text-success">keyboard_arrow_right</i>
                                    </button>
                                }
                            
                        </div>
                    </div>
                    
                </div>
                {
                    this.current_user_role === 'Agent' ? '' : 
                    <div className="border-bottom-trans marginTB35"></div>
                }
                
                {
                    this.current_user_role === 'Agent' ? '' : 
                    <div className="player-search">
                        <div className="heading-section d-flex align-items-center auto-height">
                            Assign Player to Cases
                        </div>
                        <div className="row filtter-section padding0 background-none auto-height">
                            <div className="col-md-5 col-sm-12">
                                <form className="usm-form">
                                    <div className="position-relative">
                                        <input autoComplete="off" onChange={ this.handleChange } value={this.state.case_club_name} name="search" className="form-control usm-search w-100 paddingR15" type="search" placeholder="Search by case ID, club" aria-label="Search" />
                                        { 
                                            !predictive_cases_exists ? '' :
                                            <Scrollbars style={{height: 200}} autoHide>
                                                <ul className="pre-search">{predictive_case_list}</ul>
                                            </Scrollbars>
                                        }
                                        { show_loader }
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-7 col-sm-12">
                                <button onClick={this.assignCaseToPlayer} disabled={disableButton} className="btn btn-usmPrimary">Assign Player to case</button>
                            </div>
                        </div>
                    </div>
                }

                <div className="border-bottom-trans marginTB35"></div>

                <div className="closed-cases">
                    <div className="heading-section d-flex align-items-center auto-height">
                        Closed Cases <span className="badge badge-pill badge-success">{this.state.closed_cases ? this.state.closed_cases.length : ''}</span>
                        <button onClick={ this.toggleClosedCases } className="transparent-btn ml-auto" type="button">
                        {
                            this.state.closedCasesOpen ? 
                            <img src={ require( '../../../assets/images/arrow-up.png' ) } alt="" />
                            :
                            <img src={ require( '../../../assets/images/arrow-down.png' ) } alt="" />
                        }
                    </button>
					</div>

                    <div className="usm-table club-cases-table table-responsive">
                        <table className="table table-hover">
                            <thead className="usm-thead">
                                <tr>
                                    <th scope="col">Case No</th>
                                    <th scope="col">Position</th>
                                    <th scope="col">Club</th>
                                    <th scope="col">Fulfilment Date</th>
                                    <th scope="col">Agent</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !this.state.closedCasesOpen ? <tr><td colSpan="5"></td></tr> :
                                    <ClosedCases
                                    closed_cases={this.state.closed_cases} />
                                }
                                
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        )
    }
}