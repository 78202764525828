const initialState = {
    organisation   : [],
    organisations  : [],
}

export default ( state = initialState, action ) => {
	switch ( action.type ) {
        case 'SET_ORGANISATION':
            return {
                ...state,
                organisation: action.payload.organisation
            };
        case 'SET_ORGANISATIONS':
            return {
                ...state,
                organisations: action.payload.organisations
            };    
        case 'APPEND_ORGANISATIONS':
            return {
                ...state,
                organisations: action.payload.organisations
            };
		default:
			return state;
	}
}