import React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import '../../../../node_modules/react-intl-tel-input/dist/main.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NotesHistory from './NotesHistory';
import { get_current_user_role, __x } from '../../../helpers/GeneralHelper';

const UpdateClubContacts = props => {
    const showModal     = props.modal ? 'show' : '';
    const today         = new Date();
    const submitDisabled= !props.isFormValid() || props.isProcessing ? true : false;
    const contact_notes = props.contact && props.contact.contact_notes ? props.contact.contact_notes : [];
    const email_phone_restricted = props.is_editing && get_current_user_role() !== 'Admin' && props.fields.is_restricted;
    
    return (
        <div>
            <div className={`modal form-modal fade modal-active ${showModal}`} style={{ transform: props.modal ? `translate(0, 0)` : 'translate(-125%, -125%)' }}>
                <div className="modal-dialog modal-dialog-centered maxWidth600">
                    <div className="modal-content">
                        <div className="modal-header form-modal-header d-flex align-items-center">
                            <img src={require('../../../assets/images/form-usm-logo.png')} alt="" />
                            <h5 className="form-modal-title">{ props.is_editing ? `Edit` : `Add` } Club Contact</h5>
                            <button onClick={ props.onClose } className="modal-close transparent-btn" data-dismiss="modal" aria-label="Close">
                                <img src={require('../../../assets/images/close-modal.png')} alt="" />
                            </button>
                        </div>
                        <form onSubmit={ props.onUpdate } action="" className="usm-form form-border">
                            <div className="modal-body form-modal-body">
                                <div className="row">
                                    <div className="col">
                                        <div className={ props.error.title === '' ? `form-group` : `form-group form-error`}>
                                            <label>Title in Club</label>
                                            <input onChange={ props.onChange } value={ props.fields.title } name="title" type="text" className="form-control" placeholder="Title in Club" />
                                            { props.error.title === '' || <div className="display-error">{ props.error.title }</div> }
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className={ props.error.status === '' ? `form-group` : `form-group form-error`}>
                                            <label>Status</label>
                                            <select onChange={ props.onChange } value={ props.fields.status } name="status" className="form-control">
                                                <option value=""></option>
                                                <option value="1">Active</option>
                                                <option value="0">Deactivated</option>
                                            </select>
                                            { props.error.status === '' || <div className="display-error">{ props.error.status }</div> }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className={ props.error.first_name === '' ? `form-group` : `form-group form-error`}>
                                            <label>First Name</label>
                                            <input onChange={ props.onChange } value={ props.fields.first_name } name="first_name" type="text" className="form-control" placeholder="First Name" />
                                            { props.error.first_name === '' || <div className="display-error">{ props.error.first_name }</div> }
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className={ props.error.last_name === '' ? `form-group` : `form-group form-error`}>
                                            <label>Last Name</label>
                                            <input onChange={ props.onChange } value={ props.fields.last_name } name="last_name" type="text" className="form-control" placeholder="Last Name" />
                                            { props.error.last_name === '' || <div className="display-error">{ props.error.last_name }</div> }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {
                                        email_phone_restricted ? 
                                        <div className="col">
                                            <div className="form-group"> 
                                                <label htmlFor="">Email</label>
                                                <p className="text-danger font-size13">{ __x( `Restricted, please contact the administrator.` ) }</p>
                                            </div>
                                        </div>
                                        :
                                        <div className="col">
                                            <div className={ props.error.email === '' ? `form-group` : `form-group form-error`}>
                                                <label>Email</label>
                                                <input onChange={ props.onChange } value={ props.fields.email } name="email" type="text" className="form-control" placeholder="Email" />
                                                { props.error.email === '' || <div className="display-error">{ props.error.email }</div> }
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    {
                                        email_phone_restricted ? 
                                        <div className="col">
                                            <div className="form-group"> 
                                                <label htmlFor="">Phone</label>
                                                <p className="text-danger font-size13">{ __x( `Restricted, please contact the administrator.` ) }</p>
                                            </div>
                                        </div>
                                        :
                                        <div className="col">
                                            <div className={ props.error.phone === '' ? `form-group` : `form-group form-error`}>
                                                <label>Phone</label>
                                                <div className="input-group">
                                                    <IntlTelInput 
                                                        onPhoneNumberChange={ props.onPhoneChange }
                                                        defaultCountry={ 'gb' }
                                                        css={['intl-tel-input w-100', 'form-control']} 
                                                        placeholder="Phone"
                                                        value={ props.fields.phone }
                                                        separateDialCode="true"
                                                        onSelectFlag={ props.onCountryChange }
                                                        ref={ props.set_country }
                                                    />
                                                </div>
                                                { props.error.phone === '' || <div className="display-error">{ props.error.phone }</div> }
                                            </div>
                                        </div>
                                    }

                                    <div className="col">
                                        <div className="form-group">
                                            <label>Birthday</label>
                                            <div className={ props.error.birthday === '' ? `input-group w-100` : `input-group w-100 form-error`}>
                                                <DatePicker
                                                    className="form-control"
                                                    selected={ props.fields.birthday }
                                                    onChange={ props.onBirthdayChanges }
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={ new Date( today.getFullYear(), today.getMonth(), today.getDate() - 1 ) }
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={30}
                                                />
                                            </div>
                                            { props.error.birthday === '' || <div className="display-error">{ props.error.birthday }</div> }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label>Note</label>
                                            <textarea onChange={ props.onChange } value={ props.fields.contact_note } name="contact_note" className="form-control textarea76" cols="" rows=""></textarea>
                                        </div>
                                    </div>
                                </div>
                                {
                                    contact_notes.length <= 0 ? '' : 
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <div className="note-header">
                                                    <label>Contact Notes</label>
                                                </div>
                                                <div className="notes-block">
                                                    <NotesHistory
                                                        notes={ contact_notes }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group usm-check">
                                            <input onChange={ props.onChange } value={ props.fields.show_birthday_notification } checked={ props.fields.show_birthday_notification ? true: false } name="show_birthday_notification" type="checkbox" id="show_birthday_notification_cc" />
                                            <label htmlFor="show_birthday_notification_cc">Show Birthday Notification</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {
                                        email_phone_restricted ? 
                                        <div className="col">
                                            <div className="form-group usm-check">
                                                <input checked={ true } name="show_birthday_notification" className="" type="checkbox" id="restrictUser" />
                                                <label htmlFor="restrictUser" className="text-danger disabled">{ __x( `Restricted, please contact the administrator.` ) }</label>	
                                            </div>
                                        </div>
                                        :
                                        <div className="col">
                                            <div className="form-group usm-check">
                                                <input onChange={ props.onChange } value={ props.fields.is_restricted } name="is_restricted" checked={ props.fields.is_restricted ? true: false } type="checkbox" id="is_restricted_cc" />
                                                <label htmlFor="is_restricted_cc">Restrict Personal Contact Details to Admin View Only</label>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="modal-footer form-modal-footer d-flex justify-content-between">
                                <button type="button" className="default-btn btn-danger min-width157" data-dismiss="modal" onClick={ props.onClose }>Cancel</button>
                                { !props.is_editing ? '' :
                                    <button onClick={ props.onDeleteClubContact( props.contact.id ) } type="button" className="default-btn btn-danger min-width157" data-dismiss="modal">Delete Contact</button>
                                }
                                <button type="submit" onSubmit={ props.onUpdate } disabled={ submitDisabled } className="default-btn btn-success min-width157">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            { !props.modal || <div className="modal-backdrop fade show"></div> }
        </div>
    );
}
export default UpdateClubContacts;