import React from 'react';
import { withRouter } from 'react-router-dom';
import { get_authorization_token, __x, is_valid_db_date, get_display_format_date, get_limited_player_note, set_drawer_min_height } from '../../../helpers/GeneralHelper';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import Alert from '../../../helpers/Alert';
import { Scrollbars } from 'react-custom-scrollbars';

class IntelNotes extends React.Component {
    constructor( props ) {
        super( props );

        this.is_fecthed = false;
        this._agent_id  = props.match.params.agent_id;
        this._file_url  = '';
        
        this.state = {
            notes           : [],
            full_shown_text : {},
            expanded        : false,
            isSuccess       : false,
            responseMsg     : ''
        }
    }

    /**
     * Show/Hide all notes list.
     */
    toggleExpansion = () => {
        this.setState({
            expanded : !this.state.expanded
        });
    }

    /**
     * Show/Hide full text of a note.
     */
    toggleFullText = note_id => {
        const full_shown_text = this.state.full_shown_text;

        if ( full_shown_text[ note_id ] ) {
            delete full_shown_text[ note_id ];
        } else {
            full_shown_text[ note_id ] = true;
        }        

        this.setState({
            full_shown_text
        });
    }

    /**
     * Get list of all notes.
     */
    getNotes = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }
        
        const path = APIRoutes.intel_notes + this._agent_id;
        const request = new Request( path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                this.is_fecthed = true;
                this._file_url = response.file_url ? response.file_url : '';
                this.setState({
                    notes : response.data
                });

                /**
                 * Increase drawer's height.
                 */
                if ( response.data && response.data.length >= 4 ) {
                    set_drawer_min_height( 1570 );
                }
            }
        }, error => {
            this.setState({
                responseMsg : error.message
            });
        });
    }
    
    componentDidMount = () => {
        if ( !this.is_fecthed ) {
            this.getNotes();
        }
    }

    resetAlert = () => {
        this.setState({
            responseMsg : ''
        });
    }

    render = () => {
        const notes = this.state.notes,
        view_btn    = this.state.expanded ? <img src={ require( '../../../assets/images/arrow-up.png' ) } alt="Arrow Up" /> : 'View All',
        alert       = this.state.responseMsg !== '' ? <Alert message={ this.state.responseMsg } success={ this.state.isSuccess } floating="true" onClose={ this.resetAlert } /> : '';

        return (
            <React.Fragment>
                {alert}
                <div className="dw-user-sub-detail">
                    <div className="dw-user-heading">Intel Notes</div>
                    <Scrollbars autoHeightMax={900} autoHide autoHeight hideTracksWhenNotNeeded className="scrollbar-div">
                        {
                            notes && notes.length ? notes.map(( note, idx ) => {
                                if ( !this.state.expanded && idx > 4 ) {
                                    return '';
                                }

                                const full_shown_text = this.state.full_shown_text;
                                const text = note.note && full_shown_text[ note.id ] ? note.note : get_limited_player_note( note.note, 90 );

                                return (
                                    <div className="intel-note paddingB20 border-bottom-trans" key={ note.id }>
                                        <div className="dw-user-subheading">Note</div>
                                        <p onClick={ () => this.toggleFullText( note.id ) } className="text-justify">{ text }</p>
                                        <div className="dw-user-modified-detail">
                                            <div className="row">
                                                <div className="col font-size13 paddingR0">Added by:</div>
                                                <div className="col font-size13 fontBold paddingL0" title={ note.added_by ? note.added_by : '' }>{ note.added_by ? note.added_by : '' }</div>
                                            </div>
                                            <div className="row">
                                                <div className="col font-size13 paddingR0">Last Modified:</div>
                                                <div className="col font-size13 fontBold paddingL0">{ note.updated_at && is_valid_db_date( note.updated_at ) ? get_display_format_date( note.updated_at ) : '' }</div>
                                            </div>
                                            <div className="row">
                                                <div className="col font-size13 paddingR0">Attachments:</div>
                                                <div className="col font-size13 paddingL0">
                                                    
                                                        {
                                                            note.user_note_files && note.user_note_files.length ? note.user_note_files.map( file => {
                                                                const file_url = file && file.filename ? this._file_url + file.filename : '';
                                                                return (
                                                                    <React.Fragment key={ file.id }>
                                                                        <a href={ file_url } title={ file && file.original_filename ? file.original_filename : file.filename } target="_blank" rel="noopener noreferrer">
                                                                            { file && file.original_filename ? file.original_filename : file.filename }
                                                                        </a>
                                                                        <div className="w-100"></div>
                                                                    </React.Fragment>
                                                                );
                                                            }) : ''
                                                        }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : ''
                        }
                    </Scrollbars>
                </div>
                {
                    notes && notes.length <= 5 ? '' : 
                    <div className="dw-user-action padding0 paddingL15">
                        <button onClick={ this.toggleExpansion } className="button-color transparent-btn">{ view_btn }</button>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default withRouter( IntelNotes );