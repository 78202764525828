import React from 'react';
import { get_authorization_token, get_current_user_full_name, get_db_date, __x } from '../../../helpers/GeneralHelper';
import APIRoutes from '../../../API/Routes';
import Select from 'react-select';
import Request from '../../../helpers/Request';
import DatePicker from "react-datepicker";
import UpdateNote from '../common/UpdateNote';
import Alert from '../../../helpers/Alert';
import { Link, withRouter } from 'react-router-dom';

class AddPlayerCase extends React.Component {

    countdown;

    /**
     * Time in miliseconds to wait until typing has done.
     */
    wait_for_search = 800;

    constructor(props) {
        super(props);

        this._notes = React.createRef();

        this.state = {
            _is_fetched: {
                get_agent_options: false,
                get_professional_options: false,
                get_primary_position: false,
                get_league: false,
            },
            fields: {
                master_requirement_id: '1',
                status: '1',
                player_name: '',
                player_id: '',
                agent_representation: '',
                agency_representation: '',
                agent_id: null,
                master_professional_level_id: '',
                master_position_id: '',
                additional_position_ids: null,
                agent_representation_enddate: null,
                current_club: '',
                master_league_id: '',
                detail: '',
                case_note: [],
                case_attachments: []
            },
            agentOptions: [],
            professionalLevelOptions: [],
            primaryPositionOptions: [],
            additionalPositionOptions: [],
            leagueOptions: [],
            predictive_player_list: [],
            isProcessing: false,
            responseMessage: '',
            isError: false,
            isSuccess: false,
            isSubmitted: false,
            fieldsValidityError: {
                master_position_id: '',
                additional_position_ids: ''

            },
            isValid: {
                master_position_id: true,
                additional_position_ids: true
            },
            isSearching : false
        }
    }

    componentDidMount = () => {
        if (!this.state._is_fetched.get_agent_options) {
            this.getAgentsOptions();
        }

        if (!this.state._is_fetched.get_professional_options) {
            this.getProfessionalLevelOptions();
        }

        if (!this.state._is_fetched.get_primary_position) {
            this.getPrimaryPostionOptions();
        }

        if (!this.state._is_fetched.get_league) {
            this.getLeagueOptions();
        }
    }

    /**
     * get data for agent list dropdown
     */
    getAgentsOptions = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        const request = new Request(APIRoutes.get_agent_list, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                let agentOptions = this.state.agentOptions;

                agentOptions = response.data;
                this.setState({
                    isError: false,
                    responseMessage: '',
                    isSuccess: true,
                    agentOptions,
                    _is_fetched: {
                        get_agent_options: true
                    }
                });
            }
        }, error => {
            console.log(error);
        });
    }

    /**
     * get data for professional level dropdown
     */
    getProfessionalLevelOptions = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        const request = new Request(APIRoutes.player_professional_level, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                let professionalLevelOptions = this.state.professionalLevelOptions;

                professionalLevelOptions = response.data;
                this.setState({
                    isError: false,
                    responseMessage: '',
                    isSuccess: true,
                    professionalLevelOptions,
                    _is_fetched: {
                        get_professional_options: true
                    }
                });
            }
        }, error => {
            console.log(error);
        });

    }

    /**
     * get data for primary position
     */
    getPrimaryPostionOptions = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        const request = new Request(APIRoutes.player_positions, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                let primaryPositionOptions = this.state.primaryPositionOptions;
                let additionalPositionOptions = this.state.additionalPositionOptions;

                primaryPositionOptions = response.data;
                additionalPositionOptions = response.data;
                this.setState({
                    isError: false,
                    responseMessage: '',
                    isSuccess: true,
                    primaryPositionOptions,
                    additionalPositionOptions,
                    _is_fetched: {
                        get_primary_position: true
                    }
                });
            }
        }, error => {
            console.log(error);
        });
    }

    /**
     * get data for league dropdown
     */
    getLeagueOptions = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        const request = new Request(APIRoutes.league_list, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                let leagueOptions = this.state.leagueOptions;
                let league_list = [];
                league_list = response.data.map(function (data, i) {
                    return { label: data.title, value: data.id };
                });
                leagueOptions = league_list;
                this.setState({
                    isError: false,
                    responseMessage: '',
                    isSuccess: true,
                    leagueOptions,
                    _is_fetched: {
                        get_league: true
                    }
                });
            }
        }, error => {
            console.log(error);
        });
    }

    /**
     * Handles fields changes and check for errors
     */
    handleFieldsChanges = (event) => {
        const target = event.target,
            elt_name = target.name,
            elt_value = target.value,
            fields = this.state.fields;

        let predictive_player_list = this.state.predictive_player_list;

        if (['agent_representation', 'agency_representation', 'agent_id', 'master_professional_level_id', 'master_position_id', 'additional_position_ids', 'agent_representation_enddate', 'current_club', 'master_league_id', 'detail'].indexOf(elt_name) >= 0) {
            fields[elt_name] = elt_value;
        }
        this.setState({
            fields,
            predictive_player_list
        });

    }

    /**
    * Handles input field change on searching a case for assigning to player
    */
    handlePlayerNameChanges = event => {
        const target = event.target,
            value = target.value,
            fields = this.state.fields;

        if ( value.length === 0 || value === '' ) {
            this.setState({
                predictive_player_list: []
            });
        }

        if ( value.length < 3 ) {
            fields['player_name'] = value;
            fields['player_id'] = '';
            this.setState({ fields });
            return false;
        }

        fields['player_name'] = value;
        fields['player_id'] = '';

        this.setState({ fields });

        clearTimeout( this.countdown );
        this.countdown = setTimeout( this.searchPlayer, this.wait_for_search );
    }

    /**
     * Search after {`this.wait_for_search`} miliseconds case when typing is done and text is more than 3 characters.
     */
    searchPlayer = () => {
        const query = this.state.fields.player_name;
        if ( query.length < 3 ) {
            return false;
        }

        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError: true,
                responseMessage: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        this.setState({
            isSearching: true
        });
        const request = new Request(APIRoutes.get_player_list + query, {}, 'GET', headers).send();
        request.then(response => {
            if ( response.success ) {
                const predictive_player_list = response.data;
                this.setState({
                    predictive_player_list,
                    isSearching: false
                });
            }
        }, error => {
            console.log(error);
        });
    }

    /**
     *  Handle changes of dropdown
     */
    handleChange = (value, field_name) => {
        let fields = this.state.fields,
            isValid = this.state.isValid,
            fieldsValidityError = this.state.fieldsValidityError;
        if (field_name === 'additional_position_ids') {
            fieldsValidityError[field_name] = '';
            isValid[field_name] = true;
            isValid['master_position_id'] = true;
            for (let option_value in value) {
                let option = value[option_value];
                isValid[field_name] = (fields.master_position_id !== null && option.value === fields.master_position_id.value) ? false : true;
                fieldsValidityError[field_name] = '';
                if (isValid[field_name] === false) {
                    fieldsValidityError[field_name] = __x( `The Primary Postion and Secondary Position have same value.` );
                    fields[field_name] = value;
                    this.setState({ fields, isValid, fieldsValidityError });
                    return false;
                }
            }

        }
        else if (field_name === 'master_position_id') {
            let additional_postions = fields.additional_position_ids;
            if (additional_postions) {
                for (let additional_position in additional_postions) {
                    let option = additional_postions[additional_position];
                    isValid[field_name] = (value.value === option.value) ? false : true;
                    fieldsValidityError['additional_position_ids'] = '';
                    isValid['additional_position_ids'] = true;
                    if (isValid[field_name] === false) {
                        fieldsValidityError['additional_position_ids'] = __x( `The Primary Postion and Secondary Position have same value.` );
                        fields[field_name] = value;
                        this.setState({ fields, isValid, fieldsValidityError });
                        return false;
                    }
                }
            }
        }
        fields[field_name] = value;
        this.setState({
            fields
        })

    }

    /**
     * Select player from predictive search dropdown
     */

    selectPlayer = (player_id, player_name) => {
        let fields = this.state.fields,
            predictive_player_list = this.state.predictive_player_list;
        fields['player_name'] = player_name;
        predictive_player_list = [];

        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        const request = new Request(APIRoutes.player + player_id, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                let response_data = response.data;
                console.log(response);
                fields['agent_representation'] = response_data.agent_representation;
                fields['agency_representation'] = response_data.agency_representation;
                fields['agent_representation_enddate'] = (response_data.agent_representation_enddate ? new Date(response_data.agent_representation_enddate) : null);
                fields['current_club'] = (response_data.current_club !== 0 && response_data.club ? response_data.club.club_name : response_data.other_club);
                fields['player_id'] = player_id;

                let agentOptions = this.state.agentOptions;
                let agent_arr = [];

                for (let i in agentOptions) {
                    if (agentOptions[i].value === response_data.usm_agent_id) {
                        agent_arr.push(agentOptions[i]);
                    }
                    fields['agent_id'] = agent_arr;
                }

                let professionalLevelOptions = this.state.professionalLevelOptions;

                for (let i in professionalLevelOptions) {
                    if (professionalLevelOptions[i].value === response_data.master_professional_level_id) {
                        fields['master_professional_level_id'] = professionalLevelOptions[i];
                    }
                }

                let primaryPositionOptions = this.state.primaryPositionOptions;

                for (let i in primaryPositionOptions) {
                    if (primaryPositionOptions[i].value === response_data.primary_position_id) {
                        fields['master_position_id'] = primaryPositionOptions[i];
                    }
                }

                let additionalPositionOptions = this.state.additionalPositionOptions;
                let additional_position_arr = [];

                for (let i in additionalPositionOptions) {
                    for (let j in response_data.player_additional_positions) {
                        if (response_data.player_additional_positions[j].master_position_id === additionalPositionOptions[i].value) {
                            additional_position_arr.push(additionalPositionOptions[i]);
                        }
                    }
                    fields['additional_position_ids'] = additional_position_arr;
                }

                let leagueOptions = this.state.leagueOptions;

                for (let i in leagueOptions) {
                    if (leagueOptions[i].value === response_data.master_league_id) {
                        fields['master_league_id'] = leagueOptions[i];
                    }
                }

                this.setState({
                    fields
                });
            }
        }, error => {
            console.log(error);
        });

        this.setState({
            fields,
            predictive_player_list
        });

    }

    /**
    * Save notes locally.
    * currently working on single note.
    */
    saveNotesLocally = (note_fields) => {
        let new_case_notes = [...this.state.fields.case_note];
        new_case_notes = [{ note: note_fields.note, is_restricted: note_fields.is_restricted }]

        this.setState({
            fields: {
                ...this.state.fields,
                case_note: new_case_notes,
                case_attachments: note_fields.files
            }
        }, () => {
            this._notes.toggleNotesPopup(true);
        });
    }

    listLocalNotes = () => {
        const notes = this.state.fields.case_note,
            attachments = this.state.fields.case_attachments;

        return notes && notes.length > 0 ? notes.map((note, n_idx) => {
            return (
                <li key={n_idx} className="media">
                    <div className="media-body clearfix">
                        <div className="nc-nb-heading">Note</div>
                        <p>{note.note}</p>
                        <div className="note-add-modify">
                            <div className="row marginB5">
                                <div className="col-md-1 col-sm-12">Added by: </div>
                                <div className="col-md-11 col-sm-12 fontBold">{get_current_user_full_name()}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-1 col-sm-12">Attachments: </div>
                                <div className="col-md-11 col-sm-12">
                                    {
                                        attachments && Object.keys(attachments).length > 0 ? Object.values(attachments).map((attachment, idx) => {
                                            return <div key={idx} className="text-success font-size14">{attachment.name}</div>
                                        }) : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            );
        }) : '';
    }

    /**
     * Check if form is valid or not to submit
     */
    isFormValid = () => {
        let validityErr = [];
        const fields = this.state.fields,
             isValid = this.state.isValid;

        const optional = ['master_position_id', 'additional_position_ids'];
        const toExclude = ['agent_representation', 'agency_representation', 'agent_id', 'master_professional_level_id', 'agent_representation_enddate', 'current_club', 'master_league_id', 'detail', 'case_note', 'case_attachments', 'master_requirement_id', 'player_id'];

        for (let elt in fields) {
            /**
             * Exclude fields.
             */
            if (toExclude.indexOf(elt) >= 0) {
                continue;
            }
            if (optional.indexOf(elt) >= 0) {

                if (fields[elt] === '')
                    continue;

                if (!isValid[elt])
                    validityErr.push(elt);
            }

            if (fields[elt] === '') {
                validityErr.push(elt);
            }
        }
            return validityErr.length <= 0;
        }
    

    /**
     * Add player case.
     */
    addPlayerCase = event => {
        event.preventDefault();
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        this.setState({
            isProcessing: true
        });

        const fields = this.state.fields,
            player_case_path = APIRoutes.player_case;


        let new_state = {},
            formData = new FormData();
        for (let i in fields) {

            /**
             * Get date in dd/mm/yyyy format.
             */
            if (i === 'agent_representation_enddate') {
                if(fields[i] !== null)
                formData.append(i, get_db_date(fields[i]));
                continue;
            }

            /**
             * Appends case_note into formData
             */
            if (i === 'case_note') {
                const notes = JSON.stringify(fields[i]);
                formData.append(i, notes);
                continue;
            }

            /**
             * Appends case atachments into form data.
             */
            if (i === 'case_attachments') {
                const files = fields[i];
                for (let f in files) {
                    let note_file = files[f];
                    formData.append('files', note_file);
                }
                continue;
            }

            /**
             * Filtering comma separated ids from these fields.
             */
            if (i === 'master_position_id' || i === 'master_professional_level_id' || i === 'master_league_id') {
                if (fields[i] && fields[i].value) {
                    formData.append(i, fields[i].value);
                }
                continue;
            }

            if (i === 'additional_position_ids' || i === 'agent_id') {
                formData.append(i, fields[i] && fields[i].length > 0 ? fields[i].map(function (selected_additional_position, index) {
                    return selected_additional_position.value
                }) : '');
                continue;
            }
            formData.append(i, fields[i]);
        }

        /**
         * Sending request to add/update clubs.
         */
        const request = new Request(player_case_path, formData, 'POST', headers, true).send();
        request.then(response => {
            if (response.success) {
                this.props.history.push('/admin/cases/', {
                    message: response.message,
                    success: response.success
                });
            } else {
                new_state = {
                    isProcessing: false,
                    isSubmitted: true,
                    isSuccess: response.success,
                    responseMsg: response.message
                }
                this.clearFields();
                this.setState(new_state);
            }
        }, error => {
            new_state = {
                isProcessing: false,
                isSubmitted: true,
                isSuccess: error.success,
                responseMsg: error.message
            };
            // this.clearFields();
            this.setState(new_state);
        });
     }

     /**
      * clear form fields
      */
     clearFields = () => {
         this.setState({
             fields : {
                player_name: '',
                player_id: '',
                agent_representation: '',
                agency_representation: '',
                agent_id: null,
                master_professional_level_id: '',
                master_position_id: '',
                additional_position_ids: null,
                agent_representation_enddate: null,
                current_club: '',
                master_league_id: '',
                detail: '',
                case_note: [],
                case_attachments: []
             },
             fieldsValidityError: {
                master_position_id: '',
                additional_position_ids: ''
            },
            isValid: {
                master_position_id: true,
                additional_position_ids: true
            }
         });
     }

    resetAlert = () => {
        this.setState({
            responseMsg: '',
            isSubmitted: false
        });
    }

    render() {
        const customStyles = {
            option: (styles, { isFocused }) => {
                return {
                    ...styles,
                    backgroundColor: isFocused ? 'rgba(0, 0, 0, 0.2)' : styles.backgroundColor,
                }
            }
        },
            today = new Date(),
            disableSubmit = !this.isFormValid() || this.state.isProcessing ? true : false,
            responseMsg = this.state.responseMsg !== '' && this.state.isSubmitted ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} floating="true" onClose={this.resetAlert} /> : '';

        /* Predictive player list */
        const predictive_player_exists = this.state.predictive_player_list && this.state.predictive_player_list.length > 0;
        const predictive_player_list = predictive_player_exists ?
            this.state.predictive_player_list.map(player => {
                return <li key={player.id} onClick={this.selectPlayer.bind(this, player.id, player.name)}>Name: {player.name ? player.name : ''} Age: {player.age ? player.age : ''} Nationality: {player.master_country && player.master_country.name ? player.master_country.name : ''}</li>
            }) : '';

        const show_loader = this.state.isSearching ? <img className="search-loader" src={require(`../../../assets/images/loader-usm.svg`)} alt="" /> : '';

        return (
            <div id="mainContainer">
                {responseMsg}
                <div className="heading-section height84 d-flex border-bottom-trans align-items-center">
                    <h2>Add Case</h2>
                </div>

                <div className="content-section">
                    <form action="" onSubmit={ e => {e.preventDefault(); }} className="usm-form form-border form-modal">
                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Case Type</label>
                                    <input type="text" onChange={this.handleFieldsChanges} value="Player Recruitment" className="form-control" id="" placeholder="" name="master_requirement_id" disabled={true}/>
                                    {/* <select id="" name="master_requirement_id" onChange={this.handleFieldsChanges} value={this.state.fields.master_requirement_id} className="form-control">
                                        <option value="1">Player Recruitment</option>
                                        <option value="2">Club Requirement</option>
                                    </select> */}
                                </div>
                            </div>
                        </div>
                        <div className="border-bottom-trans marginTB35"></div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group position-relative player-case">
                                    <label htmlFor="">Add Player</label>
                                    <input type="text" onChange={this.handlePlayerNameChanges} value={this.state.fields.player_name} className="form-control" id="" placeholder="" name="player_name" autoComplete="off" />
                                    {
                                        !predictive_player_exists ? '' :
                                            <ul className="pre-search">{predictive_player_list}</ul>
                                    }
                                    { show_loader }
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Current Representation Agent</label>
                                    <input type="text" onChange={this.handleFieldsChanges} value={this.state.fields.agent_representation} className="form-control" id="" placeholder="" name="agent_representation" autoComplete="off" />
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Current Representation Agency</label>
                                    <input type="text" onChange={this.handleFieldsChanges} value={this.state.fields.agency_representation} className="form-control" id="" placeholder="" name="agency_representation" autoComplete="off" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Unique SM Agent</label>
                                    <Select name="unique_sm_agent"
                                        value={this.state.fields.agent_id}
                                        onChange={(e) => this.handleChange(e, 'agent_id')}
                                        options={this.state.agentOptions}
                                        styles={customStyles}
                                        placeholder={``}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Representation End Date</label>
                                    <DatePicker
                                        className="form-control"
                                        selected={this.state.fields.agent_representation_enddate}
                                        onChange={(e) => this.handleChange(e, 'agent_representation_enddate')}
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date(today.getFullYear(), today.getMonth(), today.getDate())}
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={30}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Professional Level</label>
                                    <Select nameName="professional_level"
                                        value={this.state.fields.master_professional_level_id}
                                        onChange={(e) => this.handleChange(e, 'master_professional_level_id')}
                                        options={this.state.professionalLevelOptions}
                                        styles={customStyles}
                                        placeholder={``}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Current Club</label>
                                    <input type="text" onChange={this.handleFieldsChanges} value={this.state.fields.current_club} className="form-control" id="" placeholder="" name="current_club" autoComplete="off" />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">League</label>
                                    <Select name="master_league_id"
                                        value={this.state.fields.master_league_id}
                                        onChange={(e) => this.handleChange(e, 'master_league_id')}
                                        options={this.state.leagueOptions}
                                        styles={customStyles}
                                        placeholder={``}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom-trans marginTB35"></div>

                        <div className="row">

                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Primary Position</label>
                                    <Select name="primary_position"
                                        value={this.state.fields.master_position_id}
                                        onChange={(e) => this.handleChange(e, 'master_position_id')}
                                        options={this.state.primaryPositionOptions}
                                        styles={customStyles}
                                        placeholder={``}
                                    />
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-12">
                                <div className={this.state.fieldsValidityError.additional_position_ids === '' ? `form-group` : `form-group form-error`}>
                                    <label htmlFor="">Additional Position</label>
                                    <Select name="additional_position"
                                        value={this.state.fields.additional_position_ids}
                                        onChange={(e) => this.handleChange(e, 'additional_position_ids')}
                                        options={this.state.additionalPositionOptions}
                                        isMulti={true}
                                        styles={customStyles}
                                        placeholder={``}
                                    />
                                    {this.state.fieldsValidityError.additional_position_ids === '' || <div className="display-error">{this.state.fieldsValidityError.additional_position_ids}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="border-bottom-trans marginTB34"></div>

                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="">Detail</label>
                                    <textarea name="detail" onChange={this.handleFieldsChanges} value={this.state.fields.detail} id="" className="form-control textarea76"></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group marginT20">
                                    <div className="player-detail-block">
                                        <div className="player-detail-heading d-flex align-items-center">
                                            Notes
                                        <button onClick={this._notes.toggleNotesPopup} type="button" className="transparent-btn float-right ml-auto">
                                                <img src={require('../../../assets/images/add-icon.png')} alt="" />
                                            </button>
                                        </div>
                                        <div className="player-detail-content">
                                            <div className="notes-block">
                                                <ul className="list-unstyled">
                                                    {this.listLocalNotes()}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom-trans marginTB34"></div>
                        <div className="row">
                            <div className="col d-flex justify-content-between">
                                <Link to="/admin/cases" className="default-btn btn-danger width-auto" data-dismiss="modal">Cancel</Link>
                                <button type="button" disabled={disableSubmit} onClick={this.addPlayerCase} className="default-btn btn-success width-auto">Save</button>
                            </div>
                        </div>
                    </form>

                </div>
                <UpdateNote
                    ref={elt => this._notes = elt}
                    onSubmit={this.saveNotesLocally}
                />
            </div>
        );

    }
}

export default withRouter((AddPlayerCase));