import { combineReducers } from 'redux';
import LoginReducer from './LoginReducer';
import AuthReducer from './AuthReducer';
import UsersReducer from './UsersReducer';
import ClubsReducer from './ClubsReducer';
import PlayersReducer from './PlayersReducer';
import CasesReducer from './CasesReducer';
import NotesAndConciergesReducers from './NotesAndConciergesReducer';
import SearchReducers from './SearchReducers';
import OrganisationsReducer from './OrganisationsReducer';
import SettingReducers from './SettingReducers';

export default combineReducers({
    login   : LoginReducer,
    auth    : AuthReducer,
    users   : UsersReducer,
    clubs   : ClubsReducer,
    players : PlayersReducer,
    cases   : CasesReducer,
    notesandconcierges: NotesAndConciergesReducers,
    search          : SearchReducers,
    organisation    : OrganisationsReducer,
    settings        : SettingReducers
});