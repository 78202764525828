const initialState = {
    isTokenValid    : false
}

export default ( state = initialState, action ) => {
	switch ( action.type ) {
        case 'VALIDATE_TOKEN':
            return {
                ...state,
                isTokenValid: action.payload.validity
            };
		default:
			return state;
	}
}