const initialState = {
    clubs       : [],
    agents      : {},
    positions   : []
}

export default ( state = initialState, action ) => {
	switch ( action.type ) {
        case 'SET_CLUB_LIST':
            return {
                ...state,
                clubs: action.payload.clubs
            };
        case 'SET_CASES':
            return {
                ...state,
                cases: action.payload.cases
            };
        case 'APPEND_CASES':
            return {
                ...state,
                cases: action.payload.cases
            };        
        case 'SET_AGENTS':
            return {
                ...state,
                agents: action.payload.agents.length > 0 ? action.payload.agents : action.payload.agents
            };
        case 'SET_POSITIONS':
            return {
                ...state,
                positions: action.payload.positions
            };
		default:
			return state;
	}
}