import React from 'react';
import ClubRightSidebar from './ClubRightSidebar';
import OtherClubContacts from './club/OtherClubContacts';
import ActiveCases from './case/ActiveCases';
import ClosedCases from './case/ClosedCases';
import Alert from '../../helpers/Alert';
import { is_mobile } from '../../helpers/GeneralHelper';

class ClubDetails extends React.Component {
    
    _page_title = 'Club Details - USM';

    constructor( props ) {
        super( props );

        this._file = React.createRef();
        this._club_id = props.match.params.id;
        this._isFetching = false;

        this.state = {
            isProcessing    : false,
            isSubmitted     : false,
            isSuccess       : false,
            responseMsg     : '',
            imgPreview      : '',
            should_other_club_contact_popup_open : false,
            sidebarToggled  : !is_mobile(),
        }
    }

    openSidebar = () => {
        const sidebarToggled = this.state.sidebarToggled
        this.setState({
            sidebarToggled: !sidebarToggled
        });
    }

    resetAlert = () => {
        this.setState({
            responseMsg: ''
        });
    }

    render = () => {
        const toggledClass  = this.state.sidebarToggled ? 'toggled' : '',
        responseMsg         = this.state.responseMsg !== '' && this.state.isSubmitted ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} floating="true" onClose={this.resetAlert} /> : '';
        return (
            <div>
                { responseMsg }
                <div id="mainContainer" className={toggledClass}>                  
                    <div className="heading-section d-flex align-items-center">
                        <h2>Club Details</h2>
                        <button onClick={ this.openSidebar } to="" className="transparent-btn ml-auto usmDrawer" data-toggle="offcanvas">
                            <img src={ require( '../../assets/images/drawer.png' ) } alt="" />
                        </button>
                    </div>
                    
                    <ClubRightSidebar
                        {...this.props}
                    />
                    
                    <div className="content-section">
                        <OtherClubContacts />
                        <ActiveCases />
                        <div className="transDivider marginTB35"></div>
                        <ClosedCases />
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        document.title = this._page_title;
    }
}
export default ClubDetails;