import React from 'react';
import Club from './Club';
import Request from '../../helpers/Request';
import AddClub from './AddClub';
import { get_current_user_role, isUserImageValid, get_authorization_token, is_bday_valid, __x, get_db_date } from '../../helpers/GeneralHelper';
import * as EmailValidator from 'email-validator';
import APIRoutes from '../../API/Routes';
import { setClubs, setLeagues, appendClubs } from '../../actions/ClubsAction';
import { connect } from 'react-redux';
import LoadingSpinner from './common/LoadingSpinner';
import InfiniteScroll from 'react-infinite-scroll-component';


class Clubs extends React.Component {

    _page_title = 'Clubs - USM';

    constructor( props ) {
        super( props );

        this.fetched            = false;
        this._country           = React.createRef();
        this._file              = React.createRef();
        this.current_user_role  = get_current_user_role();

        this.state = {
            addClubModal   : false,
            filter          : '',
            filtered        : '',
            fields          : {
                club_name   : '', 
                master_league_id   : '', 
                file        : {},
                club_title  : '', 
                status      : '1', 
                first_name  : '',
                last_name     : '',
                email       : '',
                country_code: '44', 
                phone       : '', 
                birthday    : null,
                club_note   : '',
                show_birthday_notification : false,
                is_restricted : false
            },
            isValid : {
                club_name   : true,
                master_league_id   : true,
                club_title  : true,
                status      : true,
                first_name  : true,
                last_name    : true,
                email       : true,
                country_code: true,
                phone       : true,
                birthday    : true,
                club_note        : true,
                file        : true,
            },
            fieldsValidityError: {
                club_name   : '', 
                master_league_id : '', 
                club_title  : '', 
                status      : '', 
                first_name  : '',
                last_name    : '',
                email       : '',
                country_code: '', 
                phone       : '', 
                birthday    : '',
                club_note   : '',
                file        : '',
            },
            country_iso     : 'gb',
            fileName        : __x( `Add Photo` ),
            imgPreview      : '',
            isProcessing    : false,
            isSubmitted     : false,
            isSuccess       : false,        // Used for api success/failure.
            isError         : false,        // Used for only local validation errors to show.
            responseMessage : '',
            tokenExpired    : false,
            hasMoreItems    : false,
			maxPages        : null,
            nextPage        : 1,
            filter_applied  : false,
        };
    }

    handleAddClubModal = ( fetchClubs = false, club_id = 0 ) => {
        let new_state = {
            addClubModal    : !this.state.addClubModal
        }
        
        if ( new_state.addClubModal ) {
            document.body.classList.add( 'modal-open' );
        } else {
            document.body.classList.remove( 'modal-open' );
        }

        this.setState( new_state );
        
        if ( fetchClubs ) {
            this.getLeagues();
            this.getClubs();
        }
    }

    onChangeStatus = event => {
        let filter  = this.state.filter;
        filter      = event.target.value;
        this.setState({
            filter,
            filter_applied : true,
        });
    }

    clearFilter = () => {
        this.setState({
            filter : ''
        }, () => this.getClubs() );
    }

    render = () => {
        const loading = this.state.isProcessing ? <LoadingSpinner/> : '';
        return (
            <div id="mainContainer">
            {loading}
                <div className="heading-section d-flex align-items-center">
                    <h2>Clubs</h2> 
                    {
                        this.current_user_role === 'Agent' ? '' : 
                        <h6 className="ml-auto">
                            <img src={ require( '../../assets/images/add-clubs.png' ) } alt="" className="marTN6" /> 
                            <button onClick={ this.handleAddClubModal } className="text-white marginL10 transparent-btn" data-toggle="modal">Add Club</button>
                        </h6>
                    }
                </div>
                <div className="filtter-section position-relative">
                    <div className="d-flex">
                        <div className="w-100">
                            <form className="usm-form form-border">
                                <div className="row">
                                    <div className="form-group col-md-6 col-sm-12 marginB0">
                                        <label htmlFor="">Case Status</label>	
                                        <select onChange={ this.onChangeStatus } value={ this.state.filter } className="form-control input-height30">
                                            <option value="">All</option>
                                            <option value="1">Open</option>
                                            <option value="0">Closed</option>
                                        </select>
                                    </div>
                                </div>
                                                                                                            
                            </form>						
                        </div>
                        <div className="flex-shrink-1 marginL18">
                            <div className="apply-filtter float-right d-flex align-items-center flex-column">
                                <i className="material-icons marginB5"><img src={ require( '../../assets/images/filter-icon.png' ) } alt="" /></i>
                                <button onClick={ this.getClubs } className="btn btn-success borderRadius15 text-uppercase small-button">Apply Filters</button>
                            </div>
                        </div>
                    </div>
                    {/* 
                        this.state.filtered === '' ? '' : 
                        <button onClick={ this.clearFilter } className="transparent-btn text-danger font-size30 clear-filter-btn">&times;</button>
                     */}
                </div>
                <div className="content-section">
                    <div className="usm-table marginT82 table-responsive">
                        <InfiniteScroll
                            dataLength={ this.props.clubs.clubs && this.props.clubs.clubs.length > 0 ? this.props.clubs.clubs.length : 0  } //This is important field to render the next data
                            next={this.loadMoreClubs}
                            hasMore={this.state.hasMoreItems}
                            // loader={<h4>Loading...</h4>}
                        >
                            <table className="table table-hover lastTwo-center">
                                <thead className="usm-thead">
                                    <tr>
                                        <th rowSpan="2" className="align-middle width-20">Club Name</th>
                                        <th colSpan="4" scope="colgroup" className="border-bottom-trans border-right border-left border-trans">Main Contact</th>
                                        <th rowSpan="2" className="align-middle width-10">No of Cases</th>
                                        <th rowSpan="2" className="align-middle width-10">{this.current_user_role === 'Agent' ? `View` : `Manage`}</th>
                                    </tr>
                                    <tr>
                                        <th className="border-top-0 width-15">Title in Club</th>
                                        <th className="border-top-0">Name</th>
                                        <th className="border-top-0 text-left">Email</th>
                                        <th className="border-top-0 text-left">Phone</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <Club
                                        is_fetched={ this.fetched }
                                        user_role={this.current_user_role}
                                        filtered={this.state.filtered}
                                        clubs={this.props.clubs.clubs}
                                    />
                                </tbody>
                            </table>

                        </InfiniteScroll>
                    </div>
                </div>	
                <AddClub 
                    fields              = { this.state.fields }
                    isValid             = { this.state.isValid }
                    leagues             = { this.props.clubs.leagues }
                    responseMessage     = { this.state.responseMessage }
                    imgPreview          = { this.state.imgPreview }
                    isSubmitted         = { this.state.isSubmitted }
                    isSuccess           = { this.state.isSuccess }
                    isFormValid         = { this.isFormValid }
                    clearFormFields     = { this.clearFormFields }
                    updateClub          = { event => this.updateClub( event ) }
                    onPopupClose        = { this.onPopupClose }
                    modal               = { this.state.addClubModal }
                    resetAlert          = { this.resetAlert }
                    fileName            = { this.state.fileName }
                    isProcessing        = { this.state.isProcessing }
                    fieldsValidityError = { this.state.fieldsValidityError }
                    birthdayChanges     = { this.handleBirthdayChanges }
                    handleFieldsChanges = { e => this.handleFieldsChanges(e)}
                    handlePhoneChange   = { this.handlePhoneChange }
                    countryCodeChange   = { this.handleCountryCodeChange }
                    setFileRef          = { elt => this._file = elt }
                    set_country         = { elt => this._country = elt }
                />
            </div>
        );
    }    

    getLeagues = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const request = new Request( APIRoutes.league_list, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                this.props.setLeagues( response.data );
            }
        }, error => {
            console.log( error );
        });
    }

    getClubs = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        this.setState({
            nextPage : 1,
            maxPages : null,
        });

        let query_string = '';
		if (this.state.filter && this.state.filter !== '') {
            query_string += (`status=` + this.state.filter);
        }

        // const club_path = this.state.filter === '' ? APIRoutes.clubs : APIRoutes.clubs + this.state.filter;
        const club_path = query_string !== '' ?  APIRoutes.clubs + '?' + query_string : APIRoutes.clubs ; 
        const request = new Request( club_path , {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                
                this.setState({
                    filtered: this.state.filter,
                    hasMoreItems : response.pages && response.pages === 1 ? false : true,
					maxPages     : response.pages ? response.pages : null,
					nextPage     : (this.state.filter_applied) ? 2 : this.state.nextPage + 1,
                })

                this.fetched = true;
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                this.props.setClubs( response.data );
            }
        }, error => {
            console.log( error );
        });
    }

    /**
     * load more clubs on scrolling
     */
    loadMoreClubs = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        this.setState({
			hasMoreItems : false,
		});

		let maxPages = this.state.maxPages,
        nextPage     = this.state.nextPage;

		if(maxPages < nextPage) {
			this.setState({
				hasMoreItems : false,
			});
			return false;
        }
        
        let query_string = '';
		if (this.state.filter && this.state.filter !== '') {
            query_string += (`status=` + this.state.filter);
		}

        // const club_path = this.state.filter === '' ? APIRoutes.clubs + nextPage : APIRoutes.clubs + this.state.filter;
        const club_path = query_string !== '' ?  APIRoutes.clubs + nextPage + '?' + query_string : APIRoutes.clubs + nextPage ; 
        const request = new Request( club_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                
                this.setState({
                    filtered: this.state.filter,
                    hasMoreItems : true,
					nextPage : nextPage + 1,
                })
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                this.props.appendClubs( this.props.clubs.clubs,response.data);
            }
        }, error => {
            console.log( error );
        });
    }

    componentDidMount = () => {
        document.title = this._page_title;
        this.getLeagues();
        this.getClubs();
    }

    updateClub = ( event ) => {
        event.preventDefault();
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( !this.isFormValid() ) {
            return false;
        }

        this.setState({
            isProcessing: true
        });

        const club_path = APIRoutes.club;

        const fields    =  this.state.fields;
        let new_state   = {},
        formData        = new FormData();
        for ( let i in fields ) {
            if ( i === 'birthday' ) {
                if ( fields[i] !== '' && fields[i] instanceof Date ) {
                    formData.append(i, get_db_date(fields[i]));
                    // formData.append( i, `${fields[i].getDate()}/${fields[i].getMonth() + 1}/${fields[i].getFullYear()}` );
                } else {
                    formData.append( i, `` );
                }

                continue;
            }
            
            formData.append( i, fields[i] );
        }        
        formData.append( 'country_iso', this.state.country_iso );
        
        /**
         * Sending request to add/update clubs.
         */
        const request = new Request( club_path, formData, 'POST', headers, true ).send();
        request.then( response => {
            if ( response.success ) {
                this.onPopupClose( true );
            }
            
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMessage : response.message
            }
            this.setState( new_state );
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMessage : error.message
            };
            this.setState( new_state );
        });
    }

    handleFieldsChanges = ( event ) => {
        const target    = event.target,
        elt_name        = target.name,
        elt_value       = target.type === 'checkbox' ? target.checked : target.value,
        fields          = this.state.fields;

        let isValid = this.state.isValid,
        fieldsValidityError = this.state.fieldsValidityError,
        fileName        = this.state.fileName;
        
        if ( elt_name === 'file' ) {
            const file = target.files.length > 0 ? target.files[0] : false;
            const isImageValid = isUserImageValid( file );
            if ( !isImageValid ) {
                isValid[ 'file' ] = true;
                fieldsValidityError = {
                    ...fieldsValidityError,
                    file: ''
                };

                fileName = __x( `Add Photo` );
                this.setState({
                    imgPreview: ''
                });
            } else {
                if ( !isImageValid.success ) {
                    isValid[ 'file' ] = false;
                    fieldsValidityError = {
                        ...fieldsValidityError,
                        file: isImageValid.messages.map( ( error, idx ) => <li key={idx}>{error}</li> )
                    };
                    
                    fileName = __x( `Add Photo` );
                    this.setState({
                        imgPreview: ''
                    });
                } else {
                    fileName = file.name;

                    /**
                     * Get preview file url using file reader
                     */
                    const reader = new FileReader();
                    reader.readAsDataURL( file );
                    reader.onloadend = function ( e ) {
                        this.setState({
                            imgPreview: [ reader.result ]
                        });
                    }.bind( this );
                    
                    isValid[ 'file' ] = true;
                    fieldsValidityError = {
                        ...fieldsValidityError,
                        file: ''
                    };
                }
            }
            
            fields[ 'file' ] = file;
        } else if ( [ 'club_name', 'club_title', 'first_name', 'last_name' ].indexOf( elt_name ) >= 0 ) {
            const label = elt_name.replace( '_', ' ' );
            fields[ elt_name ] = elt_value;
            isValid[ elt_name ] = fields[ elt_name ] !== '' ? fields[ elt_name ].length >= 1 && fields[ elt_name ].length <= 255  : true;
            fieldsValidityError[ elt_name ] = !isValid[ elt_name ] ? __x( `{{label}} is invalid.`, `{{label}}`, label ) : '';
        } else if ( elt_name === 'email' ) {
            fields[ elt_name ] = elt_value;
            isValid[ elt_name ] = fields[ elt_name ] !== '' ? fields[ elt_name ].length >= 1 && fields[ elt_name ].length <= 255 && EmailValidator.validate( fields[ elt_name ] ) : true;
            fieldsValidityError[ elt_name ] = !isValid[ elt_name ] ? __x( `Please enter a valid email.` )  : '';
        } else {
            fields[ elt_name ] = elt_value;
        }
        
        this.setState({
            fields,
            isValid,
            fileName,
            fieldsValidityError
        });
    }

    /**
     * Check if form is valid or not to submit
     */
    isFormValid = () => {
        let validityErr = [];
        const fields    = this.state.fields,
        isValid         = this.state.isValid;

        for ( let elt in fields ) {
            if ( elt === 'is_restricted' || elt === 'show_birthday_notification' || elt === 'club_note' || elt === 'file' ) {
                if ( elt === 'file' && !isValid[ elt ] ) {
                    validityErr.push( elt );
                }
                continue;
            }

            if ( elt === 'birthday' || elt === 'phone' || elt === 'email' ) {
                if ( isValid[ elt ] || fields[ elt ] === '' ) {
                    continue;
                }
            }
            
            if ( fields[ elt ] === '' || !isValid[ elt ] ) {
                validityErr.push( elt );
            }
        }
        return validityErr.length <= 0;
    }

     /**
     * Handles country code changes
     */
    handleCountryCodeChange = ( value, countryData ) => {
        const fields    = this.state.fields,
        isValid         = this.state.isValid,
        country_code    = countryData? countryData.dialCode : '44';
        
        fields[ 'country_code' ]    = country_code;
        isValid[ 'country_code' ]   = fields.country_code !== '' ? fields.country_code.toString().length >= 0 && fields.country_code.toString().length <= 255 : true;

        this.setState({
            fields,
            isValid,
            country_iso: countryData.iso2
        });
    }

    /**
     * Handles phone changes
     */
    handlePhoneChange = ( status, value, countryData ) => {
        const fields        = this.state.fields,
        isValid             = this.state.isValid,
        fieldsValidityError = this.state.fieldsValidityError,
        phone               = value ? value : '';
        
        fields[ 'phone' ]   = phone;
        isValid[ 'phone' ]  = fields.phone !== '' ? fields.phone.length >= 0 && fields.phone.length <= 255 && !isNaN( fields.phone ) : true;
        fieldsValidityError[ 'phone' ] = !isValid.phone ? __x( `Phone is invalid.` ) : '';
        
        this.setState({
            fields,
            isValid,
            fieldsValidityError
        });
    }

    handleBirthdayChanges = ( date ) => {
        const fields        = this.state.fields,
        isValid             = this.state.isValid,
        fieldsValidityError = this.state.fieldsValidityError;

        fields[ 'birthday' ]                = date;
        isValid[ 'birthday' ]               = date === '' || date === null || !date ? true : is_bday_valid( date );
        fieldsValidityError[ 'birthday' ]   = !isValid[ 'birthday' ] ? __x( `Birthday is invalid.` ) : '';
        
        this.setState({
            fields,
            isValid,
            fieldsValidityError
        });
    }

    /**
     * Clear form fields
     */
    clearFormFields = () => {
        this._file.value = null;
        this.setState({
            fields : {
                club_name   : '', 
                master_league_id   : '', 
                file        : {},
                club_title  : '', 
                status      : '1', 
                first_name  : '',
                last_name     : '',
                email       : '',
                country_code: '44', 
                phone       : '', 
                birthday    : this.yesterday,
                club_note   : '',
                show_birthday_notification : false,
                is_restricted : false
            },
            isValid : {
                club_name   : true,
                master_league_id   : true,
                club_title  : true,
                status      : true,
                first_name  : true,
                last_name   : true,
                email       : true,
                country_code: true,
                phone       : true,
                birthday    : true,
                club_note   : true,
                file        : true,
            },
            fieldsValidityError: {
                club_name   : '', 
                master_league_id : '', 
                club_title  : '', 
                status      : '', 
                first_name  : '',
                last_name    : '',
                email       : '',
                country_code: '', 
                phone       : '', 
                birthday    : '',
                club_note   : '',
                file        : '',
            },
            country_iso     : 'gb',
            fileName        : __x( `Add Photo` ),
            imgPreview      : '',
            isSubmitted     : false,
            isSuccess       : false,        // Used for api success/failure.
            isError         : false,        // Used for only local validation errors to show.
            responseMessage : '',
        });
        this._country.setFlag( 'gb' );
    }

    onPopupClose = () => {
        this.clearFormFields();
        this.handleAddClubModal( true );
    }

    resetAlert = () => {
        this.setState({
            isSubmitted     : false,
            responseMessage : ''
        });
    }
}

const mapStateToProps = ( state ) => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setClubs    : ( clubs ) => dispatch( setClubs( clubs ) ),
    appendClubs: (all_clubs, load_clubs) => dispatch(appendClubs(all_clubs, load_clubs)),
    setLeagues  : ( leagues ) => dispatch( setLeagues( leagues ) )
});

export default connect( mapStateToProps, mapDispatchToProps )( Clubs );