export const setplayers = ( players ) => dispatch => {
    dispatch({
        type: 'SET_PLAYERS',
        payload: {
            players: players
        }
    });
}

export const appendPlayers = (all_players, loaded_players) => dispatch => {
    dispatch({
        type: 'APPEND_PLAYERS',
        payload: {
            players: [
                ...all_players,
                ...loaded_players
            ]
        }
    });
}

export const setplayerpositions = ( player_postions ) => dispatch => {
    dispatch({
        type: 'SET_PLAYER_POSITIONS',
        payload: {
            player_postions: player_postions
        }
    });
}

export const setPlayerProfessionalLevel = ( player_professional_level ) => dispatch => {
    dispatch({
        type: 'SET_PLAYER_PROFESSIONAL_LEVEL',
        payload: {
            player_professional_level: player_professional_level
        }
    });
}

export const setPlayerContractType = ( player_contract_type ) => dispatch => {
    dispatch({
        type: 'SET_PLAYER_CONTRACT_TYPE',
        payload: {
            player_contract_type: player_contract_type
        }
    });
}

export const setHighlights = highlights => dispatch => {
    dispatch({
        type    : 'SET_PLAYER_CAREER_HIGHLIGHTS',
        payload : {
            highlights
        }
    });
}

export const setHistory = history => dispatch => {
    dispatch({
        type    : 'SET_PLAYER_CAREER_HISTORY',
        payload : {
            history
        }
    });
}

export const setReports = reports => dispatch => {
    dispatch({
        type    : 'SET_PLAYER_REPORTS',
        payload : {
            reports
        }
    });
}

export const setVideos = videos => dispatch => {
    dispatch({
        type    : 'SET_PLAYER_VIDEOS',
        payload : {
            videos
        }
    });
}

export const setContacts = contacts => dispatch => {
    dispatch({
        type    : 'SET_PLAYER_CONTACTS',
        payload : {
            contacts
        }
    });
}
export const setContact = contact => dispatch => {
    dispatch({
        type    : 'SET_PLAYER_CONTACT',
        payload : {
            contact
        }
    });
}