import React, { Component } from 'react';
import PlayerRightSidebar from './PlayerRightSidebar';
import { withRouter, Switch, Route } from 'react-router-dom';
import PlayerNavTabs from './PlayerNavTabs';
import CareerHighLight from './CareerHighLight';
import VideosAndReports from './VideosAndReports';
import Contacts from './Contacts';
import Cases from './Cases';
import { get_current_user_role, is_mobile } from '../../../helpers/GeneralHelper';
import AddPlayer from './AddPlayer';

class PlayerDetails extends Component {
    
    constructor( props ) {
        super( props );

        this._player_id         = props.match.params.player_id;
        this.current_user_role  = get_current_user_role();

        this.state = {
            sidebarToggled : !is_mobile()
        }
    }

    openSidebar = () => {
        const sidebarToggled = this.state.sidebarToggled
        this.setState({
            sidebarToggled: !sidebarToggled
        });
    }

    /**
     * Checks if current route is matched with navlink pathname.
     * @param {*} match     // Returns route details if matched with current route.
     */
    isMatched = match => {
        return match;
    }

    render() {
        const toggledClass  = this.state.sidebarToggled ? 'toggled' : '',
        route_user          = this.current_user_role === 'Agent' ? 'agent' : 'admin';
        return (
            <div id="mainContainer" className={toggledClass}>
                <div className="heading-section height84 d-flex align-items-center marginB0">
                    <h2>Player Details</h2>
                    <button onClick={ this.openSidebar } to="" className="transparent-btn ml-auto usmDrawer" data-toggle="offcanvas">
                        <img src={ require( '../../../assets/images/drawer.png' ) } alt="" />
                    </button>
                </div>

                <PlayerRightSidebar />

                <div className="content-section">
                    <PlayerNavTabs player_id={ this._player_id } />
                    <Switch>
                        {/* <Route exact path="/players-details/:player_id" component={ PlayerProfile } /> */}
                        <Route exact path="/players-details/:player_id" component={ AddPlayer } />
                        <Route exact path={`/${route_user}/player-highlight-list/:player_id`} component={ CareerHighLight } /> 
                        <Route exact path={`/${route_user}/player-videos-and-reports/:player_id`} component={ VideosAndReports } /> 
                        <Route exact path={`/${route_user}/player-contacts/:player_id`} component={ Contacts } /> 
                        <Route exact path={`/${route_user}/player-cases/:player_id`} component={ Cases } />
                    </Switch>
                </div>
            </div>
        )
    }
}
export default withRouter( PlayerDetails );