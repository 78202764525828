import React from 'react';
import { get_authorization_token, __x, is_url_valid, is_protocol_exists } from '../../../helpers/GeneralHelper';
import Request from '../../../helpers/Request';
import APIRoutes from '../../../API/Routes';
import OrganisationsList from './OrganisationsList';
import AddOrganisation from './AddOrganisation';
import * as EmailValidator from 'email-validator';
import LoadingSpinner from '../common/LoadingSpinner';
import Alert from '../../../helpers/Alert';
import { connect } from 'react-redux';
import { setOrganisations, appendOrganisations } from '../../../actions/OrganisationsAction';
import InfiniteScroll from 'react-infinite-scroll-component';

 class OrganisationsAndPeople extends React.Component {

    _page_title = 'Organisations And People - USM';

    constructor(props) {
      super(props);

      this._country     = React.createRef();
      this.fetched      = false;

      this._is_fetched = {
          getOrganisationsPeople : false,
      }

      this.state = {
          all_organisations : [],
          addOrganisationModal : false,
          fields : {
            organization_name : '',
            web_address       : '',
            email             : '',
            organization_type : '',
            country_code      : '44',
            phone             : '',
          },
          isValid : {
            organization_name  : true, 
            web_address        : true,
            email              : true,
            organization_type  : true, 
            country_code       : true,
            phone              : true
        },
        fieldsValidityError: {
            organization_name  : '', 
            web_address        : '',
            email              : '',
            organization_type  : '',
            country_code       : '',
            phone              : ''
        },
        country_iso         : 'gb',
        isProcessing        : false,
        isSubmitted         : false,
        isSuccess           : false,
        responseMessage     : '',
        hasMoreItems        : false,
		maxPages            : null,
		nextPage            : 1,

      }
    }

    componentDidMount = () => {
        document.title = this._page_title;

        if(!this._is_fetched.getOrganisationsPeople) {
            this.getOrganisationsPeople();
        }
    }
    
    /**
     * get organisation and people data
     */
    getOrganisationsPeople = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        this.setState({
            nextPage : 1,
            maxPages : null,
        });

        const organisation_path = APIRoutes.all_organization;
        const request = new Request( organisation_path, {}, 'GET', headers ).send();

        request.then( response => {
            if ( response.success ) {
                this.fetched = true;
                let all_organisations = this.state.all_organisations;
                all_organisations = response.data && response.data.length > 0 ? response.data : [];

                /**
                 * set organisations list in redux store
                 */
                this.props.setOrganisations( all_organisations );
                this.setState({
                    hasMoreItems    : response.pages && response.pages === 1 ? false : true,
                    maxPages        : response.pages ? response.pages : null,
                    nextPage        : this.state.nextPage + 1,
                });
            }
        }, error => {
            console.log( error );
        });
    }

    /**
	 * load more organisations on page scroll
	 */
	loadMoreOrganisations = () => {
		const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

		this.setState({
			hasMoreItems : false,
		});

		let maxPages = this.state.maxPages,
		nextPage     = this.state.nextPage;


		if(maxPages < nextPage) {
			this.setState({
				hasMoreItems : false,
			});
			return false;
		}

        const organisation_path = APIRoutes.all_organization + nextPage ;
		const request = new Request(organisation_path, {}, 'GET', headers).send();
		
		request.then(response => {
			if (response.success) {
				this.setState({
					hasMoreItems : true,
					nextPage : nextPage + 1,
                });

                /**
					 * Dispatching action to set users to redux store.
					 */
                this.props.appendOrganisations(this.props.organisation.organisations, response.data);
				
			}
		}, error => {
			console.log(error);
		});
    }

    /**
     * Open close organisation modal/popup
     */
    handleAddOrganisationModal = ( fetchOrganisations = false ) => {
        let new_state = {
            addOrganisationModal   : !this.state.addOrganisationModal
        }

        this.setState( new_state, () => {
            if ( this.state.addOrganisationModal ) {
                document.body.classList.add( 'modal-open' );
            } else {
                document.body.classList.remove( 'modal-open' );
            }
        });
        if ( fetchOrganisations ) {
            this.getOrganisationsPeople();
        }
    }

    /**
     * close popup window
     */
    onPopupClose = ( ) => {
        this.clearFormFields();
        this.handleAddOrganisationModal( true );
    }

    /**
     * handle input fields changes
     */
    handleFieldsChanges = ( event ) => {

        const elt   = event.target,
        name        = elt.name,
        value       = elt.value,
        fields      = this.state.fields,
        isValid     = this.state.isValid,
        fieldsValidityError       = this.state.fieldsValidityError;

        
        /**
         * When the current field is `video_url`
         */
         if ( name === 'web_address' ) {
            fields[ name ]  = value;
            isValid[ name ] = fields[ name ] === '' ? true : fields[ name ].length > 0 && fields[ name ].length <= 255 && is_url_valid( fields[ name ] ) && is_protocol_exists( fields[ name ] );
            fieldsValidityError[ name ]   = isValid[ name ] ? '' : ( is_protocol_exists( fields[ name ] ) ? __x( `Incorrect URL.` ) : __x( `Please include http or https in url.` ) );
        } 

        else if( name === 'email' ) {
            console.log(name);
            fields[ name ] = value;
            isValid[ name ] = fields[ name ] === '' ? true : fields.email.length >= 1 && fields.email.length <= 255 && EmailValidator.validate( fields.email );
            fieldsValidityError[ name ] = isValid[ name ] ? '' : __x(`Please enter a vaild email.`) ;
        }
        
        /**
         * For all rest of the `fields`.
         */
        else {
            fields[ name ]  = value;
            isValid[ name ] = fields[ name ] === '' ? true : fields[ name ].length > 0 && fields[ name ].length <= 255;
            fieldsValidityError[ name ]   = isValid[ name ] ? '' : __x( `Maximum 255 characters allowed.` );
        }

        this.setState({ fields, isValid, fieldsValidityError });
    }

    /**
     * Handles country code changes
     */
    handleCountryCodeChange = ( value, countryData ) => {
        const fields    = this.state.fields,
        isValid         = this.state.isValid,
        country_code    = countryData? countryData.dialCode : '44';
        
        fields[ 'country_code' ]    = country_code;

        isValid[ 'country_code' ]   = fields.country_code !== '' ? fields.country_code.toString().length >= 0 && fields.country_code.toString().length <= 255 : true;

        this.setState({
            fields,
            isValid,
            country_iso: countryData.iso2
        });
    }

    /**
     * Handles phone changes
     */
    handlePhoneChange = ( status, value, countryData ) => {
        const fields    = this.state.fields,
        isValid         = this.state.isValid,
        country_code    = countryData? countryData.dialCode : '44',
        phone           = value ? value : '';
        
        fields[ 'country_code' ]    = country_code;
        fields[ 'phone' ]           = phone;

        isValid[ 'country_code' ]   = fields.country_code !== '' ? fields.country_code.toString().length >= 0 && fields.country_code.toString().length <= 255 : true;
        isValid[ 'phone' ]          = fields.phone !== '' ? fields.phone.length >= 0 && fields.phone.length <= 255 && !isNaN( fields.phone ) : true;

        this.setState({
            fields,
            isValid
        })
    }

    /**
     * Check if form is valid or not to submit
     */
    isFormValid = () => {
        let validityErr = [];
        const fields    = this.state.fields,
        isValid         = this.state.isValid;

        for ( let elt in fields ) {
            
             if ( fields[ elt ] === '' || !isValid[ elt ] ) {
                validityErr.push( elt );
            }
        }
        return validityErr.length <= 0;
    }

    /**
     * add organisation data
     */
    addOrganisation = (event) => {
        console.log("hjkg");
        event.preventDefault();
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( !this.isFormValid() ) {
            return false;
        }

        this.setState({
            isProcessing: true
        });

        const organisation_path   = APIRoutes.organization,
        fields                    =  this.state.fields;
        let new_state   = {},
        formData        = new FormData();
        for ( let i in fields ) {
            formData.append( i, fields[i] );
        }
        
        formData.append( 'country_iso', this.state.country_iso );
        
        /**
         * Sending request to add/update users.
         */
        const request = new Request( organisation_path, formData, 'POST', headers, true ).send();
        request.then( response => {
            if ( response.success ) {
                this.onPopupClose( true );
            }
            
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMessage : response.message,
            }
            this.setState( new_state );            
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMessage : error.message
            };
            this.setState( new_state );
        });
    }

    /**
     * remove success message
     */
    resetFlashMessage = () => {
		this.setState({
			responseMessage : ''
		});
    }
    
    /**
     * Clear form fields
     */
    clearFormFields = () => {
        this.setState({
            fields : {
                organization_name : '',
                web_address       : '',
                email             : '',
                organization_type : '',
                country_code      : '44',
                phone             : '',
              },
              isValid : {
                organization_name  : true, 
                web_address        : true,
                email              : true,
                organization_type  : true, 
                country_code       : true,
                phone              : true
            },
            fieldsValidityError: {
                organization_name  : '', 
                web_address        : '',
                email              : '',
                organization_type  : '',
                country_code       : '',
                phone              : ''
            },
              country_iso         : 'gb',
              isProcessing        : false,
              isSubmitted         : false,
              isSuccess           : false,
              responseMessage     : '',
        });
        this._country.setFlag( 'gb' );
    }

    resetFlashMessage = () => {
		this.setState({
			resetFlash: true
		});
    }

    resetMessage = () => {
        this.setState({
            responseMessage : '',
        })
    }

    render() {
        const loading = this.state.isProcessing ? <LoadingSpinner/> : '',
        responseMessage = this.state.responseMessage === '' && !this.state.isSubmitted ? '' : <Alert message={this.state.responseMessage} success={this.state.isSuccess} floating="true" onClose={this.resetMessage} />,
        flashMessage = this.state.resetFlash || !this.props.location.state || this.props.location.state.message === '' ? '' : <Alert message={this.props.location.state.message} success={this.props.location.state.success} floating="true" onClose={this.resetFlashMessage} />;
        return(
            <div id="mainContainer" className="org-mobile">
            {loading}
            {responseMessage}
            {flashMessage}
                <div className="heading-section d-flex align-items-center">
                    <h2>Organisations & People</h2> 
                        <h6 className="ml-auto">
                            <img src={ require( '../../../assets/images/add-organisation.png' ) } alt="" className="marTN6" /> 
                            <button onClick={ this.handleAddOrganisationModal } className="text-white marginL10 transparent-btn" data-toggle="modal">Add Organisation</button>
                        </h6>
                </div>

                <div className="content-section">

                    <div className="usm-table table-responsive">

                    <InfiniteScroll
                            dataLength={this.props.organisation.organisations && this.props.organisation.organisations.length > 0 ? this.props.organisation.organisations.length : 0 } //This is important field to render the next data
                            next={this.loadMoreOrganisations}
                            hasMore={this.state.hasMoreItems}
                        // loader={<h4>Loading...</h4>}
                        >
                            <table className="table table-hover lastTwo-center">
                            <thead className="usm-thead">
                                <tr>
                                    <th>Organisation Name</th>
                                    <th>Web Address</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Entity Type</th>
                                    <th>Notes</th>
                                    <th>Manage</th>
                                </tr>
                            </thead>
                            <tbody>
                                <OrganisationsList 
                                    is_fetched={ this.fetched }
                                    organisations = { this.props.organisation.organisations }
                                />
                            </tbody>
                        </table>

                        </InfiniteScroll>
                    </div>
                </div>

                <AddOrganisation 
                    fields              = { this.state.fields }
                    isValid             = { this.state.isValid }
                    fieldsValidityError = { this.state.fieldsValidityError }
                    handleFieldsChanges = { this.handleFieldsChanges }
                    handlePhoneChange   = { this.handlePhoneChange }
                    countryCodeChange   = { this.handleCountryCodeChange }
                    isFormValid         = { this.isFormValid }
                    set_country         = { elt => this._country = elt }
                    modal               = { this.state.addOrganisationModal }
                    onPopupClose        = { this.onPopupClose }
                    checkDomain         = { this.checkDomain }
                    addOrganisation     = { this.addOrganisation }
                />

            </div>
        )
    }

} 

const mapStateToProps = (state) => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setOrganisations: (organisations) => dispatch(setOrganisations(organisations)),
    appendOrganisations : (all_organisations, load_organisations) => dispatch(appendOrganisations(all_organisations, load_organisations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationsAndPeople);