import React from 'react';
import * as EmailValidator from 'email-validator';
import { __x, get_authorization_token } from '../../../helpers/GeneralHelper';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import LoadingSpinner from '../common/LoadingSpinner';
import Alert from '../../../helpers/Alert';

export default class SendReport extends React.Component {

    constructor( props ) {
        super( props );

        this.state = {
            fields : {
                email     : '',
            },
            isValid : {
                email : true,
            },
            error : {
                email : '',
            },
            isProcessing : false,
            isSubmitted     : true,
            isSuccess       : true,
            responseMsg     : '',
        }
    }

    /**
     * reset alert message
     */
    resetAlert = () => {
        this.setState({
            responseMsg : ''
        });
    }

    render = () => {
        const showModal = this.props.modal ? 'show' : '',
        submit_disabled = !this.isFormValid(),
        alert_messages  = this.state.responseMsg !== '' ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} floating="true" onClose={this.resetAlert} /> : '',
        loading         = this.state.isProcessing ? <LoadingSpinner/> : '';
           
        return(
            <div>
                {alert_messages}
                {loading}
            <div className={`modal form-modal fade modal-active ${showModal}`} style={{ transform: this.props.modal ? 'translate(0,0)' : 'translate(-125%, -125%)' }}>
                    <div className="modal-dialog modal-dialog-centered maxWidth600" role="document">
                        <div className="modal-content">
                            <div className="modal-header form-modal-header d-flex align-items-center">
                            <img src={require('../../../assets/images/form-usm-logo.png')} alt="" />
                                <h5 className="form-modal-title">Send Report</h5>
                                <button  onClick={ this.closePopup } type="button" className="trasnparent-btn modal-close">
                                    <img src={ require( '../../../assets/images/close-modal.png' ) } alt="" />
                                </button>
                            </div>
                            <div className="modal-body form-modal-body">

                                <form action="" className="usm-form form-border">
                                    <div className="row">
                                        <div className="col">
                                            <div className={ this.state.error.email === '' ? `form-group` : `form-group form-error`}>
                                                <label htmlFor="">Email</label>
                                                <input onChange={ this.handleChanges } value={ this.state.fields.email } name="email" type="text" className="form-control" placeholder="Email" />
                                                { this.state.error.email === '' ? '' : <div className="display-error">{ this.state.error.email }</div> }
                                            </div>
                                        </div>
                                    </div>
                                    
                                </form>
                            </div>
                            <div className="modal-footer form-modal-footer d-flex justify-content-between">
                                <button onClick={ this.closePopup } type="button" className={`default-btn btn-danger`} data-dismiss="modal">Cancel</button>
                                <button onClick={ this.sendReport } disabled={submit_disabled} type="submit"  className={`default-btn btn-success`}>Send Report</button>
                            </div>
                        </div>
                    </div>
                </div>
                {!this.props.modal || <div className="modal-backdrop fade show"></div>}
                </div>
                
        );
    }
    /**
     * Handle fields changes
     */
    handleChanges = ( event ) => {
        const target    = event.target,
        name            = target.name,
        value           = target.value,
        fields          = this.state.fields;

        let isValid = this.state.isValid,
        error       = this.state.error;

        if ( name === 'email' ) {
            fields[ name ]  = value;
            isValid[ name ] = fields[ name ] !== '' ? fields[ name ].length >= 1 && fields[ name ].length <= 255 && EmailValidator.validate( fields[ name ] ) : true;
            error[ name ]   = !isValid[ name ] ? __x( `Please enter a valid email.` )  : '';
        }

        this.setState({ fields, isValid, error });
    }

    /**
     * check form valid
     */
    isFormValid = () => {
        let validityErr = [];
        const fields    = this.state.fields,
        isValid         = this.state.isValid;
        
        for (let elt in fields) {
            if (fields[elt] === '' || !isValid[elt]) {
                validityErr.push(elt);
            }
        }
        return validityErr.length <= 0;
    }

    /**
     * close send report popup
     */
    closePopup = () => {
        this.clearFormFields();
        this.props.closePopup();
    }

    /**
     * clear form fields
     */
    clearFormFields = () => {
        this.setState({
            fields : {
                email     : '',
            },
            isValid : {
                email     : true,
            },
            error : {
                email     : '',
            }
        });
    }

    /**
     * send report
     */
    sendReport = event => {
        event.preventDefault();
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( !this.isFormValid() ) {
            return false;
        }

        this.setState({
            isProcessing: true
        });
        
        const send_report_path   = APIRoutes.send_report + this.props.report_id;

        const fields    =  this.state.fields;
        let new_state   = {},
        formData        = new FormData();
        for ( let i in fields ) {
            formData.append( i, fields[i] );
        }
        
        /**
         * Sending request to add/update users.
         */
        const request = new Request( send_report_path, formData, 'POST', headers, true ).send();
        request.then( response => {
            if ( response.success ) {
                this.closePopup();
            }
            
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message,
            }
            this.setState( new_state );            
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMsg     : error.message
            };
            this.setState( new_state );
        });

    }
}