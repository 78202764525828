export const setOrganisation = ( organisation ) => dispatch => {
    dispatch({
        type: 'SET_ORGANISATION',
        payload: {
            organisation: organisation
        }
    });
}

/**
 * set list of organisations in redux store
 */
export const setOrganisations = ( organisations ) => dispatch => {
    dispatch({
        type: 'SET_ORGANISATIONS',
        payload: {
            organisations: organisations
        }
    });
}

/**
 * append organisations data in already existing listing while applying pagination
 */
export const appendOrganisations = (all_organisations, loaded_organisations) => dispatch => {
    dispatch({
        type: 'APPEND_ORGANISATIONS',
        payload: {
            organisations: [
                ...all_organisations,
                ...loaded_organisations
            ]
        }
    });
}