
export const setClubList = ( clubs ) => dispatch => {
    dispatch({
        type: 'SET_CLUB_LIST',
        payload: {
            clubs: clubs
        }
    });
}

export const setCases = ( cases ) => dispatch => {
    dispatch({
        type: 'SET_CASES',
        payload: {
            cases: cases
        }
    });
}

export const appendCases = (all_cases, loaded_cases) => dispatch => {
    dispatch({
        type: 'APPEND_CASES',
        payload: {
            cases: [
                ...all_cases,
                ...loaded_cases
            ]
        }
    });
}

export const setAgents = ( agents ) => dispatch => {
    dispatch({
        type: 'SET_AGENTS',
        payload: {
            agents
        }
    });
}

export const setPositions = ( positions ) => dispatch => {
    dispatch({
        type: 'SET_POSITIONS',
        payload: {
            positions
        }
    });
}
