import React, { Component } from 'react';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import { withRouter } from 'react-router-dom';
import { setVideos } from '../../../actions/PlayersAction';
import { connect } from 'react-redux';
import { get_current_user_role, get_authorization_token, __x } from '../../../helpers/GeneralHelper';
import Video from './Video';
import UpdateVideo from './UpdateVideo';


class Videos extends Component {

    constructor( props ) {
        super( props );
 
        this._video    = React.createRef();
        this._player_id = props.match.params.player_id;
        this.current_user_role = get_current_user_role();

        this._fetched   = {
            video   : false,
            videos  : false
        };
        
        this.state = {
            video       : {},
            videos      : [],
            shouldPopupOpen   : false,
            is_editing  : false,
            video_id    : 0,
        };
    }

    /**
     * Updated state with new props.
     */
    componentWillReceiveProps = props => {
        this.setState({
            videos: props.players.videos
        });
    }

    /**
     * Toggle add/update popup.
     */
    openPopup = ( event, video_id = 0 ) => {
        if ( video_id > 0 ) {
            this.getVideo( video_id );
            return;
        }

        this.setState({
            shouldPopupOpen : !this.state.shouldPopupOpen
        }, () => {
            if ( this.state.shouldPopupOpen ) {
                document.body.classList.add( 'modal-open' );
            }
        });
    }

    closePopup = () => {
        this.setState({
            shouldPopupOpen : !this.state.shouldPopupOpen,
            video           : {},
            is_editing      : false
        }, () => {
            if ( !this.state.shouldPopupOpen ) {
                document.body.classList.remove( 'modal-open' );
            }
        });
    }

    /**
     * Get single video by id
     */
    getVideo = video_id => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const video_path = APIRoutes.player_video + video_id;
        const request = new Request( video_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                let video = this.state.video;
                video = response.data
                this.setState({ 
                    video,
                    is_editing      : true,
                    shouldPopupOpen : !this.state.shouldPopupOpen
                }, () => {
                    if ( this.state.shouldPopupOpen ) {
                        document.body.classList.add( 'modal-open' );
                    }
                });
                this._fetched.videos = true;
            }
        }, error => {
            console.log( error );
        });
    }

    /**
     * Get all videos from API.
     */
    getVideos = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const videos_path = APIRoutes.player_all_videos + this._player_id;
        const request = new Request( videos_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                this.props.setVideos( response.data );
                this._fetched.videos = true;
            }
        }, error => {
            console.log( error );
        });
    }
    
    componentDidMount = () => {
        if ( !this._fetched.videos ) {
            this.getVideos();
        }
    }

    render() {
        return (
            <div className="player-detail-block">
                <div className="player-detail-heading d-flex align-items-center">
                    Videos
                    {
                        this.current_user_role === 'Agent' ? '' :
                        <button type="button" onClick={ this.openPopup } className="transparent-btn float-right ml-auto" data-toggle="modal" data-target="#videoModal">
                            <img src={require("../../../assets/images/add-icon.png")} alt="" />
                        </button>
                    }
                </div>
                <div className="player-detail-content">
                    <div className="notes-block">
                        <ul className="list-unstyled">
                            <Video 
                                videos={ this.state.videos }
                                openPopup={ this.openPopup }
                                closePopup={ this.closePopup }
                            />
                        </ul>
                    </div>
                </div>
                <UpdateVideo 
                    player_id={ this._player_id }
                    shouldPopupOpen={ this.state.shouldPopupOpen }
                    openPopup={ this.openPopup }
                    closePopup={ this.closePopup }
                    refresh={ this.getVideos }
                    video={ this.state.video }
                    is_editing={ this.state.is_editing }
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setVideos: videos => dispatch( setVideos( videos ) )
});

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( Videos ) );