import React from 'react';
import { get_display_format_date } from '../../helpers/GeneralHelper';
import { Scrollbars } from 'react-custom-scrollbars';


const ClubListNumberRollOver = props => {
    const onlyActive    = props.filtered === "" || props.filtered === "1" ? true : false,
    case_status         = onlyActive  ? `Active` : `Closed`;
    
    let count   = 0,
    club_cases  = [];

    for ( let i in props.clubCases ) {
        let club_case = props.clubCases[i];
        if ( onlyActive && club_case.status <= 0 )
            continue;
        
        let fullfillment_date = club_case.fulfillment_date ? club_case.fulfillment_date : '';
        
        count++;

        club_cases.push(<tr key={club_case.id}>
            <td className="text-left paddingL0">{ get_display_format_date( fullfillment_date ) }</td>
            <td className="text-right paddingR0">{ club_case.master_position ? club_case.master_position.abv : '' }</td>
        </tr>);
    }

    const id = `popover-content-toggle-one-${props.club_id}`;
    return (
        <div id={id} className="popover-content-toggle-one">

            <div className="arrow-right"></div>
            <div className="usm-table-popover table-responsive">
                <div className="table-popover-heading"><span>{count}</span> {case_status} Cases</div>
                <Scrollbars autoHeightMin={100} autoHeightMax={270} autoHide autoHeight>
                    <div className="usm-table-popover-scroll">
                        <table className="">
                            <thead>
                                <tr>
                                    <th>Fulfilment Date</th>
                                    <th>Position</th>
                                </tr>
                            </thead>
                            <tbody>
                                {club_cases}
                            </tbody>
                        </table>
                    </div>
                </Scrollbars>
            </div>
        </div>
    )
}

export default ClubListNumberRollOver;

