import React, { Component } from 'react';
import UpdateContact from '../players/UpdateContact';
import { get_authorization_token, __x, get_current_user_role, get_display_format_date, is_valid_db_date } from '../../../helpers/GeneralHelper';
import Request from '../../../helpers/Request';
import APIRoutes from '../../../API/Routes';

export default class Contacts extends Component {

    constructor(props) {
        super(props);

        this.current_user_role = get_current_user_role();

        this._fetched = {
            contact: false,
            contacts: false
        };

        this.state = {
            shouldPopupOpen: false,
            is_editing: false,
            contact: {},
            contacts: [],
            view_all_contacts: false,
        }
    }

    componentDidMount = () => {
        if (!this._fetched.contacts) {
            this.getContacts();
        }
    }

    /**
     * open add/update contact popup
     */

    openPopup = (contact_id = 0, is_organisation_contact = 'false') => {
        if (contact_id > 0) {
            this.getContact(contact_id, is_organisation_contact);
        }

        this.setState({
            shouldPopupOpen: !this.state.shouldPopupOpen
        }, () => {
            if ( this.state.shouldPopupOpen ) {
                document.body.classList.add( 'modal-open' );
            } else {
                document.body.classList.remove( 'modal-open' );
            }
        });
    }

    /**
     * get particular contact detail based on id
     */
    getContact = (contact_id = 0) => {
        if (contact_id < 0) {
            return false;
        }
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x(`Session has been expired. Please reload the page to login!`)
            });
            return false;
        }

        let contact = this.state.contact;

        const contacts_path = APIRoutes.organization_contact + contact_id;
        const request = new Request(contacts_path, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {

                contact = response.data && Object.keys(response.data).length > 0 ? response.data : {};
                this._fetched.contact = true;

                this.setState({
                    contact,
                    is_editing: true,
                });
            }
        }, error => {
            console.log(error);
        });
    }

    /**
     * close contact add/edit popup
     */
    closePopup = () => {
        this.setState({
            contact: {},
            is_editing: false,
            shouldPopupOpen: !this.state.shouldPopupOpen
        }, () => {
            if ( this.state.shouldPopupOpen ) {
                document.body.classList.add( 'modal-open' );
            } else {
                document.body.classList.remove( 'modal-open' );
            }
        });
    }


    /**
     * Get all contacts from API.
     */
    getContacts = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x(`Session has been expired. Please reload the page to login!`)
            });
            return false;
        }

        let contacts = this.state.contacts;

        const contacts_path = APIRoutes.organization_all_contacts + this.props.organisation_id;
        const request = new Request(contacts_path, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {

                contacts = response.data && response.data.length > 0 ? response.data : [];
                this._fetched.contacts = true;

                this.setState({
                    contacts
                });
            }
        }, error => {
            console.log(error);
        });
    }

    /**
     * show grid of contacts
     */
    listContacts = () => {
        let contacts = this.state.contacts;
        return contacts && contacts.length > 0 ? contacts.map((contact, idx) => {
            const is_restricted = contact.is_restricted && this.current_user_role !== 'Admin';

            if (!this.state.view_all_contacts && idx > 5) {
                return null;
            }

            if (is_restricted) {
                return (
                    <div key={contact.id} className="col-md-6 col-xs-12 marginB10">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-head-dropdown">
                                    <div className="dropdown d-inline">
                                        <div className="btn card-dropdown boundary-90" id="dropdownMenuCard" title={contact.name ? contact.name : ''} >
                                            {contact.name ? contact.name : ''}</div>
                                    </div>

                                    <div className="btn-group dropright d-inline float-right marginT5 show">
                                        <button onClick={() => this.openPopup(contact.id)} className="transparent-btn padding0" type="button">
                                            <i className="material-icons text-offWhite font-size20">edit</i>
                                        </button>
                                    </div>
                                </div>
                                <div className="cardContent-section">
                                    <div className="card-data padding0">
                                        <div className="card-data-label">Title in Organisation</div>
                                        <div className="card-data-content fontNormal">{contact.title ? contact.title : ''}</div>
                                        <div className="row marginT35 marginB20">
                                            <div className="col">
                                                <div className="d-inline-block marginR10 marginT5 align-top">
                                                    <img src={require("../../../assets/images/Restricted.png")} alt="" />
                                                </div>
                                                <div className="d-inline-block">
                                                    <p className="fontBold text-danger font-size14">Restricted
                                                                            <span className="fontBold text-white d-block">Please contact administrator.</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-data-label">Birthday</div>
                                        <div className="card-data-content fontNormal"> {contact.birthday !== null && is_valid_db_date(contact.birthday) ? get_display_format_date(contact.birthday) : ''} {contact.show_birthday_notification ? <i className="align-bottom material-icons text-success font-size18 marginR5">notifications</i> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            return (
                <div key={contact.id} className="col-md-6 col-xs-12 marginB10">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-head-dropdown">
                                <div className="dropdown d-inline">
                                    <div className="btn card-dropdown boundary-90" id="dropdownMenuCard" title={contact.name ? contact.name : ''} >
                                        {contact.name ? contact.name : ''}</div>
                                </div>

                                <div className="btn-group dropright d-inline float-right marginT5 show">
                                    <button onClick={() => this.openPopup(contact.id)} className="transparent-btn padding0" type="button">
                                        <i className="material-icons text-offWhite font-size20">edit</i>
                                    </button>
                                </div>
                            </div>
                            <div className="cardContent-section">
                                <div className="card-data padding0">
                                    <div className="card-data-label">Title in Organisation</div>
                                    <div className="card-data-content fontNormal">{contact.title ? contact.title : ''}</div>
                                    <div className="card-data-label">Phone</div>
                                    <div className="card-data-content fontNormal">{contact.country_code && contact.phone ? `+${contact.country_code}` : ''}{contact.phone ? contact.phone : ''}</div>
                                    <div className="card-data-label">Email</div>
                                    <div className="card-data-content fontNormal">{contact.email ? contact.email : ''}</div>
                                    <div className="card-data-label">Birthday</div>
                                    <div className="card-data-content fontNormal"> {contact.birthday !== null && is_valid_db_date(contact.birthday) ? get_display_format_date(contact.birthday) : ''} {contact.show_birthday_notification ? <i className="align-bottom material-icons text-success font-size18 marginR5">notifications</i> : ''}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }) : '';
    }

    /**
     * toggle state to view all contacts
     */
    viewAllContacts = () => {
        this.setState({
            view_all_contacts: !this.state.view_all_contacts,
        });
    }

    render() {
        return (
            <div>
                <div className="heading-section d-flex align-items-center auto-height" >
                    Contact Details <span className="badge badge-pill badge-success">{this.state.contacts && this.state.contacts.length > 0 ? this.state.contacts.length : 0 }</span> <button type="button" onClick={this.openPopup} className="transparent-btn ml-auto float-right" data-toggle="modal" data-target="#addContactModal">
                        <img src={require("../../../assets/images/add-icon.png")} alt="" />
                    </button>
                </div>
                <div className="row usm-cards marginB35">
                    {this._fetched.contacts ? this.listContacts() : ''}

                        <div className="col-md-12"> {
                            this.state.contacts && this.state.contacts.length > 6 ?                    
                            <button onClick={this.viewAllContacts} type="button" className="transparent-btn button-color float-right">{!this.state.view_all_contacts ? `View All` : <img src={require('../../../assets/images/arrow-up.png')} alt="" />}</button>
                        :''}</div>
                </div>

               

                <UpdateContact
                    shouldPopupOpen={this.state.shouldPopupOpen}
                    closePopup={this.closePopup}
                    refresh={this.getContacts}
                    contact={this.state.contact}
                    is_editing={this.state.is_editing}
                    is_organisation={true}
                    updateContact={this.updateContact}
                />

            </div>
        )
    }
}