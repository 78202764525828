import React from 'react';
import { is_a_valid_pdf, get_authorization_token, __x,is_size_exceeded } from '../../../helpers/GeneralHelper';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import Alert from '../../../helpers/Alert';
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from '../common/LoadingSpinner';
import DeletePlayerInfoConfirm from './DeletePlayerInfoConfirm';

class UpdateReport extends React.Component {

    constructor(props) {
        super(props);

        this.files      = {};
        this._player_id = props.player_id ? props.player_id : 0;
        
        this.state = {
            files       : ['file-0'],
            fileNames   : {
                'file-0': 'File Name'
            },
            is_editing      : false,
            report_id    : 0,
            attachments     : [],
            deleted_file_ids: [],
            fields  : {
                title           : '',
                description     : '',
                files           : {
                    'file-0' : {}
                },
                is_restricted   : false
            },
            isValid : {
                title           : true,
                description     : true,
                files           : {
                    'file-0' : true
                },
                is_restricted   : true
            },
            error: {
                title           : '',
                description     : '',
                files           : {
                    'file-0' : ''
                },
            },
            isProcessing    : false,
            isSubmitted     : true,
            isSuccess       : false,
            responseMsg     : '',
            openDeleteConfirmationPoppup : false,
            last_index      : 0 //used for managing add files/attachments
        }
    }

    setFileRefernces = ( elt, file ) => {
        this.files = {
            [file]: elt
        };
    }

    componentWillReceiveProps = props => {
        if ( !props.report || Object.keys( props.report ).length <= 0 ) {
            return false;
        }

        const fields = this.state.fields;
        fields[ 'title' ]           = props.report && props.report.title ? props.report.title : '';
        fields[ 'description' ]     = props.report && props.report.description ? props.report.description : '';
        fields[ 'is_restricted' ]   = props.report && props.report.is_restricted ? props.report.is_restricted : false;

        this.setState({
            fields,
            is_editing      : props.is_editing,
            report_id       : props.report && props.report.id ? props.report.id : 0,
            attachments     : props.report && props.report.player_report_files ? props.report.player_report_files : []
        });
    }

    /**
     * Add new file input.
     */
    addFile = () => {
        const files = this.state.files,
        fileNames   = this.state.fileNames,
        fields      = this.state.fields,
        isValid     = this.state.isValid;
        let last_index  = this.state.last_index;
        last_index  = last_index + 1;
        const new_file    = `file-${last_index}`;

        files.push( new_file );
        fields.files[ new_file ]    = {};
        isValid.files[ new_file ]   = true;
        fileNames[ new_file ]   = `File Name`;
        this.setState({ files, fields, isValid, fileNames, last_index });
    }

    /**
     * List all input files data.
     */
    listFiles = () => {
        const files = this.state.files;
        return files.map( ( file, idx ) => {
            return (
                <div className="row marginB15 form-group" key={idx}>

                    <div className="col-md-3">
                        <div className="browse-custom-file">
                            <input ref={elt => this.setFileRefernces(elt, file)} onChange={this.handleChanges} type="file" className="custom-file-browse" name={file} id={file} />
                            {/* <input type="file" className="custom-file-browse" id="browseFile" /> */}
                            <label className="browse-label" htmlFor={file}></label>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <label className="marginB0 paddingT5">{this.state.fileNames[file]}</label>
                        <button onClick={this.clearAttachment.bind(this, idx, file)} type="button" className="transparent-btn d-inline">
                            <i className="material-icons text-danger font-size16 align-middle">close</i>
                        </button>
                    </div>
                    {this.state.error.files[file] && this.state.error.files[file] !== '' ? <div className="display-error custome-error-top44">{this.state.error.files[file]}</div> : ''}
                </div>
            );
        });
    }
    // listFiles = () => {
    //     const files = this.state.files;
    //     return files.map( ( file, idx ) => {
    //         return (
    //             <div key={idx} className="custom-file">
    //                 <input ref={ elt => this.setFileRefernces( elt, file ) } onChange={ this.handleChanges } type="file" className="custom-file-input" name={file} />
    //                 <label className="custom-file-label add-file-input" htmlFor="customFile">{this.state.fileNames[ file ]}</label>
    //                 { this.state.error.files[ file ] && this.state.error.files[ file ] !== '' ? <div className="display-error custome-error-top44">{ this.state.error.files[ file ] }</div> : '' }
    //             </div>
    //         );
    //     });
    // }

    /**
     * clear attachment
     */
    clearAttachment = (index, file_name) => {
        const files = this.state.files,
              fields = this.state.fields;

        files.splice( index, 1 );

        delete fields.files[ file_name ];

        this.setState({
            files,
            fields: {
                ...fields,
                fields
            }
        });
    }

    /**
     * Delete attachments from editing report.
     */
    deleteAttachment = ( event, file_id ) => {
        if ( !file_id || file_id === '' || file_id <= 0 ) {
            return false;
        }

        const deleted_file_ids = this.state.deleted_file_ids;

        deleted_file_ids.push( file_id );
        this.setState({
            deleted_file_ids 
        });
    }

    /**
     * Handles fields changes except date of services changes.
     */
    handleChanges = ( events ) => {
        const elt   = events.target,
        type        = elt.type,
        name        = elt.name,
        value       = type === 'checkbox' ? elt.checked : elt.value,
        fileNames   = this.state.fileNames,
        fields      = this.state.fields,
        isValid     = this.state.isValid,
        error       = this.state.error;

        if ( type === 'file' ) {
            const file = elt.files.length > 0 ? elt.files[0] : false;
            if ( !file ) {
                return false;
            }

            /**
             * Validting file
             */
            const is_attachment_valid = is_a_valid_pdf( file );
            if ( !is_attachment_valid || !is_attachment_valid.success ) {
                /**
                 * Set files fields according to error.
                 */
                isValid.files[ name ]   = !is_attachment_valid.success ? false : true;
                error.files[ name ]     = is_attachment_valid.messages && is_attachment_valid.messages.length > 0 ? is_attachment_valid.messages.map( ( msg, idx ) => <li key={idx}>{msg}</li> ) : `Something went wrong, please upload a valid PDF file`;
                fileNames[ name ]       = 'File Name';
                fields.files[ name ]    = {};
            } else {
                /**
                 * Set files fields according to valid files.
                 */
                fileNames[ name ]       = file.name;
                fields.files[ name ]    = file;
                isValid.files[ name ]   = true;
                error.files[ name ]     = '';
                const exceeded = is_size_exceeded( fields.files );
                if ( exceeded ) {
                    isValid.files[ name ] = false;
                    error.files[ name ]   = __x( `Total size exceeded from 5mb.` );
                }
            }
        }
        
        if ( name === 'title' ) {
            fields[ name ]  = value;
            isValid[ name ] = fields[ name ] === '' ? true : fields[ name ].length > 0 && fields[ name ].length <= 255;
            error[ name ]   = isValid[ name ] ? '' : __x( `Maximum 255 characters allowed.` );
        }

        if (  name === 'description' ) {
            fields[ name ]  = value;
            isValid[ name ] = fields[ name ] === '' ? true : fields[ name ].length > 0 && fields[ name ].length <= 500;
            error[ name ]   = isValid[ name ] ? '' : __x( `Maximum 500 characters allowed.` );
        }
        
        if ( name === 'is_restricted' ) {
            fields[ name ]  = value;
        }

        this.setState({
            fileNames,
            fields, 
            isValid, 
            error
        });
    }

    /**
     * Handles form submission.
     */
    handleSubmit = event => {
        event.preventDefault();

        /**
         * Check if user is logged in.
         */
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( !this.isFormValid() ) {
            return false;
        }

        this.setState({
            isProcessing: true
        });
        
        const fields        = this.state.fields,
        player_report_path  = this.state.is_editing ? APIRoutes.update_player_report : APIRoutes.add_player_report + this._player_id,
        method              = this.state.is_editing ? "PUT" : "POST";

        let new_state   = {},
        formData        = new FormData();
        
        if ( this.state.is_editing ) {
            formData.append( 'report_id', this.state.report_id );
            formData.append( 'deleted_file_id', JSON.stringify( this.state.deleted_file_ids ) );
        }

        formData.append( 'title', fields.title );
        formData.append( 'description', fields.description );
        for( let i in fields.files ) {
            formData.append( 'files', fields.files[i] );
        }

        formData.append( 'is_restricted', fields.is_restricted );
        
        /**
         * Sending request to add plyaer reports.
         */
        const request = new Request( player_report_path, formData, method, headers, true ).send();
        request.then( response => {
            if ( response.success ) {
                this.props.closePopup();
                this.clearFields();
                this.props.refresh();
            }

            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message
            }
            this.setState( new_state );
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMsg : error.message
            };
            this.setState( new_state );
        });
    }

    /**
     * Open confirmation poppup.
     */
    toggleDeleteConfirmation = () => {
        this.setState({
            openDeleteConfirmationPoppup : !this.state.openDeleteConfirmationPoppup
        });
    }

    /**
     * Delete notes.
     */
    deleteReports = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }
        
        let new_state = {};
        const report_id = this.state.report_id;
        const report_path = APIRoutes.player_report + report_id;
        const request = new Request( report_path, {}, 'DELETE', headers ).send();
        request.then( response => {
            if ( response.success ) {
                this.toggleDeleteConfirmation();
                this.closePopup();
                this.props.refresh();
            }

            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message
            }
            this.setState( new_state );
        }, error => {
            console.log( error );
        });
    }

    onCancelDelete = () => {
        this.toggleDeleteConfirmation();
        this.clearFields();
        this.closePopup();
    }

    /**
     * Checks if form is valid or not.
     */
    isFormValid = () => {
        const error     = [];
        const fields    = this.state.fields,
        files           = fields.files,
        isValid         = this.state.isValid;

        if ( fields.title === '' || !isValid.title ) {
            error.push( 'title' );
        }
        
        if ( !isValid.description ) {
            error.push( 'description' );
        }
        
        if ( !isValid.is_restricted ) {
            error.push( 'is_restricted' );
        }

        for( let i in files ) {
            /**
             * If currently editing a report and 
             * there are atleas one attachment and
             * number of attachments is not equal to deleted attachments.
             */
            if ( this.state.is_editing && this.state.attachments.length > 0 && ( this.state.attachments.length !== this.state.deleted_file_ids.length ) ) {
                if ( !isValid.files[ i ] ) {
                    error.push( i );
                    continue;
                }
                continue;
            }
            
            /**
             * If adding report or there's no attachment while editing,
             * Files are required.
             */
            // const file_value = typeof files[ i ] === 'object' ? files[ i ].size : ( files[ i ] === '' );
            if ( !isValid.files[ i ] ) {
                error.push( i );
            }
        }

        
        return error.length <= 0;
    }

    resetAlert = () => {
        this.setState({
            responseMsg: ''
        });
    }

    /**
     * Clear fields before the popup is closed.
     */
    closePopup = () => {
        this.clearFields();
        this.props.closePopup();
    }

    render = () => {
        const showModal = this.props.modal ? 'show' : '',
        deleted_file_ids= this.state.deleted_file_ids,
        alert_messages  = this.state.responseMsg !== '' ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} floating="true" onClose={this.resetAlert} /> : '',
        loading         = this.state.isProcessing ? <LoadingSpinner/> : '',
        btnswidth       = this.state.is_editing ? 'min-width157' : '',
        submit_disabled = !this.isFormValid();

        return (
            <div>
                {loading}
                {alert_messages}
                <div className={`modal form-modal fade modal-active ${showModal}`} style={{ transform: this.props.modal ? 'translate(0,0)' : 'translate(-125%, -125%)' }}>
                    <div className="modal-dialog modal-dialog-centered maxWidth600" role="document">
                        <div className="modal-content">
                            <div className="modal-header form-modal-header d-flex align-items-center">
                                <img src={require("../../../assets/images/form-usm-logo.png")} alt="" />
                                <h5 className="form-modal-title">{ this.state.is_editing ? 'Edit' : 'Add' } Report</h5>
                                <button type="button" className="transparent-btn modal-close" onClick={ this.closePopup }>
                                    <img src={ require( "../../../assets/images/close-modal.png" ) } alt="" />
                                </button>
                            </div>
                            <div className="modal-body form-modal-body">
                                <form action="" className="usm-form form-border">
                                    
                                    <div className="row">
                                        <div className="col">
                                            <div className={this.state.error.title !== '' ? `form-group form-error` : `form-group`}>
                                                <label htmlFor="">Report Title</label>
                                                <input onChange={ this.handleChanges } value={ this.state.fields.title } name="title" type="text" className="form-control" placeholder="" />
                                                { this.state.error.title === '' || <div className="display-error">{ this.state.error.title }</div> }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className={this.state.error.description !== '' ? `form-group form-error` : `form-group`}>
                                                <label htmlFor="">Report Description</label>
                                                <textarea onChange={ this.handleChanges } value={ this.state.fields.description } name="description" className="form-control textarea76" cols="" rows=""></textarea>
                                                { this.state.error.description === '' || <div className="display-error custome-error-top108 text-white">{ this.state.error.description }</div> }
                                            </div>
                                        </div>
                                    </div>

                                    { this.state.is_editing && this.state.attachments && this.state.attachments.length > 0 ? 
                                        (<div className="row">
                                                <div className="col">
                                                    <label>Attachments</label>
                                                    <div className="form-group">
                                                        {
                                                            this.state.attachments.map( attachment => {
                                                                if ( deleted_file_ids.indexOf( attachment.id ) >= 0 ) {
                                                                    return '';
                                                                }

                                                                return (<div key={attachment.id} className="text-success font-size14">
                                                                    <label className="word-break-all text-success">{ attachment.original_filename }</label>
                                                                    <button onClick={ e => this.deleteAttachment( e, attachment.id ) } type="button" className="transparent-btn">
                                                                        <i className="material-icons text-danger font-size16 align-middle marginL10">close</i>
                                                                    </button>
                                                                </div>)
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>)
                                        : ''
                                    }

                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="">Add Files</label>
                                            </div>
                                        </div>
                                        {/* <div className="col">
                                            <button onClick={ this.addFile } type="button" className="transparent-btn float-right top-browse-btn">
                                                <img src={require("../../../assets/images/add-file-icon.png")} alt="" />Add File
                                            </button>
                                        </div> */}
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                {this.listFiles()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row marginB15">
                                        <div className="col-md-12 testAlignR">
                                            <button onClick={this.addFile} type="button" className="top-browse-btn transparent-btn paddingL0">
                                                <img src={require('../../../assets/images/add-file-icon.png')} alt="" />
                                                Add Another File
                                                </button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group usm-check marginT5">
                                                <input type="checkbox" id="restrictReport" onChange={ this.handleChanges } name="is_restricted" checked={ this.state.fields.is_restricted } />
                                                <label htmlFor="restrictReport">  Restrict Report to Admin View Only.</label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer form-modal-footer d-flex justify-content-between">
                                <button onClick={ this.closePopup } type="button" className={`default-btn btn-danger ${btnswidth}`}>Cancel</button>
                                { this.state.is_editing ? <button type="button" className="default-btn btn-danger min-width157" onClick={ this.toggleDeleteConfirmation }>Delete</button> : '' }
                                <button onClick={ this.handleSubmit } type="button" className={`default-btn btn-success ${btnswidth}`} disabled={submit_disabled}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>

                {!this.props.modal || <div className="modal-backdrop fade show"></div>}
                <DeletePlayerInfoConfirm 
                    modal={ this.state.openDeleteConfirmationPoppup }
                    onClose={ this.onCancelDelete }
                    onConfirm={ this.deleteReports }
                    type="report"
                />
            </div>
        );
    };

    clearFields = () => {
        this.setState({
            files       : ['file-0'],
            fileNames   : {
                'file-0': 'File Name'
            },
            is_editing      : false,
            report_id    : 0,
            attachments     : [],
            deleted_file_ids: [],
            fields      : {
                title           : '',
                description     : '',
                files           : {
                    'file-0' : {}
                },
                is_restricted   : false
            },
            isValid : {
                title           : true,
                description     : true,
                files           : {
                    'file-0' : true
                },
                is_restricted   : true
            },
            error: {
                title           : '',
                description     : '',
                files           : {
                    'file-0' : ''
                },
            },
            last_index : 0,
        }, () => {
            for( let f in this.files ) {
                if ( this.state.files.indexOf( f ) < 0 ) {
                    continue;
                }

                this.files[ f ].value = null;
                this.files = {};
            }
        });
    }
}
export default UpdateReport;