import React from 'react';
import { is_valid_db_date, get_authorization_token, get_db_date, get_display_format_date, get_current_user_role, __x } from '../../../helpers/GeneralHelper';
import APIRoutes from '../../../API/Routes';
import Select from 'react-select';
import Request from '../../../helpers/Request';
import DatePicker from "react-datepicker";
import UpdateNote from '../common/UpdateNote';
import Alert from '../../../helpers/Alert';
import { Link, withRouter } from 'react-router-dom';
import DeletePlayerInfoConfirm from '../players/DeletePlayerInfoConfirm';
import LoadingSpinner from '../common/LoadingSpinner';

class EditPlayerCase extends React.Component {

    constructor(props) {
        super(props);

        this._notes = React.createRef();
        this._case_id = props.match.params.case_id;

        this._is_fetched = {
            get_agent_options: false,
            get_professional_options: false,
            get_primary_position: false,
            get_league: false,
        };

        this._get_case = false;
        this._is_case_fetching = false;

        this.state = {

            fields: {
                master_requirement_id: '1',
                status: '1',
                player_name: '',
                player_id: '',
                agent_representation: '',
                agency_representation: '',
                agent_id: '',
                master_professional_level_id: '',
                master_position_id: '',
                additional_position_ids: null,
                agent_representation_enddate: null,
                current_club: '',
                master_league_id: '',
                detail: '',
                case_note: [],
                case_attachments: [],
                is_contract_signed: false,
            },
            agentOptions: [],
            professionalLevelOptions: [],
            primaryPositionOptions: [],
            additionalPositionOptions: [],
            leagueOptions: [],
            predictive_player_list: [],
            isProcessing: false,
            responseMsg: '',
            isError: false,
            isSuccess: false,
            isSubmitted: false,
            is_editing: false,
            edit_note: {},
            note_id: 0,
            case_number: '',
            fieldsValidityError: {
                master_position_id: '',
                additional_position_ids: ''

            },
            isValid: {
                master_position_id: true,
                additional_position_ids: true
            },
            isSearching: false,
            openDeleteConfirmationPoppup: false,
            lockscreen              : false,
            lockErrorMsg            : '',
        }
    }

    componentDidMount = () => {
        if (!this._is_fetched.get_agent_options) {
            this.getAgentsOptions();
        }

        if (!this._is_fetched.get_professional_options) {
            this.getProfessionalLevelOptions();
        }

        if (!this._is_fetched.get_primary_position) {
            this.getPrimaryPostionOptions();
        }

        if (!this._is_fetched.get_league) {
            this.getLeagueOptions();
        }
    }
    
    componentDidUpdate = () => {
        if ( this._is_fetched.get_agent_options && this._is_fetched.get_professional_options && this._is_fetched.get_primary_position && this._is_fetched.get_league && !this._get_case ) {
            this.getCases();
        }
    }

    /**
     * get data for agent list dropdown
     */
    getAgentsOptions = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        const request = new Request(APIRoutes.get_agent_list, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                this._is_fetched.get_agent_options = true;
                let agentOptions = this.state.agentOptions;

                agentOptions = response.data;
                this.setState({
                    isError: false,
                    responseMsg: '',
                    isSuccess: true,
                    agentOptions,
                });
            }
        }, error => {
            console.log(error);
        });
    }

    /**
     * get data for professional level dropdown
     */
    getProfessionalLevelOptions = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        const request = new Request(APIRoutes.player_professional_level, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                this._is_fetched.get_professional_options = true;
                let professionalLevelOptions = this.state.professionalLevelOptions;

                professionalLevelOptions = response.data;
                this.setState({
                    isError: false,
                    responseMsg: '',
                    isSuccess: true,
                    professionalLevelOptions,
                });
            }
        }, error => {
            console.log(error);
        });

    }

    /**
     * get data for league dropdown
     */
    getLeagueOptions = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        const request = new Request(APIRoutes.league_list, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                let leagueOptions = this.state.leagueOptions;
                let league_list = [];
                league_list = response.data.map(function (data, i) {
                    return { label: data.title, value: data.id };
                });
                leagueOptions = league_list;
                this._is_fetched.get_league = true;
                this.setState({
                    isError: false,
                    responseMsg: '',
                    isSuccess: true,
                    leagueOptions,
                });
            }
        }, error => {
            console.log(error);
        });
    }

    /**
     * get data for primary position
     */
    getPrimaryPostionOptions = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        const request = new Request(APIRoutes.player_positions, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                this._is_fetched.get_primary_position = true;
                let primaryPositionOptions = this.state.primaryPositionOptions;
                let additionalPositionOptions = this.state.additionalPositionOptions;

                primaryPositionOptions = response.data;
                additionalPositionOptions = response.data;
                this.setState({
                    isError: false,
                    responseMsg: '',
                    isSuccess: true,
                    primaryPositionOptions,
                    additionalPositionOptions,
                });
            }
        }, error => {
            console.log(error);
        });
    }

    /**
     * get case data based on case id
     */
    getCases = () => {
        if ( this._is_case_fetching ) {
            return false;
        }

        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }
    
        this._is_case_fetching = true;

        const route_path = APIRoutes.case + this._case_id;
        const request = new Request(route_path, {}, 'GET', headers).send();
        const fields = this.state.fields;
        request.then(response => {
            if (response.success) {
                let response_data = response.data[0];
                fields['agent_representation'] = response_data.agent_representation !== '' ? response_data.agent_representation : '';
                fields['agency_representation'] = response_data.agency_representation !== '' ? response_data.agency_representation : '';
                fields['agent_representation_enddate'] = (response_data.agent_representation_enddate !== null && is_valid_db_date(response_data.agent_representation_enddate)) ? new Date(response_data.agent_representation_enddate) : null;
                fields['current_club'] = response_data.current_club !== '' ? response_data.current_club : '';
                fields['detail'] = response_data.detail !== '' ? response_data.detail : '';
                fields['player_name'] = response_data.player !== '' ? response_data.player.first_name + " " + response_data.player.last_name : '';
                fields['player_id'] = response_data.player_id !== '' ? response_data.player_id : '';
                fields['case_note'] = response_data.case_notes && response_data.case_notes.length > 0 ? response_data.case_notes : [];
                fields['is_contract_signed'] = response_data.is_contract_signed;
                fields['status'] = !response_data.status;

                let agentOptions = this.state.agentOptions;
                let selected_agent_arr = [];
                for (let i in agentOptions) {
                    for (let j in response_data.case_agents) {
                        if (response_data.case_agents[j].user_id === agentOptions[i].value) {
                            selected_agent_arr.push(agentOptions[i]);
                        }
                    }
                    fields['agent_id'] = selected_agent_arr;
                }

                let additionalPositionOptions = this.state.additionalPositionOptions;
                let selected_additional_position_arr = [];
                for (let i in additionalPositionOptions) {
                    for (let j in response_data.case_addition_positions) {
                        if (response_data.case_addition_positions[j].master_position_id === additionalPositionOptions[i].value) {
                            selected_additional_position_arr.push(additionalPositionOptions[i]);
                        }
                    }
                    fields['additional_position_ids'] = selected_additional_position_arr;
                }

                let professionalLevelOptions = this.state.professionalLevelOptions;
                for (let i in professionalLevelOptions) {
                    if (professionalLevelOptions[i].value === response_data.master_professional_level_id) {
                        fields['master_professional_level_id'] = professionalLevelOptions[i];
                        break;
                    }
                }

                let leagueOptions = this.state.leagueOptions;
                for (let i in leagueOptions) {
                    if (leagueOptions[i].value === response_data.master_league_id) {
                        fields['master_league_id'] = leagueOptions[i];
                        break;
                    }
                }

                let primaryPositionOptions = this.state.primaryPositionOptions;
                for (let i in primaryPositionOptions) {
                    if (primaryPositionOptions[i].value === response_data.master_position_id) {
                        fields['master_position_id'] = primaryPositionOptions[i];
                    }
                }

                this.setState({
                    fields,
                    case_number: response.data[0].case_number,
                });

                this._get_case = true;
            }
        }, error => {
            console.log(error);
            this.setState({
                lockErrorMsg : error.message,
                isSuccess : false,
                lockscreen : error.lockscreen ? true : false,
                _fetched: {
                    case_data: error.lockscreen ? true : false,
                }
            })
        });
    }

    /**
    * Handles fields changes and check for errors
    */
    handleFieldsChanges = (event) => {
        const target = event.target,
            elt_name = target.name,
            elt_value = target.type === 'checkbox' ? target.checked : target.value,
            fields = this.state.fields;

        let predictive_player_list = this.state.predictive_player_list;

        if (['agent_representation', 'agency_representation', 'agent_id', 'master_professional_level_id', 'master_position_id', 'additional_position_ids', 'agent_representation_enddate', 'current_club', 'master_league_id', 'detail', 'is_contract_signed', 'status'].indexOf(elt_name) >= 0) {
            fields[elt_name] = elt_value;
        }
        this.setState({
            fields,
            predictive_player_list
        });

    }

    /**
     * Handles input field change on searching a case for assigning to player
     */
    handlePlayerNameChanges = event => {
        const target = event.target,
            value = target.value,
            fields = this.state.fields;

        if (value.length === 0 || value === '') {
            this.setState({
                predictive_player_list: []
            });
        }

        if (value.length < 3) {
            fields['player_name'] = value;
            fields['player_id'] = '';
            this.setState({ fields });
            return false;
        }

        fields['player_name'] = value;
        fields['player_id'] = '';

        this.setState({ fields });

        clearTimeout(this.countdown);
        this.countdown = setTimeout(this.searchPlayer, this.wait_for_search);
    }

    /**
    * Search after {`this.wait_for_search`} miliseconds case when typing is done and text is more than 3 characters.
    */
    searchPlayer = () => {
        const query = this.state.fields.player_name;
        if (query.length < 3) {
            return false;
        }

        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        this.setState({
            isSearching: true
        });
        const request = new Request(APIRoutes.get_player_list + query, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                const predictive_player_list = response.data;
                this.setState({
                    predictive_player_list,
                    isSearching: false
                });
            }
        }, error => {
            console.log(error);
        });
    }

    /**
     *  Handle changes of dropdown
     */
    handleChange = (value, field_name) => {
        let fields = this.state.fields,
            isValid = this.state.isValid,
            fieldsValidityError = this.state.fieldsValidityError;
        if (field_name === 'additional_position_ids') {
            fieldsValidityError[field_name] = '';
            isValid[field_name] = true;
            isValid['master_position_id'] = true;
            for (let option_value in value) {
                let option = value[option_value];
                isValid[field_name] = (fields.master_position_id !== null && option.value === fields.master_position_id.value) ? false : true;
                fieldsValidityError[field_name] = '';
                if (isValid[field_name] === false) {
                    fieldsValidityError[field_name] = __x( `The Primary Postion and Secondary Position have same value.` );
                    fields[field_name] = value;
                    this.setState({ fields, isValid, fieldsValidityError });
                    return false;
                }
            }
        }
        else if (field_name === 'master_position_id') {
            let additional_postions = fields.additional_position_ids;
            if (additional_postions) {
                for (let additional_position in additional_postions) {
                    let option = additional_postions[additional_position];
                    isValid[field_name] = (value.value === option.value) ? false : true;
                    fieldsValidityError['additional_position_ids'] = '';
                    isValid['additional_position_ids'] = true;
                    if (isValid[field_name] === false) {
                        fieldsValidityError['additional_position_ids'] = __x( `The Primary Postion and Secondary Position have same value.` );
                        fields[field_name] = value;
                        this.setState({ fields, isValid, fieldsValidityError });
                        return false;
                    }
                }
            }
        }
        fields[field_name] = value;
        this.setState({
            fields
        });
    }

    /**
     * Select player from predictive search dropdown
     */
    selectPlayer = (player_id, player_name) => {
        let fields = this.state.fields,
            predictive_player_list = this.state.predictive_player_list;
        fields['player_name'] = player_name;
        predictive_player_list = [];

        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        const request = new Request(APIRoutes.player + `/` + player_id, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                let response_data = response.data;
                fields['agent_representation'] = response_data.agent_representation;
                fields['agency_representation'] = response_data.agency_representation;
                fields['agent_representation_enddate'] = (response_data.agent_representation_enddate ? new Date(response_data.agent_representation_enddate) : null);
                fields['current_club'] = (response_data.current_club !== 0 && response_data.club ? response_data.club.club_name : response_data.other_club);
                fields['player_id'] = player_id;

                let agentOptions = this.state.agentOptions;
                let agent_arr = [];

                for (let i in agentOptions) {
                    if (agentOptions[i].value === response_data.usm_agent_id) {
                        agent_arr.push(agentOptions[i]);
                    }
                    fields['agent_id'] = agent_arr;
                }

                let professionalLevelOptions = this.state.professionalLevelOptions;

                for (let i in professionalLevelOptions) {
                    if (professionalLevelOptions[i].value === response_data.master_professional_level_id) {
                        fields['master_professional_level_id'] = professionalLevelOptions[i];
                    }
                }

                let primaryPositionOptions = this.state.primaryPositionOptions;

                for (let i in primaryPositionOptions) {
                    if (primaryPositionOptions[i].value === response_data.primary_position_id) {
                        fields['master_position_id'] = primaryPositionOptions[i];
                    }
                }

                let additionalPositionOptions = this.state.additionalPositionOptions;
                let additional_position_arr = [];

                for (let i in additionalPositionOptions) {
                    for (let j in response_data.player_additional_positions) {
                        if (response_data.player_additional_positions[j].master_position_id === additionalPositionOptions[i].value) {
                            additional_position_arr.push(additionalPositionOptions[i]);
                        }
                    }
                    fields['additional_position_ids'] = additional_position_arr;
                }

                let leagueOptions = this.state.leagueOptions;

                for (let i in leagueOptions) {
                    if (leagueOptions[i].value === response_data.master_league_id) {
                        fields['master_league_id'] = leagueOptions[i];
                    }
                }

                this.setState({
                    fields
                });
            }
        }, error => {
            console.log(error);
        });

        this.setState({
            fields,
            predictive_player_list
        });
    }

    /**
    * Save notes globally and list them.
    */
    saveNotesGlobally = (note_fields, deleted_ids = []) => {
        console.log(note_fields);

        const player_note_path = this.state.is_editing ? APIRoutes.case_note : APIRoutes.case_note + this._case_id,
            mothod = this.state.is_editing ? "PUT" : "POST";

        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        this.setState({
            isProcessing: true
        });

        let formData = new FormData(),
            new_state = {};

        if (this.state.is_editing) {
            formData.append('note_id', this.state.note_id);
            formData.append('deleted_file_id', JSON.stringify(deleted_ids));
        }

        formData.append('note', note_fields.note);
        for (let i in note_fields.files) {
            formData.append('files', note_fields.files[i]);
        }

        formData.append('is_restricted', note_fields.is_restricted);
        formData.append('master_requirement_id', this.state.fields.master_requirement_id);
        const request = new Request(player_note_path, formData, mothod, headers, true).send();
        request.then(response => {
            if (response.success) {
                this._notes.toggleNotesPopup(true);
                this.getAllNotes();
                new_state = {
                    isProcessing: false,
                    isSubmitted: true,
                    isSuccess: response.success,
                    responseMsg: response.message
                }
                this.setState(new_state);
            }
        }, error => {
            console.log(error);
            new_state = {
                isProcessing: false,
                isSubmitted: true,
                isSuccess: error.success,
                responseMsg: error.message
            }
            this.setState(new_state);
        });
    }

    getAllNotes = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        const fields = this.state.fields;
        const api_route_path = APIRoutes.case_all_note + this._case_id;
        const request = new Request(api_route_path, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                console.log(response);
                fields['case_note'] = response.data;
                this.setState({
                    ...this.state.fields,
                    fields
                })
            }
        }, error => {
            console.log(error);
        });

    }

    editNote = (event, note_id) => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        const api_route_path = APIRoutes.case_note + note_id;
        const request = new Request(api_route_path, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                console.log(response.data);
                this.setState({
                    edit_note: response.data[0],
                    is_editing: true,
                    note_id: note_id
                });
            }
        }, error => {
            console.log(error);
        });
        this._notes.toggleNotesPopup();
    }

    /**
     * Delete notes.
     */
    deleteCaseNotes = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        let new_state = {};
        const note_id = this.state.note_id;
        const note_path = APIRoutes.case_note + note_id;
        const request = new Request(note_path, {}, 'DELETE', headers).send();
        request.then(response => {
            if (response.success) {
                this.getAllNotes();
            }

            new_state = {
                isProcessing: false,
                isSubmitted: true,
                isSuccess: response.success,
                responseMsg: response.message
            }
            this.setState(new_state);
        }, error => {
            console.log(error);
        });
    }

    /**
     * Check if form is valid or not to submit
     */
    isFormValid = () => {
        let validityErr = [];
        const fields = this.state.fields,
             isValid = this.state.isValid;

        const optional = ['master_position_id', 'additional_position_ids'];
        const toExclude = ['agent_representation', 'agency_representation', 'agent_id', 'master_professional_level_id', 'agent_representation_enddate', 'current_club', 'master_league_id', 'detail', 'case_note', 'case_attachments', 'master_requirement_id', 'player_id'];

        for (let elt in fields) {
            /**
             * Exclude fields.
             */
            if (toExclude.indexOf(elt) >= 0) {
                continue;
            }
            if (optional.indexOf(elt) >= 0) {

                if (fields[elt] === '')
                    continue;

                if (!isValid[elt])
                    validityErr.push(elt);
            }

            if (fields[elt] === '') {
                validityErr.push(elt);
            }
        }
            return validityErr.length <= 0;
        }

    /**
     * Add player case.
     */
    updatePlayerCase = event => {
        event.preventDefault();
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        this.setState({
            isProcessing: true
        });

        const fields = this.state.fields,
            player_case_path = APIRoutes.case + this._case_id;


        let new_state = {},
            formData = new FormData();
        for (let i in fields) {

            /**
             * Get date in dd/mm/yyyy format.
             */
            if (i === 'agent_representation_enddate') {
                formData.append(i, get_db_date(fields[i]));
                continue;
            }

            /**
             * escape case_note and case_attachement to append in formdata
             */
            if (i === 'case_note' || i === 'case_attachments') {
                continue;
            }

            /**
             * Filtering comma separated ids from these fields.
             */
            if (i === 'master_position_id' || i === 'master_professional_level_id' || i === 'master_league_id') {
                if (fields[i] && fields[i].value) {
                    formData.append(i, fields[i].value);
                }
                continue;
            }

            if (i === 'additional_position_ids' || i === 'agent_id') {
                formData.append(i, fields[i] && fields[i].length > 0 ? fields[i].map(function (selected_additional_position) {
                    return selected_additional_position.value
                }) : '');
                continue;
            }

            if (i === 'status') {
                formData.append(i, fields[i] === true ? false : true);
                continue;
            }

            formData.append(i, fields[i]);
        }

        /**
         * Sending request to add/update clubs.
         */
        const request = new Request(player_case_path, formData, 'PUT', headers, true).send();
        request.then(response => {
            if (response.success) {
                this.props.history.push('/admin/cases/', {
                    message: response.message,
                    success: response.success
                });
            } else {
                new_state = {
                    isProcessing: false,
                    isSubmitted: true,
                    isSuccess: response.success,
                    responseMsg: response.message
                }

                this.setState(new_state);
            }
        }, error => {
            new_state = {
                isProcessing: false,
                isSubmitted: true,
                isSuccess: error.success,
                responseMsg: error.message
            };
            this.setState(new_state);
            this.getCases();
        });
    }

    resetAlert = () => {
        this.setState({
            responseMsg: '',
            isSubmitted: false
        });
    }

    goBack = () => {
        this.props.history.go(-1);
    }

    render() {
        const customStyles = {
            option: (styles, { isFocused }) => {
                return {
                    ...styles,
                    backgroundColor: isFocused ? 'rgba(0, 0, 0, 0.2)' : styles.backgroundColor,
                }
            }
        },
        today = new Date(),
        disableSubmit = !this.isFormValid() || this.state.isProcessing ? true : false,
        responseMsg = this.state.responseMsg !== '' && this.state.isSubmitted ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} floating="true" onClose={this.resetAlert} /> : '',
        lockErrorMsg   = this.state.lockErrorMsg ? <Alert message={this.state.lockErrorMsg} success={this.state.isSuccess} floating="true" goBack={this.goBack} isNotFlash /> : '';

        /* Predictive player list */
        const predictive_player_exists = this.state.predictive_player_list && this.state.predictive_player_list.length > 0;
        const predictive_player_list = predictive_player_exists ?
            this.state.predictive_player_list.map(player => {
                return <li key={player.id} onClick={this.selectPlayer.bind(this, player.id, player.name)}>Name: {player.name ? player.name : ''} Age: {player.age ? player.age : ''} Nationality: {player.master_country && player.master_country.name ? player.master_country.name : ''}</li>
            }) : '';

        /* Cases listing */
        let cases_list = this.state.fields.case_note && this.state.fields.case_note.length > 0 ? this.state.fields.case_note.map( note => {
            const attachments = note.case_note_files;
            const is_restricted = note.is_restricted && get_current_user_role() !== 'Admin';

            /**
             * If current note is restricted for current user.
             */
            if (is_restricted) {
                return (
                    <li key={note.id} className="media">
                        <div className="media-body clearfix">
                            <div className="nc-nb-heading marginB10">Note</div>
                            <div className="note-add-modify">
                                <div class="row">

                                    <div class="col">
                                        <div class="d-inline-block marginR10 align-top">
                                            <img src={require(`../../../assets/images/Restricted.png`)} alt="" />
                                        </div>
                                        <div class="d-inline-block">
                                            <p class="fontBold text-danger font-size14">{ __x( `Restricted` ) }
                                            <span class="fontBold text-white d-block marginT5">{ __x( `Please contact administrator.` ) }</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                );
            }

            /**
             * List for non restricted notes
             */
            return (
                <li key={note.id} className="media">
                    <div className="media-body clearfix">
                        <div className="nc-nb-heading">Note</div>
                        <p>{note.note}</p>
                        <div className="note-add-modify">
                            <div className="row marginB5">
                                <div className="col-md-2 width10 col-sm-12">Added by: </div>
                                <div className="col-md-10 col-sm-12 fontBold">{note.added_by}</div>
                            </div>
                            <div className="row marginB5">
                                <div className="col-md-2 width10 col-sm-12">Last Modified: </div>
                                <div className="col-md-10 col-sm-12 fontBold">{ note.updated_at && is_valid_db_date(note.updated_at) ? get_display_format_date(note.updated_at) : '' }</div>
                            </div>
                            <div className="row">
                                <div className="col-md-2 width10  col-sm-12">Attachments: </div>
                                <div className="col-md-10 col-sm-12">
                                    {
                                        attachments && attachments.length > 0 ? attachments.map(attachment => {
                                            return (<div key={attachment.id} className="text-success font-size14 word-break-all marginB10">
                                                <a href={attachment.filename} target="_blank" rel="noopener noreferrer">{attachment.original_filename}</a>
                                            </div>)
                                        }) : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <button onClick={event => this.editNote(event, note.id)} type="button" className="transparent-btn align-self-start mr-3">
                        <i className="material-icons text-opt-four font-size20">edit</i>
                    </button>
                </li>
            );
        }) : '';

        const loading = this.state.isProcessing ? <LoadingSpinner /> : '';
        const show_loader = this.state.isSearching ? <img className="search-loader" src={require(`../../../assets/images/loader-usm.svg`)} alt="" /> : '';

        return (
            <div id="mainContainer">
                {responseMsg}
                {loading}
                {lockErrorMsg}
                <div className="heading-section height84 d-flex border-bottom-trans align-items-center">
                    <h2>Edit Case</h2>
                </div>

                <div className="content-section">
                    <form action="" onSubmit={this.updatePlayerCase} className="usm-form form-border form-modal">
                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Case Number</label>
                                    <h2 className="d-block text-white">{this.state.case_number}</h2>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Case Type</label>
                                    <input type="text" onChange={this.handleFieldsChanges} value="Player Recruitment" className="form-control" id="" placeholder="" name="master_requirement_id" disabled={true} />
                                </div>
                            </div>
                        </div>
                        <div className="border-bottom-trans marginTB35"></div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Edit Player</label>
                                    <input type="text" onChange={this.handlePlayerNameChanges} value={this.state.fields.player_name} className="form-control" id="" placeholder="" name="player_name" autoComplete="off" />
                                    {
                                        !predictive_player_exists ? '' :
                                            <ul className="pre-search">{predictive_player_list}</ul>
                                    }
                                    {show_loader}
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Current Representation Agent</label>
                                    <input type="text" onChange={this.handleFieldsChanges} value={this.state.fields.agent_representation} className="form-control" id="" placeholder="" name="agent_representation" />
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Current Representation Agency</label>
                                    <input type="text" onChange={this.handleFieldsChanges} value={this.state.fields.agency_representation} className="form-control" id="" placeholder="" name="agency_representation" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Unique SM Agent</label>
                                    <Select name="unique_sm_agent"
                                        value={this.state.fields.agent_id}
                                        onChange={(e) => this.handleChange(e, 'agent_id')}
                                        options={this.state.agentOptions}
                                        styles={customStyles}
                                        placeholder={``}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Representation End Date</label>
                                    <DatePicker
                                        className="form-control"
                                        selected={this.state.fields.agent_representation_enddate}
                                        onChange={(e) => this.handleChange(e, 'agent_representation_enddate')}
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date(today.getFullYear(), today.getMonth(), today.getDate())}
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={30}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Professional Level</label>
                                    <Select nameName="professional_level"
                                        value={this.state.fields.master_professional_level_id}
                                        onChange={(e) => this.handleChange(e, 'master_professional_level_id')}
                                        options={this.state.professionalLevelOptions}
                                        styles={customStyles}
                                        placeholder={``}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Current Club</label>
                                    <input type="text" onChange={this.handleFieldsChanges} value={this.state.fields.current_club} className="form-control" id="" placeholder="" name="current_club" />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">League</label>
                                    <Select name="master_league_id"
                                        value={this.state.fields.master_league_id}
                                        onChange={(e) => this.handleChange(e, 'master_league_id')}
                                        options={this.state.leagueOptions}
                                        styles={customStyles}
                                        placeholder={``}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom-trans marginTB35"></div>

                        <div className="row">

                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Primary Position</label>
                                    <Select name="primary_position"
                                        value={this.state.fields.master_position_id}
                                        onChange={(e) => this.handleChange(e, 'master_position_id')}
                                        options={this.state.primaryPositionOptions}
                                        styles={customStyles}
                                        placeholder={``}
                                    />
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-12">
                                <div className={this.state.fieldsValidityError.additional_position_ids === '' ? `form-group` : `form-group form-error`}>
                                    <label htmlFor="">Additional Position</label>
                                    <Select name="additional_position"
                                        value={this.state.fields.additional_position_ids}
                                        onChange={(e) => this.handleChange(e, 'additional_position_ids')}
                                        options={this.state.additionalPositionOptions}
                                        isMulti={true}
                                        styles={customStyles}
                                        placeholder={``}
                                    />
                                    {this.state.fieldsValidityError.additional_position_ids === '' || <div className="display-error">{this.state.fieldsValidityError.additional_position_ids}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="border-bottom-trans marginTB34"></div>

                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="">Detail</label>
                                    <textarea name="detail" onChange={this.handleFieldsChanges} value={this.state.fields.detail} id="" className="form-control textarea76"></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group marginT20">
                                    <div className="player-detail-block">
                                        <div className="player-detail-heading d-flex align-items-center">
                                            Notes
                                        <button onClick={this._notes.toggleNotesPopup} type="button" className="transparent-btn float-right ml-auto">
                                                <img src={require('../../../assets/images/add-icon.png')} alt="" />
                                            </button>
                                        </div>
                                        <div className="player-detail-content">
                                            <div className="notes-block">
                                                <ul className="list-unstyled">
                                                    {cases_list}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom-trans marginTB34"></div>

                        <div className="heading-section d-flex align-items-center auto-height">Closed Case</div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <div className="form-group usm-check marginT30">
                                        <input onChange={this.handleFieldsChanges} value={this.state.fields.status} checked={this.state.fields.status} name="status" type="checkbox" id="status" />
                                        <label htmlFor="status">Close Case</label>
                                    </div>

                                    <div className="form-group usm-check marginT30">
                                        <input onChange={this.handleFieldsChanges} value={this.state.fields.is_contract_signed} checked={this.state.fields.is_contract_signed} name="is_contract_signed" type="checkbox" id="is_contract_signed" />
                                        <label htmlFor="is_contract_signed">Contract Signed?</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom-trans marginTB34"></div>
                        <div className="row">
                            <div className="col d-flex justify-content-between">
                                <Link to="/admin/cases" className="default-btn btn-danger width-auto" data-dismiss="modal">Cancel</Link>
                                <button type="button" className="default-btn btn-danger min-width157" onClick={this.toggleDeleteConfirmation}>Delete</button>
                                <button type="submit" disabled={disableSubmit} onClick={this.updatePlayerCase} className="default-btn btn-success width-auto">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
                <UpdateNote
                    ref={elt => this._notes = elt}
                    onSubmit={this.saveNotesGlobally}
                    note={this.state.edit_note}
                    is_editing={this.state.is_editing}
                    files="case_note_files"
                    onDelete={this.deleteCaseNotes}
                />
                <DeletePlayerInfoConfirm
                    modal={this.state.openDeleteConfirmationPoppup}
                    onClose={this.onCancelDelete}
                    onConfirm={this.deleteCase}
                    type="case"
                />

            { !this.state.lockscreen || <div className="modal-backdrop fade show"></div> }
            </div>
        );

    }

    /**
     * Open confirmation poppup.
     */
    toggleDeleteConfirmation = () => {
        this.setState({
            openDeleteConfirmationPoppup: !this.state.openDeleteConfirmationPoppup
        });
    }

    /**
     * on cancel delete confirmation
     */
    onCancelDelete = () => {
        this.toggleDeleteConfirmation();
    }

    /**
     * Delete case.
     */
    deleteCase = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        let new_state = {};
        const case_path = APIRoutes.case + this._case_id;
        const request = new Request(case_path, {}, 'DELETE', headers).send();
        request.then(response => {
            if (response.success) {
                this.toggleDeleteConfirmation();
                this.props.history.push('/admin/cases/', {
                    message: response.message,
                    success: response.success
                });
            } else {
                new_state = {
                    isProcessing: false,
                    isSubmitted: true,
                    isSuccess: response.success,
                    responseMsg: response.message
                }

                this.setState(new_state);
            }
        }, error => {
            new_state = {
                isProcessing: false,
                isSubmitted: true,
                isSuccess: error.success,
                responseMsg: error.message
            };
            this.setState(new_state);
        });
    }
}

export default withRouter((EditPlayerCase));