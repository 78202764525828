
export const tokenValidate = ( validity ) => dispatch => {
    console.log( validity );
    dispatch({
        type: 'VALIDATE_TOKEN',
        payload: {
            validity: validity
        }
    });
}
