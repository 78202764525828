const initialState = {
    users   : [],
    user    : {}
}

const users = [];
export default ( state = initialState, action ) => {
	switch ( action.type ) {
        case 'SET_USERS':
            return {
                ...state,
                users: action.payload.users
            };
        case 'APPEND_USERS':
            return {
                ...state,
                users: action.payload.users
            };
        case 'SET_LOGIN_HISTORY_LIST':
            return {
                ...state,
                login_history: action.payload.login_history
            };
        case 'APPEND_LOGIN_HISTORY_LIST':
            return {
                ...state,
                login_history: action.payload.login_history
            };
        case 'FILTER_USER':
            Object.assign( users, state.users );
            return {
                ...state,
                users: users.filter( user => {
                    return user.users_role === action.payload.role;
                })
            };
        case 'SET_CURRENT_USER':
            return {
                ...state,
                user: action.payload.user
            };
		default:
			return state;
	}
}