import React, { Component } from 'react';
import * as EmailValidator from 'email-validator';
import Request from '../../helpers/Request';
import Alert from '../../helpers/Alert';
import { Link } from 'react-router-dom';
import APIRoutes from '../../API/Routes';
import { __x } from '../../helpers/GeneralHelper';

export default class ForgotPassword extends Component {

    _page_title = "Forgot Password - USM";

    constructor( props ) {
        super( props );

        this.state = {
            email           : '',
            isEmailValid    : true,
            responseMsg     : '',
            isSuccess       : false,
            isSubmitted     : false
        };

        this.handleSubmit = this.handleSubmit.bind( this );
        this.handleChange = this.handleChange.bind( this );
        this.resetAlert   = this.resetAlert.bind( this );
    }

    handleSubmit( event ) {
        event.preventDefault();

        if ( !this.state.isEmailValid ) {
            return;
        }
        
        const forgotPassUrl = APIRoutes.forgot_password;
        const fields        = {
            email: this.state.email
        };

        const response      = new Request( forgotPassUrl, fields, "POST" ).send();
        response.then( response => {
            let new_state = {
                isSubmitted : true,
                responseMsg : response.message,
                isSuccess   : response.success,
            };
            
            this.setState({
                ...new_state,
                email           : '',
                isEmailUntouched: true
            });
        }, error => {
            this.setState({
                isSubmitted     : true,
                responseMsg     : error.message,
                isSuccess       : error.success,
            });
        });
    }


    handleChange( event ) {
        const target    = event.target,
        value           = target.value;

        let email       = this.state.email,
        isEmailValid    = this.state.isEmailValid;

        email           = value;
        isEmailValid    = email === '' ? true : EmailValidator.validate( email );
        
        this.setState({
            email,
            isEmailValid
        });
    }

    resetAlert = () => {
        this.setState({
            responseMsg: ''
        });
    }

    render() {
        if ( this.state.isSubmitted && this.state.isSuccess ) {
            return (
                <div className="modal-body usm-modal-body Login minHeight-auto">
                    <h2 className="marginB10">Forgotten Password</h2>
                    <div className="width-container">
                        <p className="text-center">{ __x( 'An email with an opportunity to reset your password has been sent to your email address.' ) }</p>
                        <Link to="/login" className="btn btn-success btn-block marginLR0">Login Now</Link>
                    </div>
                </div>
            );
        }

        const email_classes     = ( this.state.isEmailValid ) ? '' : 'form-error';
        const submitDisabled    = !( this.state.isEmailValid && this.state.email !== '' );
        const errorMsg          = ( this.state.isSubmitted && !this.state.isSuccess ) ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} onClose={this.resetAlert} /> : '';
        return (
            <div className="modal-body usm-modal-body">
                { errorMsg }

                <h2 className="marginB10">Forgotten Password</h2>
                <div className="width-container">
                    <div className="static-alert text-align-center marginB30">
                        {__x( 'Please enter the email address you used when registering. You will receive an email with an opportunity to reset your password.' )}
                    </div>
                    <form action="" className="usm-form" onSubmit={ event => this.handleSubmit( event ) }>
                        <div className={`form-group ${email_classes}`}>
                            <input 
                                type="email" className="form-control" id="email" placeholder="Email." 
                                value={ this.state.email } onChange={ event => this.handleChange( event )}
                            />
                            { email_classes === '' ||
                                <div className="display-error">Please enter a vaild email.</div>                                
                            }
                        </div>
                        <button type="submit" className="btn btn-success btn-block marginLR0" disabled={submitDisabled}>Send</button>
                    </form>
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        document.title = this._page_title;
    }
}
