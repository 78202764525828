import React from 'react';
import PlayerGrid from './PlayerGrid';

const ResultSections = ({ section, players, showingAll, ...props }) => {
    const showAllNumber = 8;

    return (
        <div className="search-usm-section">
            <div className="heading-section d-flex align-items-center auto-height marginT25">
                {section} <span className="badge badge-pill badge-success">{ players.length }</span>
            </div>
            <div className="row usm-cards">
                {
                    players && players.length ? players.map( ( player, idx ) => {
                        if ( !showingAll && idx > ( showAllNumber - 1 ) ) {
                            return '';
                        }

                        return <PlayerGrid 
                            key={player.id}
                            player={player}
                        />;
                    }) : ''
                }
            </div>
            {
                players.length <= showAllNumber ? '' :
                <div className="row">
                    <div className="col">
                        <button onClick={ e => props.showAll( e, section ) } type="button" className="transparent-btn ml-auto button-color float-right">{ !showingAll ? `View All` : <img src={require('../../../assets/images/arrow-up.png')} alt="" /> }</button>
                    </div>
                </div>
            }
        </div>
    );
};
export default ResultSections;