import React, { Component } from 'react';
import { get_authorization_token, __x, is_valid_db_date, get_display_timestamp_from_date } from '../../../helpers/GeneralHelper';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import { addChangeLogs } from '../../../actions/SettingActions';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import Alert from '../../../helpers/Alert';
import ChangeLogDetails from './ChangeLogDetails';

export class ChangeLogs extends Component {

    _page_title = 'Change Log - USM';

    constructor( props ) {
        super( props );

        this._is_fetched = {
            change_logs : false
        }

        this.state = {
            changelog       : {},
            currentPage     : 0,
            maxPages        : 1,
            hasMoreItems    : false,
            showDetailsPopup: false,
            isSuccess       : false,
            responseMsg     : '',
            context         : '',
        }
    }

    /**
     * Get list of all changelogs.
     */
    getChangeLogs = ( update = false ) => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const maxPages  = this.state.maxPages,
        currentPage     = this.state.currentPage,        
        nextPage        = currentPage + 1;

        
		if( maxPages < nextPage ) {
            this.setState({
                hasMoreItems : false,
			});
			return false;
        }
        
        const path   = APIRoutes.change_logs + 'page/' + nextPage;

        const request = new Request( path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                /**
                 * Set change logs list in redux store
                 */
                this.props.addChangeLogs( response.data, this.props.settings.changelogs, update );
                this.setState({
                    hasMoreItems    : response.pages && response.pages > 1 ? true : false,
                    maxPages        : response.pages ? response.pages : 1,
                    currentPage     : this.state.currentPage + 1,
                });
    
                this._is_fetched.change_logs = true;
            }            
        }, error => {
            console.log( error );
        });
    }

    /**
     * Get single log by id.
     */
    getLogById = (log_id, context) => {
        if ( !log_id ) {
            return false;
        }

        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }
        
        const path   = APIRoutes.change_logs + log_id;
        const request = new Request( path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                this.setState({
                    changelog       : response.data,
                    showDetailsPopup: true,
                    context : context ? context.toLowerCase() : ''
                }, () => {
                    if ( this.state.showDetailsPopup ) {
                        document.body.classList.add( 'modal-open' );
                    } else {
                        document.body.classList.remove( 'modal-open' );
                    }
                });
            }
        }, error => {
            console.log( error );
        });
    }

    /**
     * Close details popup
     */
    closeDetailsPopup = () => {
        this.setState({
            showDetailsPopup : !this.state.showDetailsPopup
        }, () => {
            if ( this.state.showDetailsPopup ) {
                document.body.classList.add( 'modal-open' );
            } else {
                document.body.classList.remove( 'modal-open' );
            }
        });
    }

    componentDidMount = () => {
        document.title = this._page_title;
        
        if ( !this._is_fetched.change_logs ) {
            this.getChangeLogs( true );
        }
    }

    render() {
        const changelogs    = this.props.settings.changelogs,
            alert           = this.state.responseMsg !== '' ? <Alert message={ this.state.responseMsg } success={ this.state.isSuccess } floating="true" onClose={ this.resetAlert } /> : '';
        return (
            <div id="mainContainer">
                { alert }
                <div className="heading-section d-flex align-items-center">
                    <h2>Change Log</h2>
                </div>

                <div className="content-section">
                    <div className="usm-table table-responsive">
                        <InfiniteScroll
                            dataLength={ changelogs && changelogs.length > 0 ? changelogs.length : 0 } //This is important field to render the next data
                            next={ this.getChangeLogs }
                            hasMore={ this.state.hasMoreItems }
                        >
                            <table className="table table-hover">
                                <thead className="usm-thead">
                                    <tr>
                                        <th scope="col">Change Log Number</th>
                                        <th scope="col">User Name</th>
                                        <th scope="col">User Type</th>
                                        <th scope="col">Event</th>
                                        <th scope="col">Context</th>
                                        <th scope="col">Sub-Context</th>
                                        <th scope="col">Timestamp</th>
                                        <th scope="col">View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        changelogs && changelogs.length ? changelogs.map( changelog => (
                                            <tr key={ changelog.id }>
                                                <td>{ changelog.log_number }</td>
                                                <td>{ changelog.user ? changelog.user.first_name + ' ' + changelog.user.last_name : '' }</td>
                                                <td>{ changelog.user && changelog.user.users_role ? changelog.user.users_role : '' }</td>
                                                <td>{ changelog.event }</td>
                                                <td>{ changelog.context }</td>
                                                <td>{ changelog.sub_context }</td>
                                                <td>{ changelog.created_at && is_valid_db_date( changelog.created_at ) ? get_display_timestamp_from_date( changelog.created_at ) : '' }</td>
                                                <td>
                                                    <button onClick={ () => this.getLogById( changelog.id, changelog.context ) } className="transparent-btn">
                                                        <i className="material-icons text-offWhite font-size20">remove_red_eye</i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )) : (<tr>
                                            <td>{ this._is_fetched.change_logs ? __x( 'No record found.' ) : '' }</td>
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        </InfiniteScroll>
                    </div>
                </div>
                <ChangeLogDetails 
                    showPopup={ this.state.showDetailsPopup }
                    closePopup={ this.closeDetailsPopup }
                    logDetails={ this.state.changelog }
                    context={ this.state.context }
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    addChangeLogs: ( changelogs, old_logs = [], update = false ) => dispatch( addChangeLogs( changelogs, old_logs, update ) )
});

export default connect( mapStateToProps, mapDispatchToProps )( ChangeLogs );
