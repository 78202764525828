import React from 'react';
import { withRouter } from 'react-router-dom';
import { get_display_format_date, is_valid_db_date } from '../../helpers/GeneralHelper';

class RightDrawer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            view_all_representation_date: false,
            view_all_birthday: false,
        }
    }

    displayRepresentationDate = () => {
        const representation_dates = this.props.representation_date;
        let representationDateArr = [];

        representationDateArr = Object.keys(representation_dates).map((date, idx) => {
            if (!this.state.view_all_representation_date && idx > 4) {
                return null;
            }

            const players = representation_dates[date];
            return (
                <div key={date} className="dw-note-detail dw-note-detail-hover position-relative">
                    <h5 className="fontNormal">{date && is_valid_db_date(date) ? get_display_format_date(date) : ''}</h5>
                    {
                        players && players.length ? players.map((player, index) => {
                            return (
                                <p className="player-representation-date" key={index}>
                                    {player.name ? player.name : ''}
                                    {player.club_name ? ` (${player.club_name})` : ''}
                                </p>
                            )
                        }) : ''
                    }
                </div>
            )
        });

        return representationDateArr;
    }

    /**
     * display upcoming bday data
     */
    displayUpcomingBday = () => {
        const birthday = this.props.birthday;
        let birthdayArr = [];

        birthdayArr = Object.keys(birthday).map((date, idx) => {
            if (!this.state.view_all_birthday && idx > 4) {
                return null;
            }

            const contacts = birthday[date];
            return (
                <div key={date} className="dw-note-detail dw-note-detail-hover position-relative">
                    <h5 className="fontNormal">{date && is_valid_db_date(date) ? get_display_format_date(date) : ''}</h5>
                    {
                        contacts && contacts.length ? contacts.map((contact, index) => {
                            const image_name = contact.type === 'Player' ? 'add-player-white.png' : contact.type === 'Player Contact' ? 'dashboard-player3.png' : 'agent-icon-grey.png';
                            return (

                                <div className="d-flex align-items-center adminContactBlock" key={index}>
                                    <span className="table-playerImg"><img src={require(`../../assets/images/${image_name}`)} alt="" /></span>
                                    <div className="text">
                                        <p>
                                            {contact.title ? contact.title : ''}
                                        </p>
                                        <p className="fontSize11">
                                            {contact.subtitle ? contact.subtitle : ''}
                                        </p>
                                    </div>

                                </div>
                            )
                        }) : ''
                    }
                </div>
            )
        });

        return birthdayArr;
    }

    viewAllBirthday = () => {
        this.setState({
            view_all_birthday: !this.state.view_all_birthday,
        })
    }

    viewAllRepresentationDate = () => {
        this.setState({
            view_all_representation_date: !this.state.view_all_representation_date,
        });

    }

    render() {
        return (
            <div id="drawerOpen" className="d-flex align-items-stretch">
                <div className="drawerInner">
                    <div className="dw-user-block border-top-0 border-bottom-0 auto-height"></div>
                    <div className="dw-user-detail border-bottom-0 paddingT0">
                        <div className="dw-user-contact-detail border-top-0 marginT0 paddingT0">
                            <div className="dw-user-heading marginB20 position-relative">
                                Upcoming Birthdays
                            </div>
                        </div>
                        {this.displayUpcomingBday()}
                    </div>
                    {
                        this.props.birthday && Object.keys(this.props.birthday).length > 5 ?
                            <div className="dw-user-action text-left">
                                <button onClick={this.viewAllBirthday} type="button" className="transparent-btn ml-auto button-color">{!this.state.view_all_birthday ? `View All` : <img src={require('../../assets/images/arrow-up.png')} alt="" />}</button>
                            </div>
                            :
                            ''
                    }

                    <div className="dw-user-detail border-bottom-0">
                        <div className="dw-user-contact-detail border-top-0 marginT0">
                            <div className="dw-user-heading marginB20 position-relative">Representation End Dates

                            </div>
                        </div>
                        {this.displayRepresentationDate()}
                    </div>
                    {
                        this.props.representation_date && Object.keys(this.props.representation_date).length > 5 ?
                            <div className="dw-user-action text-left">
                                <button onClick={this.viewAllRepresentationDate} type="button" className="transparent-btn ml-auto button-color">{!this.state.view_all_representation_date ? `View All` : <img src={require('../../assets/images/arrow-up.png')} alt="" />}</button>
                            </div>
                            :
                            ''
                    }

                </div>
            </div>
        );
    }
}

export default withRouter(RightDrawer);