const initialState = {
    search_result               : {},
    primaryPositionOptions      : [],
    additionalPositionOptions   : [],
    nationalityOptions          : [],
    passportOptions             : [],
    opportunityTypeOptions      : [],
    professionalLevelOptions    : [],
    leagueOptions               : []
}

export default ( state = initialState, action ) => {
	switch ( action.type ) {
        case 'SET_SEARCH_RESULT':
            return {
                ...state,
                search_result: action.payload.result
            };
        case 'SET_FILTER_OPTIONS':
            return {
                ...state,
                [ action.payload.option ]: action.payload.data
            };
		default:
			return state;
	}
}