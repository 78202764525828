import React from 'react';
import { get_img_name_from_url, get_authorization_token, __x } from '../../helpers/GeneralHelper';
import APIRoutes from '../../API/Routes';
import Request from '../../helpers/Request';
import Alert from '../../helpers/Alert';
// import { withRouter } from 'react-router-dom';

 export default class UploadPics extends React.Component {
    constructor( props ) {
        super( props );

        this._file = React.createRef();

        this.state = {
            modal: false,
            lockscreen: false,
            lockErrorMsg: '',
            isSuccess: false,
        }
    }

    handlePopup = () => {
        if( this.props.identifier && this.props.identifier === 'club' ) {
            const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }
        
        const club_id = this.props.club_id ? this.props.club_id : 0;
        const check_lock_path = APIRoutes.club_badge + club_id ;
        
        /**
         * Sending request to add/update clubs.
         */
        const request = new Request( check_lock_path, {}, 'GET', headers).send();
        request.then( response => {
        }, error => {
            this.setState({
                isSuccess : false,
                lockErrorMsg : error.message,
                lockscreen : error.lockscreen ? error.lockscreen : false,
            })
        });
        }
        this.setState({
            modal: !this.state.modal
        }, () => {
            if ( this.state.modal ) {
                document.body.classList.add( 'modal-open' );
            } else {
                document.body.classList.remove( 'modal-open' );
            }
        });
    }
    
    closePopup = () => {
        this._file.value = null;
        this.setState({
            modal: !this.state.modal
        }, () => {
            if ( this.state.modal ) {
                document.body.classList.add( 'modal-open' );
            } else {
                document.body.classList.remove( 'modal-open' );
            }
        });
        this.props.onClose();
    }

    render = () => {
        const modal     = this.state.modal ? 'show' : '',
        uploadDisabled  = this.props.required && !( this.props.badge && this.props.badge.size > 0 ) ? true : false,
        lockErrorMsg    = this.state.lockErrorMsg ? <Alert message={this.state.lockErrorMsg} success={this.state.isSuccess}  floating="true" goBack={this.props.goBack} isNotFlash /> : '',
        lockscreen      = this.state.lockscreen ? 'modal-backdrop fade show' : 'modal form-modal fade modal-active';
        return (
            <div>
                {lockErrorMsg}
                <button onClick={ this.handlePopup } to="" className="user-img-select transparent-btn">
                    <img src={ require( '../../assets/images/browse-image.png' ) } alt="" />
                </button>
                <div className={`${modal} ${lockscreen}`} onClick={ this.closePopup } style={{ transform: this.state.modal ? `translate(0, 0)` : 'translate(-125%, -125%)' }} id="uploadModal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered maxWidth600" role="document" onClick={e => e.stopPropagation()}>
                        <div className="modal-content">
                            <div className="modal-header form-modal-header d-flex align-items-center">
                                <img src={ require('../../assets/images/form-usm-logo.png')} alt="" />
                                <h5 className="form-modal-title marginL0 font-size24 text-transform-normal">{ this.props.title ? this.props.title : `Upload Team Badge` }</h5>
                                <button className="modal-close transparent-btn" data-dismiss="modal" aria-label="Close" onClick={this.closePopup}>
                                    <img src={ require( '../../assets/images/close-modal.png' ) } alt="" />
                                </button>
                            </div>
                            <form onSubmit={ this.props.onSave } action="" className="usm-form form-border">
                                <div className="modal-body form-modal-body">
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group w-100">
                                                <div className="upload-image ml-auto">
                                                    <img src={ this.props.imgPreview } alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-photo-section">
                                        <div className="form-group w-100 upload-pics-comp">
                                            <div className="custom-file">
                                                <input ref={ elt => this._file = elt } onChange={ event => this.props.onChange( event, event.target.files[0] ) } type="file" title={this.props.fileName} className="custom-file-input" name="file" />
                                                <label className="custom-file-label" htmlFor="customFile">{ get_img_name_from_url( this.props.fileName ) }</label>
                                            </div>
                                            { !this.props.error || this.props.error === '' ? '' : <div className="display-error">{ this.props.error }</div> }
                                        </div>   
                                    </div>
                                </div>
                                <div className="modal-footer form-modal-footer d-flex justify-content-between">
                                    <button type="button" className="default-btn btn-danger" data-dismiss="modal" onClick={ this.closePopup }>{ this.props.cacnelText ? this.props.cacnelText : `Cancel` }</button>
                                    <button onClick={ this.props.onSave } type="submit" className="default-btn btn-success" disabled={uploadDisabled}>Upload</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                { !this.state.modal || <div className="modal-backdrop fade show"></div> }
            </div>
        );
    }
}

// export default withRouter ( UploadPics );