import React from 'react';
import { __x } from '../../../helpers/GeneralHelper';

const User = ({ users, is_fetched, ...rest }) => {
    let usersList = [];
        
    if ( !users || !users.length || users === 'undefined' ) {
        if ( is_fetched ) {
            usersList.push(
                <tr className="text-center" key={1}>
                    <td colSpan="8">{ __x( `No record found.` ) }</td>
                </tr>
            );
        }
        return usersList;
    }

    for ( let i in users ) {
        let id      = users[i].id;
        let status  = users[i].status;
        const status_classes = status ? 'btn-success' : 'btn-danger';
        usersList.push(
            <tr key={id}>
                <td className="fontBold">{ users[i].emp_id }</td>
                <td>{ users[i].first_name }</td>
                <td>{ users[i].last_name }</td>
                <td>{users[i].email}</td>
                <td>+{ users[i].country_code }{ users[i].phone }</td>
                <td className="fontBold">{ users[i].users_role }</td>
                <td><button className={`table-btn ${status_classes}`}>{ status ? 'Active' : 'Deactivated' }</button></td>
                <td><button className="transparent-btn" onClick={ ( e ) => rest.handleAddUserModal( false, id ) }><i className="material-icons text-offWhite font-size20">edit</i></button></td>
            </tr>
        );
    }

    return usersList;
}
export default User;