import React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import { __x } from '../../../helpers/GeneralHelper';

const AddOrganisation = ( props ) => {

    const modalActiveClass         = props.modal ? 'show' : '',
    organization_name_err_classes  = props.isValid.organization_name ? '' : 'form-error',
    web_address_err_classes        = props.isValid.web_address ? '' : 'form-error',
    organization_type_err_classes  = props.isValid.organization_type ? '' : 'form-error',
    email_err_classes              = props.isValid.email ? '' : 'form-error',
    phone_err_classes              = props.isValid.phone ? '' : 'form-error',
    disableSubmit                  = !props.isFormValid() || props.isProcessing ? true : false;

    return(
        <div>
            <div className={`modal form-modal fade modal-active ${modalActiveClass}`} style={{ transform: props.modal ? `translate(0, 0)` : 'translate(-125%, -125%)' }}>
                <div className="modal-dialog modal-dialog-centered maxWidth600" role="document">
                    <div className="modal-content">
                    <form action="" className="usm-form form-border"  onSubmit={ e => {e.preventDefault();} }>
                        <div className="modal-header form-modal-header d-flex align-items-center">
                            <img src={require("../../../assets/images/form-usm-logo.png")} alt="" />
                            <h5 className="form-modal-title">Add Organisation</h5>
                            <button type="button" className="modal-close" aria-label="Close" onClick={ props.onPopupClose }>
                                    <img src={ require( '../../../assets/images/close-modal.png' ) } alt="Close" />
                                </button>

                        </div>
                        <div className="modal-body form-modal-body">

                            
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className={`form-group ${organization_name_err_classes}`}>
                                            <label htmlFor="">Organisation Name *</label>
                                            <input value={ props.fields.organization_name } onChange={ props.handleFieldsChanges } className="form-control" name="organization_name" type="text" placeholder="" autoComplete="off" />
                                            { props.fieldsValidityError.organization_name !== '' ? <div className="display-error text-white">{ props.fieldsValidityError.organization_name }</div> : '' }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className={`form-group ${web_address_err_classes}`}>
                                            <label htmlFor="">Web Address *</label>
                                            <input value={ props.fields.web_address } type="text" onChange={ props.handleFieldsChanges } className="form-control" name="web_address" id="" placeholder="" autoComplete="off" />
                                            { props.fieldsValidityError.web_address !== '' ? <div className="display-error text-white">{ props.fieldsValidityError.web_address }</div> : '' }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className={`form-group ${email_err_classes}`}>
                                            <label htmlFor="">Email *</label>
                                            <input type="email" value={ props.fields.email } className="form-control" name="email" onChange={ props.handleFieldsChanges } id="" placeholder="" autoComplete="off" />
                                            { props.fieldsValidityError.email !== '' ? <div className="display-error text-white">{ props.fieldsValidityError.email }</div> : '' }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                <div className="col-md-6 col-xs-12">                                            
                                        <div className={`form-group ${phone_err_classes}`}>
                                            <label htmlFor="phone">Phone *</label>
                                            <div className={`input-group ${phone_err_classes}`}>
                                                <IntlTelInput 
                                                    ref={ props.set_country }
                                                    onPhoneNumberChange={ props.handlePhoneChange }
                                                    defaultCountry={ 'gb' }
                                                    css={['intl-tel-input w-100', 'form-control']} 
                                                    placeholder="Phone"
                                                    value={props.fields.phone}
                                                    separateDialCode="true"
                                                    onSelectFlag={ props.countryCodeChange }
                                                />
                                            </div>
                                            { phone_err_classes === '' || <div className="display-error">{ __x( `Phone is invalid.` ) }</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className={`form-group ${organization_type_err_classes}`}>
                                            <label htmlFor="">Entity Type *</label>
                                            <select value={ props.fields.organization_type } name="organization_type" onChange={ props.handleFieldsChanges } id="" className="form-control">
                                                <option value=""></option>
                                                <option value="Agency">Agency</option>
                                                <option value="National Team">National Team</option>
                                                <option value="Supplier">Supplier</option>
                                            </select>
                                            { props.fieldsValidityError.organization_type !== '' ? <div className="display-error text-white">{ props.fieldsValidityError.organization_type }</div> : '' }
                                        </div>
                                    </div>
                                </div>
                            
                        </div>
                        <div className="modal-footer form-modal-footer d-flex justify-content-between">
                            <button type="button" className="default-btn btn-danger" data-dismiss="modal" onClick={ props.onPopupClose }>Cancel</button>
                            <button type="button" onClick={props.addOrganisation} disabled={disableSubmit} className="default-btn btn-success">Add</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
            {!props.modal || <div className="modal-backdrop fade show"></div>}
        </div>


    )

}

export default AddOrganisation;