import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import * as EmailValidator from "email-validator";
import PasswordValidator from '../../helpers/PasswordValidator';
import Request from "../../helpers/Request";
import { base64_decode, get_token_from_search_string, set_user_data, get_user_dashboard_url, __x } from '../../helpers/GeneralHelper';
import Alert from '../../helpers/Alert';
import APIRoutes from '../../API/Routes';
export default class AccountSetup extends Component {
    _page_title     = "Account Setup - USM";
    token_info;

    constructor( props ) {
        super( props );
        
        this.token_info = base64_decode( this.props.location.search );

        this.state = {
            fields      : {
                email       : this.token_info.length > 0 ? this.token_info[0] : '',
                password    : '',
                cPassword   : ''
            },
            isValid     : {
                email       : false,
                password    : false,
                cPassword   : false
            },
            isUntouched : {
                email       : true,
                password    : true,
                cPassword   : true
            },
            errorMsgs   : {
                email       : '',
                password    : '',
                cPassword   : ''
            },
            isSubmitted         : false,
            isSuccess           : false,
            responseMsg         : '',
            passVaidationMsg    : [],
            tokenString         : get_token_from_search_string( this.props.location.search ),
            redirectTo          : '/terms-and-conditions'
        };
        
        this.handleSubmit       = this.handleSubmit.bind( this );
        this.handleChange       = this.handleChange.bind( this );
        this.isFormValid        = this.isFormValid.bind( this );
        this.isPasswordValid    = this.isPasswordValid.bind( this );
        this.resetAlert         = this.resetAlert.bind( this );
    }
    
    /**
     * Handles form submission.
     * @param {*} event 
     */
    handleSubmit( event ) {
        event.preventDefault();

        const postUrl       = APIRoutes.activate_user;
        
        if( !this.isFormValid() ) {
            return;
        }
        
        let fields          = {
            token           : this.state.tokenString,
            email           : this.state.fields.email,
            password        : this.state.fields.password,
            confirmPassword : this.state.fields.cPassword
        };

        /**
         * Creating new request instance to call api
         */
        const request = new Request( postUrl, fields, "POST" ).send();
        request.then( response => {
            /**
             * Set submission result
             */
            let new_state = {
                isSubmitted : true,
                isSuccess   : response.success,
                responseMsg : response.message
            }

            if ( response.success ) {
                /**
                 * Clear fields after success
                 */
                new_state = {
                    ...new_state,
                    fields: {
                        email       : this.token_info.length > 0 ? this.token_info[0] : '',
                        password    : '',
                        cPassword   : ''
                    }
                }

                /**
                 * Set temporary user information in local storage
                 */
                set_user_data( 'temp_token', response.token );
                set_user_data( 'temp_user', response.user );
                
                /**
                 * Get redirect url according to user role and tnc accepted.
                 */
                const redirectTo = response.user.tnc_accepted ? get_user_dashboard_url() : '/terms-and-conditions';
                new_state = {
                    ...new_state,
                    redirectTo
                }
            }
            
            /**
             * Setting new state into the real state
             */
            this.setState( new_state );
        }, error => {
            this.setState({
                isSubmitted : true,
                isSuccess   : error.success,
                responseMsg : error.message
            });
        });
    }

    /**
     * Handles form field's changes
     * @param {*} event 
     */
    handleChange( event ) {
        const target    = event.target,
        value           = target.type === 'checkbox' ? target.checked : target.value,
        id              = target.id;
        
        let fields      = this.state.fields,
        isValid         = this.state.isValid,
        isUntouched     = this.state.isUntouched;
        
        /**
         * Set fields values
         */
        fields[ id ]        = value;

        /**
         * Set validation fields
         */
        isValid.email       = fields.email !== '' && EmailValidator.validate( fields.email );
        isValid.password    = this.isPasswordValid( fields.password );
        isValid.cPassword   = fields.cPassword === '' || fields.cPassword === fields.password;

        /**
         * Set touched fields
         */
        isUntouched[ id ]   = false;

        this.setState({
            fields,
            isValid,
            isUntouched
        });
    }

    /**
     * Checks if form is valid or not.
     */
    isFormValid() {
        return this.state.isValid.email             // Checks through email validator
            && this.state.isValid.password !== ''   // Checks is password field is not empty
            && this.state.isValid.password          // Checks password validtion compliance
            && this.state.isValid.cPassword         // Checks is cPassword matches with password field
            && this.state.fields.cPassword !== '';  // Checks is cPassword field is not empty
    }

    /**
     * Checks if password is valid or not.
     */
    isPasswordValid = ( passToValidate = '' ) => {
        let passVaidationMsg    = this.state.passVaidationMsg;

        /**
         * Validating password
         */
        passVaidationMsg        = PasswordValidator( passToValidate );

        this.setState({
            passVaidationMsg
        })

        return !( passVaidationMsg.length > 0 );
    }

    listInvalidPasswordError = () => {
        const passInvalidRulesError = this.state.passVaidationMsg;
        const invalid_pass_error_list = passInvalidRulesError.map( ( error, idx ) => {
            return <li key={idx}>{error}</li>;
        });
        return invalid_pass_error_list;
    }

    resetAlert = () => {
        this.setState({
            isSubmitted: false,            
            responseMsg: ''
        });
    }

    render() {
        if ( this.state.isSubmitted && this.state.isSuccess ) {
            return <Redirect to={ { pathname: this.state.redirectTo, state: this.state.responseMsg, success: true } } />;
        }

        const email_classes = ( this.state.isValid.email || this.state.isUntouched.email ) ? '' : 'form-error',
        pass_classes        = ( this.state.isValid.password || this.state.isUntouched.password ) ? '' : 'form-error',
        cpass_classes       = ( this.state.isValid.cPassword || this.state.isUntouched.cPassword ) ? '' : 'form-error',
        submitDisabled      = !this.isFormValid(),
        errorMsg            = ( this.state.isSubmitted && !this.state.isSuccess ) ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} onClose={this.resetAlert} /> : '';

        return (
            <div className="modal-body usm-modal-body AccountSetup">
                { errorMsg }
                <h2>Account Setup</h2>
                <div className="width-container">
                    <form action="" className="usm-form" onSubmit={ event => this.handleSubmit( event ) }>
                        <div className={`form-group ${email_classes}`}>
                            <input type="email" className={`form-control ${email_classes}`} id="email" defaultValue={ this.state.fields.email } placeholder="Email" readOnly />
                            { email_classes === '' ||
                                <div className="display-error">{ __x( 'Please enter a vaild email.' ) }</div>
                            }
                        </div>
                        <div className={`form-group ${pass_classes}`}> 
                            <input type="password" className={`form-control ${pass_classes}`} id="password" value={ this.state.fields.password } onChange={ event => this.handleChange( event )} placeholder="Set Password" />
                            { pass_classes === '' ||
                                <div className="display-error zIndex3"><ul>{this.listInvalidPasswordError()}</ul></div>
                            }
                        </div>
                        <div className={`form-group ${cpass_classes}`}>
                            <input type="password" className={`form-control ${cpass_classes}`} id="cPassword" value={ this.state.fields.cPassword } onChange={ event => this.handleChange( event )} placeholder="Confirm Password" />
                            { cpass_classes === '' ||
                                <div className="display-error">{ __x( 'The entered passwords do not match.' ) }</div>
                            }
                        </div>
                        <button type="submit" className="btn btn-success btn-block marginLR0" disabled={submitDisabled}>Create</button>
                    </form>
                </div>
            </div>
        );
    }

    /**
     * Sets document title
     */
    componentDidMount = () => {
        document.title = this._page_title;
    }
}
