export const setSearchResult = ( result ) => dispatch => {
    dispatch({
        type: 'SET_SEARCH_RESULT',
        payload: {
            result
        }
    });
}

export const setFilterOption = ( option, data ) => dispatch => {
    dispatch({
        type: 'SET_FILTER_OPTIONS',
        payload: {
            option,
            data
        }
    });
}
