import React from 'react';
import UpdateUser from './UpdateUser';
import User from './User';
import Request from '../../../helpers/Request';
import { get_user_data, isUserImageValid, __x } from '../../../helpers/GeneralHelper';
import { connect } from 'react-redux';
import { setUsers, filterUser, appendUsers } from '../../../actions/UsersAction';
import * as EmailValidator from 'email-validator';
import APIRoutes from '../../../API/Routes';
import LoadingSpinner from '../common/LoadingSpinner';
import InfiniteScroll from 'react-infinite-scroll-component';

class Users extends React.Component {

    _page_title = 'Users - USM';

    constructor( props ) {
        super( props );
        
        /**
         * Set refernce for intel-tel-input for setting country flag.
         */
        this._country   = React.createRef();
        this._file      = React.createRef();
        this.fetched    = false;

        this.state = {
            addUserModal: false,
            filteredRole: '',
            users       : [],
            user        : {},
            visibleUsers: [],
            id_format   : 1000,
            imgAPIUrl   : '',
            country_iso : 'gb',
            fields      : {
                first_name  : '', 
                last_name   : '', 
                email       : '', 
                users_role  :  '',
                status      : '', 
                country_code: '44', 
                phone       : '', 
                file        : ''
            },
            isValid : {
                first_name  : true, last_name    : true, email   : true, users_role : true,
                status      : true, country_code  : true, phone   : true, file      : true
            },
            fieldsValidityError: {
                first_name  : '', last_name     : '', email   : '', users_role  : '',
                status      : '', country_code  : '', phone   : '', file        : ''
            },
            fileName        : __x( `Add Photo` ),
            isUpdating      : false,
            updatingUserId  : 0,
            imgPreview      : '',
            isProcessing    : false,
            isSubmitted     : false,
            isSuccess       : false,        // Used for api success/failure.
            isError         : false,        // Used for only local validation errors to show.
            responseMessage : '',
            tokenExpired    : false,
            fileFieldRef    : '',
            filtered        : false,
            hasMoreItems    : false,
			maxPages        : null,
            nextPage        : 1,
            lockscreen      : false,
            lockErrorMsg    : '',
        };
    }
    
    /**
     * Clears agent's filter.
     */
    clearFilter = () => {
        this.setState({
            filteredRole : '',
            filtered    : false
        }, () => this.filterByRole() );
    }

    handleAddUserModal = ( fetchUsers = false, user_id = 0 ) => {
        let new_state = {
            // addUserModal    : !this.state.addUserModal,
            addUserModal    : true,
            filteredRole    : ''
        }
        
        /**
         * If user id is given, set user's details as user object in state
         */
        if ( user_id > 0 ) {
            // const user = get_user_by_id( this.state.users, user_id );
            this.getUser( user_id );
            return;
        }
        
        this.setState( new_state, () => {
            if ( this.state.addUserModal ) {
                document.body.classList.add( 'modal-open' );
            } else {
                document.body.classList.remove( 'modal-open' );
            }
        });
        
        if ( fetchUsers ) {
            this.getUsers();
        }
    }

    render = () => {
        const loading = this.state.isProcessing ? <LoadingSpinner/> : '';
        return (
            <div id="mainContainer">
            {loading}
                <div className="heading-section d-flex align-items-center">
                    <h2>Users</h2> 
                    <h6 className="ml-auto">
                        <i className="material-icons user-menu-icon">person</i> 
                        <button className="text-white text-upper" data-toggle="modal" data-target="#addUserModal" onClick={ () => this.handleAddUserModal() }>Add User</button>
                    </h6>
                </div>
                <div className="filtter-section position-relative">
                    <div className="d-flex">
                        <div className="w-100">
                            <form action="" className="usm-form">
                                <div className="form-group marginB0 filter-form">
                                    <label>User Role</label>
                                    <select name="user_role" className="form-control" onChange={ event => this.setState({ filteredRole: event.target.value }) } value={this.state.filteredRole}>
                                        <option value="">All</option>
                                        <option value="Admin">Admin</option>
                                        <option value="Staff">Staff</option>
                                    </select> 
                                </div>
                            </form>
                            
                        </div>
                        <div className="flex-shrink-1 marginL18">
                            <div className="apply-filtter float-right d-flex align-items-center flex-column">
                                <i className="material-icons marginB5"><img src={require( '../../../assets/images/filter-icon.png' )} alt="" /></i>
                                <button onClick={ this.filterByRole } className="btn btn-success borderRadius15 text-uppercase small-button">Apply Filter</button>
                            </div>
                        </div>
                    </div>
                    {/* 
                        !this.state.filtered ? '' : 
                        <button onClick={ this.clearFilter } className="transparent-btn text-danger font-size30 clear-filter-btn">&times;</button>
                     */}
                </div>
                <div className="content-section">

                    <div className="usm-table marginT82 table-responsive">
                    <InfiniteScroll
                            dataLength={this.state.visibleUsers && this.state.visibleUsers.length > 0 ? this.state.visibleUsers.length : 0} //This is important field to render the next data
                            next={this.loadMoreUsers}
                            hasMore={this.state.hasMoreItems}
                        // loader={<h4>Loading...</h4>}
                        >
                            <table className="table table-hover">
                                <thead className="usm-thead">
                                    <tr>
                                        <th scope="col">Employee ID</th>
                                        <th scope="col">First Name</th>
                                        <th scope="col">Last Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Role</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Manage</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <User
                                        is_fetched={this.fetched}
                                        users={this.state.visibleUsers}
                                        id_format={this.state.id_format}
                                        handleAddUserModal={(fetchUsers, id) => this.handleAddUserModal(fetchUsers, id)}
                                    />
                                </tbody>
                            </table>

                        </InfiniteScroll>
                    </div>
                </div>
                <UpdateUser 
                    fields              = { this.state.fields }
                    isValid             = { this.state.isValid }
                    responseMessage     = { this.state.responseMessage }
                    imgPreview          = { this.state.imgPreview }
                    isSubmitted         = { this.state.isSubmitted }
                    isSuccess           = { this.state.isSuccess }
                    isFormValid         = { this.isFormValid }
                    clearFormFields     = { this.clearFormFields }
                    updateUser          = { event => this.updateUser( event ) }
                    onPopupClose        = { this.onPopupClose }
                    user                = { this.state.user }
                    fileName            = { this.state.fileName }
                    modal               = { this.state.addUserModal }
                    resetAlert          = { this.resetAlert }
                    isUpdating          = { this.state.isUpdating }
                    isProcessing        = { this.state.isProcessing }
                    fieldsValidityError = { this.state.fieldsValidityError }
                    handleFieldsChanges = { e => this.handleFieldsChanges(e)}
                    handlePhoneChange   = { this.handlePhoneChange }
                    countryCodeChange   = { this.handleCountryCodeChange }
                    country_iso         = { this.state.country_iso }
                    set_country         = { elt => this._country = elt }
                    countryElt          = { this._country }
                    setFileRef          = { elt => this._file = elt }
                    lockErrorMsg        = { this.state.lockErrorMsg }
                    lockscreen          = { this.state.lockscreen }
                    goBack              = { this.goBack }
                />
            </div>
        );
    }

    /**
     * go back when screen is locked for a user
     */
    goBack = () => {
        document.body.classList.remove( 'modal-open' );
        // this.props.history.go(-1);
        this.onPopupClose();
    }

    /**
     * Get single user by id.
     */
    getUser = user_id => {
        if ( !user_id ) {
            return false;
        }

        const token = get_user_data( 'token' );

        if ( !token ) {
            this.setState({
                isSuccess       : false,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const users_path = APIRoutes.user + user_id;
        const headers = {
            "Authorization" : token
        }
        const request = new Request( users_path, {}, 'GET', headers ).send();
        request.then( response => {
            const user = response.success ? response.user : {};
            const new_state = {
                fields: {
                    ...this.state.fields,
                    first_name  : user.first_name ? user.first_name : '',
                    last_name   : user.last_name ? user.last_name : '',
                    email       : user.email ? user.email : '',
                    users_role  : user.users_role ? user.users_role : '',
                    status      : user.status ? '1' : '0',
                    country_code: user.country_code ? user.country_code : '44',
                    phone       : user.phone ? user.phone : '',
                },
                country_iso     : user.country_iso ? user.country_iso : 'gb',
                fileName        : user.imagepath ? user.imagepath : this.state.fileName,
                imgPreview      : user.imagepath ? this.state.imgAPIUrl + user.imagepath : '',
                isUpdating      : true,
                updatingUserId  : user_id,
                // addUserModal    : !this.state.addUserModal
                addUserModal    : true
            }
            
            this._country.setFlag( new_state.country_iso );
            this.setState( new_state, () => {
                if ( this.state.addUserModal ) {
                    document.body.classList.add( 'modal-open' );
                } else {
                    document.body.classList.remove( 'modal-open' );
                }
            });
            
            if ( response.success ) {
                /**
                 * Dispatching action to set users to redux store.
                 */
                this.props.setUsers( response.users );
            }
        }, error => {
            console.log( error );
            console.log(this.state.addUserModal);
            this.setState({
                isSuccess : false,
                lockErrorMsg : error.message,
                lockscreen : error.lockscreen ? error.lockscreen : false,
                addUserModal    : !this.state.addUserModal,
            }, () => {
                if ( this.state.addUserModal ) {
                    document.body.classList.add( 'modal-open' );
                } else {
                    document.body.classList.remove( 'modal-open' );
                }
            });
        });
    }

    getUsers = () => {
        const token = get_user_data( 'token' );

        if ( !token ) {
            this.setState({
                isSuccess       : false,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        this.setState({
            maxPages : null,
            nextPage : 1,
        })

        const users_path = APIRoutes.all_users;
        const headers = {
            "Authorization" : token
        }
        const request = new Request( users_path, {}, 'GET', headers ).send();
        request.then( response => {
            this.fetched = true;
            this.setState({
                users       : response.success ? response.users : [],
                visibleUsers: response.success ? response.users : [],
                id_format   : response.success ? response.id_format : 1000,
                imgAPIUrl   : response.success ? response.file_path : '',
                hasMoreItems    : response.pages && response.pages === 1 ? false : true,
				maxPages        : response.pages ? response.pages : null,
				nextPage        : this.state.nextPage + 1,
            });
            
            if ( response.success ) {
                /**
                 * Dispatching action to set users to redux store.
                 */
                this.props.setUsers( response.users );
            }
        }, error => {
            console.log( error );
        });
    }

    /**
	 * load more users on page scroll
	 */
	loadMoreUsers = () => {
		const token = get_user_data( 'token' );

        if ( !token ) {
            this.setState({
                isSuccess       : false,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

		this.setState({
			hasMoreItems : false,
		});

		let maxPages = this.state.maxPages,
		nextPage     = this.state.nextPage;


		if(maxPages < nextPage) {
			this.setState({
				hasMoreItems : false,
			});
			return false;
		}

		const filteredRole = this.state.filteredRole;
		
		let query_string = '';
		if (filteredRole && filteredRole !== '') {
            query_string += (`role=` + filteredRole);
		}

        const agent_path = query_string !== '' ?  APIRoutes.all_users + nextPage + '?' + query_string : APIRoutes.all_users + nextPage ;
        const headers = {
            "Authorization" : token
        }
		const request = new Request(agent_path, {}, 'GET', headers).send();
		
		request.then(response => {
			if (response.success) {
				this.setState({
					hasMoreItems : true,
					nextPage : nextPage + 1,
                });

                /**
					 * Dispatching action to set users to redux store.
					 */
                this.props.appendUsers(this.state.visibleUsers, response.users);
				
			}
		}, error => {
			console.log(error);
		});
    }

    /**
     * Filter users by role
     */
    filterByRole = () => {
        const token = get_user_data( 'token' );

        if ( !token ) {
            this.setState({
                isSuccess       : false,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }
        
		const filteredRole = this.state.filteredRole;
		
		let query_string = '';
		if (filteredRole && filteredRole !== '') {
            query_string += (`role=` + filteredRole);
		}
        
        const headers = {
            "Authorization" : token
        }
		const request = new Request(APIRoutes.all_users + '?' + query_string, '', 'GET', headers, true ).send();
		request.then(response => {
			if (response.success) {
				this.setState({
					hasMoreItems : response.pages && response.pages === 1 ? false : true,
					maxPages     : response.pages ? response.pages : null,
					nextPage     : 2,
				});

				/**
					* Dispatching action to set players to redux store.
					*/
				this.props.setUsers(response.users);

			}
		}, error => {
			console.log(error);
		});
    }

    componentDidMount = () => {
        document.title = this._page_title;
        this.getUsers();
    }

    componentWillReceiveProps = (props) => {
		this.setState({
            visibleUsers: props.users.users ? props.users.users : []
		});
	}

    updateUser = ( event ) => {
        event.preventDefault();
        const token = get_user_data( 'token' );

        if ( !token ) {
            this.setState({
                isSuccess       : false,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( !this.isFormValid() ) {
            return false;
        }

        this.setState({
            isProcessing: true
        });

        const isUpdating = this.state.isUpdating,
        updatingUserId   = this.state.updatingUserId,
        method           = isUpdating ? "PUT" : "POST",
        user_path        = isUpdating ? APIRoutes.user + updatingUserId : APIRoutes.user,
        headers          = {
            "Authorization": token
        }

        const fields    =  this.state.fields;
        let new_state   = {},
        formData        = new FormData();
        
        for ( let i in fields ) {
            formData.append( i, fields[i] );
        }

        formData.append( 'country_iso', this.state.country_iso );
        
        /**
         * Sending request to add/update users.
         */
        const request = new Request( user_path, formData, method, headers, true ).send();
        request.then( response => {
            if ( response.success ) {
                this.onPopupClose( true );
            }
            
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMessage : response.message,
                updatingUserId  : response.success ? 0 : updatingUserId,
                isUpdating      : isUpdating ? ( response.success ? !isUpdating : isUpdating ) : isUpdating
            }
            this.setState( new_state );            
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMessage : error.message
            };
            this.setState( new_state );
        });
    }

    handleFieldsChanges = ( event ) => {
        const target    = event.target,
        elt_id          = target.name,
        elt_value       = target.value,
        fields          = this.state.fields;

        let isFileValid = this.state.isValid,
        errors          = {},
        fileName        = this.state.fileName;
        
        if ( elt_id === 'file' ) {
            const file = target.files.length > 0 ? target.files[0] : false;
            
            const isImageValid = isUserImageValid( file );
            if ( !isImageValid ) {
                isFileValid[ 'file' ] = true;
                errors[ 'fieldsValidityError' ] = {
                    file: ''
                };

                fileName = __x( `Add Photo` );
                this.setState({
                    imgPreview: ''
                });
            } else {
                if ( !isImageValid.success ) {
                    isFileValid[ 'file' ] = false;
                    errors[ 'fieldsValidityError' ] = {
                        file: isImageValid.messages.map( ( error, idx ) => <li key={idx}>{error}</li> )
                    };

                    fileName = __x( `Add Photo` );
                    this.setState({
                        imgPreview: ''
                    });
                } else {
                    fileName = file.name;

                    /**
                     * Get preview file url using file reader
                     */
                    const reader = new FileReader();
                    reader.readAsDataURL( file );
                    reader.onloadend = function ( e ) {
                        this.setState({
                            imgPreview: [ reader.result ]
                        });
                    }.bind( this );
                    
                    isFileValid[ 'file' ] = true;
                    errors[ 'fieldsValidityError' ] = {
                        file: ''
                    };
                }
            }
            
            fields[ 'file' ] = file;
        } else {
            fields[ elt_id ] = elt_value;
        }

        this.setState({
            fields,
            fieldsValidityError: {
                ...this.state.fieldsValidityError,
                file: !errors.fieldsValidityError ? '' : errors.fieldsValidityError.file
            },
            isValid : {
                ...isFileValid,
                first_name  : fields.first_name !== '' ? fields.first_name.length >= 0 && fields.first_name.length <= 255 : true,
                last_name   : fields.last_name !== '' ? fields.last_name.length >= 0 && fields.last_name.length <= 255 : true,
                email       : fields.email !== '' ? fields.email.length >= 1 && fields.email.length <= 255 && EmailValidator.validate( fields.email ) : true,
                users_role  : fields.users_role !== '' ? fields.users_role.length >= 0 || fields.users_role.length <= 255 : true,
                status      : fields.status !== '' ? fields.status.length >= 0 && fields.status.length <= 255 : true,
                file        : Object.keys( isFileValid ).length > 0 ? isFileValid.file : true
            },
            fileName
        });        
    }

    /**
     * Check is form is valid or not to submit
     */
    isFormValid = () => {
        let validityErr = [];
        const fields    = this.state.fields,
        isValid         = this.state.isValid;

        for ( let elt in fields ) {
            if ( elt === 'file' )
                continue;
            
            if ( fields[ elt ] === '' || !isValid[ elt ] ) {
                validityErr.push( elt );
            }
        }

        return validityErr.length <= 0;
    }

    /**
     * Handles country code changes
     */
    handleCountryCodeChange = ( value, countryData ) => {
        const fields    = this.state.fields,
        isValid         = this.state.isValid,
        country_code    = countryData? countryData.dialCode : '44';
        
        fields[ 'country_code' ]    = country_code;
        isValid[ 'country_code' ]   = fields.country_code !== '' ? fields.country_code.toString().length >= 0 && fields.country_code.toString().length <= 255 : true;

        this.setState({
            fields,
            isValid,
            country_iso: countryData.iso2
        });
    }

    /**
     * Handles phone changes
     */
    handlePhoneChange = ( status, value ) => {
        const fields    = this.state.fields,
        isValid         = this.state.isValid,
        phone           = value ? value : '';
        
        fields[ 'phone' ]   = phone;
        isValid[ 'phone' ]  = fields.phone !== '' ? fields.phone.length >= 0 && fields.phone.length <= 255 && !isNaN( fields.phone ) : true;

        this.setState({
            fields,
            isValid
        });
    }

    /**
     * Clear form fields
     */
    clearFormFields = () => {
        this._file.value = null;
        this.setState({
            fields : {
                first_name  : '',
                last_name   : '',
                email       : '',
                users_role  : '',
                status      : '',
                country_code: '44',
                phone       : '',
                file        : ''
            },
            isValid : {
                first_name  : true,
                last_name   : true,
                email       : true,
                users_role  : true,
                status      : true,
                country_code: true,
                phone       : true,
                file        : true
            },
            country_iso     : 'gb',
            fileName        : __x( `Add Photo` ),
            imgPreview      : '',
            isUpdating      : false,
            isSubmitted     : false,
            isSuccess       : false,        // Used for api success/failure.
            isError         : false,        // Used for only local validation errors to show.
            responseMessage : '',
            filteredRole    : '',
            updatingUserId  : 0,
            lockErrorMsg    : '',
            lockscreen      : false 
        });
        this._country.setFlag( 'gb' );
    }

    onPopupClose = () => {
        this.clearFormFields();
        //this.handleAddUserModal( true );
        this.setState({
            addUserModal : false
        });

        document.body.classList.remove( 'modal-open' );

        this.getUsers();
    }

    resetAlert = () => {
        this.setState({
            isSubmitted     : false,
            responseMessage : ''
        });
    }
}

const mapStateToProps = ( state ) => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setUsers    : ( users ) => dispatch( setUsers( users ) ),
    appendUsers : (all_users, load_users) => dispatch(appendUsers(all_users, load_users)),
    filterUser  : ( role ) => dispatch( filterUser( role ) )
});

export default connect( mapStateToProps, mapDispatchToProps )( Users );