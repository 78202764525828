import React from 'react';
import { get_display_format_date } from '../../../helpers/GeneralHelper.js';
const Fields = require( '../../../helpers/Fields.json' );

const ChangeLogDetails = ({ logDetails, ...props }) => {
    const showPopup = props.showPopup ? 'show' : '',
        detail_keys = logDetails ? Object.keys( logDetails ) : [],
        date_fields = [ 'birthday', 'fulfillment_date', 'agent_representation_enddate', 'date_of_service', 'last_sigining_date', 'contract_enddate' ],
        context     = props.context ? props.context : '',
        boolean_values = {
            normal : {
                "true"  : "Yes",
                "false" : "No",
                "0"     : "No",
                "1"     : "Yes"
            },
            status : {
                "true"  : "Active",
                "1"     : "Active",
                "false" : "Deactive",
                "0"     : "Deactive",
            },
            case  : {
                "true"  : "Open",
                "1"     : "Open",
                "false" : "Close",
                "0"     : "Close",
            }
        };
        
    return (
        <>
            <div className={`modal form-modal fade modal-active ${showPopup}`} style={{ transform: props.showPopup ? `translate(0, 0)` : 'translate(-125%, -125%)' }}>
                <div className="modal-dialog modal-dialog-centered maxWidth600">
                    <div className="modal-content">
                        <div className="modal-header form-modal-header d-flex align-items-center">
                            <img src={require('../../../assets/images/form-usm-logo.png')} alt="" />
                            <h5 className="form-modal-title">Change Log Details</h5>
                            <button type="button" className="modal-close transparent-btn" data-dismiss="modal" aria-label="Close" onClick={ props.closePopup }>
                                <img src={require('../../../assets/images/close-modal.png')} alt="" />
                            </button>
                        </div>
                        <div className="modal-body form-modal-body">
                            <div className="usm-table table-responsive">
                                <table className="table table-hover">
                                    <thead className="usm-thead">
                                        <tr>
                                            <th scope="col">Field Name</th>
                                            <th scope="col">Previous Data</th>
                                            <th scope="col">Changed Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            logDetails && detail_keys.length ? detail_keys.map( key => {
                                                let prev_value      = date_fields.indexOf( key ) >= 0 ? get_display_format_date( logDetails[ key ].previous ) : (key === 'master_outcome_id' || key === 'contract_with_player') && logDetails[ key ].previous === 0 ? '' : logDetails[ key ].previous,
                                                    changed_value   = date_fields.indexOf( key ) >= 0 ? get_display_format_date( logDetails[ key ].changed ) : (key === 'master_outcome_id' || key === 'contract_with_player') && logDetails[ key ].changed === 0 ? '' : logDetails[ key ].changed ;

                                                    
                                                    if ( key === 'country_code' ) {
                                                        prev_value      = logDetails[ key ].previous ? '+' + logDetails[ key ].previous : '';
                                                        changed_value   = logDetails[ key ].changed ? '+' + logDetails[ key ].changed : '';
                                                    } 
                                                    
                                                    const booleans = [ 'true', 'false', 'TRUE', 'FALSE' ];
                                                    const bool_data_key = (key === 'status' && context !== 'case') ? 'status' : (context === 'case' && key ==='status' )? 'case' : 'normal';

                                                    if ( typeof prev_value === 'boolean' || typeof changed_value === 'boolean' || booleans.indexOf( prev_value ) >= 0 || booleans.indexOf( changed_value ) >= 0 ) {
                                                        const bools     = boolean_values[ bool_data_key ];
                                                        
                                                        prev_value      = logDetails[ key ].previous !== null && logDetails[ key ].previous !== undefined ? logDetails[ key ].previous.toString() : '';
                                                        prev_value      = bools[ prev_value.toLowerCase() ];
                                                        
                                                        changed_value   = logDetails[ key ].changed !== null && logDetails[ key ].changed !== undefined ? logDetails[ key ].changed.toString() : '';
                                                        changed_value   = bools[ changed_value.toLowerCase() ];
                                                    }
                                                
                                                return (
                                                    <tr key={ key }>
                                                        <td>{ key && Fields[ key ] ? Fields[ key ] : key }</td>
                                                        <td>{ prev_value }</td>
                                                        <td>{ changed_value }</td>
                                                    </tr>
                                                );
                                            }) : (
                                                <tr>
                                                    <td></td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer form-modal-footer d-flex justify-content-between">
                            <button type="button" className="default-btn btn-danger min-width157" data-dismiss="modal" onClick={props.closePopup}>Back</button>
                        </div>
                    </div>
                </div>
            </div>
            { !props.showPopup || <div className="modal-backdrop fade show"></div> }
        </>
    )
}

export default ChangeLogDetails;