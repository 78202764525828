import React from 'react';
import Alert from '../../helpers/Alert';
import IntlTelInput from 'react-intl-tel-input';
import '../../../node_modules/react-intl-tel-input/dist/main.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { __x } from '../../helpers/GeneralHelper';

const AddClub = ( props ) => {
    const modalActiveClass  = props.modal ? 'show' : '',
    disableSubmit           = !props.isFormValid() || props.isProcessing ? true : false,
    responseMsg             = props.responseMessage !== '' && props.isSubmitted ? <Alert message={props.responseMessage} success={props.isSuccess} duration={5000} floating="true" onClose={props.resetAlert} /> : '';
    
    const leagues   = props.leagues && props.leagues.length > 0 ? props.leagues.map( league => {
        return <option key={ league.id } value={ league.id }>{ league.title }</option>;
    }) : '';

    const today = new Date();
    
    return (
        <div>
            {responseMsg}
            <div className={`modal form-modal fade modal-active ${modalActiveClass}`} style={{ transform: props.modal ? `translate(0, 0)` : 'translate(-125%, -125%)' }}>
                <div className="modal-dialog modal-dialog-centered maxWidth600">
                    <div className="modal-content">
                        <div className="modal-header form-modal-header d-flex align-items-center">
                            <img src={ require( '../../assets/images/form-usm-logo.png' ) } alt="" />
                            <h5 className="form-modal-title">Add Club</h5>
                            <button className="modal-close transparent-btn" data-dismiss="modal" aria-label="Close" onClick={ props.onPopupClose }>
                                <img src={ require( '../../assets/images/close-modal.png' ) } alt="" />
                            </button>
                        </div>
                        <form action="" className="usm-form form-border" onSubmit={ e => {e.preventDefault();} }>
                            <div className="modal-body form-modal-body">
                                <div className="row">
                                    <div className="col-md-6 col-xs-12">
                                        <div className={ props.fieldsValidityError.club_name === '' ? `form-group marginB0` : `form-group marginB0 form-error`}>
                                            <label htmlFor="">Club Name</label>
                                            <input onChange={ props.handleFieldsChanges } value={ props.fields.club_name } name="club_name" type="text" className="form-control" placeholder="First Name" autoComplete="off" />
                                            { props.fieldsValidityError.club_name === '' || <div className="display-error">{ props.fieldsValidityError.club_name }</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xs-12">
                                        <div className="form-group marginB0">
                                            <label htmlFor="">League</label>
                                            <select name="master_league_id" className="form-control" onChange={ props.handleFieldsChanges } value={ props.fields.master_league_id }>
                                                <option value=""></option>
                                                {leagues}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="add-photo-section d-flex align-items-cente">
                                    <div className={ props.fieldsValidityError.file === '' ? `form-group paddingT0` : `form-group paddingT0 form-error`}>
                                        <label htmlFor="">Upload Badge</label>
                                        <div className="custom-file">
                                            <input ref={ props.setFileRef } onChange={ props.handleFieldsChanges } name="file" type="file" className="custom-file-input" id="customFile" />
                                            <label className="custom-file-label" htmlFor="customFile">{props.fileName}</label>
                                        </div>
                                        { props.fieldsValidityError.file === '' || <div className="display-error">{ props.fieldsValidityError.file }</div> }
                                    </div>
                                    
                                    <div className="upload-image ml-auto text-right">
                                        <img src={ props.imgPreview } alt="" />
                                    </div>				
                                </div>

                                <div className="row form-transDivider marginT15">
                                    <div className="col">
                                        <div className="form-subheading">Main Contact</div>	
                                    </div>			
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className={ props.fieldsValidityError.club_title === '' ? `form-group` : `form-group form-error`}>
                                            <label htmlFor="">Title in Club</label>
                                            <input onChange={ props.handleFieldsChanges } value={ props.fields.club_title } name="club_title" className="form-control" autoComplete="off" />
                                            { props.fieldsValidityError.club_title === '' || <div className="display-error">{ props.fieldsValidityError.club_title }</div> }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-xs-12">
                                        <div className={ props.fieldsValidityError.first_name === '' ? `form-group` : `form-group form-error`}>
                                            <label htmlFor="">First Name</label>
                                            <input onChange={ props.handleFieldsChanges } value={ props.fields.first_name } name="first_name" type="text" className="form-control" placeholder="First Name" autoComplete="off" />
                                            { props.fieldsValidityError.first_name === '' || <div className="display-error">{ props.fieldsValidityError.first_name }</div> }
                                        </div>					
                                    </div>
                                    <div className="col-md-6 col-xs-12">
                                        <div className={ props.fieldsValidityError.last_name === '' ? `form-group` : `form-group form-error`}>
                                            <label htmlFor="">Last Name</label>
                                            <input onChange={ props.handleFieldsChanges } value={ props.fields.last_name } name="last_name" type="text" className="form-control" placeholder="Last Name" autoComplete="off" />
                                            { props.fieldsValidityError.last_name === '' || <div className="display-error">{ props.fieldsValidityError.last_name }</div> }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className={ props.fieldsValidityError.email === '' ? `form-group` : `form-group form-error`}>
                                            <label htmlFor="">Email</label>
                                            <input onChange={ props.handleFieldsChanges } value={ props.fields.email } name="email" type="text" className="form-control" placeholder="Email" autoComplete="off" />
                                            { props.fieldsValidityError.email === '' || <div className="display-error">{ props.fieldsValidityError.email }</div> }
                                        </div>
                                    </div>
                                </div>


                                <div className="row">				
                                    <div className="col-md-6 col-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="">Phone</label>
                                            <div className={ props.fieldsValidityError.phone === '' ? `input-group` : `input-group form-error`}>
                                                <IntlTelInput 
                                                    ref={ props.set_country }
                                                    onPhoneNumberChange={ props.handlePhoneChange }
                                                    defaultCountry={ 'gb' }
                                                    css={['intl-tel-input w-100', 'form-control']} 
                                                    placeholder="Phone"
                                                    value={props.fields.phone}
                                                    separateDialCode="true"
                                                    onSelectFlag={ props.countryCodeChange }
                                                />
                                            </div>
                                            { props.fieldsValidityError.phone === '' || <div className="display-error">{ props.fieldsValidityError.phone }</div> }
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="">Birthday</label>
                                            <div className={ props.fieldsValidityError.birthday === '' ? `input-group w-100` : `input-group w-100 form-error`}>
                                                <DatePicker
                                                    className="form-control"
                                                    selected={ props.fields.birthday }
                                                    onChange={ props.birthdayChanges }
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={ new Date( today.getFullYear(), today.getMonth(), today.getDate() - 1 ) }
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={30}
                                                />
                                            </div>
                                            { props.fieldsValidityError.birthday === '' || <div className="display-error">{ props.fieldsValidityError.birthday }</div> }
                                        </div>					
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="">Note</label>
                                            <textarea onChange={ props.handleFieldsChanges } name="club_note" className="form-control textarea76" cols="" rows="" value={ props.fields.club_note }></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group usm-check">
                                            <input onChange={ props.handleFieldsChanges } value={ props.fields.show_birthday_notification } checked={ props.fields.show_birthday_notification } name="show_birthday_notification" type="checkbox" id="bdayNotification" />
                                            <label htmlFor="bdayNotification">{ __x( `Show Birthday Notification` ) }</label>	
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group usm-check">
                                            <input onChange={ props.handleFieldsChanges } value={ props.fields.is_restricted } checked={ props.fields.is_restricted } name="is_restricted" type="checkbox" id="restrictUser" />
                                            <label htmlFor="restrictUser">{ __x( `Restrict Personal Contact Details to Admin View Only` ) }</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer form-modal-footer d-flex justify-content-between">
                                <button type="button" className="default-btn btn-danger" data-dismiss="modal" onClick={ props.onPopupClose }>Cancel</button>
                                <button onClick={ props.updateClub } type="button" className="default-btn btn-success" disabled={disableSubmit}>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            { !props.modal || <div className="modal-backdrop fade show"></div> }
        </div>
    );
}
export default AddClub;
