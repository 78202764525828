import RequestAPI from './RequestApi';

export default class Request extends RequestAPI {
    send = () => {
        const request = this.sendRequest();
        
        return request.then( result => {
            const new_result = {
                ...result
            }
            return new_result;
        }, error => {
            throw error;
        });
    }
}