import React, { Component } from 'react';
import PasswordValidator from '../../helpers/PasswordValidator';
import Request from '../../helpers/Request';
import { get_token_from_search_string, __x } from '../../helpers/GeneralHelper';
import { Redirect } from 'react-router-dom';
import Alert from '../../helpers/Alert';
import APIRoutes from '../../API/Routes';
export default class ResetPassword extends Component {

    _page_title     = "Reset Password - USM";

    constructor( props ) {
        super( props );
        
        this.state = {
            fields      : {
                password    : '',
                cPassword   : '',
                rememberme  : false
            },
            isValid     : {
                password    : true,
                cPassword   : true
            },
            errorMsgs   : {
                password    : '',
                cPassword   : ''
            },
            isSubmitted         : false,
            isSuccess           : false,
            responseMsg         : '',
            passVaidationMsg    : [],
            tokenString         : get_token_from_search_string( this.props.location.search )
        };
        
        this.handleSubmit               = this.handleSubmit.bind( this );
        this.handleChange               = this.handleChange.bind( this );
        this.isFormValid                = this.isFormValid.bind( this );
        this.isPasswordValid            = this.isPasswordValid.bind( this );
        this.listInvalidPasswordError   = this.listInvalidPasswordError.bind( this );
        this.resetAlert                 = this.resetAlert.bind( this );
    }
    
    /**
     * Handles form submission
     * @param {*} event 
     */
    handleSubmit( event ) {
        event.preventDefault();
        
        if( !this.isFormValid() ) {
            return;
        }
        
        const fields        = {
            token           : this.state.tokenString,
            newPassword     : this.state.fields.password,
            verifyPassword  : this.state.fields.cPassword 
        };

        const resetPassUrl  = APIRoutes.reset_password;

        const response      = new Request( resetPassUrl, fields, "POST" ).send();
        
        response.then( res => {
            this.setState({
                isSuccess   : res.success,
                isSubmitted : true,
                responseMsg : res.message
            });
        }, error => {
            console.log( error );
            this.setState({
                isSubmitted : true,
                isSuccess   : error.success,
                responseMsg : error.message
            });
        });
    }

    handleChange( event ) {
        const target    = event.target,
        value           = target.type === 'checkbox' ? target.checked : target.value,
        id              = target.id;
        
        let fields      = this.state.fields,
        isValid         = this.state.isValid;
        
        /**
         * Set fields values
         */
        fields[ id ]        = value;

        /**
         * Set validation fields
         */
        isValid.password    = this.isPasswordValid( fields.password );
        isValid.cPassword   = fields.cPassword === '' || fields.cPassword === fields.password;
        
        this.setState({
            fields,
            isValid
        });
    }

    /**
     * Checks form validity with following rules
     * Both fields are not blank and are matched and valid
     */
    isFormValid() {
        return this.state.fields.password !== '' && this.state.fields.cPassword !== '' && this.state.isValid.password && this.state.isValid.cPassword;
    }

    isPasswordValid = ( passToValidate = '' ) => {
        let passVaidationMsg    = this.state.passVaidationMsg;

        /**
         * Validating password
         */
        passVaidationMsg        = PasswordValidator( passToValidate );

        this.setState({
            passVaidationMsg
        });

        return !( passVaidationMsg.length > 0 );
    }

    listInvalidPasswordError = () => {
        const passInvalidRulesError = this.state.passVaidationMsg;
        const invalid_pass_error_list = passInvalidRulesError.map( ( error, idx ) => {
            return <li key={idx}>{error}</li>;
        });
        return invalid_pass_error_list;
    }

    resetAlert = () => {
        this.setState({
            responseMsg: ''
        });
    }

    render() {
        if ( this.state.isSubmitted && this.state.isSuccess ) {
            const message = this.state.responseMsg;
            return <Redirect to={ { 
                pathname: "/login", 
                state: { message, success: true } 
            }} />;
        }

        const pass_classes      = ( this.state.isValid.password ) ? '' : 'form-error',
        cpass_classes           = ( this.state.isValid.cPassword ) ? '' : 'form-error',
        submitDisabled          = !this.isFormValid(),
        errorMsg                = ( this.state.isSubmitted && !this.state.isSuccess ) ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} onClose={this.resetAlert} /> : '',
        invalid_pass_error_list = this.listInvalidPasswordError();

        return (
            <div className="modal-body usm-modal-body ResetPassword">
                { errorMsg }
                <h2 className="marginB10">Reset Password</h2>
                <div className="width-container">
                    <div className="static-alert text-align-center marginB30">{ __x( `Please enter your new password, and verify it, in the fields below.` ) }</div>
                    <form action="" className="usm-form" onSubmit={ event => this.handleSubmit( event ) }>
                        <div className={`form-group ${pass_classes}`}> 
                            <input type="password" className={`form-control ${pass_classes}`} id="password" value={ this.state.fields.password } onChange={ event => this.handleChange( event )} placeholder="Create New Password." />
                            { pass_classes === '' ||
                                <div className="display-error zIndex3"><ul>{invalid_pass_error_list}</ul></div>
                            }
                        </div>
                        <div className={`form-group ${cpass_classes}`}>
                            <input type="password" className={`form-control ${cpass_classes}`} id="cPassword" value={ this.state.fields.cPassword } onChange={ event => this.handleChange( event )} placeholder="Confirm New Password." />
                            { cpass_classes === '' ||
                                <div className="display-error">{ __x( `The entered passwords do not match.` ) }</div>
                            }
                        </div>
                        <button type="submit" className="btn btn-success btn-block marginLR0" disabled={submitDisabled}>Save new password</button>
                    </form>
                </div>
            </div>
        );
    }

    /**
     * Sets document title
     */
    componentDidMount = () => {
        document.title = this._page_title;
    }
}
