import React from 'react';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import { get_authorization_token, __x } from '../../../helpers/GeneralHelper';
import Alert from '../../../helpers/Alert';

class UpdateCareerHighlights extends React.Component {
    constructor( props ) {
        super( props );
        
        this._player_id = props.player_id;
        this.state      = {
            fields          : {
                0 : ''
            },
            isValid         : {
                0 : false
            },
            error           : {
                0 : ''
            },
            highlights      : [],
            deleting_ids    : [],
            isProcessing    : false,
            isSubmitted     : false,
            isSuccess       : false,
            responseMsg     : '',
        }
    }

    componentWillReceiveProps = props => {
        const fields    = { 0 : '' },
        error           = this.state.error,
        highlights      = props.highlights,
        isValid         = this.state.isValid;

        /**
         * Set states to fill the saved data to be edited.
         */
        for ( let h in highlights ) {
            if ( !highlights[ h ] ) {
                continue;
            }

           /**
            * Set field's value in state.
            */
           fields[ h ]    = highlights[ h ].highlight ? highlights[ h ].highlight : '';
           isValid[ h ]   = highlights[ h ].highlight && highlights[ h ] !== '';
           error[ h ]     = '';
        }
        
        this.setState({ 
            highlights,
            fields, isValid, error
        });
    }

    /**
     * Adds new highlight field.
     */
    addHighlight = () => {
        const fields    = this.state.fields,
            isValid     = this.state.isValid,
            error       = this.state.error;
        
        const sorted_keys = Object.keys( fields ).length ? Object.keys( fields ).sort( ( a, b ) => a - b ) : [];
        const key = Object.keys( fields ).length ? sorted_keys[0] - 1 : 0;
        fields[ key ]    = '';
        isValid[ key ]   = false;
        error[ key ]     = '';

        this.setState({
            fields,
            isValid,
            error
        })
    }

    /**
     * Handles fields changes.
     */
    handleChange = event => {
        const elt   = event.target,
        name        = elt.name,
        value       = elt.value,
        fields      = this.state.fields,
        isValid     = this.state.isValid,
        error       = this.state.error;
        
        fields[ name ]  = value;
        isValid[ name ] = fields[ name ] !== '' && ( value.length > 0 && value.length <= 255 );
        error[ name ]   = fields[ name ] !== '' && !isValid[ name ] ? __x( `Highlight is not valid.` ) : '';

        this.setState({ fields, isValid, error });
    }

    /**
     * Checks if form fields are valid.
     */
    isFormValid = () => {
        let validityErr = [];
        const fields    = this.state.fields,
        isValid         = this.state.isValid;
        
        for ( let elt in fields ) {
            if ( isValid[ elt ] && fields[ elt ] !== '' && fields[ elt ] !== null ) {
                continue;
            }

            validityErr.push( elt );
        }

        return validityErr.length <= 0;
    }

    /**
     * Handles add/update career highlights.
     */
    updateHighlights = event => {
        event.preventDefault();

        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( !this.isFormValid() ) {
            return false;
        }

        this.setState({
            isProcessing: true
        });
        

        const fields    = this.state.fields,
        highlight_path  = APIRoutes.player_hightlight;
        let highlight   = [];

        const highlight_order = Object.keys( fields ).sort( ( a, b ) => a - b );

        highlight = highlight_order.map( key => fields[ key ] );

        let new_state   = {};
        const body = {
            player_id : this._player_id,
            highlight
        };

        /**
         * Sending request to add/update clubs.
         */
        const request = new Request( highlight_path, body, 'POST', headers ).send();
        request.then( response => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message
            };

            this.setState( new_state );

            /**
             * Refresh list of career highlights.
             */
            if ( response.success ) {
                this.props.refresh();
                this.closePoppup();
            }            
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMsg : error.message
            };
            this.setState( new_state );
        });
    }

    closePoppup = () => {
        this.clearFields();
        this.props.togglePoppup();
    }

    clearFields = () => {
        this.setState({
            fields          : {
                0 : ''
            },
            isValid         : {
                0 : ''
            },
            error           : {
                0 : ''
            },
            deleting_ids: [],
        });
    }

    /**
     * Deletes highlights from local fields.
     */
    deleteLocalField = ( deleting_id ) => {
        const fields = this.state.fields,
        deleting_ids = this.state.deleting_ids;

        if ( Object.keys( fields ).length === 1 && !this.state.highlights.length ) {
            return false;
        }

        if ( fields[ deleting_id ] !== '' ) {
            deleting_ids.push( deleting_id );
        }        
        
        delete fields[ deleting_id ];
        
        this.setState({
            fields,
            deleting_ids
        });
    }

    /**
     * List all the new fields added without save.
     */
    fieldsList = () => {
        const fields = this.state.fields,
        sorted_keys = Object.keys( fields ).sort( ( a, b ) => a - b );
        
        return sorted_keys.map( ( field_idx ) => {
            return (
                <div className="input-delete-box form-group" key={ field_idx }>
                    <input onChange={ this.handleChange } value={ this.state.fields[ field_idx ] } type="text" className="form-control" placeholder="Add New Highlight Text" name={ field_idx } />
                    <button onClick={ () => this.deleteLocalField( field_idx ) } type="button" className="transparent-btn float-right">
                        <img src={ require( "../../../assets/images/close-icon.png" ) } alt="" />
                    </button>
                    { this.state.error[ field_idx ] && this.state.error[ field_idx ] !== '' ? <div className="display-error custome-error-top44">{ this.state.error[ field_idx ] }</div> : '' }
                </div>
            );
        });
    }

    resetAlert = () => {
        this.setState({
            responseMsg: ''
        });
    }

    render = () => {
        const showModal = this.props.popupOpen ? 'show' : '',
        disabledSubmit  = !this.isFormValid(),
        alert_messages  = this.state.responseMsg !== '' ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} floating="true" onClose={this.resetAlert} /> : '';
        return (
            <div>
                {alert_messages}

                <div className={`modal form-modal fade modal-active ${showModal}`} style={{ transform: this.props.popupOpen ? 'translate(0,0)' : 'translate(-125%, -125%)' }} >
                    <div className="modal-dialog modal-dialog-centered maxWidth600">
                        <div className="modal-content">
                            <div className="modal-header form-modal-header d-flex align-items-center">
                                <img src={require("../../../assets/images/form-usm-logo.png")} alt="" />
                                <h5 className="form-modal-title">Career Highlights</h5>
                                <button onClick={ this.props.togglePoppup } type="button" className="modal-close" data-dismiss="modal" aria-label="Close">
                                    <img src={require("../../../assets/images/close-modal.png")} alt="" />
                                </button>
                            </div>
                            <div className="modal-body form-modal-body">
                                <form onSubmit={ this.updateHighlights } action="" className="usm-form form-border">
                                    <div className="row form-top-buttons">
                                        <div className="col-md-12">
                                            <button onClick={ this.addHighlight } type="button" className="transparent-btn float-right top-browse-btn">
                                                <img src={ require( "../../../assets/images/career-heighlight.png" ) } alt="" />ADD A Highlight
                                            </button>
                                        </div>
                                    </div>
        
                                    <div className="row">
                                        <div className="col-md-12">
                                            { this.fieldsList() }
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer form-modal-footer d-flex justify-content-between">
                                <button type="button" className="default-btn btn-danger" data-dismiss="modal" onClick={ this.closePoppup }>Cancel</button>
                                <button onClick={ this.updateHighlights } type="submit" className="default-btn btn-success" disabled={disabledSubmit}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default UpdateCareerHighlights;