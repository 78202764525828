import React from 'react';
import Alert from '../../../helpers/Alert';
import { get_user_data, get_current_user_role, get_user_dashboard_url, __x, delete_user_data } from '../../../helpers/GeneralHelper';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import PasswordValidator from '../../../helpers/PasswordValidator';
import { Link } from 'react-router-dom';
import IntlTelInput from 'react-intl-tel-input';
import '../../../../node_modules/react-intl-tel-input/dist/main.css';

export default class Profile extends React.Component {
    
    _page_title = 'User Profile - USM';

    constructor( props ) {
        super( props );
        
        /**
         * Set refernce for intel-tel-input for setting country flag.
         */
        this._country = React.createRef();

        this._is_fetched = {
            get_user_profile : false,
        }

        this.state = {
            user_id : props.match.params.id,
            fields  : {
                first_name: '',
                last_name: '',
                email: '',
                country_code : '',
                phone: '',
                current_password : '',
                new_password : '',
                confirm_password : ''
            },
            isValid : {
                first_name  : true,
                last_name   : true,
                email       : true,
                phone       : true,
                country_code : true,
                current_password : true,
                new_password     : true,
                confirm_password : true
            },
            country_iso             : 'gb',
            isProcessing            : false,
            isSubmitted             : false,
            isSuccess               : false,
            responseMsg             : '',
            pswdFieldsActive        : false,
            newPswdValidationErrs   : []
        }
    }

    handleFormSubmit = ( event ) => {
        event.preventDefault();
        const token = get_user_data( 'token' );

        if ( !token ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( !this.isFormValid() ) {
            return false;
        }

        this.setState({
            isProcessing: true
        });

        const user_path   = APIRoutes.user_profile,
        headers     = {
            "Authorization": token
        }

        const fields    = this.state.fields;
        let new_state   = {};
        let formData    = new FormData();

        for ( let i in fields ) {
            if( i === 'current_password' || i === 'new_password' || i === 'confirm_password') {
                continue;
            }

            formData.append( i, fields[i] );
        }
        
        formData.append( 'country_iso', this.state.country_iso );
        
        /**
         * Sending request to add/update users.
         */
        const request = new Request( user_path, formData, 'PUT', headers, true ).send();
        request.then( response => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message
            }
            this.setState( new_state );
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMsg     : error.message
            };
            this.setState( new_state );
        });
    }

    handleFieldsChanges = ( event ) => {
        const target  = event.target,
        elt     = target.name,
        value   = target.value;
        
        let fields  = { ...this.state.fields },
        isValid     = { ...this.state.isValid };

        fields[ elt ]   = value;
        
        if ( elt === 'new_password' ) {
            this.setState({
                newPswdValidationErrs: []
            });
        }

        isValid.current_password    = elt === 'current_password' && fields.current_password !== '' ? value.length >= 8 && value.length <= 255 : true;

        if ( elt === 'new_password' ) {
            const isPswdValid   = this.isPasswordValid( value );
            isValid[ elt ]      =  fields.new_password !== '' ? ( value.length >= 8 && value.length <= 255 && isPswdValid ) : true;
        }
        
        isValid.confirm_password =  fields.confirm_password !== '' ? ( fields.confirm_password === fields.new_password ) : true;
        this.setState({ fields, isValid });
    }

    /**
     * Checks if password is valid or not.
     */
    isPasswordValid = ( passToValidate = '' ) => {
        let passVaidationErrs    = this.state.newPswdValidationErrs;

        /**
         * Validating password
         */
        passVaidationErrs        = PasswordValidator( passToValidate );
        
        if ( passToValidate === this.state.fields.current_password ) {
            passVaidationErrs.push( __x( `Your new password is the same as the current password. Please enter a different password.` ) );
        }
        
        this.setState({
            newPswdValidationErrs: passVaidationErrs
        });
        
        return !( passVaidationErrs.length > 0 );
    }
    
    isFormValid = () => {
        return this.state.fields.phone !== '' && this.state.isValid.phone 
        && this.state.fields.country_code !== '' && this.state.isValid.country_code;
    }
    
    listPswdErros = () => {
        const errors = this.state.newPswdValidationErrs;

        const errorsList = errors.map( ( error, idx ) => {
            return <li key={idx}>{error}</li>;
        });
        return errorsList;
    }

    resetAlert = () => {
        this.setState({
            responseMsg: ''
        });
    }

    /**
     * Handles country code changes
     */
    handleCountryCodeChange = ( value, countryData ) => {
        const fields    = this.state.fields,
        isValid         = this.state.isValid,
        country_code    = countryData? countryData.dialCode : '44';
        
        fields[ 'country_code' ]    = country_code;
        isValid[ 'country_code' ]   = fields.country_code !== '' ? fields.country_code.toString().length >= 0 && fields.country_code.toString().length <= 255 : true;

        this.setState({
            fields,
            isValid,
            country_iso: countryData.iso2
        });
    }

    /**
     * Handles phone and country code changes
     */
    handlePhoneChange = ( status, value ) => {
        const fields    = this.state.fields,
        isValid         = this.state.isValid,
        phone           = value ? value : '';
        
        fields[ 'phone' ]           = phone;        
        isValid[ 'phone' ]          = fields.phone !== '' ? fields.phone.length >= 0 && fields.phone.length <= 255 && !isNaN( fields.phone ) : true;

        this.setState({
            fields,
            isValid
        });
    }

    render = () => {
        const submidtDisabled   = this.isFormValid() ? false : true,
        currentUserRole         = get_current_user_role(),
        readOnlyField           = true,
        changePasswordVibility  = this.state.pswdFieldsActive ? 'active' : '',
        first_name_err_classes  = this.state.isValid.first_name ? '' : 'form-error',
        last_name_err_classes   = this.state.isValid.last_name ? '' : 'form-error',
        email_err_classes       = this.state.isValid.email ? '' : 'form-error',
        phone_err_classes       = this.state.isValid.phone ? '' : 'form-error',
        current_pswd_err_classes= this.state.isValid.current_password ? '' : 'form-error',
        new_pswd_err_classes    = this.state.isValid.new_password ? '' : 'form-error',
        new_pswd_err_list       = new_pswd_err_classes !== '' ? <ul>{this.listPswdErros()}</ul> : '',
        confirm_pswd_err_classes= this.state.isValid.confirm_password ? '' : 'form-error',
        responseMsg             = this.state.responseMsg !== '' && this.state.isSubmitted ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} floating="true" onClose={this.resetAlert} /> : '',
        passwordSubmitDisabled = this.isPasswordFormValid() ? false : true;
        
        return (
            <div id="mainContainer">
                {responseMsg}
                <div className="heading-section d-flex align-items-center">
                    <h2>My Profile</h2>
                </div>

                <div className="content-section form-modal">	
                    <form action="" className="usm-form form-border padding0" onSubmit={this.handleFormSubmit}>
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className={`form-group ${first_name_err_classes}`}>
                                    <label htmlFor="">First Name</label>
                                    <input name="first_name" value={this.state.fields.first_name} onChange={ this.handleFieldsChanges } readOnly={readOnlyField} type="text" className="form-control" placeholder="First Name" />
                                    { first_name_err_classes === '' || <div className="display-error">First Name is invalid.</div> }
                                </div>
                            </div>
                        
                            <div className="col-md-6 col-sm-12">
                                <div className={`form-group ${last_name_err_classes}`}>
                                    <label htmlFor="">Last Name</label>
                                    <input name="last_name" value={this.state.fields.last_name} onChange={ this.handleFieldsChanges } readOnly={readOnlyField} type="text" className="form-control" placeholder="Last Name" />
                                    { last_name_err_classes === '' || <div className="display-error">First Name is invalid.</div> }
                                </div>
                            </div>
                        </div>	
                        <div className="row marginB30">
                            <div className="col-md-6 col-sm-12">
                                <div className={`form-group ${email_err_classes}`}>
                                    <label htmlFor="">Email</label>
                                    <input name="email" value={this.state.fields.email} onChange={ this.handleFieldsChanges } type="text" readOnly={readOnlyField} className="form-control" placeholder="Email" />
                                    { email_err_classes === '' || <div className="display-error">Email is invalid.</div> }
                                </div>
                            </div>
                        
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="phone">Phone</label>
                                    <div className={`input-group ${phone_err_classes}`}>
                                        <IntlTelInput 
                                            onPhoneNumberChange={ this.handlePhoneChange }
                                            defaultCountry={ 'gb' }
                                            css={['intl-tel-input w-100', 'form-control']} 
                                            placeholder="Phone"
                                            value={this.state.fields.phone}
                                            separateDialCode="true"
                                            onSelectFlag={ this.handleCountryCodeChange }
                                            ref = { elt => this._country = elt }
                                            disabled={ currentUserRole === 'Agent' ? true : false }
                                        />
                                    </div>
                                    { phone_err_classes === '' || <div className="display-error">Phone is invalid.</div> }				
                                </div>
                            </div>
                        </div>                       	
                        
                        { get_current_user_role() !== 'Agent' ?
                        <div className="d-flex justify-content-md-center">
                            <Link to={get_user_dashboard_url()} className="default-btn btn-danger" data-dismiss="modal">Cancel Changes</Link>
                            <button onClick={this.handleFormSubmit} type="submit" className="default-btn btn-success marginL10" disabled={submidtDisabled}>Save Changes</button>
                        </div> 
                        : ''
                        }
                        </form> 

                        <div className="border-bottom-trans marginTB34"></div>

                        <form action="" className="usm-form form-border padding0" onSubmit={this.handlePasswordChange}>

                        <div className="row">
                            <div className="col col-sm-12">
                                <div className="usm-form-subtext text-transform-uppercase text-right marginB25">
                                    <button className="transparent-btn text-white" type="button" onClick={() => this.setState({ pswdFieldsActive: !this.state.pswdFieldsActive })}>Change Password?</button>
                                </div>
                            </div>
                        </div>  

                        <div className={`change-password ${changePasswordVibility}`}>
                            <div className="row marginB30">
                                <div className="col-md-4 col-sm-12">
                                    <div className={`form-group ${current_pswd_err_classes}`}>
                                        <label htmlFor="">Current Password</label>
                                        <input name="current_password" onChange={ this.handleFieldsChanges } type="password" className="form-control" placeholder="Current Password" />
                                        { current_pswd_err_classes === '' || <div className="display-error">Current Password invalid.</div> }
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className={`form-group ${new_pswd_err_classes}`}>
                                        <label htmlFor="">New Password</label>
                                        <input name="new_password" onChange={ this.handleFieldsChanges } type="password" className="form-control" placeholder="New Password" />
                                        { new_pswd_err_list === '' || <div className="display-error">{new_pswd_err_list}</div> }
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className={`form-group ${confirm_pswd_err_classes}`}>
                                        <label htmlFor="">Confirm Password</label>
                                        <input name="confirm_password" onChange={ this.handleFieldsChanges } type="password" className="form-control" placeholder="Confirm Password" />
                                        { confirm_pswd_err_classes === '' || <div className="display-error">The entered passwords do not match.</div> }
                                    </div>					
                                </div>
                            </div>

                            <div className="d-flex justify-content-md-center">
                                <Link to={get_user_dashboard_url()} className="default-btn btn-danger" data-dismiss="modal">Cancel Changes</Link>
                                <button onClick={this.handlePasswordChange} type="submit" className="default-btn btn-success marginL10" disabled={passwordSubmitDisabled}>Save Changes</button>
                            </div> 
                        </div> 
                                           
                    </form>
                </div>	
            </div>	
        );
    }

    componentDidMount = () => {
        document.title = this._page_title;

        if(!this._is_fetched.get_user_profile) {
            this.getUserProfile();
        }

    }

    getUserProfile = () => {
        const token = get_user_data( 'token' );

        if ( !token ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const user_path = APIRoutes.user_profile;
        const headers = {
            "Authorization" : token
        }
        const request = new Request( user_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                const user = response.user;
                if ( user && Object.keys( user ).length > 0 ) {
                    this.setState({
                        fields: {
                            ...this.state.fields,
                            first_name  : user.first_name ? user.first_name : '',
                            last_name   : user.last_name ? user.last_name : '',
                            email       : user.email ? user.email : '',
                            country_code: user.country_code ? user.country_code : '44',
                            phone       : user.phone ? user.phone : ''
                        },
                        country_iso     : user.country_iso ? user.country_iso : 'gb'
                    }, () => this._country.setFlag( this.state.country_iso ) );
                }
                this._is_fetched.get_user_profile = true;
            }
        }).catch( error => {
            console.log( error.status, error.statusText );
        });
    }

    /**
     * check all password fields
     */
    isPasswordFormValid = () => {
        let validityErr = [];
        const fields = this.state.fields,
            isValid = this.state.isValid;

        for ( let i in fields ) {
            if( i !== 'current_password' && i !== 'new_password' && i !== 'confirm_password' ) {
                continue;
            }

            if ( fields[i] === '' || !isValid[i] ) {
                validityErr.push(i);
            }
        }
        
        return validityErr.length <= 0;
    }

    handlePasswordChange = (event) => {
        event.preventDefault();
        const token = get_user_data( 'token' );

        if ( !token ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( !this.isPasswordFormValid() ) {
            return false;
        }

        this.setState({
            isProcessing: true
        });

        const user_path   = APIRoutes.change_password,
        headers     = {
            "Authorization": token
        }

        const fields    = this.state.fields;
        let new_state   = {};
        let formData    = new FormData();

        for ( let i in fields ) {
            if( i === 'current_password' || i === 'new_password' || i === 'confirm_password')
                formData.append( i, fields[i] );
        }
        
        /**
         * Sending request to add/update users.
         */
        const request = new Request( user_path, formData, 'PUT', headers, true ).send();
        request.then( response => {
            if( response.success && response.is_logged_out ) {
                delete_user_data( 'user' );
                delete_user_data( 'token' );
                this.props.history.push('/login', { message: response.message, success: true });
            } else {
                new_state = {
                    isProcessing    : false,
                    isSubmitted     : true,
                    isSuccess       : response.success,
                    responseMsg     : response.message
                };
                this.setState( new_state );
            }
        }, error => {
            console.log( error );
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMsg     : error.message
            };
            this.setState( new_state );
        });
    }
}