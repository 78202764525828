import React from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../Auth/ProtectedRoutes';
import StaffDashboard from './StaffDashboard';
import Header from '../Header';
import Users from './users/Users';
import { tokenValidate } from '../../actions/AuthAction';
import { withRouter } from "react-router-dom";
import Clubs from './Clubs';
import ClubDetails from './ClubDetails';
import Agents from './agent/Agents';
import UserProfile from './users/Profile';
import AgentDetails from './agent/AgentDetails';
import Players from './players/Players';
import AddPlayer from './players/AddPlayer';
import AddClubCase from './case/AddClubCase';
import NotesAndConcierge from './players/NotesAndConcierge';
import Cases from './case/Cases';
import PlayerDetails from './players/PlayerDetails';
import AddPlayerCase from './case/AddPlayerCase';
import EditPlayerCase from './case/EditPlayerCase';
import ClubCaseView from '../common/ClubCaseView';
import PlayerCaseView from '../common/PlayerCaseView';
import SearchDashboard from './search/SearchDashboard';
import AdminDashboard from '../admin/Dashboard';
import PushNotifications from '../admin/settings/PushNotifications';
import OrganisationsAndPeople from './organisations-people/OrganisationsAndPeople';
import LoginHistory from '../admin/settings/LoginHistory';
import OrganisationDetails from './organisations-people/OrganisationDetails';
import ChangeLogs from '../admin/settings/ChangeLogs';

/**
 * Wrapper component for wrapping up the admin/staff sections by defining routes
 */
const Wrapper = () => {
    return (
        <div>
            <Header />
            <Switch>
                <ProtectedRoute exact path="/admin/dashboard" component={AdminDashboard} accessibleTo="Admin" />
                <ProtectedRoute exact path="/staff/dashboard" component={StaffDashboard} />
                <ProtectedRoute exact path="/admin/users" component={Users} accessibleTo="Admin" />
                <ProtectedRoute exact path="/user/profile" component={UserProfile} />
                
                {/* Agents' Routes */}
                <ProtectedRoute exact path="/admin/agents" component={Agents} accessibleTo="Admin" />
                <ProtectedRoute exact path="/admin/agent-details/:agent_id" component={AgentDetails} accessibleTo="Admin" />
                
                {/* Clubs' Routes */}
                <ProtectedRoute exact path="/clubs" component={Clubs} />
                <ProtectedRoute exact path="/clubs-details/:id" component={ClubDetails} />
                
                {/* Plyers' Routes */}
                <ProtectedRoute exact path="/admin/players" component={Players} />
                <ProtectedRoute exact path="/admin/add-player" component={AddPlayer} />
                <ProtectedRoute exact path="/admin/player-notes-and-concierge/:player_id" component={NotesAndConcierge} /> 
                <ProtectedRoute exact path="/players-details/:player_id" component={PlayerDetails} />
                <ProtectedRoute exact path="/admin/player-highlight-list/:player_id" component={PlayerDetails} /> 
                <ProtectedRoute exact path="/admin/player-videos-and-reports/:player_id" component={PlayerDetails} />
                <ProtectedRoute exact path="/admin/player-contacts/:player_id" component={PlayerDetails} />
                <ProtectedRoute exact path="/admin/player-cases/:player_id" component={PlayerDetails} />

                {/* Cases' Routes */}
                <ProtectedRoute exact path="/admin/cases" component={Cases} />
                <ProtectedRoute exact path="/admin/add-club-case" component={AddClubCase} />
                <ProtectedRoute exact path="/admin/edit-club-case/:case_id" component={AddClubCase} />
                <ProtectedRoute exact path="/admin/club-case/:case_id" component={ClubCaseView} />
                <ProtectedRoute exact path="/admin/add-player-case" component={AddPlayerCase} />
                <ProtectedRoute exact path="/admin/edit-player-case/:case_id" component={EditPlayerCase} />
                <ProtectedRoute exact path="/admin/view-player-case/:case_id" component={PlayerCaseView} />
                
                <ProtectedRoute exact path="/admin/search-dashboard" component={SearchDashboard} />


                {/* Organisation Routes */}
                <ProtectedRoute exact path="/organisations" component={OrganisationsAndPeople} />
                <ProtectedRoute exact path="/organisations-details/:id" component={OrganisationDetails} />

                {/* Settings */}
                <ProtectedRoute exact path="/admin/push-notifications" component={PushNotifications} accessibleTo="Admin" />
                <ProtectedRoute exact path="/admin/login-history" component={LoginHistory} accessibleTo="Admin" />
                <ProtectedRoute exact path="/admin/change-logs" component={ChangeLogs} accessibleTo="Admin" />   

                {/* Agent notes section which have to display in admin section */}
                <ProtectedRoute exact path="/admin/notes" component={StaffDashboard} accessibleTo="Admin" />             
            </Switch>
        </div>
    );
}

const mapStateToProps = ( state ) => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    tokenValidate   : ( validity ) => dispatch( tokenValidate( validity ) )
});

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( Wrapper ) );