import React from 'react';
import { get_display_format_date, is_valid_db_date } from '../../../helpers/GeneralHelper';
import CaseAgentRollOver from '../case/CaseAgentRollOver';


const ActiveCases = ({ active_case, active_widgets, idx }) => {

    let visible = '';

    if (active_widgets.length > 0) {
        for (let i in active_widgets) {
            if (active_widgets[i].id === active_case.id) {
                visible = `visible`;
            }
        }
    } else {
        visible = idx > 3 ? '' : `visible`;
    }

    return (
        <div className={`col-md-3 col-sm-6 col-xs-12 ${visible}`}>
            <div className="card">
                <div className="card-body">
                    <div className="cardImg-section">
                        <div className="card-image">
                            <img className="club" src={active_case.club && active_case.club.image_path && active_case.club.image_path !== '' ? active_case.club.image_path : active_case.player && active_case.player.image_path && active_case.player.image_path !== '' ? active_case.player.image_path : require(`../../../assets/images/club.png`)} alt="" />
                        </div>
                        <div className="cardTitle-section" title={`${active_case.club && Object.keys(active_case.club).length > 0 ? active_case.club.club_name : active_case.player && Object.keys(active_case.player).length > 0 ? active_case.player.first_name + " " + active_case.player.last_name : ''} (${active_case.master_position && Object.keys(active_case.master_position).length > 0 ? active_case.master_position.title : ''})`}>
                            <div className="card-title">{active_case.club && Object.keys(active_case.club).length > 0 ? active_case.club.club_name : active_case.player && Object.keys(active_case.player).length > 0 ? active_case.player.first_name + " " + active_case.player.last_name : ''}</div>
                            <div className="card-subtitle">{active_case.master_position && Object.keys(active_case.master_position).length > 0 ? active_case.master_position.title : ''}</div>
                        </div>
                    </div>
                    <div className="cardContent-section">
                        <div className="d-flex card-text marginB15">
                            <div className="mr-auto text-success fontBold">No of Agents</div>
                            <div className="fontBold text-success show-rollover position-relative">{active_case.case_agents ? active_case.case_agents.length : ''}
                                {active_case.case_agents.length > 0 ? <CaseAgentRollOver case_id={active_case.id} agents={active_case.case_agents} /> : ''}</div>
                        </div>
                        <div className="d-flex card-text marginB15">
                            <div className="mr-auto">Case ID</div>
                            <div className="fontBold">{active_case.case_number && active_case.case_number !== '' ? active_case.case_number : ''}</div>
                        </div>
                        {
                            active_case.club_id && (active_case.club_id !== null || active_case.club_id !== '') ?
                            <div className="d-flex card-text marginB20">
                                <div className="mr-auto">Fulfilment Date</div>
                                <div className="fontBold">{active_case.fulfillment_date && active_case.fulfillment_date !== '' && is_valid_db_date(active_case.fulfillment_date) ? get_display_format_date(active_case.fulfillment_date) : ''}</div>
                            </div> : ''
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}
export default ActiveCases;

