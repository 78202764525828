import { get_user_data } from '../helpers/GeneralHelper';

const initialState = {
    token           : !get_user_data( 'token' ) ? "" : get_user_data( 'token' ),
	currentUser     : !get_user_data( 'user' ) ? {} : get_user_data( 'user' ),
    isUserLoggedIn  : false,
}

export default ( state = initialState, action ) => {
	switch ( action.type ) {
		case 'LOGIN_SUCCESS':
			return {
				...state,
                token       : action.payload.token,
                currentUser : action.payload.user,
                isUserLoggedIn: true
			}
        case 'LOGIN_FAILED':
            return {
                ...state
            };
		default:
			return state
	}
}