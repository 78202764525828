import React from 'react';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import Alert from '../../../helpers/Alert';
import IntlTelInput from 'react-intl-tel-input';
import '../../../../node_modules/react-intl-tel-input/dist/main.css';
import * as EmailValidator from 'email-validator';
import { withRouter } from 'react-router-dom';
import { setCurrentUser } from '../../../actions/UsersAction';
import { connect } from 'react-redux';
import { get_authorization_token, __x } from '../../../helpers/GeneralHelper';
import DeletePlayerInfoConfirm from '../players/DeletePlayerInfoConfirm';

class UpdateAgent extends React.Component {
    constructor( props ) {
        super( props );

        this._country   = React.createRef();
        this._agent_id  = props.match.params.agent_id;

        this.state = {
            agent : {},
            fields          : {
                first_name  : '', 
                last_name   : '', 
                email       : '', 
                status      : '', 
                country_code: '44', 
                phone       : '', 
            },
            isValid : {
                first_name  : true, last_name    : true, email   : true,
                status      : true, country_code  : true, phone   : true, 
            },
            error: {
                first_name  : '', last_name     : '', email   : '',
                status      : '', country_code  : '', phone   : '', 
            },
            country_iso     : 'gb',
            isProcessing    : false,
            isSubmitted     : false,
            isSuccess       : false,
            responseMsg     : '',
            tokenExpired    : false,
            openDeleteConfirmationPoppup : false,
        }
    }

    /**
     * Set gobla store's data into local state.
     */
    componentWillReceiveProps = props => {
        const agent = props.users.user;
        this.setState({
            agent: agent,
            fields : {
                first_name  : agent && agent.first_name ? agent.first_name : '',
                last_name   : agent && agent.last_name ? agent.last_name : '',
                email       : agent && agent.email ? agent.email : '',
                status      : agent ? agent.status === true ? "1" : '0' : '',
                country_code: agent && agent.country_code ? agent.country_code : '44',
                phone       : agent && agent.phone ? agent.phone : '',
            },
            country_iso : agent.country_iso
        });
        this._country.setFlag( agent.country_iso );
    }

    /**
     * Handles fields' changes.
     */
    handleFieldsChanges = event => {
        const target    = event.target,
        name            = target.name,
        value           = target.value,
        fields          = this.state.fields,
        isValid = this.state.isValid,
        error           = this.state.error;
        
        if ( name === 'email' ) {
            fields[ name ]  = value;
            isValid[ name ] = fields.email !== '' ? fields.email.length >= 1 && fields.email.length <= 255 && EmailValidator.validate( fields.email ) : true;
            error[ name ]   = !isValid[ name ] ? __x( `Please enter a valid email.` ) : '';
        } else {
            fields[ name ]  = value;
            isValid[ name ] = fields[ name ] !== '' ? fields[ name ].length >= 1 && fields[ name ].length <= 255 : true;
            error[ name ]   = !isValid[ name ] ? __x( `Maximum 255 characters allowed.` ) : '';
        }
        
        this.setState({ fields, isValid, error });
    }

    updateAgent = event => {
        event.preventDefault();
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( !this.isFormValid() ) {
            return false;
        }

        this.setState({
            isProcessing: true
        });

        const agent_path   = APIRoutes.agent + this._agent_id;

        const fields    =  this.state.fields;
        let new_state   = {},
        formData        = new FormData();
        for ( let i in fields ) {
            formData.append( i, fields[i] );
        }
        
        formData.append( 'users_role', "Agent" );
        formData.append( 'country_iso', this.state.country_iso );
        
        /**
         * Sending request to add/update users.
         */
        const request = new Request( agent_path, formData, 'PUT', headers, true ).send();
        request.then( response => {
            if ( response.success ) {
                this.onPopupClose();
                this.props.refresh();
            }
            
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message,
            }
            this.setState( new_state );            
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMsg     : error.message
            };
            this.setState( new_state );
        });

    }

    /**
     * Check if form is valid or not to submit
     */
    isFormValid = () => {
        let validityErr = [];
        const fields    = this.state.fields,
        isValid         = this.state.isValid;

        for ( let elt in fields ) {
            if ( fields[ elt ] === '' || !isValid[ elt ] ) {
                validityErr.push( elt );
            }
        }
        
        return validityErr.length <= 0;
    }

     /**
     * Handles country code changes
     */
    handleCountryCodeChange = ( value, countryData ) => {
        const fields    = this.state.fields,
        isValid         = this.state.isValid,
        country_code    = countryData? countryData.dialCode : '44';
        
        fields[ 'country_code' ]    = country_code;
        isValid[ 'country_code' ]   = fields.country_code && fields.country_code !== '' ? fields.country_code.toString().length >= 0 && fields.country_code.toString().length <= 255 : true;
        
        this.setState({
            fields,
            isValid,
            country_iso: countryData.iso2
        }, () => console.log( this.state.fields.country_code ));
    }
    
    /**
     * Handles phone changes
     */
    handlePhoneChange = ( status, value, countryData ) => {
        const fields    = this.state.fields,
        isValid         = this.state.isValid,
        error           = this.state.error,
        phone           = value ? value : '';
        
        fields[ 'phone' ]    = phone;
        isValid[ 'phone' ]   = fields.phone !== '' ? fields.phone.length >= 0 && fields.phone.length <= 255 && !isNaN( fields.phone ) : true;
        error[ 'phone' ]     = !isValid[ 'phone' ] ? __x( `Phone is invalid.` ) : '';
        
        this.setState({ fields, isValid, error });
    }

    onPopupClose = () => {
        this.clearFormFields();
        this.props.closePopup();
    }

    resetAlert = () => {
        this.setState({
            isSubmitted     : false,
            responseMsg : ''
        });
    }

    /**
     * Get current agent details.
     */
    getAgent = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const agent_path = APIRoutes.agent + this._agent_id;
        const request = new Request( agent_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                const agent = response.success ? response.users : {};
                this.props.setAgent( agent );
                this._fetched.agent = true;
            }
        }, error => {
            console.log( error );
        });
    }

    render = () => {
        const modalActiveClass  = this.props.modal ? 'show' : '',
        popup_tile              = `Edit Agent`,
        disableSubmit           = !this.isFormValid() || this.isProcessing ? true : false,
        responseMsg             = this.state.responseMsg !== '' && this.state.isSubmitted ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} floating="true" onClose={this.resetAlert} /> : '';
        
        return (
            <div>
                { responseMsg }
                <div className={`modal form-modal fade modal-active ${modalActiveClass}`} style={{ transform: this.props.modal ? `translate(0, 0)` : 'translate(-125%, -125%)' }}>
                    <div className="modal-dialog modal-dialog-centered maxWidth600">
                        <div className="modal-content">
                            <form action="" className="usm-form form-border" onSubmit={ e => { e.preventDefault(); } }>
                                <div className="modal-header form-modal-header d-flex align-items-center">
                                    <img src={ require( '../../../assets/images/form-usm-logo.png' ) } alt="" />
                                    <h5 className="form-modal-title">{popup_tile}</h5>
                                    <button type="button" className="modal-close" aria-label="Close" onClick={ this.props.closePopup }>
                                        <img src={ require( '../../../assets/images/close-modal.png' ) } alt="Close" />
                                    </button>
                                </div>
                                <div className="modal-body form-modal-body">
                                    <div className="row">
                                        <div className="col-md-6 col-xs-12">
                                            <div className={ this.state.error.first_name === '' ? `form-group` : `form-group form-error`}>
                                                <label htmlFor="">First Name</label>
                                                <input value={ this.state.fields.first_name } onChange={ e => this.handleFieldsChanges( e ) } className="form-control" name="first_name" type="text" placeholder="First Name" autoComplete="off" />
                                                { this.state.error.first_name === '' || <div className="display-error">{ this.state.error.first_name }</div> }
                                            </div>					
                                        </div>
                                        <div className="col-md-6 col-xs-12">
                                            <div className={ this.state.error.last_name === '' ? `form-group` : `form-group form-error`}>
                                                <label htmlFor="last_name">Last Name</label>
                                                <input value={ this.state.fields.last_name } onChange={ e => this.handleFieldsChanges( e ) } type="text" className="form-control" name="last_name" placeholder="Last Name" autoComplete="off" />
                                                { this.state.error.last_name === '' || <div className="display-error">{ this.state.error.last_name }</div> }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-xs-12">
                                            <div className={ this.state.error.email === '' ? `form-group` : `form-group form-error`}>
                                                <label htmlFor="email">Email</label>
                                                <input value={ this.state.fields.email } onChange={ e => this.handleFieldsChanges( e ) } type="text" className="form-control" name="email" placeholder="Email" autoComplete="off" />
                                                { this.state.error.email === '' || <div className="display-error">{ this.state.error.email }</div> }
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xs-12">                                            
                                            <div className={ this.state.error.phone === '' ? `form-group` : `form-group form-error`}>
                                                <label htmlFor="phone">Phone</label>
                                                <div className={`input-group`}>
                                                    <IntlTelInput 
                                                        ref={ elt => this._country = elt }
                                                        onPhoneNumberChange={ this.handlePhoneChange }
                                                        defaultCountry={ 'gb' }
                                                        css={['intl-tel-input w-100', 'form-control']} 
                                                        placeholder="Phone"
                                                        value={this.state.fields.phone}
                                                        separateDialCode="true"
                                                        onSelectFlag={ this.handleCountryCodeChange }
                                                    />
                                                </div>
                                                { this.state.error.phone === '' || <div className="display-error">{ this.state.error.phone }</div> }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className={`form-group`}>
                                                <label htmlFor="status">Status</label>
                                                <select name="status" className="form-control" value={ this.state.fields.status } onChange={ e => this.handleFieldsChanges( e ) } >
                                                    <option value=""></option>
                                                    <option value="1">Active</option>
                                                    <option value="0">Deactivated</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="modal-footer form-modal-footer d-flex justify-content-between">
                                    <button type="button" className="default-btn btn-danger min-width157" data-dismiss="modal" onClick={ this.props.closePopup }>Cancel</button>
                                    <button type="button" className="default-btn btn-danger min-width157" onClick={ this.toggleDeleteConfirmation }>Delete</button>
                                    <button type="button" onClick={ this.updateAgent } className="default-btn btn-success min-width157" disabled={disableSubmit}>Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                { !this.props.modal || <div className="modal-backdrop fade show"></div> }

                <DeletePlayerInfoConfirm 
                    modal={ this.state.openDeleteConfirmationPoppup }
                    onClose={ this.onCancelDelete }
                    onConfirm={ this.deleteAgent }
                    type="agent"
                />
            </div>
        );
    }

     /**
     * Open confirmation poppup.
     */
    toggleDeleteConfirmation = () => {
        this.setState({
            openDeleteConfirmationPoppup : !this.state.openDeleteConfirmationPoppup
        });
    }
   
    /**
     * Close popup on clicking cancel button
     */
    onCancelDelete = () => {
        this.toggleDeleteConfirmation();
        this.props.closePopup();
        this.clearFormFields();
    }

    /**
     * Delete agent.
     */
    deleteAgent = () => {

        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }
        
        let new_state = {};
        const agent_id = this._agent_id;
        const agent_path = APIRoutes.agent + agent_id;
        const request = new Request( agent_path, {}, 'DELETE', headers ).send();
        request.then( response => {
            if ( response.success ) {
                this.props.closePopup();
                this.toggleDeleteConfirmation();
                this.props.history.push('/admin/agents', { message: response.message, success: true });
            }

            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message
            }
            this.setState( new_state );
        }, error => {
            console.log( error );
        });
    }

    /**
     * Clear form fields
     */
    clearFormFields = () => {
        this.setState({
            fields          : {
                first_name  : '', 
                last_name   : '', 
                email       : '', 
                status      : '', 
                country_code: '44', 
                phone       : '', 
            },
            isValid : {
                first_name  : true, last_name    : true, email   : true,
                status      : true, country_code  : true, phone   : true, 
            },
            error: {
                first_name  : '', last_name     : '', email   : '',
                status      : '', country_code  : '', phone   : '', 
            },
            country_iso     : 'gb',
        });
        this._country.setFlag( 'gb' );
    }
}

const mapStateToProps = state => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setAgent: agent => dispatch( setCurrentUser( agent ) )
});

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( UpdateAgent ) );