
export const setUsers = ( users ) => dispatch => {
    dispatch({
        type: 'SET_USERS',
        payload: {
            users: users
        }
    });
}

export const appendUsers = (all_users, loaded_users) => dispatch => {
    dispatch({
        type: 'APPEND_USERS',
        payload: {
            users: [
                ...all_users,
                ...loaded_users
            ]
        }
    });
}

export const filterUser = ( role ) => dispatch => {
    dispatch({
        type: 'FILTER_USER',
        payload: {
            role: role
        }
    });
}

export const setCurrentUser = user => dispatch => {
    dispatch({
        type    : 'SET_CURRENT_USER',
        payload : {
            user
        }
    })
}

export const SetLoginHistoryList = login_history => dispatch => {
    dispatch({
        type    : 'SET_LOGIN_HISTORY_LIST',
        payload : {
            login_history
        }
    })
}

export const appendLoginHistoryList = (all_login_history, loaded_login_history) => dispatch => {
    dispatch({
        type: 'APPEND_LOGIN_HISTORY_LIST',
        payload: {
            login_history: [
                ...all_login_history,
                ...loaded_login_history
            ]
        }
    });
}