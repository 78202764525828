const initialState = {
    notes       : [],
    concierges  : [],
    player      : {}
}

export default ( state = initialState, action ) => {
	switch ( action.type ) {
        case 'SET_NOTES':
            return {
                ...state,
                notes: action.payload.notes
            };
        case 'SET_CONCIERGES':
            return {
                ...state,
                concierges: action.payload.concierges
            };
        case 'SET_CURRENT_PLAYER':
            return {
                ...state,
                player: action.payload.player
            };
		default:
			return state;
	}
}