import React from 'react';
import { get_authorization_token, get_current_user_full_name, get_current_user_role, get_user_data, __x } from '../../helpers/GeneralHelper';
import Case from './Case';
import APIRoutes from '../../API/Routes';
import Request from '../../helpers/Request';
import UpdateNote from '../admin-staff/common/UpdateNote';
import LoadingSpinner from '../admin-staff/common/LoadingSpinner';
import IntelNotesConfirmationPopup from '../common/IntelNotesConfirmationPopup';


class AgentDashboard extends React.Component {

    _page_title = `Agent's Dashboard - USM`;

    constructor( props ) {
        super( props );

        this._notes = React.createRef();
        this._current_user_role = get_current_user_role();
        this._logged_agent_id = get_user_data( 'user' ).id;

        this._fetched = {
            cases: false
        }

        this.state = {
            cases           : [],
            toggleShow      : false,
            dropDown        : false,
            isProcessing    : false,
            isSubmitted     : false,
            isSuccess       : false,
            responseMsg     : '',
            openIntelNotesConfirmationPoppup : false,
        };
    }

    /**
     * Get agent dashboard data.
     */
    getDashboardData = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const contacts_path = APIRoutes.agent_dashboard;
        const request = new Request( contacts_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                this.setState({
                    cases : response.data
                });
                this._fetched.cases = true;
            }
        }, error => {
            console.log( error );
        });
    }

    componentDidMount = () => {
        document.title = this._page_title;
        if ( !this._fetched.cases ) {
            this.getDashboardData();
        }
    }

    /**
     * Save notes globally and list them.
     */
    saveNotesGlobally= ( note_fields ) => {
        console.log(note_fields);

        
        const intel_note_path    = APIRoutes.add_agent_note + this._logged_agent_id;

        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess       : false
            });
            return false;
        }

        this.setState({
            isProcessing: true
        });

        let formData = new FormData(),
            new_state = {};

        formData.append( 'note', note_fields.note );
        for( let i in note_fields.files ) {
            formData.append( 'files', note_fields.files[i] );
        }
        const request = new Request(intel_note_path, formData, 'POST', headers, true).send();
        request.then(response => {
            if (response.success) {
                this._notes.toggleNotesPopup( true );
                new_state = {
                    isProcessing    : false,
                    isSubmitted     : true,
                    isSuccess       : response.success,
                    responseMsg     : response.message,
                    openIntelNotesConfirmationPoppup : true
                }
                this.setState( new_state );
            }
        }, error => {
            console.log(error);
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMsg     : error.message,
            }
            this.setState( new_state );
        });
    }

    closeConfirmationPopUp = () => {
        this.setState({
            openIntelNotesConfirmationPoppup : false
        });
    }


    render = () => {
        const cases = this.state.cases,
        cases_exist = cases && cases.length > 0;
        const loading  = this.state.isProcessing ? <LoadingSpinner/> : '';
        return (
            <div id="mainContainer" className="org-mobile">
               { loading }
                <div className="heading-section d-flex align-items-center">
                    <h2>Welcome back, {get_current_user_full_name()}</h2> 
                    <h6 className="ml-auto">
                        <img src={ require( '../../assets/images/active-edit-note.png' ) } alt="" className="align-bottom" /> 
                        <button className="text-white  transparent-btn text-upper" onClick={ this._notes.toggleNotesPopup }>Add Intel Note</button>
                    </h6>
                </div>
                <div className="content-section">
                    <div className="heading-section d-flex align-items-center">
                        My Cases <span className="badge badge-pill badge-success">{ cases_exist ? cases.length : 0 }</span>
                    </div>
                    <div className="players-section">
                        <div className="row usm-cards">
                            {
                                cases_exist ? cases.map( current_case => {
                                    return <Case key={ current_case.id } current_case={ current_case } />
                                }) : []
                            }
                        </div>
                    </div>
                </div>  
                <UpdateNote 
                
                    ref={ elt => this._notes = elt }
                    onSubmit={ this.saveNotesGlobally }
                    note={this.state.edit_note}
                    files="case_note_files"
                    userRole={this._current_user_role}
                />  

                <IntelNotesConfirmationPopup
                    modal={this.state.openIntelNotesConfirmationPoppup}
                    onConfirm={this.closeConfirmationPopUp}
                />
            </div>
        );
    }
}

export default AgentDashboard;