// Github Fetchpackage to support fetch api in lower capable browsers as well like IE
import { fetch } from 'whatwg-fetch';
import { get_user_data, get_current_browser } from './GeneralHelper';

export default class RequestAPI {

    REQ_URL     = process.env.REACT_APP_API_URL;
    
    REQ_HEADERS = {
        'Accept'        : 'application/json',
        "cache-control" : "no-cache",
        "ip-address"    : get_user_data( 'ip' ),
        "browser"       : get_current_browser()
    };
    
    REQ_RESPONSE;

    constructor( path, body, method = "GET", headers = {}, formData = false ) {
        this.REQ_URL        += path;
        this.REQ_METHOD     = method;
        this.REQ_HEADERS    = {
            ...this.REQ_HEADERS,
            ...headers
        };
        this.REQ_IS_FORMDATA    = formData;

        this.REQ_BODY           = body;
        if ( !formData && !headers[ 'Content-Type' ] && !headers[ 'content-type' ] ) {
            this.REQ_HEADERS = {
                ...this.REQ_HEADERS,
                "Content-Type"  : 'application/json'
            }
        }
    }
    
    /**
     * Calls `fetch` as per given parameters.
     */
    sendRequest = () => {
        const extraParams = {
            method: this.REQ_METHOD,
            headers: this.REQ_HEADERS
        };

        if( this.REQ_METHOD !== "GET" ) {
            extraParams[ 'body' ] = this.REQ_IS_FORMDATA ? this.REQ_BODY : JSON.stringify( this.REQ_BODY );
        }
        
        let request = fetch( this.REQ_URL, extraParams ).then( response => {
            if ( response.ok ) {
                return this.REQ_RESPONSE = response.json();
            }
            
            return response.json().then( err => { 
                throw err; 
            });
            /* if ( response.status === 422 ) {
                return response.json().then( err => { 
                    throw err; 
                });
            } else {
                console.log( `Error: ${response.statusText} for the url: ${this.REQ_URL}` );
                throw response;
            } */
            
        }, error => {
            throw error;
        }).catch( error => {
            throw error;
        });

        return request;
    }
}