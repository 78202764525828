import React from 'react';
import { __x, delete_user_data, get_authorization_token } from '../../helpers/GeneralHelper';
import { Redirect } from 'react-router-dom';
import APIRoutes from '../../API/Routes';
import Request from "../../helpers/Request";
import Alert from '../../helpers/Alert';

class Logout extends React.Component {
    
    constructor( props ) {
        super( props );

        this.state = {
            isConfirmed : false,
            isSubmitted : false,
            isSuccess   : false,
            responseMsg : ''
        }
    }
    
    onConfirm = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const request = new Request( APIRoutes.logout, {}, "GET", headers ).send();
        request.then( reponse => {
            if ( reponse.success ) {
                delete_user_data( 'user' );
                delete_user_data( 'token' );
                this.setState({
                    isConfirmed: true
                });
            }
        }, error => {
            delete_user_data( 'token' );
            delete_user_data( 'user' );
            this.setState({
                isConfirmed: true
            });
            // this.setState({
            //     isSubmitted : true,
            //     isSuccess   : error.success,
            //     responseMsg : error.message
            // });
        });
    }

    resetAlert = () => {
        this.setState({
            responseMsg : ''
        });
    }

    render = () => {
        if ( this.state.isConfirmed ) {
            return <Redirect to={{ pathname: '/login', state: { message: __x( `You've successfully logged out!!` ), success: true }  }} />
        }

        const modalActiveClass  = this.props.modal ? 'show modal-active' : '',
        error_msg               = this.state.responseMsg !== '' && this.state.isSubmitted ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} floating="true" onClose={this.resetAlert} /> : '';

        return (
            <div>
                {error_msg}
                <div className={`modal form-modal fade ${modalActiveClass}`}>
                    <div className="modal-dialog modal-dialog-centered maxWidth400">
                        <div className="modal-content">
                            <div className="modal-body form-modal-body">
                                <h5 className="form-modal-content text-white fontBold text-center">{ __x( 'Please confirm you want to log out of this account.' ) }</h5>
                            </div>
                            <div className="modal-footer form-modal-footer d-flex justify-content-between">
                                <button type="button" className="default-btn btn-danger" data-dismiss="modal" onClick={ this.props.onClose }>Cancel</button>
                                <button type="button" className="default-btn btn-success" onClick={this.onConfirm}>Log Out</button>
                            </div>
                        </div>
                    </div>
                </div>
                { !this.props.modal || <div className="modal-backdrop fade show"></div> }
            </div>
        );
    }
}
export default Logout;