import React, { Component } from 'react';
import { get_current_user_full_name, get_authorization_token, __x, get_current_user_role } from '../../helpers/GeneralHelper';
import APIRoutes from '../../API/Routes';
import Request from '../../helpers/Request';
import Notes from './Notes';
import UpdateNote from './common/UpdateNote';
import LoadingSpinner from './common/LoadingSpinner';
import Alert from '../../helpers/Alert';


export default class StaffDashboard extends Component {

    _page_title = get_current_user_role() === 'Admin' ? `Notes - USM` : `Staff's Dashboard - USM`;

    constructor( props ) {
        super( props );

        this._notes = React.createRef();
       
        this._is_fetched = {
            agent_list : false,
            notes_list : false,
        }
        this.state = {
            fields  : {
                note_type : '',
                agent_id  : '',
            },
            names   : {
                note_type : '',
                agent_id  : '',
            },
            agentOptions        : [],
            notes               : [],
            isError             : false,
            responseMsg         : '',
            isSuccess           : false,
            isUpdateNoteOpen    : false,
            is_editing          : false,
            edit_note           : {},
            files               : '',
            note_manage_by_staff : false,
            note_name           : '',
            note_id             : 0,
            isProcessing        : false,
            note_manage_by_admin : false,
        }
    }

    /**
     * get data for agent list dropdown
     */
    getAgentsOptions = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        const request = new Request(APIRoutes.get_agent_list, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                let agentOptions = this.state.agentOptions;

                this._is_fetched.agent_list = true;

                agentOptions = response.data;
                this.setState({
                    isError: false,
                    responseMsg: '',
                    isSuccess: true,
                    agentOptions,
                });
            }
        }, error => {
            console.log(error);
        });
    }

    /**
     * Get agent dashboard data.
     */
    getDashboardData = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const staff_notes_path = APIRoutes.staff_dashboard;
        const request = new Request( staff_notes_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                this.setState({
                    notes : response.data
                });
                this._is_fetched.notes_list = true;
            }
        }, error => {
            console.log( error );
        });
    }

    componentDidMount = () => {
        document.title = this._page_title;

        if(!this._is_fetched.agent_list)
        {
            this.getAgentsOptions();
        }

        if(!this._is_fetched.notes_list)
        {
            this.getDashboardData();
        }
    }

    /**
     * handle field value change
     */
    handleFieldsChanges = (event) => {
		const target = event.target,
			elt_name = target.name,
			elt_value = target.value,
            fields = this.state.fields,
            names = this.state.names;
            fields[elt_name] = elt_value;
            names[elt_name] = event.target.options[event.target.selectedIndex].text;

		this.setState({
            ...fields,
            fields,
            ...names,
            names,
		});
    }

    /**
     * filter notes
     */
    filterNotes = () => {
		const headers = get_authorization_token();
		if (!headers) {
			this.setState({
				isError: true,
				responseMsg: __x( `Session has been expired. Please reload the page to login!` ),
				isSuccess: false
			});
			return false;
		}
        const fields = this.state.fields;
        let query_string = '';
        
		if (fields.note_type && fields.note_type !== '') {
            query_string += (`note_type=` + fields.note_type + `&`);
		}

		if (fields.agent_id && fields.agent_id !== '') {
            query_string += (`agent=` + fields.agent_id + `&`);
		}
		const request = new Request(APIRoutes.staff_dashboard + '?' + query_string, '', 'GET', headers, true).send();
		request.then(response => {
			if (response.success) {
				this.setState({
					isError: false,
					responseMsg: '',
					isSuccess: true,
					fields: {
						...this.state.fields
                    },
                    notes : response.data,
				});

			}
		}, error => {
			console.log(error);
		});
    }
    
    /**
     * open update note overlay popup
     */
    openUpdateNote = ( event, note_id = 0, note_type = '' ) => {
        this.setState({
            isUpdateNoteOpen : true
        }, () => {
            if ( this.state.isUpdateNoteOpen ) {
                this.getNoteData( note_id, note_type );
            }
        })
    }

    /**
     * get agent note by note_id
     */
    getNoteData = (note_id, note_type) => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        let file_name = (note_type && (note_type === 'Club Requirement' || note_type === 'Player Recruitment')) ? 'case_note_files' : (note_type && note_type === 'Player Note') ? 'player_note_files' : (note_type && note_type === 'Intel Note') ? 'user_note_files' : '';

        let note_name = (note_type && (note_type === 'Club Requirement' || note_type === 'Player Recruitment')) ? 'case' : (note_type && note_type === 'Player Note') ? 'player' : (note_type && note_type === 'Intel Note') ? 'intel' : '';
        
        const api_route_path = (note_type && (note_type === 'Club Requirement' || note_type === 'Player Recruitment')) ? 
            APIRoutes.case_note + note_id : 
            (note_type && note_type === 'Intel Note') ? APIRoutes.agent_note + note_id : APIRoutes.player_note + note_id;

        const request = new Request(api_route_path, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                 this.setState({
                     edit_note : response.data[0],
                     is_editing : true,
                     files : file_name,
                     note_manage_by_staff : true,
                     note_name : note_name,
                     note_id : note_id,
                     note_manage_by_admin : get_current_user_role() === 'Admin' ? true : false,
                 });
            }
        }, error => {
            console.log(error);
        });
       this._notes.toggleNotesPopup();
    }

     /**
     * update agent's note by a staff
     */
    saveNote = (note_fields, deleted_ids = [], note_name) => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        this.setState({
            isProcessing: true
        });


        const note_path = (note_name && note_name === 'case') ? 
            APIRoutes.case_note : (note_name && note_name === 'player') ? 
            APIRoutes.update_player_note : (note_name && note_name === 'intel') ? APIRoutes.agent_note : '';

        let formData = new FormData(),
            new_state = {};

        formData.append('note', note_fields.note);
        formData.append('note_id', this.state.note_id);
        for (let i in note_fields.files) {
            formData.append('files', note_fields.files[i]);
        }

        formData.append('is_restricted', note_fields.is_restricted);
        formData.append('status', true);
        formData.append( 'show_to_all' , note_fields.show_to_all);
        const request = new Request(note_path, formData, 'PUT', headers, true).send();
        request.then(response => {
            if (response.success) {
                this._notes.toggleNotesPopup(true);
                this.clearFields();
                this.getDashboardData();
                new_state = {
                    isProcessing: false,
                    isSubmitted: true,
                    isSuccess: response.success,
                    responseMsg: response.message
                }
                this.setState(new_state);
            }
        }, error => {
            console.log(error);
        });
    }

    clearFields = () => {
        this._is_fetched = {
            agent_list : false,
            notes_list : false,
        }
        this.setState({
            fields  : {
                note_type : '',
                agent_id  : '',
            },
            agentOptions        : [],
            notes               : [],
            isError             : false,
            responseMsg         : '',
            isSuccess           : false,
            isUpdateNoteOpen    : false,
            is_editing          : false,
            edit_note           : {},
            files               : '',
            note_manage_by_staff : false,
            note_name           : '',
            note_id             : 0,
            isProcessing        : false,
            note_manage_by_admin : false
        });
    }

    /**
     * delete agent note by staff
     */
    deleteNote = (note_name = '') => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }
        
        let new_state = {};
        const note_id = this.state.note_id;
        const note_path = (note_name && note_name === 'case') ? APIRoutes.case_note : (note_name && note_name === 'player') ? APIRoutes.delete_player_note : (note_name && note_name === 'intel') ? APIRoutes.agent_note : '';
        const request = new Request( note_path + note_id, {}, 'DELETE', headers ).send();
        request.then( response => {
            if ( response.success ) {
                this._notes.toggleNotesPopup(true);
                this.clearFields();
                this.getDashboardData();
            }

            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message
            }
            this.setState( new_state );
        }, error => {
            console.log( error );
        });
    }

    resetAlert = () => {
        this.setState({
            responseMsg: ''
        });
    }

    render() {
        /**
         * agent list to show in dropdown for filter
         */
        const agent_list = this.state.agentOptions && this.state.agentOptions.length > 0 ? this.state.agentOptions.map(agent => {
            return <option key={agent.value} value={agent.label}>{agent.label}</option>
        }) : [];

        const loading = this.state.isProcessing ? <LoadingSpinner/> : '',
        alert_messages  = this.state.responseMsg !== '' ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} floating="true" onClose={this.resetAlert} /> : '';
        return (
            <div id="mainContainer">
            {loading}
            {alert_messages}
                <div className="heading-section d-flex align-items-center">
                    <h2>Welcome back, {get_current_user_full_name()}</h2>
                </div>

                <div className="filtter-section">
                    <div className="d-flex">
                        <div className="w-100">
                            <form className="usm-form form-border">
                                <div className="row">
                                    <div className="col-md-3 col-sm-12">
                                        <div className="form-group marginB0">
                                            <label htmlFor="">Note Type</label>
                                            <select name="note_type" onChange={this.handleFieldsChanges} className="form-control input-height30" value={this.state.fields.note_type} title={this.state.names.note_type}>
                                                <option value="">All</option>
                                                <option value="1">Case - Player Recruitment</option>
                                                <option value="2">Case - Club Requirement</option>
                                                <option value="3">Player Note</option>
                                                <option value="4">Intel Note</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-12">
                                        <div className="form-group marginB0">
                                            <label htmlFor="">Agent</label>
                                            <select name="agent_id" onChange={this.handleFieldsChanges} className="form-control input-height30" value={this.state.fields.agent_id} title={this.state.names.agent_id} >
                                                <option value="">All</option>
                                                {agent_list}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                        <div className="flex-shrink-1 marginL18">
                            <div className="apply-filtter float-right d-flex align-items-center flex-column">
                                <i className="material-icons marginB5"><img src={require('../../assets/images/filter-icon.png')} alt="" /></i>
                                <button onClick={this.filterNotes} className="btn btn-success borderRadius15 text-uppercase small-button">Apply Filters</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="content-section">

                    <div className="usm-table marginT82 table-responsive">
                        <table className="table table-hover lastTwo-center">
                            <thead className="usm-thead">
                                <tr>
                                    <th>Agent Name</th>
                                    <th>Case Number</th>
                                    <th>Note Type</th>
                                    <th>Fulfilment Date</th>
                                    <th>Attachments</th>
                                    <th>Date of Receipt</th>
                                    <th>Manage</th>
                                </tr>
                            </thead>
                            <tbody>
                                <Notes 
                                    is_fetched={ this._is_fetched.notes_list }
                                    notes = { this.state.notes }
                                    openUpdateNote={ this.openUpdateNote }
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
                {
                    !this.state.isUpdateNoteOpen ? '' : 
                    <UpdateNote 
                    ref={ elt => this._notes = elt }
                    onSubmit={ this.saveNote }
                    note={this.state.edit_note}
                    is_editing={this.state.is_editing}
                    files={this.state.files}
                    onDelete={ this.deleteNote }
                    note_manage_by_staff={this.state.note_manage_by_staff}
                    note_name={this.state.note_name}
                    note_manage_by_admin={this.state.note_manage_by_admin}
                />
                }
            </div>
        );
    }
}
