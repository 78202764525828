import React from 'react';
import Request from '../../../helpers/Request';
import APIRoutes from '../../../API/Routes';
import { get_authorization_token, __x } from '../../../helpers/GeneralHelper';
import { setClosedCases } from '../../../actions/ClubsAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ClosedCase from './ClosedCase';

class ClosedCases extends React.Component {
    constructor( props ) {
        super( props );

        this._club_id = props.match.params.id;
        this._fetched = false;
        this.state = {
            cases           : [],
            closedCasesOpen : false
        }
    }

    componentWillReceiveProps = new_props => {
        this.setState({
            cases: new_props.clubs.closedCases
        })
    }

    listCases = () => {
        const cases = this.state.cases;
        if ( cases.length < 0 ) {
            return false;
        }
        
        return cases.map( res => {
            return res && res !== null ? <ClosedCase key={res.id} case={res} /> : '';
        });
    }

    toggleClosedCases = () => {
        this.setState({
            closedCasesOpen: !this.state.closedCasesOpen
        });
    }
    
    render = () => {
        const cases = this.state.cases;
        return (
            <div className="closed-cases">
                <div className="heading-section d-flex align-items-center auto-height">
                    Closed Cases <span className="badge badge-pill badge-success">{ cases.length > 0 ? cases[0] === null ? 0 : cases.length : 0 }</span>
                    <button onClick={ this.toggleClosedCases } className="transparent-btn ml-auto" type="button">
                        {
                            this.state.closedCasesOpen ? 
                            <img src={ require( '../../../assets/images/arrow-up.png' ) } alt="" />
                            :
                            <img src={ require( '../../../assets/images/arrow-down.png' ) } alt="" />
                        }
                    </button>
                </div>

                <div className="player-cases-table usm-table table-responsive">
                    <table className="table table-hover lastOne-center">
                        <thead className="usm-thead">
                            <tr>
                            <th scope="col">Case No</th>
                            <th scope="col">Position</th>
                            <th scope="col">Fulfilment Date</th>
                            <th scope="col">Agent</th>
                            <th scope="col">Players Considered</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                !this.state.closedCasesOpen ? <tr><td colSpan="5"></td></tr> : this.listCases()
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    getCases = () => {        
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const cases_path = APIRoutes.getcasewidget + this._club_id;
        const request = new Request( cases_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                const cases = response.data && response.data.closed ? response.data.closed : [];
                
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                this.props.setClosedCases( cases );
                this._fetched = true;
            }
        }, error => {
            console.log( error );
        });
    }

    componentDidMount = () => {
        if ( !this._fetched ) {
            this.getCases();
        }
    }
}

const mapStateToProps = ( state ) => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setClosedCases : ( cases ) => dispatch( setClosedCases( cases ) )
});

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( ClosedCases ) );