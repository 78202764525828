import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import './assets/css/custom.css';
import LoginWrap from './components/login/LoginWrap';
import AdminStaffWrapper from './components/admin-staff/Wrapper';
import { isAuthenticated } from './helpers/GeneralHelper';
import Logout from './components/login/Logout';
import AgentsWrapper from './components/agents/Wrapper';

class App extends Component {

    render() {
        const signin_first = <Redirect to={{ pathname: '/login', state: 'Please sign in first!!' }} />;
        return (
            <div className="usm-outer-container">
                <Switch>
                    <Route exact path="/login/" component={LoginWrap} />
                    <Route exact path="/account-setup/" component={LoginWrap} />
                    <Route exact path="/forgot-password/" component={LoginWrap} />
                    <Route exact path="/reset-password/" component={LoginWrap} />
                    <Route exact path="/terms-and-conditions/" component={LoginWrap} />
                    <Route exact path="/logout/" render={() => (isAuthenticated() ? (<Logout />) : (signin_first))} />
                    <Route path="/admin/" component={AdminStaffWrapper} />
                    <Route exact path="/staff/dashboard" component={AdminStaffWrapper} />
                    <Route exact path="/clubs" component={AdminStaffWrapper} />
                    <Route exact path="/clubs-details/:id" component={AdminStaffWrapper} />
                    {/* <Route exact path="/user/profile/:id" component={AdminStaffWrapper} /> */}
                    <Route exact path="/user/profile/" component={AdminStaffWrapper} />
                    <Route exact path="/players-details/:player_id" component={AdminStaffWrapper} />
                    <Route path="/agent/" component={AgentsWrapper} />
                    <Route path="/organisations" component={AdminStaffWrapper} />
                    <Route path="/organisations-details/:id" component={AdminStaffWrapper} />
                    {/* <Footer /> */}
                    <Route exact path="/" component={LoginWrap} />
                </Switch>
            </div>
        );
    }
}

export default withRouter(App);