import React from 'react';
import { get_days_by_date, get_display_format_date, get_current_user_role, is_valid_db_date } from '../../../helpers/GeneralHelper';
import { Link } from 'react-router-dom';
import CaseAgentRollOver from './CaseAgentRollOver';

const ActiveCase = props => {
    const active_case = props.case;
    const active_widgets = props.active_widgets ? props.active_widgets : [];
    let visible   = ''; 
    
    if ( active_widgets.length > 0 ) {
        for ( let i in active_widgets ) {
            if ( active_widgets[i].id === active_case.id ) {
                visible = `visible`;
            }
        }
    } else {
        visible = props.idx > 3 ? '' : `visible`;
    }
    return (
        <div className={`col-md-3 col-xs-12 ${visible}`}>
            <div className="card">
                <div className="card-body">
                    <div className="cardImg-section">
                        <div className="card-data">
                            <div className="card-data-label">Fulfilment Date</div>
                            <div className="card-data-content">{ active_case.fulfillment_date && is_valid_db_date(active_case.fulfillment_date) ? get_display_format_date( active_case.fulfillment_date ) : '' }</div>
                            <div className="card-data-label">Requirement</div>
                            <div className="card-data-content">{ active_case.requirement_type ? active_case.requirement_type : '' }</div>
                            <div className="card-data-label">Case ID</div>
                            <div className="card-data-content">{ active_case.case_number ? active_case.case_number : '' }</div>
                        </div>
                        <div className="cardTitle-section" title={ active_case.master_position ? active_case.master_position.title : '' }>
                            <div className="card-subtitle">{ active_case.master_position ? active_case.master_position.title : '' }</div>
                        </div>
                    </div>
                    <div className="cardContent-section">
                        <div className="d-flex card-text marginB18">
                            <div className="mr-auto text-success fontBold font-size14">No of Agents</div>
                            <div className="fontBold text-success font-size14 show-rollover position-relative">{ active_case.case_agents ? active_case.case_agents.length : 0 }
                            {active_case.case_agents.length > 0 ? <CaseAgentRollOver case_id={active_case.id} agents={active_case.case_agents} /> : ''}</div>
                        </div>
                        <div className="d-flex card-text marginB20">
                            <div className="mr-auto font-size14">Last Update</div>
                            <div className="fontBold font-size14">{ active_case.updated_at ? get_days_by_date( active_case.updated_at ) : '' } Days</div>
                        </div>
                        { get_current_user_role() === 'Agent' ? <Link to={`/agent/club-case/${active_case.id}`} className="btn btn-usmPrimary">View Case</Link> : 
                        <Link to={`/admin/edit-club-case/${active_case.id}`} className="btn btn-usmPrimary">View Case</Link>  }
                        
                        <div className="card-alert">Alert last sent 09:01 3 Nov 2018</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ActiveCase;