import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';


const CaseAgentRollOver = props => {
    
    let case_agents  = [];
    let agent_count = '';
    agent_count = props.agents.length;

    for ( let i in props.agents ) {
        let case_agent = props.agents[i];

        case_agents.push(<tr key={i}>
            <td className="text-left paddingL0 ">{ case_agent.user ? case_agent.user.first_name + " " + case_agent.user.last_name : case_agent.name }</td>
        </tr>);
    }

    const id = `popover-content-toggle-one-${props.case_id}`;
    return (
        <div id={id} className="popover-content-toggle-one">

            <div className="arrow-right"></div>
            <div className="usm-table-popover table-responsive">
                <div className="table-popover-heading"><span>{agent_count}</span> AGENTS</div>
                <Scrollbars autoHeightMin={100} autoHeightMax={270} autoHide autoHeight>
                    <div className="usm-table-popover-scroll">
                        <table className="">
                            <tbody>
                                {case_agents}
                            </tbody>
                        </table>
                    </div>
                </Scrollbars>
            </div>
        </div>
    )
}

export default CaseAgentRollOver;

