import React from 'react';
import { connect } from 'react-redux';
import { setClubList, setPositions, setAgents } from '../../../actions/CasesActions';
import { is_valid_db_date, get_authorization_token, get_db_date, get_current_user_full_name, get_current_user_role, get_display_format_date, __x } from '../../../helpers/GeneralHelper';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import UpdateNote from '../common/UpdateNote';
import Alert from '../../../helpers/Alert';
import ReactTags from 'react-tag-autocomplete';
import { Link, withRouter } from 'react-router-dom';
import LoadingSpinner from '../common/LoadingSpinner';
import DeletePlayerInfoConfirm from '../players/DeletePlayerInfoConfirm';
import ReactTagsComponent from '../common/ReactTagsComponent';


class AddClubCase extends React.Component {

    constructor( props ) {
        super( props );

        this._notes = React.createRef();
        this._isFetching = false;
        this._case_id = props.match.params.case_id ? props.match.params.case_id : ''; 
        this.is_editing = false;
        this.fields_label    = {
            lead_title          : 'Lead Title',
            lead_source         : 'Lead Source',
            fulfillment_date    : 'Fulfilment Date',
            detail              : 'Details',
            case_note           : 'NOTES',
            min_age             : 'Minimum Age',
            max_age             : 'Maximum Age'
        };

        this.state = {
            openNotesPopup: false,
            _fetched: {
                agents      : false,
                club_list   : false,
                positions   : false,
                outcomes    : false,
            },
            fields: {
                club_id                 : '',
                lead_title              : '',
                lead_source             : '',
                agent_id                : null,
                fulfillment_date        : null,
                master_position_id      : '',
                additional_position_ids : '',
                detail                  : '',
                status                  : '1',
                case_note               : [],
                case_attachments        : [],
                master_requirement_id   : '2',
                min_age                 : '',
                max_age                 : '',
                requirement_type        : '',
                players_considered      : [],
                is_contract_signed      : false,
                contract_with_player    : '',
                master_outcome_id       : ''
            },
            isValid : {
                club_id                 : true,
                agent_id                : true,
                master_position_id      : true,
                lead_title              : true,
                lead_source             : true,
                fulfillment_date        : true,
                detail                  : true,
                additional_position_ids : true,
                status                  : true,
                case_note               : true,
                master_requirement_id   : true,
                min_age                 : true,
                max_age                 : true,
                requirement_type        : true,
                players_considered      : true,
            },
            error: {
                club_id                 : '',
                lead_title              : '',
                lead_source             : '',
                agent_id                : '',
                fulfillment_date        : '',
                master_position_id      : '',
                additional_position_ids : '',
                detail                  : '',
                status                  : '',
                case_note               : '',
                master_requirement_id   : '',
                min_age                 : '',
                max_age                 : '',
                players_considered      : ''
            },
            players                 : [],
            isProcessing            : false,
            isSubmitted             : false,
            isSuccess               : false,
            responseMsg             : '',
            is_editing              : false,
            edit_note               : {},
            note_id                 : 0,
            case_number             : '',
            openDeleteConfirmationPoppup : false,
            lockscreen              : false,
            lockErrorMsg            : '',

        };
    }

    /**
     * Handle add case form fields changes.
     */
    handleChange = event => {
        const elt   = event.target,
        name        = elt.name,
        value       = elt.type === 'checkbox' ? elt.checked : elt.value,
        fields      = this.state.fields,
        isValid     = this.state.isValid,
        error       = this.state.error,
        fieldsLabel = this.fields_label;

        /**
         * Handles lead_title, lead_source and detail fields' values and validation.
         */
        if (  [ 'lead_title', 'lead_source', 'detail' ].indexOf( name ) >= 0 ) {
            const elt_label  = fieldsLabel[ name ];
            fields[ name ]   = value;
            isValid[ name ]  = value === '' ? true: value.length > 0 && value.length <= 255;
            error[ name ]    = !isValid[ name ] ? __x( `{{label}} is invalid.`, `{{label}}`, elt_label ) : '';
        } 

        /**
         * Set min_age and max_age fields with validations and errors.
         */
        if ( [ 'min_age', 'max_age' ].indexOf( name ) >= 0 ) {
            const elt_label  = fieldsLabel[ name ];
            fields[ name ]   = value;
            isValid[ name ]  = value === '' ? true: value.length > 0 && value.length <= 255 && !isNaN( value );
            error[ name ]    = !isValid[ name ] ? __x( `{{label}} is invalid.`, `{{label}}`, elt_label ) : '';
        }

        // /**
        //  * Validate and set additional_position_ids field
        //  */
        // if ( name === 'additional_position_ids' || name === 'master_position_id' ) {
        //     fields[ name ]   = value;
        //     isValid[ 'additional_position_ids' ]  = value === '' ? true: value.length > 0 && value.length <= 255 && fields.master_position_id !== fields.additional_position_ids;
        //     error[ 'additional_position_ids' ]    = !isValid.additional_position_ids ? `The Primary Postion and Secondary Position have same value.` : '';
        // }

        /**
         * Handles club_id, master_position_id and requirement_type field's value.
         */
        if ( [ 'club_id', 'requirement_type' ].indexOf( name ) >= 0 ) {
            fields[ name ]   = value;
        }

        if( [ 'is_contract_signed', 'master_outcome_id', 'contract_with_player', 'status' ].indexOf( name ) >= 0 ) {
            fields[ name ] = value;
        }

        this.setState({ fields, isValid, error });
    }

    /**
     * Set agent ids to state.
     */
    handleAgentChange = selected_agent => {
        const fields  = this.state.fields;
        this.setState({
            fields: {
                ...fields,
                agent_id: selected_agent
            }
        });
    }

    /**
     *  Handle position changes
     */
    handlePositionChange = (value, field_name) => {
        let fields = this.state.fields,
            isValid = this.state.isValid,
            error       = this.state.error;
        if (field_name === 'additional_position_ids') {
            error[field_name] = '';
            isValid[field_name] = true;
            isValid['master_position_id'] = true;
            for (let option_value in value) {
                let option = value[option_value];
                isValid[field_name] = (fields.master_position_id !== null && option.value === fields.master_position_id.value) ? false : true;
                isValid['master_position_id'] = true;
                
                if (isValid[field_name] === false) {
                    console.log("false");
                    error[field_name] = __x( `The Primary Postion and Secondary Position have same value.` );
                    fields[field_name] = value;
                    this.setState({ 
                        ...fields,
                        fields, 
                        isValid, 
                        error });
                    return false;
                }
            }

        }
        else if (field_name === 'master_position_id') {
            let additional_postions = fields.additional_position_ids;
            if (additional_postions) {
                for (let additional_position in additional_postions) {
                    let option = additional_postions[additional_position];
                    isValid[field_name] = (value.value === option.value) ? false : true;
                    error['additional_position_ids'] = '';
                    isValid['additional_position_ids'] = true;
                    if (isValid[field_name] === false) {
                        error['additional_position_ids'] = __x( `The Primary Postion and Secondary Position have same value.` );
                        fields[field_name] = value;
                        this.setState({ 
                            ...fields,
                            fields, 
                            isValid, 
                            error });
                        return false;
                    }
                }
            }
        }
        
        fields[field_name] = value;
        this.setState({
            ...fields,
            fields,
            ...error,
            error,
            ...isValid,
            isValid
        })
    }

    /**
     * Handles changes for fulfillment date.
     */
    onFulfillmentDateChange = date => {
        const fields    = this.state.fields,
        isValid         = this.state.isValid,
        error           = this.state.error;
        
        fields[ 'fulfillment_date' ]    = date;
        isValid[ 'fulfillment_date' ]   = date === '' || date === null || !date ? true : date instanceof Date;
        error[ 'fulfillment_date' ]     = !isValid[ 'fulfillment_date' ] ? __x( `{{label}} is invalid.`, `{{label}}`, this.fields_label[ 'fulfillment_date' ] ) : '';
        
        this.setState({ fields, isValid, error });
    }

    /**
     * Handle deleting the considered players.
     */
    onPlayerDelete = idx => {
        const players_considered = this.state.fields.players_considered;
        
        players_considered.splice( idx, 1 );
        this.setState({ 
           fields: {
               ...this.state.fields,
               players_considered
           }
        });
    }

    /**
     * Handles player selection.
     */
    onPlayerSelection = player => {
        const modified_player = {
            ...player,
            name: `${player.name}  Age: ${player.age}   Current Club: ${player.current_club ? player.current_club : ''}`,
            full_name: `${player.name}`
        };

        const players_considered_state = this.state.fields.players_considered;
        for( let i in players_considered_state ) {
            if ( player.id === players_considered_state[i].id )  {
                return;
            }
        }

        let players_considered  = [].concat( players_considered_state, modified_player );

        this.setState({
            fields: {
                ...this.state.fields,
                players_considered 
            }
        });
    }

    /**
     * Search players by typeing name.
     */
    onPlayersSearch = query => {
        if ( query.length < 3 )
            return false;
        
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        this._isFetching = true;
        const path = APIRoutes.get_player_list + query;
        const request = new Request( path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                const players = response.data && response.data.length > 0 ? response.data : [];
                this.setState({
                    players
                });
                this._isFetching = false;
            }
        }, error => {
            console.log( error );
        });
    }

    /**
     * Checks if fomr is valid or not.
     */
    isFormValid = () => {
        let validityErr = [];
        const fields    = this.state.fields,
        isValid         = this.state.isValid;

        const optional  = [ 'lead_title', 'lead_source', 'detail', 'min_age', 'max_age', 'additional_position_ids' ];
        const toExclude = [ 'master_requirement_id', 'case_note', 'case_attachments', 'is_contract_signed', 'contract_with_player', 'master_outcome_id' ];

        for ( let elt in fields ) {
            /**
             * Exclude fields.
             */
            if ( toExclude.indexOf( elt ) >= 0 ) {
                continue;
            }

            if ( optional.indexOf( elt ) >= 0 ) {
                if ( fields[ elt ] === '' )
                    continue;
                
                if ( !isValid[ elt ] )
                    validityErr.push( elt );
            }

            if ( elt === 'agent_id' ) {
                if ( !fields[ elt ] || fields[ elt ].length <= 0 || !isValid[ elt ] )
                validityErr.push( elt );
            }

            if ( fields[ elt ] === '' || fields[ elt ] === null || !isValid[ elt ] ) {
                validityErr.push( elt );
            }
        }
        return validityErr.length <= 0;
    }

    /**
     * Add club case.
     */
    addClubCase = event => {
        event.preventDefault();
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( !this.isFormValid() ) {
            return false;
        }

        this.setState({
            isProcessing: true
        });

        const fields    =  this.state.fields,
        case_path       = this.is_editing ? APIRoutes.case + this._case_id : APIRoutes.case,
        method          = this.is_editing ? 'PUT' : 'POST';
        

        let new_state   = {},
        formData        = new FormData();

        for ( let i in fields ) {
            if ( i === 'club_name' )
                continue;

            else if ( i === 'min_age' || i === 'max_age' ) {
                    formData.append(i, fields[i]);
                }    

            /**
             * Get date in dd/mm/yyyy format.
             */
            else if ( i === 'fulfillment_date' ) {
                formData.append( i, get_db_date( fields[ i ] ) );
                continue;
            }
            
            /**
             * Appends case_note into formData
             */
            else if ( i === 'case_note' ) {
                const notes = JSON.stringify( fields[ i ] );
                formData.append( i, notes );
                continue;
            }

            /**
             * Appends case atachments into form data.
             */
            else if ( i === 'case_attachments' ) {
                const files = fields[ i ];
                for( let f in files ) {
                    let note_file = files[f];
                    formData.append( 'files', note_file );
                }
                continue;
            }

            /**
             * Filtering comma separated ids from these fields.
             */
            else if ( i === 'agent_id' || i === 'players_considered' ) {
                const toReturn = i === 'agent_id' ? `value` : `id`;
                const ids = fields[i] && fields[i].length > 0 ? fields[i].map( entity => {
                    return entity[ toReturn ];
                }).join( ',' ) : '';

                if ( ids !== '' ) {
                    formData.append( i, ids );
                }
                continue;
            }

            /**
             * Master position id
             */
            else if (i === 'master_position_id') {
                if (fields[i] && fields[i].value) {
                    formData.append(i, fields[i].value);
                }
                continue;
            }

            /**
             * Filtering comma seperated additional position id
             */
            else if (i === 'additional_position_ids') {
                formData.append(i, fields[i] && fields[i].length > 0 ? fields[i].map(function (selected_additional_position, index) {
                    return selected_additional_position.value
                }) : '');
                continue;
            }

            /**
             * If close case is selected then false value will be send
             */
            else if (i === 'status') {

                formData.append(i, fields[i] === true ? false : true);
                continue;
            }

            // else if (i === 'contract_with_player') {
            //     if (fields[i] === null || fields[i] === '') {
            //         formData.append(i, 0);
            //     }
            //     else {
            //         formData.append(i, fields[i]);
            //     }
            // }

            // else if( i === 'master_outcome_id' ) {
            //     if (fields[i] === '') {
            //         formData.append(i, 0);
            //     }
            //     else {
            //         formData.append(i, fields[i]);
            //     }
            // }
           
            else
            formData.append(i, fields[i]);
        }
        
        /**
         * Sending request to add/update clubs.
         */
        const request = new Request( case_path, formData, method, headers, true ).send();
        request.then( response => {
            if ( response.success ) {
                this.clearFields();
                this.props.history.push( '/admin/cases/', { 
                    message: response.message, 
                    success: response.success 
                });
            } else {
                new_state = {
                    isProcessing    : false,
                    isSubmitted     : true,
                    isSuccess       : response.success,
                    responseMsg     : response.message
                }

                this.setState( new_state );
            }
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMsg : error.message
            };
            this.setState( new_state );
        });
    }

    resetAlert = () => {
        this.setState({
            responseMsg: '',
            isSubmitted: false
        });
    }

    goBack = () => {
        this.props.history.go(-1);
    }

    render = () => {
        const { clubs, agents, positions } = this.props.cases;
        const submit_disabled = !this.isFormValid();
        const responseMsg   = this.state.responseMsg !== '' && this.state.isSubmitted ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} floating="true" onClose={this.resetAlert} /> : '',
        lockErrorMsg   = this.state.lockErrorMsg ? <Alert message={this.state.lockErrorMsg} success={this.state.isSuccess} floating="true" goBack={this.goBack} isNotFlash /> : '';

        
        
        /**
         * Set list hover backgourd to transparent black.
         */
        const customerStyles = { 
            option: ( styles, { isFocused } ) => { 
                return { 
                    ...styles,
                    backgroundColor: isFocused ? 'rgba(0, 0, 0, 0.2)' : styles.backgroundColor, 
                }
            }
        };
       
        /**
         * If user role is agent then add/edit functionality not allowed
         */
        const is_agent = this.current_user_role === 'Agent';
        const not_allowed_cursor = is_agent ? 'disable_not_allowed_cursor' : '';

        /**
         * loader
         */
        const loading   = this.state.isProcessing ? <LoadingSpinner/> : '';

         /**
         * dropdown of player considered if contract signed is checked
         */
        let players_considered_dropdown = this.state.fields.players_considered && this.state.fields.players_considered.length > 0 ? this.state.fields.players_considered.map( (player, p_idx) => {
            return (
                <option key={player.id} value={player.id}>{player.full_name}</option>
            )
        }) : '';

        /**
         * dropdown for outcome list
         */
        let outcomes = this.state.outcomes && this.state.outcomes.length > 0 ? this.state.outcomes.map( ( outcome, o_idx) => {
            return (
                <option key={outcome.value} value={outcome.value}>{outcome.label}</option>
            )
        }) : '';

                /* Cases listing */
        let cases_list = this.state.fields.case_note && this.state.fields.case_note.length > 0 ? this.state.fields.case_note.map( ( note, n_idx ) => {
            const attachments = note.case_note_files ; 
            const is_restricted = note.is_restricted && get_current_user_role() !== 'Admin';  
    
                /**
                     * If current note is restricted for current user.
                     */
                if (is_restricted) {
                    return (
                        <li key={note.id} className="media">
                            <div className="media-body clearfix">
                                <div className="nc-nb-heading marginB10">Note</div>
                                <div className="note-add-modify">
                                    <div className="row">

                                        <div className="col">
                                            <div className="d-inline-block marginR10 align-top">
                                                <img src={require(`../../../assets/images/Restricted.png`)} alt="" />
                                            </div>
                                            <div className="d-inline-block">
                                                <p className="fontBold text-danger font-size14">Restricted
                                                <span className="fontBold text-white d-block marginT5">Please contact administrator.</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    );
                }
    
                /**
                 * List for non restricted notes
                 */
                return (
                    <li key={note.id} className="media">								
                        <div className="media-body clearfix">
                            <div className="nc-nb-heading">Note</div>	
                            <p>{note.note}</p> 
                            <div className="note-add-modify">
                                <div className="row marginB10">
                                    <div className="col-md-2 width10 col-sm-12">Added by: </div>
                                    <div className="col-md-10 col-sm-12 fontBold">{ note.added_by }</div>
                                </div>
                                <div className="row marginB10">
                                    <div className="col-md-2 width10 col-sm-12">Last Modified: </div>
                                    <div className="col-md-10 col-sm-12 fontBold">{ note.updated_at && is_valid_db_date(note.updated_at) ? get_display_format_date(note.updated_at) : '' }</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2 width10 col-sm-12">Attachments: </div>
                                    <div className="col-md-10 col-sm-12">
                                        {
                                            attachments && attachments.length > 0 ? attachments.map( attachment => {
                                                return (<div key={attachment.id} className="text-success font-size14 word-break-all marginB10">
                                                    <a href={attachment.filename} target="_blank" rel="noopener noreferrer">{ attachment.original_filename }</a>
                                                </div>)
                                            }) : ''
                                        }
                                        {/* <div className="text-success font-size14">Filename02.pdf</div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button onClick={ event => this.editNote( event, note.id ) } type="button" className="transparent-btn align-self-start mr-3">
                            <i className="material-icons text-opt-four font-size20">edit</i>
                        </button>
                    </li>
                );
            }) : '';

        return (
            <div id="mainContainer">
                {responseMsg}
                {loading}
                {lockErrorMsg}
                <div className="heading-section height84 d-flex border-bottom-trans align-items-center">
                    <h2>{this.is_editing ? `Edit` : `Add`} Case</h2>
                </div>

                <div className="content-section form-modal">
                    <form onSubmit={ e => {e.preventDefault(); } } action="" className={`usm-form form-border ${not_allowed_cursor}`}>
                        <div className="row">
                        {this.is_editing ? 
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Case Number</label>
                                    <h2 className="d-block text-white">{this.state.case_number}</h2>
                                </div>
                            </div> : ''}
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label>Case Type</label>
                                    <input onChange={ this.handleChange } value="Club Requirement" name="master_requirement_id" type="text" className="form-control" placeholder="" disabled={true} />
                                    {/* <select onChange={ this.handleChange } value={ this.state.fields.master_requirement_id } name="master_requirement_id" className="form-control">
                                        <option value=""></option>
                                        <option value="1">Player Recruitment</option>
                                        <option value="2">Club Requirement</option>
                                    </select> */}
                                    { this.state.error.master_requirement_id === '' || <div className="display-error">{ this.state.error.master_requirement_id }</div> }
                                </div>
                            </div>
                        </div>
                        <div className="border-bottom-trans marginTB35"></div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                    <label>Club Name*</label>
                                    <select onChange={ this.handleChange } value={ this.state.fields.club_id } name="club_id" className="form-control">
                                        <option value=""></option>
                                        {
                                            clubs.length > 0 ? clubs.map( club => {
                                                return <option key={ club.value } value={ club.value }>{ club.label }</option>
                                            }) : ''
                                        }
                                    </select>
                                    { this.state.error.club_id === '' || <div className="display-error">{ this.state.error.club_id }</div> }
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className={ this.state.error.lead_title === '' ? `form-group` : `form-group form-error`}>
                                    <label>Lead Title</label>
                                    <input onChange={ this.handleChange } value={ this.state.fields.lead_title } name="lead_title" type="text" className="form-control" placeholder="" autoComplete="off" />
                                    { this.state.error.lead_title === '' || <div className="display-error">{ this.state.error.lead_title }</div> }
                                </div>	
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className={ this.state.error.lead_source === '' ? `form-group` : `form-group form-error`}>
                                    <label>Lead Source</label>
                                    <input onChange={ this.handleChange } value={ this.state.fields.lead_source } name="lead_source" type="text" className="form-control" placeholder="" autoComplete="off" />
                                    { this.state.error.lead_source === '' || <div className="display-error">{ this.state.error.lead_source }</div> }
                                </div>	
                            </div>                           
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                    <label>Agent *</label>
                                    <Select
                                                onChange={ this.handleAgentChange }
                                                options={ agents.length > 0 ? agents : [] }
                                                isMulti={true}
                                                isSearchable={true}
                                                value={ this.state.fields.agent_id } 
                                                name="agent_id"
                                                placeholder=""
                                                styles={ customerStyles }
                                            />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                    <label>Fulfilment Date*</label>
                                    <DatePicker
                                        className="form-control"
                                        selected={ this.state.fields.fulfillment_date }
                                        onChange={ this.onFulfillmentDateChange }
                                        dateFormat="dd/MM/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={30}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className={ this.state.error.min_age === '' ? `form-group` : `form-group form-error`}>
                                    <label>Minimum Age:</label>
                                    <input onChange={ this.handleChange } value={ this.state.fields.min_age } name="min_age" type="text" className="form-control" placeholder="Minimum age" autoComplete="off" />
                                    { this.state.error.min_age === '' || <div className="display-error">{ this.state.error.min_age }</div> }
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className={ this.state.error.max_age === '' ? `form-group` : `form-group form-error`}>
                                    <label>Maximum Age:</label>
                                    <input onChange={ this.handleChange } value={ this.state.fields.max_age } name="max_age" type="text" className="form-control" placeholder="Maximum age" autoComplete="off" />
                                    { this.state.error.max_age === '' || <div className="display-error">{ this.state.error.max_age }</div> }
                                </div>
                            </div>
                        </div>	

                        <div className="border-bottom-trans marginTB35"></div>

                        <div className="row">
                            <div className="col">
                                <div className={ this.state.error.master_position_id === '' ? `form-group` : `form-group form-error` }>
                                    <label>Position Required*</label>
                                    <Select name="master_position_id"
                                        value={this.state.fields.master_position_id}
                                        onChange={(e) => this.handlePositionChange(e, 'master_position_id')}
                                        options={positions}
                                        styles={customerStyles}
                                        placeholder={``}
                                    />
                                    { this.state.error.master_position_id === '' || <div className="display-error">{ this.state.error.master_position_id }</div> }
                                </div>	
                            </div>
                            <div className="col">
                                <div className={ this.state.error.additional_position_ids === '' ? `form-group` : `form-group form-error` }>
                                    <label>Additional Positions</label>
                                    <Select name="additional_position_ids"
                                        value={this.state.fields.additional_position_ids}
                                        onChange={(e) => this.handlePositionChange(e, 'additional_position_ids')}
                                        options={positions}
                                        isMulti={true}
                                        styles={customerStyles}
                                        placeholder={``}
                                    />
                                    { this.state.error.additional_position_ids === '' || <div className="display-error">{ this.state.error.additional_position_ids }</div> }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label>Requirement Type*</label>
                                    <select onChange={ this.handleChange } value={ this.state.fields.requirement_type } name="requirement_type" className="form-control">
                                        <option value=""></option>
                                        <option value="Sell an Existing Player">Sell an Existing Player</option>
                                        <option value="Loan a Player">Loan a Player</option>
                                        <option value="Source a Player">Source a Player</option>                                        
                                        <option value="Source a Loan Player">Source a Loan Player</option>                                        
                                    </select>
                                </div>	
                            </div>	
                            <div className="col-md-9 col-sm-12">
                                <div className={ this.state.error.detail === '' ? `form-group` : `form-group form-error`}>
                                    <label>Details</label>
                                    <input onChange={ this.handleChange } value={ this.state.fields.detail } name="detail" type="text" className="form-control" placeholder="" autoComplete="off" />
                                    { this.state.error.detail === '' || <div className="display-error">{ this.state.error.detail }</div> }
                                </div>	
                            </div>	
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="form-group marginT20">
                                    <div className="player-detail-block">
                                        <div className="player-detail-heading d-flex align-items-center">
                                            Notes 
                                            <button onClick={ this._notes.toggleNotesPopup } type="button" className="transparent-btn float-right ml-auto">
                                                <img src={ require( '../../../assets/images/add-icon.png' ) } alt="" />
                                            </button>
                                        </div>
                                        <div className="player-detail-content">
                                            <div className="notes-block">
                                                <ul className="list-unstyled">
                                                    { this.is_editing ? cases_list : this.listLocalNotes() }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom-trans marginTB34"></div>

                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className={ this.state.error.players_considered === '' ? `form-group` : `form-group form-error`}>
                                    <label>Player Considered</label>
                                    <ReactTags
                                        classNames={{searchInput: 'players-considered form-control'}}
                                        tags={ this.state.fields.players_considered }
                                        suggestions={ this.state.players }
                                        handleDelete={ this.onPlayerDelete  }
                                        handleAddition={ this.onPlayerSelection } 
                                        handleInputChange={ this.onPlayersSearch }
                                        placeholder={``}
                                        autofocus={false}
                                        allowBackspace={false}
                                        tagComponent={ReactTagsComponent}
                                    />
                                    { this.state.error.players_considered === '' || <div className="display-error">{ this.state.error.players_considered }</div> }
                                </div>	
                            </div>	
                        </div>
                        
                        <div className="border-bottom-trans marginTB34"></div>

                        {
                            this.is_editing ?
                            <div>
                                <div className="font-size24 fontBold d-flex align-items-center auto-height">Close Case</div>

                                <div className="row marginB5">
                                    <div className="col-md-3 col-sm-12 marginT20">
                                        <div className="form-group">
                                            <div className="form-group usm-check">
                                                <input onChange={this.handleChange} value={this.state.fields.status} checked={this.state.fields.status} name="status" type="checkbox" id="status" disabled={is_agent} />
                                                <label htmlFor="status" className="font-size16 fontBold">Close Case</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                    <div className="row marginB20 d-flex-middle">
                                        <div className="col-md-1 col-sm-12 outcomCol">
                                            <div className="form-group marginB0">
                                                <label className="font-size16 fontBold">Outcome :</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12">
                                            <select onChange={this.handleChange} value={this.state.fields.master_outcome_id} name="master_outcome_id" className="form-control" disabled={is_agent}>
                                                <option value=""></option>
                                                {outcomes}
                                            </select>
                                        </div>

                                    </div>
                            

                                <div className="row marginB20 d-flex-middle">
                                    <div className="col-md-2 col-sm-12">
                                        <div className="form-group usm-check">
                                            <input onChange={this.handleChange} value={this.state.fields.is_contract_signed} checked={this.state.fields.is_contract_signed} name="is_contract_signed" type="checkbox" id="is_contract_signed" disabled={is_agent} />
                                            <label htmlFor="is_contract_signed" className="font-size16 fontBold">Contract Signed?</label>
                                        </div>
                                    </div>

                                        {this.state.fields.is_contract_signed && players_considered_dropdown ?
                                            <div className="col-md-3 col-sm-12">
                                                <div className="form-group">
                                                    <select onChange={this.handleChange} value={this.state.fields.contract_with_player} name="contract_with_player" className="form-control">
                                                        <option value=""></option>
                                                        {players_considered_dropdown}
                                                    </select>
                                                </div>
                                            </div> : ''
                                        }
                                </div> 
                            </div>
                            : ''
                        }

                        <div className="row">
                            <div className="col d-flex justify-content-between">
                                <Link to="/admin/cases" className="default-btn btn-danger width-auto" data-dismiss="modal">Cancel</Link>
                                { this.is_editing === true ? <button type="button" className="default-btn btn-danger min-width157" onClick={ this.toggleDeleteConfirmation }>Delete</button> : ''}
                                <button onClick={ this.addClubCase } disabled={submit_disabled} type="button" className="default-btn btn-success width-auto">Save</button>
                            </div>
                        </div>
                    </form>
                </div>	
                { this.is_editing ? <UpdateNote 
                    ref={ elt => this._notes = elt }
                    onSubmit={ this.saveNotesGlobally }
                    note={this.state.edit_note}
                    is_editing={this.state.is_editing}
                    files="case_note_files"
                    onDelete={ this.deleteCaseNotes }
                /> : <UpdateNote 
                    ref={ elt => this._notes = elt }
                    onSubmit={ this.saveNotesLocally }
                />
                }

                <DeletePlayerInfoConfirm
                    modal={this.state.openDeleteConfirmationPoppup}
                    onClose={this.onCancelDelete}
                    onConfirm={this.deleteCase}
                    type="case"
                />

                    { !this.state.lockscreen || <div className="modal-backdrop fade show"></div> }
            </div>
        );
    }

    /**
     * Open confirmation poppup.
     */
    toggleDeleteConfirmation = () => {
        this.setState({
            openDeleteConfirmationPoppup : !this.state.openDeleteConfirmationPoppup
        });
    }

    /**
     * on cancel delete confirmation
     */
    onCancelDelete = () => {
        this.toggleDeleteConfirmation();
    }

    /**
     * Delete case.
     */
    deleteCase = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }
        
        let new_state = {};
        const case_path = APIRoutes.case + this._case_id;
        const request = new Request( case_path, {}, 'DELETE', headers ).send();
        request.then( response => {
            if ( response.success ) {
                this.toggleDeleteConfirmation();
                this.clearFields();
                this.props.history.push( '/admin/cases/', { 
                    message: response.message, 
                    success: response.success 
                });
            } else {
                new_state = {
                    isProcessing    : false,
                    isSubmitted     : true,
                    isSuccess       : response.success,
                    responseMsg     : response.message
                }

                this.setState( new_state );
            }
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMsg : error.message
            };
            this.setState( new_state );
        });
    }

    /**
     * Save notes locally.
     * currently working on single note.
     */
    saveNotesLocally = ( note_fields ) => {
        let new_case_notes = [ ...this.state.fields.case_note ];
        new_case_notes = [{ note: note_fields.note, is_restricted: note_fields.is_restricted }]

        this.setState({
            fields: {
                ...this.state.fields,
                case_note: new_case_notes,
                case_attachments: note_fields.files
            }
        }, () => {
            this._notes.toggleNotesPopup( true );
        });
    }

    listLocalNotes = () => {
        const notes = this.state.fields.case_note,
        attachments = this.state.fields.case_attachments;

        return notes && notes.length > 0 ? notes.map( ( note, n_idx ) => {
            return (
                <li key={n_idx} className="media">								
                    <div className="media-body clearfix">
                        <div className="nc-nb-heading">Note</div>	
                        <p>{note.note}</p> 
                        <div className="note-add-modify">
                            <div className="row marginB5">
                                <div className="col-md-1 col-sm-12">Added by: </div>
                                <div className="col-md-11 col-sm-12 fontBold">{ get_current_user_full_name() }</div>
                            </div>
                            <div className="row">
                                <div className="col-md-1 col-sm-12">Attachments: </div>
                                <div className="col-md-11 col-sm-12">
                                    {
                                        attachments && Object.keys( attachments ).length > 0 ? Object.values( attachments ).map( ( attachment, idx ) => {
                                            return <div key={idx} className="text-success font-size14 word-break-all">{ attachment.name }</div>
                                        }) : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            );
        }) : '';
    }

        /**
     * handle edit note button click
     */
    editNote = (event , note_id) => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }
        
        const api_route_path = APIRoutes.case_note + note_id;
        const request = new Request(api_route_path, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                console.log(response.data);
                 this.setState({
                     edit_note : response.data[0],
                     is_editing : true,
                     note_id : note_id
                 });
            }
        }, error => {
            console.log(error);
        });
       this._notes.toggleNotesPopup();
    }

    /**
     * Save notes globally and list them.
     */
    saveNotesGlobally= ( note_fields, deleted_ids = [] ) => {
        const player_note_path    = this.state.is_editing ? APIRoutes.case_note : APIRoutes.case_note + this._case_id,
              mothod              = this.state.is_editing ? "PUT" : "POST";

        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        this.setState({
            isProcessing: true
        });

        let formData = new FormData(),
            new_state = {};

        if ( this.state.is_editing ) {
            formData.append( 'note_id', this.state.note_id );
            formData.append( 'deleted_file_id', JSON.stringify( deleted_ids ) );
        }

        formData.append( 'note', note_fields.note );
        for( let i in note_fields.files ) {
            formData.append( 'files', note_fields.files[i] );
        }
       
        formData.append( 'is_restricted', note_fields.is_restricted );
        formData.append('master_requirement_id', this.state.fields.master_requirement_id);
        const request = new Request(player_note_path, formData, mothod, headers, true).send();
        request.then(response => {
            if (response.success) {
                this._notes.toggleNotesPopup( true );
                this.getAllNotes();
                new_state = {
                    isProcessing    : false,
                    isSubmitted     : true,
                    isSuccess       : response.success,
                    responseMsg     : response.message
                }
                this.setState( new_state );
            }
        }, error => {
            console.log(error);
        });
    }

        /**
     * get all notes of a particular case
     */
    getAllNotes = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }
        
        const fields = this.state.fields;
        const api_route_path = APIRoutes.case_all_note + this._case_id;
        const request = new Request(api_route_path, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                console.log(response);
                 fields['case_note'] = response.data;
                 this.setState({
                     ...this.state.fields,
                        fields
                 })
            }
        }, error => {
            console.log(error);
        });

    }

    /**
     * Delete notes.
     */
    deleteCaseNotes = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }
        
        let new_state = {};
        const note_id = this.state.note_id;
        const note_path = APIRoutes.case_note + note_id;
        const request = new Request( note_path, {}, 'DELETE', headers ).send();
        request.then( response => {
            if ( response.success ) {
                this.getAllNotes();
            }

            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message
            }
            this.setState( new_state );
        }, error => {
            console.log( error );
        });
    }


    getClubList = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const club_path = APIRoutes.get_club_list;
        const request = new Request( club_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                this.props.setClubList( response.data );
                this.setState({
                    _fetched: {
                        club_list: true
                    }
                });
            }
        }, error => {
            console.log( error );
        });
    }

    getPositions = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const position_path = APIRoutes.get_position;
        const request = new Request( position_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                this.props.setPositions( response.data );
                this.setState({
                    _fetched: {
                        positions: true
                    }
                });
            }
        }, error => {
            console.log( error );
        });
    }

    getAgents = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const agent_path = APIRoutes.get_agent_list;
        const request = new Request( agent_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                this.props.setAgents( response.data );
                this.setState({
                    _fetched: {
                        agents: true
                    }
                });
            }
        }, error => {
            console.log( error );
        });
    }

    /** 
     * get outcome data
     */
    getOutcomes = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const outcome_path = APIRoutes.get_outcome_list;
        const request = new Request(outcome_path, {}, 'GET', headers).send();
        let outcomes = [];
        request.then(response => {
            if (response.success) {
                outcomes = response.data && response.data.length > 0 ? response.data : [];
            }

            this.setState({
                outcomes: outcomes,
                _fetched: {
                    outcomes: true
                }
            })
        }, error => {
            console.log(error);
        });
    }
    
    componentDidMount = () => {
        if ( !this.state._fetched.club_list ) {
            this.getClubList();
        }

        if ( !this.state._fetched.agents ) {
            this.getAgents();
        }

        if ( !this.state._fetched.positions ) {
            this.getPositions();
        }

        if( !this.state._fetched.outcomes ) {
            this.getOutcomes();
        }

        if( this._case_id !== '') {
            this.is_editing = true;
            this.getCase();
        }
    }

    /**
     * get data of selected case for edit
     */
    getCase = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }
        
        const fields = this.state.fields;
        const club_case_path = APIRoutes.case + this._case_id;
        const request = new Request( club_case_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                const selected_club_case = response.data[0];
                
                fields['club_id'] = selected_club_case.club_id;
                fields['lead_title'] = selected_club_case.lead_title && selected_club_case.lead_title !== '' ? selected_club_case.lead_title : '';
                fields['lead_source'] = selected_club_case.lead_source && selected_club_case.lead_source !== '' ? selected_club_case.lead_source : '';
                fields['fulfillment_date'] = is_valid_db_date(selected_club_case.fulfillment_date) && selected_club_case.fulfillment_date ? new Date(selected_club_case.fulfillment_date) : null;
                fields['detail'] = selected_club_case.detail && selected_club_case !== '' ? selected_club_case.detail : '';
                fields['min_age'] = selected_club_case.min_age && selected_club_case.min_age !== '' ? selected_club_case.min_age : '';
                fields['max_age'] = selected_club_case.max_age && selected_club_case.max_age !== '' ? selected_club_case.max_age : '';
                fields['requirement_type'] = selected_club_case.requirement_type && selected_club_case.requirement_type !== '' ? selected_club_case.requirement_type : '';
                // fields['master_position_id'] = selected_club_case.master_position_id && selected_club_case.master_position_id !== '' ? selected_club_case.master_position_id  : '' ;
                // fields['additional_position_ids'] = selected_club_case.case_addition_positions[0] ? selected_club_case.case_addition_positions[0].master_position_id : '';
                fields['case_note'] = selected_club_case.case_notes && selected_club_case.case_notes.length > 0 ? selected_club_case.case_notes : [];
                fields['status'] = !selected_club_case.status;
                fields['is_contract_signed'] = selected_club_case.is_contract_signed;
                fields['master_outcome_id'] = selected_club_case.master_outcome_id !== null ? selected_club_case.master_outcome_id : '';
                fields['contract_with_player'] = selected_club_case.contract_with_player !== null ? selected_club_case.contract_with_player : '';
                
                let modified_player = {};
                for(let i in selected_club_case.case_player_considereds)
                {
                    const players_response = selected_club_case.case_player_considereds[i];
                    modified_player[i] = {name: `${players_response.player.first_name + " "+players_response.player.last_name}  Age: ${players_response.player.age}   Current Club: ${players_response.player.current_club > 0 && players_response.player.club ? players_response.player.club.club_name : players_response.player.other_club }`,
                     id: players_response.player_id, 
                     current_club: `${players_response.player.current_club > 0 && players_response.player.club ? players_response.player.club.club_name : players_response.player.other_club }`,
                     age: players_response.player.age,
                     full_name: `${players_response.player.first_name + " "+players_response.player.last_name}` };
                    fields['players_considered'] = [].concat( this.state.fields.players_considered, modified_player[i] );
                }
        
                

                let selected_agents = [];
                const agents = this.props.cases.agents;

                for(let i in agents)
                {
                    for(let j in selected_club_case.case_agents)
                {
                    if(agents[i].value === selected_club_case.case_agents[j].user_id)
                    {
                       selected_agents.push(agents[i]);
                    }
                }
                }
                fields['agent_id'] = selected_agents;

                const positions = this.props.cases.positions;
                for(let i in positions)
                {
                    if(positions[i].value === selected_club_case.master_position_id)
                    {
                        fields['master_position_id'] = positions[i];
                    }
                }
                
                let selected_additional_position = [];
                for(let i in positions)
                {
                    for(let j in selected_club_case.case_addition_positions)
                    {
                        if(positions[i].value === selected_club_case.case_addition_positions[j].master_position_id)
                        {
                            selected_additional_position.push(positions[i])
                        }
                    }
                }
                fields['additional_position_ids'] = selected_additional_position;
                

                this.setState({
                    ...fields,
                    fields,
                    case_number: selected_club_case.case_number,
                    _fetched: {
                        case_data: true
                    }
                });
            }
        }, error => {
            console.log( error );
            this.setState({
                lockErrorMsg : error.message,
                isSuccess : false,
                lockscreen : error.lockscreen ? true : false,
                _fetched: {
                    case_data: error.lockscreen ? true : false,
                }
            })
        });
    }

    /**
     * Clear form fields.
     */
    clearFields = () => {
        this.setState({
            fields: {
                club_id                 : '',
                lead_title              : '',
                lead_source             : '',
                agent_id                : null,
                fulfillment_date        : new Date(),
                master_position_id      : '',
                additional_position_ids : '',
                detail                  : '',
                status                  : '1',
                case_note               : '',
                master_requirement_id   : '2',
                min_age                 : '',
                max_age                 : '',
                requirement_type        : '',
                players_considered      : [],
                is_contract_signed      : false,
                contract_with_player    : '',
                master_outcome_id       : ''
            },
            isValid : {
                club_id                 : true,
                agent_id                : true,
                master_position_id      : true,
                lead_title              : true,
                lead_source             : true,
                fulfillment_date        : true,
                detail                  : true,
                additional_position_ids : true,
                status                  : true,
                case_note               : true,
                master_requirement_id   : true,
                min_age                 : true,
                max_age                 : true,
                requirement_type        : true,
                players_considered      : true,
            },
            error: {
                club_id                 : '',
                lead_title              : '',
                lead_source             : '',
                agent_id                : '',
                fulfillment_date        : '',
                master_position_id      : '',
                additional_position_ids : '',
                detail                  : '',
                status                  : '',
                case_note               : '',
                master_requirement_id   : '',
                min_age                 : '',
                max_age                 : '',
                players_considered      : '',
                lockscreen              : false,
                lockErrorMsg            : '',
            }
        });
    }
}

const mapStateToProps = ( state ) => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setClubList  : ( agents ) => dispatch( setClubList( agents ) ),
    setAgents    : ( agents ) => dispatch( setAgents( agents ) ),
    setPositions : ( role ) => dispatch( setPositions( role ) )
});

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( AddClubCase ) );