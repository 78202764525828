import React from 'react';
import { get_display_format_date, is_valid_db_date } from '../../../helpers/GeneralHelper';
import { Link } from 'react-router-dom';


const ClosedCases = ({ closed_cases }) => {

    let closedCasesList = [];

    if (!closed_cases || closed_cases === 'undefined' || closed_cases.length <= 0) {
        return closedCasesList;
    }

    for ( let i in closed_cases ) {
        
        const closed_case = closed_cases[i];
        
        if ( !closed_case || Object.keys( closed_case ).length <= 0 ) {
            continue;
        }
        
        const agents      = closed_case.case_agents,        
        agent_names = [];
        
        if ( agents && Object.keys( agents ).length > 0 ) {
            for( let a in agents ) {
                const agent = agents[ a ].user;
                if ( !agent || Object.keys( agent ).length <= 0 ) {
                    continue;
                }

                agent_names.push( agent.first_name + " " + agent.last_name );
            }
        }

        closedCasesList.push(
            <tr key={closed_case.id}>
                <td className="fontBold font-size16"><Link to={closed_case.club_id && (closed_case.club_id !== null || closed_case.club_id !== '') ? `/admin/club-case/${closed_case.id}` : `/admin/view-player-case/${closed_case.id}`}>{closed_case.case_number}</Link></td>

                <td>{closed_case.master_position && Object.keys(closed_case.master_position).length > 0 ? closed_case.master_position.title : ''}</td>

                <td className="d-flex align-items-center"><span className="table-clubImg"><img src={closed_case.club && closed_case.club.image_path && closed_case.club.image_path !== '' ? closed_case.club.image_path : closed_case.player && closed_case.player.image_path !== '' ? closed_case.player.image_path : require('../../../assets/images/dummy-image.png')} alt="" /></span> 

                {closed_case.club && Object.keys(closed_case.club).length > 0 ? closed_case.club.club_name : closed_case.player && Object.keys(closed_case.player).length > 0 && closed_case.player.current_club ? closed_case.player.current_club : ''}</td>

                <td className="fontBold text-opt-four">{closed_case.fulfillment_date && closed_case.fulfillment_date !== '' && is_valid_db_date(closed_case.fulfillment_date) ? get_display_format_date(closed_case.fulfillment_date) : ''}</td>

                <td>{ agent_names.join( ', ' ) }</td>
            </tr>
        );
    }
    return closedCasesList;
}
export default ClosedCases;