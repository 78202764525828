import React from 'react';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import UpdateNote from '../common/UpdateNote';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setNotes } from '../../../actions/NotesAndConciergesAction';
import { get_current_user_role, get_display_format_date, get_authorization_token, __x } from '../../../helpers/GeneralHelper';

class Notes extends React.Component {
    constructor( props ) {
        super( props );

        this._notes             = React.createRef();
        this._player_id         = props.match.params.player_id;
        this.current_user_role  = get_current_user_role();

        this._organisation_id   = props.match.params.id; // organisation id

        this._fetched = {
            notes   : false,
            filters : false
        }
        
        this.state = {
            note            : {},
            notes           : [],
            filters         : [],
            visible_notes   : [],
            added_by_filter : '',
            is_editing      : false,
            is_organisation : props.is_organisation ? props.is_organisation : false,
        }
    }

    /**
     * Set required states when props changed.
     */
    componentWillReceiveProps = new_props => {
        this.setState({
            notes: new_props.notesandconcierges.notes,
            visible_notes: new_props.notesandconcierges.notes,
            added_by_filter: '' //clear filter field
        });
    }

    /**
     * List filter options.
     */
    listFilter = () => {
        const filters = this.state.filters;
        let filter_options = filters && filters.length > 0 ? filters.map( filter => {
            return <option key={filter.added_by} value={filter.added_by}>{filter.added_by}</option>;
        }) : '';

        return filter_options;
    }

    /**
     * Set current chosen added_by to filter the notes.
     */
    handleFilterChange = event => {
        this.setState({
            added_by_filter: event.target.value
        });
    }

    /**
     * Filter notes by added_by field.
     */
    filterByAddedby = () => {
        const added_by_filter = this.state.added_by_filter;
        const all_notes = this.state.notes;
        const notes = all_notes && all_notes.length > 0 ? all_notes.filter( note => {
            return note.added_by === added_by_filter || added_by_filter === '';
        }) : '';

        this.setState({
            visible_notes: notes
        });
    }

    render = () => {
        return (
            <div className="col-md-6 col-sm-12">
                <div className="player-detail-block">
                    <div className="player-detail-heading d-flex align-items-center">
                        Notes
                        <button onClick={ this._notes.toggleNotesPopup } type="button" className="transparent-btn float-right ml-auto" data-toggle="modal" data-target="#addNotesModal">
                            <img src={ require( "../../../assets/images/add-icon.png" ) } alt="" />
                        </button>
                    </div>
                    <div className="player-detail-search">
                        <div className="filtter-section">
                            <div className="row">
                                <div className="col-md-9 d-flex align-items-center">
                                    <form action="" className="usm-form">
                                        <div className="form-group marginB0 filter-form">
                                            <label>View Notes by</label>
                                            <select onChange={ this.handleFilterChange } value={ this.state.added_by_filter } name="filter" className="form-control">
                                                <option value=""></option>
                                                { this.listFilter() }
                                            </select>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-3">
                                    <div className="apply-filtter float-right d-flex align-items-center flex-column">
                                        <i className="material-icons marginB5"><img src={ require( "../../../assets/images/filter-icon.png" ) } alt="" /></i>
                                        <button onClick={ this.filterByAddedby } className="btn btn-success borderRadius15 text-uppercase small-button">Apply Filter</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="player-detail-content">
                        <div className="notes-block">
                            <ul className="list-unstyled">
                                { this.listNotes() }
                            </ul>
                        </div>
                    </div>
                </div>
                <UpdateNote
                    ref={ elt => this._notes = elt }
                    player_id={ this._player_id }
                    refreshNotes={ this.refreshNotes }
                    is_editing={ this.state.is_editing }
                    note={ this.state.note }
                    userRole={ this.current_user_role }
                    is_organisation={ this.state.is_organisation }
                    organisation_id={ this._organisation_id }
                    files={ this.state.is_organisation ? 'organization_note_files' : ''}
                />
            </div>
        );
    }

    /**
     * List notes rows.
     */
    listNotes = () => {
        const notes = this.state.visible_notes;
        
        return notes && notes.length > 0 ? notes.map( note => {
            const attachments = this.state.is_organisation ? note.organization_note_files : note.player_note_files;
            const is_restricted = note.is_restricted && this.current_user_role !== 'Admin';

            /**
             * If current note is restricted for current user.
             */
            if ( is_restricted ) {
                return (
                    <li key={ note.id } className="media">
                        <div className="media-body clearfix">
                            <div className="nc-nb-heading marginB10">Note</div>
                            <div className="note-add-modify">
                                <div className="row">
                                    <div className="col-md-12">
                                        <img src={ require("../../../assets/images/Restricted.png") } alt="" />
                                        <p className="fontBold text-danger font-size14 restricted-css">{ __x( `Restricted` ) }
                                        <span className="fontBold text-white d-block">{ __x( `Please contact administrator.` ) }</span>
                                        </p> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                );
            }
            
            /**
             * List for non restricted notes
             */
            return (
                <li key={ note.id } className="media">
                    {
                        this.current_user_role === 'Agent' ? '' :
                        <button onClick={ event => this.editNote( event, note.id ) } type="button" className="transparent-btn align-self-start mr-3">
                            <i className="material-icons text-opt-four font-size20">edit</i>
                        </button>
                    }
                    <div className="media-body clearfix">
                        <div className="nc-nb-heading">Note</div>
                        <p>{note.note}</p>
                        <div className="note-add-modify">
                            <div className="row marginB5">
                                <div className="col-md-3 col-sm-12">Added by:</div>
                                <div className="col-md-9 col-sm-12 fontBold">{ note.added_by }</div>
                            </div>
                            <div className="row marginB5">
                                <div className="col-md-3 col-sm-12">Last modified:</div>
                                <div className="col-md-9 col-sm-12 fontBold">{ get_display_format_date( note.updated_at ) }</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12">Attachments: </div>
                                <div className="col-md-9 col-sm-12">
                                    {
                                        attachments && attachments.length > 0 ? attachments.map( attachment => {
                                            return (<div key={attachment.id} className="text-success font-size14 word-break-all marginB10">
                                                <a href={attachment.filename} target="_blank" rel="noopener noreferrer">{ attachment.original_filename }</a>
                                            </div>)
                                        }) : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            );
        }) : '';
    }

    editNote = ( event, note_id ) => {
        if ( !note_id )
            return false;

        const notes = this.state.notes;
        let current_note = notes && notes.length > 0 ? notes.filter( note => {
            return note.id === note_id;
        }) : [];

        current_note = current_note.length > 0 ? current_note[0] : {};

        this.setState({
            note        : current_note,
            is_editing  : true
        }, this._notes.toggleNotesPopup() );
    }

    refreshNotes = () => {
        this.getNotes();
        this.getPlayerNotesFilters();
    }

    /**
     * Fetch all notes
     */
    getNotes = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }
        
        const notes_path = this.state.is_organisation ? APIRoutes.organization_note + this._organisation_id : APIRoutes.get_player_notes + this._player_id; 
        const request = new Request( notes_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                this.props.setNotes( response.data );
                this._fetched = {
                    ...this._fetched,
                    notes: true
                }
                /**
                 * Clear filter field
                 */
                this.setState({
                    added_by_filter : ''
                });
            }
        }, error => {
            console.log( error );
        });
    }

    /**
     * Fetch all notes
     */
    getPlayerNotesFilters = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }
        // organisation path will be provided once api will be generated
        const notes_filter_path = this.state.is_organisation ? APIRoutes.get_orgnotes_addedby + this._organisation_id : APIRoutes.get_player_notes_filters + this._player_id;
        const request = new Request( notes_filter_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                this._fetched = {
                    ...this._fetched,
                    filters: true
                }

                this.setState({
                    filters: response.data
                });
            }
        }, error => {
            console.log( error );
        });
    }

    componentDidMount = () => {
        if ( !this._fetched.notes ) {
            this.getNotes();
        }
        
        if ( !this._fetched.filters ) {
            this.getPlayerNotesFilters();
        }
    }
}

const mapStateToProps = ( state ) => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setNotes  : notes => dispatch( setNotes( notes ) )
});

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( Notes ) );