import React from 'react';
import 'react-input-range/lib/css/index.css';
import { get_parameter_by_name, get_authorization_token, __x } from '../../../helpers/GeneralHelper';
import Request from '../../../helpers/Request';
import APIRoutes from '../../../API/Routes';
import ResultSections from './ResultSections';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class SearchDashboard extends React.Component {
    
    _page_title = `Search Dashboard - USM`;

    constructor ( props ) {
        super( props );

        const query = props.location.search;

        this.filters = {
            tag     : {
                label   : "Keyword",
                store   : ''
            },
            age_min : {
                label   : "Age Min",
                store   : ''
            },
            age_max : {
                label   : "Age Max",
                store   : ''
            },
            height_min : {
                label   : "Height Min",
                store   : ''
            },
            height_max : {
                label   : "Height Max",
                store   : ''
            },
            primary_position_id : {
                label   : "Primary Position",
                store   : 'primaryPositionOptions'
            },
            secondary_position_id : {
                label   : "Additional Positions",
                store   : 'additionalPositionOptions'
            },
            nationality_id : {
                label   : "Nationality",
                store   : 'nationalityOptions'
            },
            opportunity_type_id : {
                label   : "Opportunity Type",
                store   : 'opportunityTypeOptions'
            },
            professional_level_id : {
                label   : "Professional Level",
                store   : 'professionalLevelOptions'
            },
            league_id : {
                label   : "League",
                store   : 'leagueOptions'
            },
            player_passport_id : {
                label   : "Passport",
                store   : 'passportOptions'
            }
        }

        this.state = {
            query,
            result          : {},
            message         : '',
            showAllSections : {
                broker      : false,
                other       : false,
                usm         : false,
                watchlist   : false
            }
        };
        this.searchPlayers();
    }

    componentWillReceiveProps = props => {
        if ( props.location.search !== this.state.query ) {
            this.setState({
                query : props.location.search
            });
        }
    }
    
    showSearchedFilters = () => {
        const { search } = this.props;
        const result = [];
        
        for ( let i in this.filters ) {
            const searched = get_parameter_by_name( i );
            if ( !searched ) {
                continue;
            }
            
            const filter = this.filters[i];
            if ( filter.store === '' ) {
                const unit = i === 'tag' ? '' : ( i.includes( 'age' ) ? 'Y' : ' cm' );
                result.push(
                    <span key={i} className="usm-searched-options">
                        { filter.label }:<span className="marginL5 tag-value">{ searched }{unit}</span>
                    </span>
                );
                continue;
            }

            if ( i === 'secondary_position_id' ) {
                const positions = [];
                for( let j in search[ filter.store ] ) {
                    const option = search[ filter.store ][j];
                    if ( option.value !== '' && searched.indexOf( option.value.toString() ) >= 0 ) {
                        positions.push( option.label );
                    }
                }

                result.push(
                    <span key={i} className="usm-searched-options">
                        { filter.label }:<span className="marginL5 tag-value">{ positions.join( ', ' ) }</span>
                    </span>
                );
                continue;
            }
            
            const state = search[ filter.store ] && search[ filter.store ] !== null ? search[ filter.store ].filter( option => option.value.toString() === searched ) : [];
            result.push(
                <span key={i} className="usm-searched-options">
                    { filter.label }:<span className="marginL5 tag-value">{ state && state.length > 0 ? state[0].label : '' }</span>
                </span>
            );
        }
        return result;
    }

    componentDidUpdate = ( prevState ) => {
        if ( prevState.location.key !== this.props.location.key ) {
            this.searchPlayers();
        }
    }

    componentDidMount = () => {
        document.title = this._page_title;
    }

    /**
     * Global player search
     */
    searchPlayers = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess       : false
            });
            return false;
        }
        
        const route = APIRoutes.search + this.state.query;
        
        const request = new Request( route, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                this.setState({
                    message: response.message && response.message !== null ? response.message : '',
                    result: response.data
                });
            }
        }, error => {
            console.log( error );
        });
    }

    showAll = ( event, section ) => {
        const showAllSections = this.state.showAllSections;
        this.setState({
            showAllSections : {
                ...showAllSections,
                [section] : !showAllSections[ section ]
            }
        });
    }

    /**
     * Render searched result sections.
     */
    renderSections = () => {
        const result = this.state.result;
        const sections = [];

        for ( let i in result ) {
            if ( i === 'count' ) {
                continue;
            }

            sections.push(
                <ResultSections
                    key={i}
                    section={i}
                    players={ result[i] }
                    showAll={ this.showAll }
                    showingAll={ this.state.showAllSections[ i ] }
                />
            );
        }
        return sections;
    }

    render = () => {
        const result = this.state.result,
        total_result = result.count && Object.keys( result.count ).length > 0 ? Object.values(  result.count  ).reduce( ( total, value ) => parseInt( total ) + parseInt( value ) ) : 0;

        return (
            <div id="mainContainer">
                <div className="heading-section align-items-center marginTB35 auto-height">
                    <h2 className="d-inline-block">Search: 
                        <span className="lh20 marginT5 font-size20"> { this.state.message !== '' && total_result === 0 ? this.state.message : `${total_result} result for` }
                        </span>
                    </h2>
                    { this.showSearchedFilters() }
                </div>
                <div className="content-section">
                    { this.renderSections() }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter( connect( mapStateToProps )( SearchDashboard ) );