import React from 'react';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import { withRouter } from 'react-router-dom';
import { setCurrentUser } from '../../../actions/UsersAction';
import { connect } from 'react-redux';
import { get_authorization_token, isUserImageValid, __x } from '../../../helpers/GeneralHelper';
import IntelNotes from './IntelNotes';
import UpdateAgent from './UpdateAgent';
import UploadPics from '../UploadPics';
import LoadingSpinner from '../common/LoadingSpinner';
import Alert from '../../../helpers/Alert';


class RightDrawer extends React.Component {

    constructor(props) {
        super(props);

        this._agent_id = props.match.params.agent_id;
        this._uploadComponent = React.createRef();

        this._fetched = {
            agent: false
        }

        this.state = {
            agent: {},
            openAgentPopup: false,
            badgeUploaded: false,
            fileName: 'Upload Photo',
            isbadgeValid: false,
            badgeError: '',
            imgPreview: '',
            badge: {},
            responseMsg: '',
            isProcessing: false,
            isSubmitted: false,
            isSuccess: false,
            imgUploadCancel: `Cancel`,
            imgUploadHeader: `Replace Photo`,
            imgPreviewInProfile: '',
            fileNameInProfile: '',
            show_status : false, //used to show the status of agent when data is available
        };
    }

    componentWillReceiveProps = props => {
        const agent_details = props.users.user;
        let imgPreview = this.state.imgPreview,
            fileName = this.state.fileName,
            imgPreviewInProfile = this.state.imgPreviewInProfile,
            fileNameInProfile = this.state.fileNameInProfile,
            show_status = this.state.show_status;
        if (agent_details) {
            imgPreview = (agent_details.imagepath !== null ? agent_details.imagepath : "");
            fileName = agent_details.imagepath ? agent_details.imagepath : 'Upload Photo';
            imgPreviewInProfile = (agent_details.imagepath !== null ? agent_details.imagepath : "");
            fileNameInProfile = agent_details.imagepath ? agent_details.imagepath : '';
            show_status = true;
        }
        this.setState({
            agent: props.users.user,
            imgPreview,
            fileName,
            imgPreviewInProfile,
            fileNameInProfile,
            show_status
        });
    }

    /**
     * Get current agent details.
     */
    getAgent = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x(`Session has been expired. Please reload the page to login!`)
            });
            return false;
        }

        const agent_path = APIRoutes.agent + this._agent_id;
        const request = new Request(agent_path, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                const agent = response.success ? response.data : {};
                this.props.setAgent(agent);
                this._fetched.agent = true;

                this.setState({
                    show_status : true
                })
            }
        }, error => {
            console.log(error);
        });
    }

    componentDidMount = () => {
        if (!this._fetched.agent) {
            this.getAgent();
        }
    }

    toggleAgentPopup = () => {
        this.setState({
            openAgentPopup: !this.state.openAgentPopup
        }, () => {
            if ( this.state.openAgentPopup ) {
                document.body.classList.add( 'modal-open' );
            } else {
                document.body.classList.remove( 'modal-open' );
            }
        });
    }

    /**
     * handle pic change
     */
    handleBadgeChange = (event, file) => {
        const isImageValid = isUserImageValid(file);
        if (!isImageValid) {
            this.setState({
                fileName: 'Upload Photo',
                isbadgeValid: false,
                badgeError: "No Image Found!",
            });
        } else {
            if (!isImageValid.success) {
                this.setState({
                    fileName: 'Upload Photo',
                    isbadgeValid: false,
                    badgeError: isImageValid.messages.map((error, idx) => <li key={idx}>{error}</li>)
                });
            } else {
                /**
                 * Get preview file url using file reader
                 */
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = function (e) {
                    this.setState({
                        imgPreview: [reader.result],
                        fileName: file.name,
                        badge: file,
                        isbadgeValid: true,
                        badgeError: ''
                    });
                }.bind(this);
            }
        }
    }

    /**
     * save new agent image
     */
    handleUplodadBadge = (event) => {
        event.preventDefault();

        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x(`Session has been expired. Please reload the page to login!`)
            });
            return false;
        }

        if (!this.state.isbadgeValid) {
            return false;
        }

        this.setState({
            isProcessing: true
        });

        const badge_updated_path = APIRoutes.agent_profile_image + this._agent_id;

        let new_state = {},
            formData = new FormData();
        formData.append('file', this.state.badge);

        /**
         * Sending request to add/update player image.
         */
        const request = new Request(badge_updated_path, formData, 'POST', headers, true).send();
        request.then(response => {
            new_state = {
                badgeUploaded: response.success,
                isProcessing: false,
                isSubmitted: true,
                isSuccess: response.success,
                responseMsg: response.message
            };

            if (response.success) {
                this.getAgent();
                this._uploadComponent.current.closePopup();
            }

            this.setState(new_state);
        }, error => {
            new_state = {
                isProcessing: false,
                isSubmitted: true,
                isSuccess: error.success,
                responseMsg: error.message
            };
            this.setState(new_state);
        });
    }

    /**
     * handle close image change popup
     */
    onCloseImgUpload = () => {
        this.setState({
            badge: {},
            imgPreview: this.state.imgPreviewInProfile ? this.state.imgPreviewInProfile : '',
            fileName: this.state.fileNameInProfile && this.state.fileNameInProfile !== '' ? this.state.imgPreviewInProfile : __x(`Upload Photo`)
        });
    }

    resetAlert = () => {
        this.setState({
            responseMsg: ''
        });
    }

    render() {
        const agent = this.state.agent,
            responseMsg = this.state.responseMsg !== '' && this.state.isSubmitted ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} floating="true" onClose={this.resetAlert} /> : '';
        return (
            <div id="drawerOpen" className="d-flex align-items-stretch">
                {responseMsg}
                {this.state.isProcessing ? <LoadingSpinner /> : ''}
                <div className="drawerInner">
                    <div className="dw-user-block">
                        {
                            this.current_user_role === 'Agent' ? '' :
                            <UploadPics
                                ref={this._uploadComponent}
                                badgeUploaded={this.state.badgeUploaded}
                                onChange={this.handleBadgeChange}
                                onSave={this.handleUplodadBadge}
                                imgPreview={this.state.imgPreview}
                                file={this._file}
                                onClose={this.onCloseImgUpload}
                                error={this.state.badgeError}
                                fileName={this.state.fileName}
                                required={true}
                                title={this.state.imgUploadHeader}
                                cacnelText={this.state.imgUploadCancel}
                                badge={this.state.badge}
                            />
                        }
                        <div className="player-img-wrap">
                            <img src={this.state.imgPreviewInProfile && this.state.imgPreviewInProfile !== '' ? this.state.imgPreviewInProfile : require('../../../assets/images/dummy-image.png')} className="marginT14" alt="" />
                        </div>
                    </div>
                    <div className="dw-user-detail">
                        <h5>{agent.first_name ? agent.first_name : ''} {agent.last_name ? agent.last_name : ''}</h5>
                       { this._fetched.agent ? agent && agent.status ? <button className="btn btn-success xs-small-btn">Active</button> : <button className="btn btn-danger xs-small-btn">Deactivated</button> : ''}
                        <div className="dw-user-contact-detail">
                            <button onClick={this.toggleAgentPopup} type="button" className="transparent-btn float-right">
                                <i className="material-icons text-offWhite font-size20 align-middle">edit</i>
                            </button>
                            <div className="user-label">Phone</div>
                            <div className="label-content">{agent.country_code && agent.phone ? `+${agent.country_code}` : ''}{agent.phone ? agent.phone : ''}</div>
                            <div className="user-label">Email</div>
                            <div className="label-content">{agent.email ? agent.email : ''}</div>
                        </div>
                    </div>

                    <IntelNotes />
                </div>
                <UpdateAgent
                    modal={this.state.openAgentPopup}
                    closePopup={this.toggleAgentPopup}
                    refresh={this.getAgent}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setAgent: agent => dispatch(setCurrentUser(agent))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RightDrawer));