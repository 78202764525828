import React, { Component } from 'react';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import { withRouter } from 'react-router-dom';
import { setReports } from '../../../actions/PlayersAction';
import { connect } from 'react-redux';
import { get_current_user_role, get_display_format_date, get_authorization_token, __x } from '../../../helpers/GeneralHelper';
import UpdateReport from './UpdateReport';
import SendReport from './SendReport';

class Reports extends Component {

    constructor( props ) {
        super( props );
        
        this._report            = React.createRef();
        this._player_id         = props.match.params.player_id;
        this.current_user_role  = get_current_user_role();

        this._fetched   = {
            reports: false
        };

        this.state = {
            report      : {},
            reports     : [],
            openPopup   : false,
            is_editing  : false,
            report_id   : 0,
            shouldReportSendPopupOpen : false,
            send_report_id : 0,
        };
    }

    /**
     * Updated state with new props.
     */
    componentWillReceiveProps = props => {
        this.setState({
            reports: props.players.reports
        });
    }

    /**
     * Toggle add/edit popup.
     */
    openPopup = ( event, report_id ) => {
        if ( report_id && report_id > 0 ) {
            /**
             * Get data from API for single report.
             */
            this.getReport( report_id );
            return;
        }

        this.setState({
            openPopup: !this.state.openPopup
        }, () => {
            if ( this.state.openPopup ) {
                document.body.classList.add( 'modal-open' );
            }
        });
    }

    closePopup = () => {
        let report      = {},
        is_editing      = false;

        this.setState({
            report,
            is_editing,
            openPopup: !this.state.openPopup
        }, () => {
            if ( !this.state.openPopup ) {
                document.body.classList.remove( 'modal-open' );
            }
        });
    }

    /**
     * Get single report from API.
     */
    getReport = report_id => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const reports_path = APIRoutes.player_report + report_id;
        const request = new Request( reports_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                let report = response.data;
                this.setState({
                    report,
                    is_editing  : true,
                    openPopup   : !this.state.openPopup
                }, () => {
                    if ( this.state.openPopup ) {
                        document.body.classList.add( 'modal-open' );
                    }
                });

                this._fetched.reports = true;
            }
        }, error => {
            console.log( error );
        });
    }

    /**
     * Get all reports from API.
     */
    getReports = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const reports_path = APIRoutes.get_player_reports + this._player_id;
        const request = new Request( reports_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                this.props.setReports( response.data );
                this._fetched.reports = true;
            }
        }, error => {
            console.log( error );
        });
    }
    
    componentDidMount = () => {
        if ( !this._fetched.reports ) {
            this.getReports();
        }
    }

    render() {
        return (
            <div className="player-detail-block">
                <div className="player-detail-heading d-flex align-items-center">Reports
                    {
                        this.current_user_role === 'Agent' ? '' : 
                        <button type="button" onClick={ this.openPopup } className="transparent-btn float-right ml-auto" data-toggle="modal" data-target="#reportModal">
                            <img src={require("../../../assets/images/add-icon.png")} alt="" />
                        </button>
                    }
                </div>
                <div className="player-detail-content">
                    <div className="notes-block">
                        <ul className="list-unstyled">
                            { this.listReports() }
                        </ul>
                    </div>
                </div>

                <UpdateReport 
                    ref={ elt => this._report = elt }
                    modal={ this.state.openPopup }
                    openPopup={ this.openPopup }
                    closePopup={ this.closePopup }
                    refresh={ this.getReports }
                    is_editing={ this.state.is_editing }
                    report={ this.state.report }
                    report_id={ this.state.report_id }
                    player_id={ this._player_id }
                />

                <SendReport
                    modal     = { this.state.shouldReportSendPopupOpen }
                    report_id = {this.state.send_report_id }
                    closePopup = { this.closeSendReportPopup }
                />    
            </div>
        );
    }

    listReports = () => {
        const reports = this.state.reports;
        return reports && reports.length > 0 ? reports.map( report => {
            const files         = report.player_report_files;
            const is_restricted = report.is_restricted && get_current_user_role() !== 'Admin';

            /**
             * If current note is restricted for current user.
             */
            if ( is_restricted ) {
                return (
                    <li key={ report.id } className="media marginB15">
                        <div className="media-body clearfix">
                            <div className="note-add-modify">
                                <div className="row">
                                    <div className="col-md-2 text-center">
                                        <img src={ require("../../../assets/images/Restricted.png") } alt="" />
                                    </div>
                                    <div className="col-md-10 paddingL0">
                                        <p className="fontBold text-danger font-size14">{ __x( `Restricted` ) }
                                        <span className="fontBold text-white d-block">{ __x( `Please contact administrator.` ) }</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                );
            }

            return(
                <li key={ report.id } className="media marginB15 usm-report">
                    <div className="align-self-start mr-3">
                        <button type="button" className="transparent-btn cusror-default">
                            <img src={ require( "../../../assets/images/report-icon.png" ) } alt="" />
                        </button>

                        <button title="Send Report" type="button" onClick={ e => this.openReportSendPopup( report.id ) } className="transparent-btn d-block marginT5">
                        <i className="material-icons text-offWhite font-size20">share</i>
                        </button>
                    </div>
                    <div className="media-body clearfix">
                        <div className="nc-nb-heading">
                            {
                                this.current_user_role === 'Agent' ? '' : 
                                <button type="button" onClick={ e => this.openPopup( e, report.id ) } className="transparent-btn float-right">
                                    <i className="material-icons text-opt-four font-size20">edit</i>
                                </button>
                            }
                            <p className="text-white">{ report.title } [{ get_display_format_date( report.updated_at ) }]</p>
                        </div>
                        <p>{ report.description }</p>
                        <div className="row">
                            <div className="col">
                                {
                                    files && files.length > 0 ? files.map( file => {
                                        return (<div key={file.id} className="text-success font-size14 word-break-all marginB10">
                                            <a href={file.filename} target="_blank" rel="noopener noreferrer">{ file.original_filename }</a>
                                        </div>)
                                    }) : ''
                                }
                            </div>
                        </div>
                    </div>
                </li>
            );
        }) : [];
    }
    /**
     * open popup to send report 
     */
    openReportSendPopup = (report_id = 0) => {

        this.setState({
            shouldReportSendPopupOpen: !this.state.shouldReportSendPopupOpen,
            send_report_id : report_id
        }, () => {
            if (this.state.shouldReportSendPopupOpen) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            }
        });
    }
    /**
 * close send report popup
 */
    closeSendReportPopup = () => {
        this.setState({
            shouldReportSendPopupOpen: !this.state.shouldReportSendPopupOpen,
            send_report_id : 0
        }, () => {
            if ( !this.state.shouldReportSendPopupOpen ) {
                document.body.classList.remove( 'modal-open' );
            }
        });
    }
}

const mapStateToProps = state => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setReports: reports => dispatch( setReports( reports ) )
});

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( Reports ) );