import React from 'react';
import { withRouter } from 'react-router-dom';
import IntlTelInput from 'react-intl-tel-input';
import '../../../../node_modules/react-intl-tel-input/dist/main.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { get_current_user_role, get_db_date, get_authorization_token, is_bday_valid, __x, is_valid_db_date } from '../../../helpers/GeneralHelper';
import * as EmailValidator from 'email-validator';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import LoadingSpinner from '../common/LoadingSpinner';
import Alert from '../../../helpers/Alert';
import DeletePlayerInfoConfirm from './DeletePlayerInfoConfirm';

class UpdateContact extends React.Component {

    constructor( props ) {
        super( props );

        this._player_id = props.match.params.player_id;
        this._organisation_id = props.match.params.id;
        
        this._country   = React.createRef();
        this.state = {
            contact : {},
            fields : {
                first_name      : '',
                last_name       : '',
                email           : '',
                phone           : '',
                birthday        : null,
                country_code    : '44',
                country_iso     : 'gb',
                relation        : '',
                is_restricted   : false,
                is_player_contact           : false,
                show_birthday_notification  : false,
                title           : '', //added for organisation contact popup 
            },
            isValid : {
                first_name      : true,
                last_name       : true,
                email           : true,
                phone           : true,
                birthday        : true,
                country_code    : true,
                relation        : true,
                title           : true, //added for organisation contact popup 
            },
            error : {
                first_name      : '',
                last_name       : '',
                email           : '',
                phone           : '',
                birthday        : '',
                country_code    : '',
                relation        : '',
                title           : '', //added for organisation contact popup
            },
            is_editing      : false,
            contact_id      : 0,
            isProcessing    : false,
            isSubmitted     : false,
            isSuccess       : false,
            responseMsg     : '',
            is_organisation : false, // used if organisation add/update contact modal needs to be opened
        }
    }

    /**
     * Set contact data into local fields state to be edited.
     */
    componentWillReceiveProps = props => {
        
        /**
         * condition added for setting organisation contact popup fields 
         */
        if( props.is_organisation ) {
            this.setState({
                is_organisation : props.is_organisation
            });
        }

        const contact = props.contact;
        if ( !contact || Object.keys( contact ).length <= 0 || !props.is_editing ) {
            return false;
        }

        let fields = this.state.fields;

        fields = {
            ...fields,
            first_name      : contact && contact.first_name ? contact.first_name : '',
            last_name       : contact && contact.last_name ? contact.last_name : '',
            email           : contact && contact.email ? contact.email : '',
            phone           : contact && contact.phone ? contact.phone : '',
            birthday        : contact && contact.birthday && is_valid_db_date( contact.birthday ) ? new Date( contact.birthday ) : null,
            country_code    : contact && contact.country_code ? contact.country_code : '44',
            country_iso     : contact && contact.country_iso ? contact.country_iso : 'gb',
            relation        : contact && contact.relation ? contact.relation : '',
            is_restricted   : contact && contact.is_restricted ? contact.is_restricted : false,
            is_player_contact           : contact && contact.is_player_contact && contact.is_player_contact === true,
            show_birthday_notification  : contact && contact.show_birthday_notification ? contact.show_birthday_notification : false,
            title           : contact && contact.title ? contact.title : '',
        };

        this.setState({
            fields,
            contact,
            contact_id : contact && contact.id ? contact.id : 0,
            is_editing : props.is_editing,
            is_organisation : props.is_organisation ? true : false
        }, () => {
            if ( get_current_user_role() === 'Admin' || !this.state.is_editing || !this.state.contact.is_restricted ) {
                this._country.setFlag( fields.country_iso );
            }
        });
    }

    /**
     * Handle fields changes
     */
    handleChanges = ( event ) => {
        const target    = event.target,
        name            = target.name,
        value           = target.type === 'checkbox' ? target.checked : target.value,
        fields          = this.state.fields;

        let isValid = this.state.isValid,
        error       = this.state.error,
        fileName    = this.state.fileName;
        
        if ( [ 'first_name', 'last_name', 'relation', 'title' ].indexOf( name ) >= 0 ) {
            fields[ name ]  = value;
            isValid[ name ] = fields[ name ] !== '' ? fields[ name ].length >= 1 && fields[ name ].length <= 255  : true;
            error[ name ]   = !isValid[ name ] ? __x( `Maximum 255 characters allowed.` ) : '';
        } 
        
        if ( name === 'email' ) {
            fields[ name ]  = value;
            isValid[ name ] = fields[ name ] !== '' ? fields[ name ].length >= 1 && fields[ name ].length <= 255 && EmailValidator.validate( fields[ name ] ) : true;
            error[ name ]   = !isValid[ name ] ? __x( `Please enter a valid email.` )  : '';
        } 
        
        else {
            fields[ name ] = value;
        }
        
        this.setState({ ...fields, fields, isValid, fileName, error });
    }

    /**
     * Handles country code changes
     */
    handleCountryCodeChange = ( value, countryData ) => {
        const fields    = this.state.fields,
        isValid         = this.state.isValid,
        country_code    = countryData? countryData.dialCode : '44';
        
        fields[ 'country_code' ]    = country_code;
        fields[ 'country_iso' ]     = countryData.iso2 ? countryData.iso2 : fields.country_iso;
        isValid[ 'country_code' ]   = fields.country_code !== '' ? fields.country_code.toString().length >= 0 && fields.country_code.toString().length <= 255 : true;
        
        this.setState({
            fields,
            isValid,
        });
    }

    /**
     * Handles phone changes.
     */
    handlePhoneChange = ( status, value, countryData ) => {
        const fields    = this.state.fields,
        isValid         = this.state.isValid,
        error           = this.state.error,
        phone           = value ? value : '';
        
        fields[ 'phone' ]   = phone;
        isValid[ 'phone' ]  = fields.phone !== '' ? fields.phone.length >= 0 && fields.phone.length <= 255 && !isNaN( fields.phone ) : true;
        error[ 'phone' ]    = !isValid.phone ? __x( `Phone is invalid.` ) : '';

        this.setState({
            fields,
            isValid,
            error
        });
    }

    /**
     * Handles birthday changes.
     */
    handleBirthdayChanges = ( date ) => {
        const fields        = this.state.fields,
        isValid             = this.state.isValid,
        error = this.state.error;

        fields[ 'birthday' ]                = date;
        isValid[ 'birthday' ]               = date === '' || date === null || !date ? true : is_bday_valid( date );
        error[ 'birthday' ]   = !isValid[ 'birthday' ] ? __x( `Birthday is invalid.` ) : '';
        
        this.setState({
            fields,
            isValid,
            error
        });
    }

    /**
     * Checks if form is valid or not.
     */
    isFormValid = () => {
        let validityErr = [];
        const fields    = this.state.fields,
        isValid         = this.state.isValid;

        const optional = [ 'is_restricted', 'country_iso', 'country_code', 'show_birthday_notification', 'email', 'phone' ];

        /**
         * If admin is not editing and `is_restricted` is checked then email and phone will be skipped.
         */
        // if ( get_current_user_role() !== 'Admin' && this.state.is_editing && this.state.contact.is_restricted ) {
        //     optional.push( 'email' );
        //     optional.push( 'phone' );
        // }

        for ( let elt in fields ) {
            if ( optional.indexOf( elt ) >= 0 ) {
                continue;
            }

            if ( elt === 'birthday' && fields[ elt ] === '' ) {
                continue;
            }

            if ( elt === 'is_player_contact' ) {
                continue;
            }
            
            // if ( elt === 'relation' ) {
            //     if ( fields.is_player_contact ) {
            //         continue;
            //     }
            // }

            /**
             * check for organisation title field (valid only for organisation contact form)
             */
            
            if(this.state.is_organisation && ( elt === 'title' && fields[ elt ] === '' )) {
                  validityErr.push( elt );
                
            }
            else{
                if ( !this.state.is_organisation && elt === 'relation' && fields[ elt ] === '' ) {
                        validityErr.push( elt );
                }
                if ( (fields[ elt ] === '' || !isValid[ elt ]) && elt !== 'title' && elt !== 'relation' ) {
                    validityErr.push( elt );
                }
            }

            // if ( fields[ elt ] === '' || !isValid[ elt ] ) {
            //     validityErr.push( elt );
            // }
        }
        return validityErr.length <= 0;
    }

    /**
     * Handles add/update contact.
     */
    updateContact = event => {

        event.preventDefault();
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( !this.isFormValid() ) {
            return false;
        }

        this.setState({
            isProcessing: true
        });

        const is_editing= this.state.is_editing,
        contact_id      = this.state.contact_id,
        is_organisation = this.state.is_organisation, //added for organisation popup
        // contact_path    = is_editing ? APIRoutes.player_contact : APIRoutes.player_contact + this._player_id,
        // contact_path    = is_organisation ? APIRoutes.organization_contact : is_editing ? APIRoutes.player_contact : APIRoutes.player_contact + this._player_id,
        contact_path    = (is_organisation && !is_editing) ? APIRoutes.organization_contact : is_organisation && is_editing ? APIRoutes.organization_contact + contact_id : is_editing ? APIRoutes.player_contact : APIRoutes.player_contact + this._player_id,
        method          = is_editing ? "PUT" : "POST";

        const fields    =  this.state.fields;
        let new_state   = {}, form_body = {};
        form_body       = is_organisation ? {
            ...fields,
            birthday : get_db_date( fields.birthday ),
            organization_id: this._organisation_id
        } : {
            ...fields,
            birthday : get_db_date( fields.birthday ),
            contact_id,
            player_id: this._player_id
        };

        /**
         * If admin is not editing and `is_restricted` is checked then email and phone will be skipped.
         */
        if ( get_current_user_role() !== 'Admin' && this.state.is_editing && this.state.contact.is_restricted ) {
            delete form_body.email;
            delete form_body.phone;
        }

        /**
         * Sending request to add/update contacts.
         */
        const request = new Request( contact_path, form_body, method, headers ).send();
        request.then( response => {
            if ( response.success ) {
                this.closePopup();
                this.props.refresh();
            }
            
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message,
                contact_id      : response.success ? 0 : contact_id,
                is_editing      : response.success ? 0 : is_editing
            }
            this.setState( new_state );            
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMsg     : error.message
            };
            this.setState( new_state );
        });
    }

    /**
     * Handles popup closing.
     */
    closePopup = () => {
        this.clearFields();
        this.props.closePopup();
    }

    /**
     * Open confirmation poppup.
     */
    toggleDeleteConfirmation = () => {
        this.setState({
            openDeleteConfirmationPoppup : !this.state.openDeleteConfirmationPoppup
        });
    }

    /**
     * Delete contacts.
     */
    deleteContact = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }
        
        let new_state       = {};
        const contact_id      = this.state.contact_id;
        const contact_path    = this.state.is_organisation ? APIRoutes.organization_contact + contact_id : APIRoutes.player_contact + contact_id;
        const request       = new Request( contact_path, {}, 'DELETE', headers ).send();
        request.then( response => {
            if ( response.success ) {
                this.toggleDeleteConfirmation();
                this.closePopup();
                
                if ( this.props.refresh ) {
                    this.props.refresh();
                }
            }

            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message
            }
            this.setState( new_state );
        }, error => {
            console.log( error );
        });
    }

    onCancelDelete = () => {
        this.toggleDeleteConfirmation();
        this.closePopup();
    }

    /**
     * Resets alert messages when cross icon is clicked.
     */
    resetAlert = () => {
        this.setState({
            responseMsg: ''
        });
    }

    clearFields = () => {
        this.setState({
            fields : {
                first_name      : '',
                last_name       : '',
                email           : '',
                phone           : '',
                birthday        : null,
                country_code    : '44',
                country_iso     : 'gb',
                relation        : '',
                is_restricted   : false,
                is_player_contact           : false,
                show_birthday_notification  : false,
                title           : '' //added for organisation contact popup
            },
            isValid : {
                first_name      : true,
                last_name       : true,
                email           : true,
                phone           : true,
                birthday        : true,
                country_code    : true,
                relation        : true,
                title           : true, //added for organisation contact popup
            },
            error : {
                first_name      : '',
                last_name       : '',
                email           : '',
                phone           : '',
                birthday        : '',
                country_code    : '',
                relation        : '',
                title           : '',
            },
            is_editing      : false,
            contact_id      : 0,
            is_organisation : false, //added for organisation contact popup
        }, () => {
            this._country.setFlag( 'gb' );
        });
    }

    render = () => {
        const today             = new Date(),
        showPopup               = this.props.shouldPopupOpen ? 'show' : '',
        submit_disabled         = !this.isFormValid(),
        loading                 = this.state.isProcessing ? <LoadingSpinner/> : '',
        alert_message           = this.state.responseMsg !== '' ? <Alert message={ this.state.responseMsg } success={ this.state.isSuccess } floating="true" onClose={ this.resetAlert } /> : '',
        is_restricted           = get_current_user_role() !== 'Admin' && this.state.is_editing && this.state.contact.is_restricted,
        btnswidth               = this.state.is_editing ? 'min-width157' : '',
        disable_player_fields   = this.state.fields.is_player_contact === true;

        return (
            <div>
                { loading }
                { alert_message }
                <div className={`modal form-modal fade modal-active ${showPopup}`} style={{ transform: this.props.shouldPopupOpen ? 'translate(0,0)' : 'translate(-125%, -125%)' }}>
                    <div className="modal-dialog modal-dialog-centered maxWidth600" role="document">
                        <div className="modal-content">
                            <div className="modal-header form-modal-header d-flex align-items-center">
                            <img src={require('../../../assets/images/form-usm-logo.png')} alt="" />
                                <h5 className="form-modal-title">{ this.state.is_editing ? `Edit` : `Add` } { this.state.is_organisation ? `Organisation` : ``} Contact</h5>
                                <button  onClick={ this.closePopup } type="button" className="trasnparent-btn modal-close">
                                    <img src={ require( '../../../assets/images/close-modal.png' ) } alt="" />
                                </button>
                            </div>
                            <div className="modal-body form-modal-body">

                                <form action="" className="usm-form form-border">

                                    {
                                        this.state.is_organisation ? 
                                            <div className="row">
                                                <div className="col">
                                                    <div className={this.state.error.title === '' ? `form-group` : `form-group form-error`}>
                                                        <label htmlFor="">Title in Org.</label>
                                                        <input onChange={this.handleChanges} value={this.state.fields.title} disabled={disable_player_fields} name="title" type="text" className="form-control" placeholder="Title in Organisation" autoComplete="off" />
                                                        {this.state.error.title === '' ? '' : <div className="display-error">{this.state.error.title}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        : ''
                                    }
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className={ this.state.error.first_name === '' ? `form-group` : `form-group form-error`}>
                                                <label htmlFor="">First Name</label>
                                                <input onChange={ this.handleChanges } value={ this.state.fields.first_name } disabled={disable_player_fields} name="first_name" type="text" className="form-control" placeholder="First Name" autoComplete="off" />
                                                { this.state.error.first_name === '' ? '' : <div className="display-error">{ this.state.error.first_name }</div> }
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className={ this.state.error.last_name === '' ? `form-group` : `form-group form-error`}>
                                                <label htmlFor="">Last Name</label>
                                                <input onChange={ this.handleChanges } value={ this.state.fields.last_name } disabled={disable_player_fields} name="last_name" type="text" className="form-control" placeholder="Last Name" autoComplete="off" />
                                                { this.state.error.last_name === '' ? '' : <div className="display-error">{ this.state.error.last_name }</div> }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            {
                                                is_restricted ? <div className="form-group"> 
                                                    <label htmlFor="">Email</label>
                                                    <p className="text-danger font-size13">{ __x( `Restricted, please contact the administrator.` ) }</p>
                                                </div>
                                                :
                                                <div className={ this.state.error.email === '' ? `form-group` : `form-group form-error`}>
                                                    <label htmlFor="">Email</label>
                                                    <input onChange={ this.handleChanges } value={ this.state.fields.email } name="email" type="text" className="form-control" placeholder="Email" autoComplete="off" />
                                                    { this.state.error.email === '' ? '' : <div className="display-error">{ this.state.error.email }</div> }
                                                </div>
                                            }
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            {
                                                is_restricted ? <div className="form-group"> 
                                                    <label htmlFor="">Phone</label>
                                                    <p className="text-danger font-size13">{ __x( `Restricted, please contact the administrator.` ) }</p>
                                                </div>
                                                :
                                                <div className={ this.state.error.phone === '' ? `form-group` : `form-group form-error`}>
                                                    <label htmlFor="">Phone</label>
                                                    <div className="d-flex bd-highlight">
                                                        <IntlTelInput 
                                                            ref={ elt => this._country = elt }
                                                            onPhoneNumberChange={ this.handlePhoneChange }
                                                            defaultCountry={ 'gb' }
                                                            css={['intl-tel-input w-100', 'form-control']} 
                                                            placeholder="Phone"
                                                            value={ this.state.fields.phone }
                                                            separateDialCode="true"
                                                            onSelectFlag={ this.handleCountryCodeChange }
                                                        />
                                                    </div>
                                                    { this.state.error.phone === '' ? '' : <div className="display-error">{ this.state.error.phone }</div> }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        this.state.fields.is_player_contact === true || this.state.is_organisation ? '' :
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                <div className={this.state.error.relation === '' ? `form-group` : `form-group form-error`}>
                                                    <label htmlFor="">Relationship</label>
                                                    <input onChange={ this.handleChanges } value={ this.state.fields.relation } name="relation" type="text" className="form-control" placeholder="" autoComplete="off" />
                                                    { this.state.error.relation === '' ? '' : <div className="display-error">{ this.state.error.relation }</div> }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className={ this.state.error.birthday === '' ? `form-group` : `form-group form-error`}>
                                                <label htmlFor="">Birthday</label>
                                                <DatePicker
                                                    className="form-control calendar-icon"
                                                    selected={ this.state.fields.birthday }
                                                    onChange={ this.handleBirthdayChanges }
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={ new Date( today.getFullYear(), today.getMonth(), today.getDate() - 1 ) }
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={30}
                                                    disabled={disable_player_fields}
                                                />
                                                { this.state.error.birthday === '' ? '' : <div className="display-error">{ this.state.error.birthday }</div> }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group usm-check">
                                                <input onChange={ this.handleChanges } checked={ this.state.fields.show_birthday_notification } name="show_birthday_notification" type="checkbox" id="bdaynotifcation" />
                                                <label htmlFor="bdaynotifcation"> Show birthday notification</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            {
                                                is_restricted ? <div className="form-group usm-check">
                                                    <input checked={ true } name="show_birthday_notification" className="" type="checkbox" id="restrictUser" />
                                                    <label htmlFor="restrictUser" className="text-danger disabled">{ __x( `Restricted, please contact the administrator.` ) }</label>
                                                </div>
                                                :
                                                <div className="form-group usm-check">
                                                    <input onChange={ this.handleChanges } checked={ this.state.fields.is_restricted } name="is_restricted" type="checkbox" id="restrictContact" />
                                                    <label htmlFor="restrictContact"> Restrict personal contact detail to Admin View Only.</label>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer form-modal-footer d-flex justify-content-between">
                                <button onClick={ this.closePopup } type="button" className={`default-btn btn-danger ${btnswidth}`} data-dismiss="modal">Cancel</button>
                                { this.state.is_editing ? <button type="button" className="default-btn btn-danger min-width157" onClick={ this.toggleDeleteConfirmation }>Delete</button> : '' }
                                <button onClick={ this.updateContact } type="submit" disabled={submit_disabled} className={`default-btn btn-success ${btnswidth}`}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                {!this.props.shouldPopupOpen || <div className="modal-backdrop fade show"></div>}

                <DeletePlayerInfoConfirm 
                    modal={ this.state.openDeleteConfirmationPoppup }
                    onClose={ this.onCancelDelete }
                    onConfirm={ this.deleteContact }
                    type={this.state.is_organisation ? `Organisation Contact` : `Contact`}
                />
            </div>
        );
    }
}

export default withRouter( UpdateContact );