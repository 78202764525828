import React, { Component } from 'react';
import { get_authorization_token, __x } from '../../../helpers/GeneralHelper';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import LoginHistoryList from './LoginHistoryList';
import { SetLoginHistoryList, appendLoginHistoryList } from '../../../actions/UsersAction';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

 class LoginHistory extends Component { 

    constructor(props) {
        super(props);

        this._is_fetched = {
            get_login_history_data : false,
        }

        this.state = {
            login_history_results : [],
            hasMoreItems        : false,
            maxPages            : null,
            nextPage            : 1,
        }
    }

    componentDidMount = () => {
        if(!this._is_fetched.get_login_history_data)
        {
            this.getLoginHistory();
        }
    }

    /**
     * get login history data
     */
    getLoginHistory = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        this.setState({
            nextPage : 1,
            maxPages : null,
        });
        
        let login_history_results = this.state.login_history_results;
        const login_history_path   = APIRoutes.users_login_history;
        const request = new Request( login_history_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                login_history_results = response.data && response.data.length > 0 ? response.data : [];
            }
            
            /** set login history list in redux store */
            this.props.SetLoginHistoryList( login_history_results );
            this.setState({
                hasMoreItems    : response.pages && response.pages === 1 ? false : true,
                maxPages        : response.pages ? response.pages : null,
                nextPage        : this.state.nextPage + 1,
            });

            this._is_fetched.get_login_history_data = true;
        }, error => {
            console.log( error );
        });
    }

    /**
	 * load more organisations on page scroll
	 */
	loadMoreLoginHistory = () => {
		const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

		this.setState({
			hasMoreItems : false,
		});

		let maxPages = this.state.maxPages,
		nextPage     = this.state.nextPage;


		if(maxPages < nextPage) {
			this.setState({
				hasMoreItems : false,
			});
			return false;
		}

        const login_history_path = APIRoutes.users_login_history + nextPage ;
		const request = new Request(login_history_path, {}, 'GET', headers).send();
		
		request.then(response => {
			if (response.success) {
				this.setState({
					hasMoreItems : true,
					nextPage : nextPage + 1,
                });

                /**
					 * Dispatching action to set users to redux store.
					 */
                this.props.appendLoginHistoryList(this.props.users.login_history, response.data);
				
			}
		}, error => {
			console.log(error);
		});
    }

    render() {
        return(
            <div id="mainContainer">
                <div className="heading-section d-flex align-items-center">
                    <h2>Login History</h2>
                </div>

                <div className="content-section">

                    <div className="usm-table table-responsive">

                    <InfiniteScroll
                            dataLength={this.props.users.login_history && this.props.users.login_history.length > 0 ? this.props.users.login_history.length : 0 } //This is important field to render the next data
                            next={this.loadMoreLoginHistory}
                            hasMore={this.state.hasMoreItems}
                        // loader={<h4>Loading...</h4>}
                        >
                            <table className="table table-hover">
                            <thead className="usm-thead">
                                <tr>
                                    <th scope="col">User Email</th>
                                    <th scope="col">Date and Time</th>
                                    <th scope="col">Browser Used</th>
                                    <th scope="col">IP Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                <LoginHistoryList
                                    login_history_results={this.props.users.login_history} />
                            </tbody>
                        </table>

                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    SetLoginHistoryList: (login_history) => dispatch(SetLoginHistoryList(login_history)),
    appendLoginHistoryList : (all_login_history, load_login_history) => dispatch(appendLoginHistoryList(all_login_history, load_login_history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginHistory);