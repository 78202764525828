import React from 'react';

class Alert extends React.Component {
    constructor( props ) {
        super( props );

        this.state = {
            duration:  props.duration ? props.duration : 10000
        }
    }

    render = () => {
        const { message, success, floating, ...rest } = this.props;
        if ( message === '' || !message ) {
            return false;
        }
        
        let alert_classes = success ? "bg-success" : "bg-danger";
        alert_classes += floating ? ' text-center alert-float' : '';
        alert_classes += !rest.onClose ? ' text-center' : '';
    
        return (
            <div className={`alert usm-alert alert-dismissible fade show ${alert_classes}`} role="alert">
                {message}
                { !rest.onClose ? '' :
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={ rest.onClose }>
                        <span aria-hidden="true"><img src={ require( '../assets/images/close-modal.png' ) } alt="" /></span>
                    </button>
                }

                { !rest.goBack ? '' : 
                <button type="button" onClick={ rest.goBack } className="lock-back-button-color transparent-btn">Please click here to go back.</button>
            }
            </div>
        );
    }

    componentDidMount = () => {
        /**
         * Remove alert message from current history.
         * Use `isNotFlash` attribute in Alert component to not remove the messages automatically.
         */
        if ( this.props.isNotFlash === undefined ) {
            window.history.pushState({
                key     : window.history && window.history.state && window.history.state.key ? window.history.state.key : '',
                state   : undefined
            }, window.location.pathname );
        }

        if ( this.props.onClose ) {
            setTimeout( () => {
                this.props.onClose();
            }, this.state.duration );
        }
    }
}
export default Alert;