import React from 'react';
import Player from './Player';
import Request from '../../../helpers/Request';
import { get_current_user_role, get_authorization_token, __x } from '../../../helpers/GeneralHelper';
import APIRoutes from '../../../API/Routes';
import { setplayers, appendPlayers, setplayerpositions, setPlayerProfessionalLevel, setPlayerContractType } from '../../../actions/PlayersAction';
import { connect } from 'react-redux';
import Alert from '../../../helpers/Alert';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';


class Players extends React.Component {

    _page_title = 'Players - USM';

    countdown;

    /**
     * Time in miliseconds to wait until typing has done.
     */
    wait_for_search = 800;

    constructor(props) {
        super(props);

        this.fetched            = false;
        this.current_user_role  = get_current_user_role();

        this.state = {
            resetFlash: false,
            isError: false,
            responseMessage: '',
            isSuccess: false,
            fields: {
                position: '',
                professional_level: '',
                contract_type: '',
                players: [],
                player_name: '',
                predictive_player_list: []
            },
            isSearching: false,
            filtered: false,
            hasMoreItems: false,
            maxPages: null,
            nextPage: 1,
        }
    }

    handleFieldsChanges = (event) => {
        const target = event.target,
            elt_name = target.name,
            elt_value = target.value,
            fields = this.state.fields;


        if (elt_name === 'player_name') {
            fields[elt_name] = elt_value;
            fields['predictive_player_list'] = [];
            if (elt_value.length >= 3) {
                const headers = get_authorization_token();
                if (!headers) {
                    this.setState({
                        isError: true,
                        responseMessage: __x(`Session has been expired. Please reload the page to login!`),
                        isSuccess: false
                    });
                    return false;
                }
                const request = new Request(APIRoutes.get_player_list + elt_value, {}, 'GET', headers).send();
                request.then(response => {
                    if (response.success) {
                        fields['predictive_player_list'] = response.data;
                        fields[elt_name] = elt_value;
                        this.setState({
                            ...fields,
                            fields
                        });
                    }
                }, error => {
                    console.log(error);
                });
            }
        }
        else {
            fields[elt_name] = elt_value;
        }

        this.setState({
            ...fields,
            fields
        });
    }

    /**
    * Handles input field change on searching a case for assigning to player
    */
    handlePlayerNameChanges = event => {
        const target = event.target,
            value = target.value,
            fields = this.state.fields;

        if (value.length === 0 || value === '') {
            fields['predictive_player_list'] = [];
            fields['player_name'] = value;
            this.filterPlayers();
        }

        if (value.length < 3) {
            fields['player_name'] = value;
            this.setState({ fields });
            return false;
        }

        fields['player_name'] = value;

        this.setState({ fields });

        clearTimeout(this.countdown);
        this.countdown = setTimeout(this.searchPlayer, this.wait_for_search);
    }

    /**
     * Search after {`this.wait_for_search`} miliseconds case when typing is done and text is more than 3 characters.
     */
    searchPlayer = () => {
        const query = this.state.fields.player_name;
        if (query.length < 3) {
            return false;
        }

        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x(`Session has been expired. Please reload the page to login!`),
                isSuccess: false
            });
            return false;
        }

        this.setState({
            isSearching: true
        });
        const request = new Request(APIRoutes.get_player_list + query, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                const predictive_player_list = response.data;
                this.setState({
                    fields: {
                        ...this.state.fields,
                        predictive_player_list,
                    },
                    isSearching: false
                });
            }
        }, error => {
            console.log(error);
        });
    }



    /**
     * Clears all filters
     */
    clearFilter = () => {
        this.setState({
            fields: {
                position: '',
                professional_level: '',
                contract_type: '',
                players: [],
                // file_path: '',
                player_name: '',
                predictive_player_list: []
            },
            filtered: false
        });
        this.getPlayers();
    }

    selectPlayer = (player_name) => {
        let fields = this.state.fields;
        fields['player_name'] = player_name;
        fields['predictive_player_list'] = [];
        this.filterPlayers();
        this.setState({ fields });
    }

    resetFlashMessage = () => {
        this.setState({
            resetFlash: true
        });
    }

    render = () => {
        const responseMsg = this.state.responseMessage !== '' ? <Alert message={this.state.responseMessage} success={this.state.isSuccess} duration={5000} floating="true" onClose={this.resetAlert} /> : '',
            flashMessage = this.state.resetFlash || !this.props.location.state || this.props.location.state.message === '' ? '' : <Alert message={this.props.location.state.message} success={this.props.location.state.success} floating="true" onClose={this.resetFlashMessage} />;

        /* Players Position dropdown data */
        const player_positions = this.props.players.player_positions && this.props.players.player_positions.length > 0 ? this.props.players.player_positions.map(player_positions => {
            return <option key={player_positions.value} value={player_positions.value}>{player_positions.label}</option>;
        }) : '';

        /* Professional level dropdown */
        const professional_levels = this.props.players.player_professional_level && this.props.players.player_professional_level.length > 0 ? this.props.players.player_professional_level.map(player_professional_level => {
            return <option key={player_professional_level.value} value={player_professional_level.value}>{player_professional_level.label}</option>;
        }) : '';

        /* Professional level dropdown */
        const contract_list = this.props.players.player_contract_type && this.props.players.player_contract_type.length > 0 ? this.props.players.player_contract_type.map(player_contract_type => {
            return <option key={player_contract_type.id} value={player_contract_type.id}>{player_contract_type.name}</option>;
        }) : '';

        /* Predictive player list */
        const player_list_exist = this.state.fields.predictive_player_list && this.state.fields.predictive_player_list.length > 0,
            predictive_player_list = player_list_exist ?
                this.state.fields.predictive_player_list.map(player => {
                    return <li key={player.id} onClick={this.selectPlayer.bind(this, player.name)}>{player.name}</li>
                }) : '';

        const show_loader = this.state.isSearching ? <img className="search-loader" src={require(`../../../assets/images/loader-usm.svg`)} alt="" /> : '';

        return (
            <div id="mainContainer">
                {responseMsg}
                {flashMessage}
                <div className="heading-section d-flex align-items-center">
                    <h2>Players</h2>
                    {
                        this.current_user_role === 'Agent' ? '' :
                            <h6 className="ml-auto">
                                <img src={require('../../../assets/images/add-player-icon.png')} alt="" className="align-baseline" />
                                <Link to="/admin/add-player" className="text-white marginL10">Add Player</Link>
                            </h6>
                    }
                </div>
                <div className="filtter-section position-relative">
                    <div className="d-flex">
                        <div className="w-100">
                            <form className="usm-form form-border" onSubmit={e => { e.preventDefault(); }}>
                                <div className="row all-filter-block">
                                    <div className="col-md-3 col-sm-12">
                                        <div className="form-group marginB0">
                                            <label htmlFor="">Player Name</label>
                                            <input autoComplete="off" onChange={this.handlePlayerNameChanges} type="text" name="player_name" className="form-control input-height30" id="" placeholder="" value={this.state.fields.player_name} />
                                            {
                                                !player_list_exist ? '' : <ul className="pre-search">{predictive_player_list}</ul>
                                            }
                                            {show_loader}
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-12">
                                        <div className="form-group marginB0">
                                            <label htmlFor="">Position</label>
                                            <select name="position" onChange={this.handleFieldsChanges} className="form-control input-height30"
                                                value={this.state.fields.position}>
                                                <option value="">All</option>
                                                {player_positions}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-12">
                                        <div className="form-group marginB0">
                                            <label htmlFor="">Professional Level</label>
                                            <select name="professional_level" onChange={this.handleFieldsChanges} className="form-control input-height30" value={this.state.fields.professional_level}>
                                                <option value="">All</option>
                                                {professional_levels}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-12">
                                        <div className="form-group marginB0">
                                            <label htmlFor="">Contract Type</label>
                                            <select name="contract_type" onChange={this.handleFieldsChanges} className="form-control input-height30" value={this.state.fields.contract_type} >
                                                <option value="">All</option>
                                                {contract_list}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                        <div className="flex-shrink-1 marginL18">
                            <div className="apply-filtter float-right d-flex align-items-center flex-column">
                                <i className="material-icons marginB5"><img src={require('../../../assets/images/filter-icon.png')} alt="" /></i>
                                <button onClick={this.filterPlayers} className="btn btn-success borderRadius15 text-uppercase small-button">Apply Filters</button>
                            </div>
                        </div>
                    </div>
                    {/* 
                        !this.state.filtered ? '' : 
                        <button onClick={ this.clearFilter } className="transparent-btn text-danger font-size30 clear-filter-btn">&times;</button>
                     */}
                </div>
                <div className="content-section">

                    <div className="usm-table marginT82 table-responsive">
                        <InfiniteScroll
                            dataLength={this.state.fields.players.length} //This is important field to render the next data
                            next={this.loadMorePlayers}
                            hasMore={this.state.hasMoreItems}
                        >
                            <table className="table table-hover lastTwo-center">
                                <thead className="usm-thead">
                                    <tr>
                                        <th>Name</th>
                                        <th>Prof. Level</th>
                                        <th>Current Club</th>
                                        <th className="width15">Contract Type</th>
                                        <th>Current Rep.</th>
                                        <th>Rep. End Date</th>
                                        <th className="width12">Cases</th>
                                        <th>{this.current_user_role === 'Agent' ? `View` : `Manage`}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <Player
                                        is_fetched={ this.fetched }
                                        user_role={this.current_user_role}
                                        players={this.state.fields.players}
                                    />
                                </tbody>
                            </table>
                        </InfiniteScroll>

                    </div>
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        document.title = this._page_title;
        this.getPlayers();
        this.getPositions();
        this.getPlayerProfessionalLevel();
        this.getPlayerContractType();
    }

    componentWillReceiveProps = (props) => {
        this.setState({
            fields: {
                ...this.state.fields,
                players: props.players.players ? props.players.players : []
            },
        });
    }

    getPlayers = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x(`Session has been expired. Please reload the page to login!`),
                isSuccess: false
            });
            return false;
        }

        this.setState({
            nextPage : 1,
            maxPages : null,
        });

        const player_path = APIRoutes.players;
        const request = new Request(player_path, {}, 'GET', headers).send();

        request.then(response => {
            if (response.success) {
                this.fetched = true;
                this.setState({
                    isError: false,
                    responseMessage: '',
                    isSuccess: true,
                    fields: {
                        ...this.state.fields,
                    },
                    hasMoreItems: response.pages && response.pages === 1 ? false : true,
                    maxPages: response.pages ? response.pages : null,
                    nextPage: this.state.nextPage + 1,
                });

                /**
                 * Dispatching action to set players to redux store.
                 */
                this.props.setplayers(response.data);

            }
        }, error => {
            console.log(error);
        });
    }

	/**
	 * load more players on page scroll
	 */
    loadMorePlayers = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x(`Session has been expired. Please reload the page to login!`),
                isSuccess: false
            });
            return false;
        }

        this.setState({
            hasMoreItems: false,
        });

        let maxPages = this.state.maxPages,
            nextPage = this.state.nextPage;


        if (maxPages < nextPage) {
            this.setState({
                hasMoreItems: false,
            });
            return false;
        }

        const fields = this.state.fields;

        let query_string = '';
        if (fields.position && fields.position !== '') {
            query_string += (`position=` + fields.position + `&`);
        }

        if (fields.professional_level && fields.professional_level !== '') {
            query_string += (`professional_level=` + fields.professional_level + `&`);
        }

        if (fields.contract_type && fields.contract_type !== '') {
            query_string += (`contract_type=` + fields.contract_type + `&`);
        }

        if (fields.player_name && fields.player_name !== '') {
            query_string += (`player_name=` + fields.player_name + `&`);
        }

        const player_path = query_string !== '' ? APIRoutes.players + nextPage + '?' + query_string : APIRoutes.players + nextPage;
        const request = new Request(player_path, {}, 'GET', headers).send();

        request.then(response => {
            if (response.success) {
                this.setState({
                    isError: false,
                    responseMessage: '',
                    isSuccess: true,
                    fields: {
                        ...this.state.fields,
                        // file_path: response.file_path
                    },
                    hasMoreItems: true,
                    nextPage: nextPage + 1,
                });

                /**
					 * Dispatching action to set players to redux store.
					 */
                this.props.appendPlayers(this.props.players.players, response.data);

            }
        }, error => {
            console.log(error);
        });
    }

    filterPlayers = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x(`Session has been expired. Please reload the page to login!`),
                isSuccess: false
            });
            return false;
        }

        const fields = this.state.fields;

        let query_string = '';
        if (fields.position && fields.position !== '') {
            query_string += (`position=` + fields.position + `&`);
        }

        if (fields.professional_level && fields.professional_level !== '') {
            query_string += (`professional_level=` + fields.professional_level + `&`);
        }

        if (fields.contract_type && fields.contract_type !== '') {
            query_string += (`contract_type=` + fields.contract_type + `&`);
        }

        if (fields.player_name && fields.player_name !== '') {
            query_string += (`player_name=` + fields.player_name + `&`);
        }

        const request = new Request(APIRoutes.players + '?' + query_string, '', 'GET', headers, true).send();
        request.then(response => {
            if (response.success) {
                this.setState({
                    isError: false,
                    responseMessage: '',
                    isSuccess: true,
                    fields: {
                        ...this.state.fields,
                        // players: response.data,
                        predictive_player_list: []
                    },
                    filtered: true,
                    hasMoreItems: response.pages && response.pages === 1 ? false : true,
                    maxPages: response.pages ? response.pages : null,
                    nextPage: 2,
                });

				/**
					* Dispatching action to set players to redux store.
					*/
                this.props.setplayers(response.data);

            }
        }, error => {
            console.log(error);
        });
    }

    /* get positions for applying filters */
    getPositions = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x(`Session has been expired. Please reload the page to login!`),
                isSuccess: false
            });
            return false;
        }

        const request = new Request(APIRoutes.player_positions, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                this.setState({
                    isError: false,
                    responseMessage: '',
                    isSuccess: true
                });
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                this.props.setplayerpositions(response.data);
            }
        }, error => {
            console.log(error);
        });

    }

    /* get professional level for applying filters */
    getPlayerProfessionalLevel = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x(`Session has been expired. Please reload the page to login!`),
                isSuccess: false
            });
            return false;
        }

        const request = new Request(APIRoutes.player_professional_level, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                this.setState({
                    isError: false,
                    responseMessage: '',
                    isSuccess: true
                });
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                this.props.setPlayerProfessionalLevel(response.data);
            }
        }, error => {
            console.log(error);
        });

    }

    /* get contract type for applying filters */
    getPlayerContractType = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x(`Session has been expired. Please reload the page to login!`),
                isSuccess: false
            });
            return false;
        }

        const request = new Request(APIRoutes.player_contract_type, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                this.setState({
                    isError: false,
                    responseMessage: '',
                    isSuccess: true
                });
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                this.props.setPlayerContractType(response.data);
            }
        }, error => {
            console.log(error);
        });

    }

    resetAlert = () => {
        this.setState({
            isSuccess: false,
            responseMessage: ''
        });
    }

}

const mapStateToProps = (state) => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setplayers: (players) => dispatch(setplayers(players)),
    appendPlayers: (all_players, load_players) => dispatch(appendPlayers(all_players, load_players)),
    setplayerpositions: (player_positions) => dispatch(setplayerpositions(player_positions)),
    setPlayerProfessionalLevel: (player_professional_level) => dispatch(setPlayerProfessionalLevel(player_professional_level)),
    setPlayerContractType: (player_contract_type) => dispatch(setPlayerContractType(player_contract_type))
});

export default connect(mapStateToProps, mapDispatchToProps)(Players);