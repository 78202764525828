import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import IntlTelInput from 'react-intl-tel-input';
import * as EmailValidator from 'email-validator';
import { is_valid_db_date, isUserImageValid, get_authorization_token, get__format_date, is_bday_valid, is_representation_enddate_valid, __x, get_db_date, get_current_user_role } from '../../../helpers/GeneralHelper';
import Select from 'react-select';
import Request from '../../../helpers/Request';
import APIRoutes from '../../../API/Routes';
import Alert from '../../../helpers/Alert';
import { Link } from "react-router-dom";
import ReactTags from 'react-tag-autocomplete';
import LoadingSpinner from '../common/LoadingSpinner';
import ReactTagsComponent from '../common/ReactTagsComponent';


export default class AddPlayer extends Component {

    countdown;

    /**
     * Time in miliseconds to wait until typing has done.
     */
    wait_for_search = 800;

    constructor(props) {
        super(props);

        this._player_id = props.match.params.player_id;
        this._page_title = this._player_id ? 'Player Profile - USM' : 'Add Player - USM';
        const today = new Date();
        this.previous_date = new Date(today.getFullYear() - 10, today.getMonth(), today.getDate() - 1);
        this.current_user_role = get_current_user_role();

        this._fetched = {
            nationalityOptions: false,
            passportOptions: false,
            preferredFootOptions: false,
            professionalLevelOptions: false,
            primaryPositionOptions: false,
            opportunityTypeOptions: false,
            outfitterOptions: false,
            agentOptions: false,
            contractTypeOptions: false,
            leagueOptions: false,
            player_details: false
        }

        this.state = {
            is_editing: false,
            nationalityOptions: [],
            passportOptions: [],
            preferredFootOptions: [],
            professionalLevelOptions: [],
            primaryPositionOptions: [],
            additionalPositionOptions: [],
            opportunityTypeOptions: [],
            outfitterOptions: [],
            agentOptions: [],
            additionalAgentOptions: [],
            contractTypeOptions: [],
            leagueOptions: [],
            fields: {
                first_name: '',
                last_name: '',
                birthday: null,
                age: '',
                country_code: '44',
                phone: '',
                email: '',
                height: '',
                weight: '',
                passport: null,
                nationality_id: null,
                preferred_foot: null,
                master_professional_level_id: null,
                primary_position_id: null,
                additional_position_id: null,
                master_opportunity_type_id: null,
                outfiter: null,
                usm_agent_id: null,
                additional_usm_agent_id: null,
                master_contract_type_id: null,
                agent_representation_enddate: null,
                contract_enddate: null,
                last_sigining_date: null,
                agent_representation: '',
                agency_representation: '',
                master_league_id: null,
                show_birthday_notification: false,
                is_restricted: false,
                show_ending_notification: false,
                tags: [],
                club_name: '',
                current_club: '',
                predictive_club_list: [],
                file: {},
                who_scored: '',
                transfer_market: '',
                restrict_representation_enddate: false
            },
            fieldsValidityError: {
                first_name: '',
                last_name: '',
                birthday: '',
                age: '',
                country_code: '',
                phone: '',
                email: '',
                height: '',
                weight: '',
                nationality_id: '',
                preferred_foot: '',
                master_professional_level_id: '',
                primary_position_id: '',
                additional_position_id: '',
                master_opportunity_type_id: '',
                outfiter: '',
                usm_agent_id: '',
                additional_usm_agent_id: '',
                master_contract_type_id: '',
                agent_representation_enddate: '',
                contract_enddate: '',
                last_sigining_date: '',
                agent_representation: '',
                agency_representation: '',
                file: '',
                tags: '',
                who_scored: '',
                transfer_market: ''
            },
            isValid: {
                first_name: true,
                last_name: true,
                birthday: true,
                age: true,
                country_code: true,
                phone: true,
                email: true,
                height: true,
                weight: true,
                nationality_id: true,
                preferred_foot: true,
                master_professional_level_id: true,
                primary_position_id: true,
                additional_position_id: true,
                master_opportunity_type_id: true,
                outfiter: true,
                usm_agent_id: true,
                additional_usm_agent_id: true,
                master_contract_type_id: true,
                agent_representation_enddate: true,
                contract_enddate: true,
                last_sigining_date: true,
                agent_representation: true,
                agency_representation: true,
                file: true,
                tags: true,
                who_scored: true,
                transfer_market: true
            },
            imgPreview: '',
            fileName: 'Add Photo',
            country_iso: 'gb',
            isSubmitted: false,
            isSuccess: false,           // Used for api success/failure.
            isError: false,             // Used for only local validation errors to show.
            responseMessage: '',
            isProcessing: false,
            disableAgent: false,
            isSearching: false,
            lockscreen: false,
            lockErrorMsg: '',
        };
    }

    componentDidMount = () => {
        document.title = this._page_title;
        /**
         * Get data for passports dropdown
         */
        this.getOptionsData('get_passport', 'passportOptions');

        /**
         * Get data for nationality dropdown
         */
        this.getOptionsData('get_nationality', 'nationalityOptions');

        this.getPreferredFootOptions();

        /**
         * Get data for professional level dropdown
         */
        this.getOptionsData('player_professional_level', 'professionalLevelOptions');

        /**
         * Get data for primary position
         */
        this.getOptionsData('player_positions', 'primaryPositionOptions');

        /**
         * Get data for opportunity dropdown
         */
        this.getOptionsData('get_opportunity_list', 'opportunityTypeOptions');

        /**
         * Get data for outfitter dropdown
         */
        this.getOptionsData('get_outfiter', 'outfitterOptions');

        /**
         * Get data for agent list dropdown
         */
        this.getOptionsData('get_agent_list', 'agentOptions');

        /**
         * Get data for league dropdown
         */
        this.getOptionsData('league_list', 'leagueOptions');

        /**
         * Get data for contract type dropdown
         */
        this.getOptionsData('player_contract_type', 'contractTypeOptions');
    }

    /**
     * get player data in case of edit
     */
    componentDidUpdate = () => {
        if (this._fetched.passportOptions && this._fetched.nationalityOptions && this._fetched.preferredFootOptions && this._fetched.professionalLevelOptions &&
            this._fetched.primaryPositionOptions && this._fetched.opportunityTypeOptions && this._fetched.outfitterOptions && this._fetched.agentOptions &&
            this._fetched.leagueOptions && this._fetched.contractTypeOptions && !this._fetched.player_details && this._player_id) {

            this.getPlayerDetail();
        }
    }

    /**
     * Get player data for edit
     */
    getPlayerDetail = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x(`Session has been expired. Please reload the page to login!`),
                isSuccess: false
            });
            return false;
        }

        const request = new Request(APIRoutes.player + this._player_id, {}, 'GET', headers).send();
        const fields = this.state.fields;
        request.then(response => {
            if (response.success) {
                let response_data = response.data;

                fields['last_name'] = response_data.last_name;
                fields['first_name'] = response_data.first_name;
                fields['birthday'] = is_valid_db_date(response_data.birthday) ? new Date(response_data.birthday) : null;
                fields['age'] = response_data.age;
                fields['height'] = response_data.height ? response_data.height : '';
                fields['weight'] = response_data.weight ? response_data.weight : '';
                fields['agent_representation'] = response_data.agent_representation ? response_data.agent_representation : '';
                fields['agency_representation'] = response_data.agency_representation ? response_data.agency_representation : '';
                fields['agent_representation_enddate'] = is_valid_db_date(response_data.agent_representation_enddate) && response_data.agent_representation_enddate ? new Date(response_data.agent_representation_enddate) : null;
                fields['contract_enddate'] = is_valid_db_date(response_data.contract_enddate) && response_data.contract_enddate ? new Date(response_data.contract_enddate) : null;
                fields['last_sigining_date'] = is_valid_db_date(response_data.last_sigining_date) && response_data.last_sigining_date ? new Date(response_data.last_sigining_date) : null;
                fields['club_name'] = response_data.current_club === 0 ? response_data.other_club !== null ? response_data.other_club : '' : (response_data.club !== null) ? response_data.club.club_name : '';
                fields['current_club'] = response_data.current_club === 0 ? '' : response_data.current_club;
                fields['show_ending_notification'] = response_data.show_ending_notification;
                fields['transfer_market'] = (response_data.transfer_market !== null ? response_data.transfer_market : '');
                fields['who_scored'] = (response_data.who_scored !== null ? response_data.who_scored : '');
                fields['restrict_representation_enddate'] = response_data.restrict_representation_enddate ? response_data.restrict_representation_enddate : false;
                if (response_data.tags !== "") {
                    let tag_data = response_data.tags.split(',');
                    fields['tags'] = tag_data.length > 0 ? tag_data.map(tag => {
                        return tag && tag !== '' ? { name: tag.trim() } : '';
                    }) : [];
                }

                let nationalityOptions = this.state.nationalityOptions;

                for (let i in nationalityOptions) {
                    if (nationalityOptions[i].value === response_data.nationality_id) {
                        fields['nationality_id'] = nationalityOptions[i];
                        break;
                    }
                }

                let preferredFootOptions = this.state.preferredFootOptions;

                for (let i in preferredFootOptions) {
                    if (preferredFootOptions[i].value === response_data.preferred_foot) {
                        fields['preferred_foot'] = preferredFootOptions[i];
                    }
                }
                let professionalLevelOptions = this.state.professionalLevelOptions;

                for (let i in professionalLevelOptions) {
                    if (professionalLevelOptions[i].value === response_data.master_professional_level_id) {
                        fields['master_professional_level_id'] = professionalLevelOptions[i];
                        break;
                    }
                }

                let primaryPositionOptions = this.state.primaryPositionOptions;

                for (let i in primaryPositionOptions) {
                    if (primaryPositionOptions[i].value === response_data.primary_position_id) {
                        fields['primary_position_id'] = primaryPositionOptions[i];
                        break;
                    }
                }

                let opportunityTypeOptions = this.state.opportunityTypeOptions;

                for (let i in opportunityTypeOptions) {
                    if (opportunityTypeOptions[i].value === response_data.master_opportunity_type_id) {
                        fields['master_opportunity_type_id'] = opportunityTypeOptions[i];
                        break;
                    }
                }

                //check if opportunity type is broker client then Unique Agent and Additional Unique Agent will be inactive
                let disableAgent = this.state.disableAgent;
                disableAgent = response_data.master_opportunity_type_id === 4 ? true : false;

                let leagueOptions = this.state.leagueOptions;

                for (let i in leagueOptions) {
                    if (leagueOptions[i].value === response_data.master_league_id) {
                        fields['master_league_id'] = leagueOptions[i];
                        break;
                    }
                }

                let contractTypeOptions = this.state.contractTypeOptions;

                for (let i in contractTypeOptions) {
                    if (contractTypeOptions[i].value === response_data.master_contract_type_id) {
                        fields['master_contract_type_id'] = contractTypeOptions[i];
                        break;
                    }
                }

                let agentOptions = this.state.agentOptions;

                for (let i in agentOptions) {
                    if (agentOptions[i].value === response_data.usm_agent_id) {
                        fields['usm_agent_id'] = agentOptions[i];
                        break;
                    }
                }

                let passportOptions = this.state.passportOptions,
                    passport_arr = [];
                for (let i in passportOptions) {
                    let player_passport = passportOptions[i];
                    for (let j in response_data.player_passports) {
                        let response = response_data.player_passports[j];
                        if (response.master_country_id === player_passport.value) {
                            passport_arr.push(player_passport);
                        }
                    }
                }
                fields['passport'] = passport_arr;

                let additionalPositionOptions = this.state.additionalPositionOptions;
                let additional_position_arr = [];

                for (let i in additionalPositionOptions) {
                    for (let j in response_data.player_additional_positions) {
                        if (response_data.player_additional_positions[j].master_position_id === additionalPositionOptions[i].value) {
                            additional_position_arr.push(additionalPositionOptions[i]);
                        }
                    }
                }
                fields['additional_position_id'] = additional_position_arr;


                let outfitterOptions = this.state.outfitterOptions;
                let outfitter_arr = [];
                for (let i in outfitterOptions) {
                    for (let j in response_data.player_outfiters) {
                        if (response_data.player_outfiters[j].master_outfiter_id === outfitterOptions[i].value) {
                            outfitter_arr.push(outfitterOptions[i]);
                        }
                    }
                }
                fields['outfiter'] = outfitter_arr;

                let additionalAgentOptions = this.state.additionalAgentOptions;
                let additional_agent_arr = [];

                for (let i in additionalAgentOptions) {
                    for (let j in response_data.player_additional_agents) {
                        if (response_data.player_additional_agents[j].user_id === additionalAgentOptions[i].value) {
                            additional_agent_arr.push(additionalAgentOptions[i]);
                        }
                    }
                }
                fields['additional_usm_agent_id'] = additional_agent_arr;
                this._fetched.player_details = true;
                this.setState({
                    // is_locked: response.lock_status,
                    fields,
                    disableAgent,
                    is_editing: true
                })
            }
        }, error => {
            console.log(error);
            this._fetched.player_details = error.lockscreen ? true : false;
            this.setState({
                isSuccess : false,
                lockErrorMsg : error.message,
                lockscreen : error.lockscreen ? error.lockscreen : false,
            })
        });

    }

    /**
     * Get select options values from API.
     */
    getOptionsData = (route = 'player_positions', state = 'primaryPositionOptions') => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x(`Session has been expired. Please reload the page to login!`),
                isSuccess: false
            });
            return false;
        }

        const request = new Request(APIRoutes[route], {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                let data = response.data && response.data.length > 0 ? response.data : [];
                const keysToAlter = {};

                /**
                 * Change title => label and id => value to make it as per requirement by select package.
                 */
                if (state === 'leagueOptions') {
                    keysToAlter['label'] = 'title';
                    keysToAlter['value'] = 'id';
                }

                /**
                 * Change name => label and id => value to make it as per requirement by select package.
                 */
                if (state === 'contractTypeOptions') {
                    keysToAlter['label'] = 'name';
                    keysToAlter['value'] = 'id';
                }

                if (['contractTypeOptions', 'leagueOptions'].indexOf(state) >= 0) {
                    const new_data = data.length > 0 ? data.map(option => {
                        return { label: option[keysToAlter.label], value: option[keysToAlter.value] };
                    }) : [];
                    data = new_data;
                }

                const new_state = {
                    isError: false,
                    responseMessage: '',
                    isSuccess: true,
                    [state]: data
                };

                /**
                 * If currently fetching for agents, set same agents to additional agents state.
                 */
                if (route === 'get_agent_list') {
                    new_state['additionalAgentOptions'] = data;
                }

                /**
                 * If currently fetching player positions, set same positions to additional positions state.
                 */
                if (route === 'player_positions') {
                    new_state['additionalPositionOptions'] = data;
                }

                this._fetched[state] = true;
                this.setState(new_state);
            }
        }, error => {
            console.log(error);
        });
    }

    /**
     * Handles birthday changes
     */
    handleBirthdayChanges = (date) => {
        const fields = this.state.fields,
            isValid = this.state.isValid,
            fieldsValidityError = this.state.fieldsValidityError;

        fields['birthday'] = date;
        isValid['birthday'] = date === '' || date === null || !date ? true : is_bday_valid(date, 'player_bday');
        fieldsValidityError['birthday'] = !isValid['birthday'] ? `Birthday is invalid.` : '';

        fields['age'] = date !== '' && date instanceof Date ? this.getAge(get__format_date(fields['birthday'])) : '';
        isValid['age'] = fields['age'] !== '' ? parseInt(fields['age']) > 10 : true;
        fieldsValidityError['age'] = !isValid['age'] ? __x(`Sorry you cannot add a player with age less than 10 years.`) : '';

        this.setState({
            fields,
            isValid,
            fieldsValidityError
        });
    }

    /**
     * get age from birthday
     */
    getAge = (dateString) => {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    /**
     * Handles phone changes
     */
    handlePhoneChange = (status, value, countryData) => {
        const fields = this.state.fields,
            isValid = this.state.isValid,
            fieldsValidityError = this.state.fieldsValidityError,
            phone = value ? value : '';

        fields['phone'] = phone;
        isValid['phone'] = fields.phone !== '' ? fields.phone.length >= 0 && fields.phone.length <= 255 && !isNaN(fields.phone) : true;
        fieldsValidityError['phone'] = !isValid.phone ? __x(`Phone is invalid.`) : '';

        this.setState({
            fields,
            isValid,
            fieldsValidityError
        });
    }

    /**
     * Handles country code changes
     */
    handleCountryCodeChange = (value, countryData) => {
        const fields = this.state.fields,
            isValid = this.state.isValid,
            country_code = countryData ? countryData.dialCode : '44';

        fields['country_code'] = country_code;
        isValid['country_code'] = fields.country_code !== '' ? fields.country_code.toString().length >= 0 && fields.country_code.toString().length <= 255 : true;

        this.setState({
            fields,
            isValid,
            country_iso: countryData.iso2
        });
    }

    /**
     * Handles fields changes and check for errors
     */
    handleFieldsChanges = (event) => {
        const target = event.target,
            elt_name = target.name,
            elt_value = target.type === 'checkbox' ? target.checked : target.value,
            fields = this.state.fields;

        let isValid = this.state.isValid,
            fieldsValidityError = this.state.fieldsValidityError,
            fileName = this.state.fileName;

        if (elt_name === 'file') {
            const file = target.files.length > 0 ? target.files[0] : false;
            const isImageValid = isUserImageValid(file);
            if (!isImageValid) {
                isValid['file'] = true;
                fieldsValidityError = {
                    ...fieldsValidityError,
                    file: ''
                };

                fileName = 'Add Photo';
                this.setState({
                    imgPreview: ''
                });
            } else {
                if (!isImageValid.success) {
                    isValid['file'] = false;
                    fieldsValidityError = {
                        ...fieldsValidityError,
                        file: isImageValid.messages.map((error, idx) => <li key={idx}>{error}</li>)
                    };

                    fileName = 'Add Photo';
                    this.setState({
                        imgPreview: ''
                    });
                } else {
                    fileName = file.name;

                    /**
                     * Get preview file url using file reader
                     */
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = function (e) {
                        this.setState({
                            imgPreview: [reader.result]
                        });
                    }.bind(this);

                    isValid['file'] = true;
                    fieldsValidityError = {
                        ...fieldsValidityError,
                        file: ''
                    };
                }
            }

            fields['file'] = file;
        } else if (['first_name', 'last_name', 'height', 'weight', 'agency_representation', 'agent_representation'].indexOf(elt_name) >= 0) {
            const label = elt_name.replace('_', ' ');
            fields[elt_name] = elt_value;
            isValid[elt_name] = fields[elt_name] !== '' ? fields[elt_name].length >= 1 && fields[elt_name].length <= 255 : true;
            fieldsValidityError[elt_name] = !isValid[elt_name] ? __x(`Missing the {{label}}.`, '{{label}}', label) : '';
        } else if (elt_name === 'email') {
            fields[elt_name] = elt_value;
            isValid[elt_name] = fields[elt_name] !== '' ? fields[elt_name].length >= 1 && fields[elt_name].length <= 255 && EmailValidator.validate(fields[elt_name]) : true;
            fieldsValidityError[elt_name] = !isValid[elt_name] || fields[elt_name] === '' ? __x(`Please enter a valid email.`) : '';
        } else if (elt_name === 'age') {
            fields[elt_name] = elt_value;
            isValid[elt_name] = fields[elt_name] !== '' ? parseInt(fields[elt_name]) > 10 : true;
            fieldsValidityError[elt_name] = !isValid[elt_name] ? __x(`Sorry you cannot add a player with age less than 10 years.`) : '';
        } else {
            fields[elt_name] = elt_value;
        }

        this.setState({
            fields,
            isValid,
            fileName,
            fieldsValidityError
        });

    }

    /**
     * Handles `club_name` field's changes.
     */
    handleClubNameChanges = event => {
        const target = event.target,
            value = target.value,
            fields = this.state.fields;

        if (value.length === 0 || value === '') {
            fields['predictive_club_list'] = [];
            fields['club_name'] = '';
            fields['current_club'] = '';
            this.setState({ fields });
            return false;
        }

        if (value.length < 3) {
            fields['club_name'] = value;
            this.setState({ fields });
            return false;
        }

        fields['club_name'] = value;

        this.setState({ fields });

        clearTimeout(this.countdown);
        this.countdown = setTimeout(this.searchClubName, this.wait_for_search);
    }

    /**
     * Search after {`this.wait_for_search`} miliseconds case when typing is done and text is more than 3 characters.
     */
    searchClubName = () => {
        const query = this.state.fields.club_name;
        if (query.length < 3) {
            return false;
        }

        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x(`Session has been expired. Please reload the page to login!`),
                isSuccess: false
            });
            return false;
        }

        this.setState({
            isSearching: true
        });

        const request = new Request(APIRoutes.get_club_list + query, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                const fields = this.state.fields;
                fields['predictive_club_list'] = response.data;
                fields['current_club'] = '';
                this.setState({
                    fields,
                    isSearching: false
                });
            }
        }, error => {
            console.log(error);
        });
    }

    /**
     * select club from dropdown on predictive search on club name
     */
    selectClub = (club_name, current_club, event) => {
        let fields = this.state.fields;
        fields['club_name'] = club_name;
        fields['current_club'] = current_club;
        fields['predictive_club_list'] = [];
        this.setState({ fields });
    }

    /**
     * get data for preferred foot dropdown 
     */
    getPreferredFootOptions = () => {
        let preferredFootOptions = this.state.preferredFootOptions;

        preferredFootOptions = [
            { value: 'Left', label: 'Left' },
            { value: 'Right', label: 'Right' },
            { value: 'Both', label: 'Both' }
        ];

        this._fetched.preferredFootOptions = true;
        this.setState({ preferredFootOptions });
    }

    /**
     * handle dropdown fields changes
     */
    handleChange = ( selectedOption, field ) => {
        let fields          = this.state.fields,
            isValid         = this.state.isValid,
            disableAgent    = this.state.disableAgent,
            fieldsValidityError = this.state.fieldsValidityError;

        if ( [ 'additional_position_id', 'additional_usm_agent_id' ].indexOf( field ) >= 0 ) {
            const comparator = {
                additional_position_id  : 'primary_position_id',
                additional_usm_agent_id : 'usm_agent_id'
            };

            if ( !selectedOption.length ) {
                isValid[field]              = true;
                isValid[comparator[field]]  = true;
                fieldsValidityError[field]  = '';
            }

            if ( selectedOption.length ) {
                let comparision_data = this.state.fields[comparator[field]];
                for ( let option_value in selectedOption ) {
                    const option                    = selectedOption[option_value];
                    isValid[ field ]                = !(comparision_data && option.value && option.value === comparision_data.value);
                    isValid[comparator[field]]      = !(comparision_data && option.value && option.value === comparision_data.value);
                    fieldsValidityError[ field ]    = '';

                    if ( !isValid[ field ] ) {
                        fieldsValidityError[field] = field === 'additional_position_id' ? __x(`The Primary Postion and Secondary Position have same value.`) : __x( `You have selected same agent name in Unique SM agent field and Additional Agents.` );
                        fields[field] = selectedOption;
                        break;
                    }
                }
            }
        }

        if ( [ 'primary_position_id', 'usm_agent_id' ].indexOf( field ) >= 0 ) {
            const comparator = {
                primary_position_id : 'additional_position_id',
                usm_agent_id        : 'additional_usm_agent_id'
            };

            let comparision_data = this.state.fields[comparator[field]];
            if ( comparision_data ) {
                for ( let i in comparision_data ) {
                    let option                  = comparision_data[i];
                    isValid[field]              = !(option.value && selectedOption.value && selectedOption.value === option.value);
                    isValid[comparator[field]]  = !(option.value && selectedOption.value && selectedOption.value === option.value);
                    fieldsValidityError[comparator[field]] = '';

                    if (!isValid[field]) {
                        fieldsValidityError[comparator[field]] = field === 'primary_position_id' ? __x(`The Primary Postion and Secondary Position have same value.`) : __x(`You have selected same agent name in Unique SM agent field and Additional Agents.`);
                        fields[field] = selectedOption;
                        break;
                    }
                }
            }
        }

        if (field === 'master_opportunity_type_id') {
            fields['usm_agent_id'] = selectedOption.value === 4 ? '' : fields['usm_agent_id'];
            fields['additional_usm_agent_id'] = selectedOption.value === 4 ? '' : fields['additional_usm_agent_id'];
            disableAgent = selectedOption.value === 4 ? true : false;
        }

        if (field === 'last_sigining_date') {
            const error_msg = field === 'contract_enddate' ? `Contract End Date is invalid.` : `Last Signing Date is Invalid`;
            isValid[field] = selectedOption === '' || selectedOption === null || !selectedOption ? true : is_bday_valid(selectedOption);
            fieldsValidityError[field] = !isValid[field] ? error_msg : '';
        }

        if (field === 'agent_representation_enddate') {
            isValid[field] = selectedOption === '' || selectedOption === null || !selectedOption ? true : is_representation_enddate_valid(selectedOption);
            fieldsValidityError[field] = !isValid[field] ? `Representation End Date is invalid` : '';
        }

        fields[field] = selectedOption;
        this.setState({ fields, isValid, fieldsValidityError, disableAgent });
    }

    /**
     * Check if form is valid or not to submit
     */
    isFormValid = () => {
        let validityErr = [];
        const fields    = this.state.fields,
            isValid     = this.state.isValid,        
            optionals   = [ 
                'is_restricted', 'show_birthday_notification', 'show_ending_notification', 'email', 'passport', 'master_league_id', 'phone',
                'club_name', 'current_club', 'predictive_club_list', 'preferred_foot', 'master_professional_level_id', 'master_opportunity_type_id', 'outfiter', 
                'master_contract_type_id', 'agent_representation', 'agency_representation', 'master_league_id', 'who_scored', 'transfer_market', 'height', 'weight','restrict_representation_enddate'
            ];

        for ( let elt in fields ) {
            if ( optionals.indexOf( elt ) >= 0 ) {
                continue;
            }

            if (elt === 'first_name' || elt === 'last_name') {
                if (fields['first_name'] === '' && fields['last_name'] === '') {
                    validityErr.push(elt);
                }
            }

            if (elt === 'birthday' || elt === 'age') {
                if ((fields['birthday'] === '' || !(fields['birthday'] instanceof Date)) && fields['age'] === '') {
                    validityErr.push(elt);
                }
            }

            if (elt === 'nationality_id') {
                if (fields[elt] === '' || fields[elt] === null) {
                    validityErr.push(elt);
                }
            }

            if (elt === 'agent_representation_enddate' || elt === 'contract_enddate' || elt === 'last_sigining_date') {
                if (isValid[elt]) {
                    continue;
                }
            }

            if (elt === 'tags') {
                if (fields[elt].length > 0 && !isValid[elt]) {
                    validityErr.push(elt);
                }
            }

            if (!isValid[elt]) {
                validityErr.push(elt);
            }
        }
        return validityErr.length <= 0;
    }

    /**
     * Select tag value
     */
    onTagSelection = (tag) => {
        const tags = this.state.fields.tags;
        let isValid = this.state.isValid,
            fieldsValidityError = this.state.fieldsValidityError;

        for (let i in tags) {
            tag.name = String.prototype.trim.call(tag.name);
            tags[i].name = String.prototype.trim.call(tags[i].name);
            if (tag.name.toLowerCase() === tags[i].name.toLowerCase()) {
                return false;
            }
        }

        isValid['tags'] = tags.length > 99 ? false : true;
        fieldsValidityError['tags'] = !isValid['tags'] ? __x(`You can select maximum 100 tags for a player.`) : '';

        tags.push(tag);
        this.setState({
            ...this.state.fields,
            isValid,
            fieldsValidityError,
            tags
        });
    }
    /**
     *  Delete tag
     */
    onTagDelete = (tag_id) => {
        const tags = this.state.fields.tags;
        let isValid = this.state.isValid,
            fieldsValidityError = this.state.fieldsValidityError;
        isValid['tags'] = tags.length > 98 ? false : true;
        fieldsValidityError['tags'] = !isValid['tags'] ? __x(`You can select maximum 100 tags for a player.`) : '';
        tags.splice(tag_id, 1)
        this.setState({ tags })
    }

    addPlayer = (event) => {
        event.preventDefault();
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x(`Session has been expired. Please reload the page to login!`)
            });
            return false;
        }

        if (!this.isFormValid()) {
            return false;
        }

        this.setState({
            isProcessing: true
        })

        const player_path = this.state.is_editing ? APIRoutes.player + this._player_id : APIRoutes.player,
            method = this.state.is_editing ? 'PUT' : 'POST';

        const fields = this.state.fields;
        let new_state = {},
            formData = new FormData();
        for (let i in fields) {

            if (i === 'current_club') {
                if (fields[i] === '') {
                    fields[i] = fields['club_name'];
                }
                formData.append(i, fields[i] && fields[i] !== 'null' ? fields[i] : '');
                continue;
            }


            if (i === 'birthday' || i === 'agent_representation_enddate' || i === 'contract_enddate' || i === 'last_sigining_date') {
                if (fields[i] !== '' && fields[i] instanceof Date) {
                    // let month = (`0${fields[i].getMonth() + 1}`).slice(-2);

                    // formData.append(i, `${fields[i].getDate()}/${month}/${fields[i].getFullYear()}`);
                    formData.append(i, get_db_date(fields[i]));
                }

                continue;
            }

            if (i === 'passport' || i === 'additional_position_id' || i === 'outfiter' || i === 'additional_usm_agent_id') {
                if (fields[i] && fields[i].length) {
                    formData.append(i, fields[i].map(function (selected_passport, index) {
                        return selected_passport.value
                    }));
                }

                continue;
            }

            if (i === 'tags') {
                formData.append(i, fields[i] && fields[i].length > 0 ? fields[i].map( tag => {
                    return tag.name
                }).join( ', ' ) : '');
                continue;
            }

            if (i === 'master_league_id') {
                if (fields[i] && fields[i].value !== '') {
                    formData.append(i, fields[i].value)
                }
                continue;
            }

            if (i === 'nationality_id' || i === 'preferred_foot' || i === 'master_professional_level_id' || i === 'primary_position_id' || i === 'master_opportunity_type_id' || i === 'usm_agent_id' || i === 'master_contract_type_id') {
                if (fields[i] && fields[i].value) {
                    formData.append(i, fields[i].value);
                }
                continue;
            }

            if (i === 'height' || i === 'weight') {
                if (fields[i] !== '') {
                    formData.append(i, fields[i])
                }
                continue;
            }

            if (this.state.is_editing) {
                if (i === 'email' || i === 'phone' || i === 'is_restricted' || i === 'show_birthday_notification') {
                    continue;
                }
            }

            formData.append(i, fields[i]);
        }
        formData.append('country_iso', this.state.country_iso);

        /**
         * Sending request to add/update players.
         */
        const request = new Request(player_path, formData, method, headers, true).send();
        request.then(response => {
            if (response.success) {
                this.clearFields();
                this.props.history.push('/admin/players', { message: response.message, success: true });
            }
            else {
                new_state = {
                    isProcessing: false,
                    isSubmitted: true,
                    isSuccess: response.success,
                    responseMessage: response.message
                }
                this.setState(new_state);
            }


        }, error => {
            new_state = {
                isProcessing: false,
                isSubmitted: true,
                isSuccess: error.success,
                responseMessage: error.message
            };
            this.setState(new_state);
        });
    }

    /**
     * clear form fields
     */
    clearFields = () => {
        this.setState({
            is_editing: false,
            nationalityOptions: [],
            passportOptions: [],
            preferredFootOptions: [],
            professionalLevelOptions: [],
            primaryPositionOptions: [],
            additionalPositionOptions: [],
            opportunityTypeOptions: [],
            outfitterOptions: [],
            agentOptions: [],
            additionalAgentOptions: [],
            contractTypeOptions: [],
            leagueOptions: [],
            fields: {
                first_name: '',
                last_name: '',
                birthday: null,
                age: '',
                country_code: '44',
                phone: '',
                email: '',
                height: '',
                weight: '',
                passport: null,
                nationality_id: null,
                preferred_foot: null,
                master_professional_level_id: null,
                primary_position_id: null,
                additional_position_id: null,
                master_opportunity_type_id: null,
                outfiter: null,
                usm_agent_id: null,
                additional_usm_agent_id: null,
                master_contract_type_id: null,
                agent_representation_enddate: null,
                contract_enddate: null,
                last_sigining_date: null,
                agent_representation: '',
                agency_representation: '',
                master_league_id: null,
                show_birthday_notification: false,
                is_restricted: false,
                show_ending_notification: false,
                tags: [],
                club_name: '',
                current_club: '',
                predictive_club_list: [],
                file: {},
                who_scored: '',
                transfer_market: '',
                restrict_representation_enddate: false,
            },
            fieldsValidityError: {
                first_name: '',
                last_name: '',
                birthday: '',
                age: '',
                country_code: '',
                phone: '',
                email: '',
                height: '',
                weight: '',
                nationality_id: '',
                preferred_foot: '',
                master_professional_level_id: '',
                primary_position_id: '',
                additional_position_id: '',
                master_opportunity_type_id: '',
                outfiter: '',
                usm_agent_id: '',
                additional_usm_agent_id: '',
                master_contract_type_id: '',
                agent_representation_enddate: '',
                contract_enddate: '',
                last_sigining_date: '',
                agent_representation: '',
                agency_representation: '',
                file: '',
                tags: '',
                who_scored: '',
                transfer_market: ''
            },
            isValid: {
                first_name: true,
                last_name: true,
                birthday: true,
                age: true,
                country_code: true,
                phone: true,
                email: true,
                height: true,
                weight: true,
                nationality_id: true,
                preferred_foot: true,
                master_professional_level_id: true,
                primary_position_id: true,
                additional_position_id: true,
                master_opportunity_type_id: true,
                outfiter: true,
                usm_agent_id: true,
                additional_usm_agent_id: true,
                master_contract_type_id: true,
                agent_representation_enddate: true,
                contract_enddate: true,
                last_sigining_date: true,
                agent_representation: true,
                agency_representation: true,
                file: true,
                tags: true,
                who_scored: true,
                transfer_market: true
            },
            imgPreview: '',
            fileName: 'Add Photo',
            country_iso: 'gb',
            isSubmitted: false,
            isSuccess: false,           // Used for api success/failure.
            isError: false,             // Used for only local validation errors to show.
            responseMessage: '',
            isProcessing: false,
            disableAgent: false,
            isSearching: false,
            lockscreen: false,
            lockErrorMsg: '',
        });
    }

    resetAlert = () => {
        this.setState({
            isSuccess: false,
            responseMessage: ''
        });
    }

    componentWillUnmount = () => {
        clearTimeout(this.countdown);
    }

    goBack = () => {
        this.props.history.go(-1);
    }

    render() {
        const today = new Date(),
            disableSubmit = !this.isFormValid() || this.state.isProcessing ? true : false,
            responseMsg = this.state.responseMessage !== '' && this.state.isSubmitted ? <Alert message={this.state.responseMessage} success={this.state.isSuccess}  floating="true" onClose={this.resetAlert} /> : '',
            lockErrorMsg = this.state.lockErrorMsg ? <Alert message={this.state.lockErrorMsg} success={this.state.isSuccess}  floating="true" goBack={this.goBack} isNotFlash /> : '',
            loading = this.state.isProcessing ? <LoadingSpinner /> : '';
        const customerStyles = {
            option: (styles, { isFocused }) => {
                return {
                    ...styles,
                    backgroundColor: isFocused ? 'rgba(0, 0, 0, 0.2)' : styles.backgroundColor,
                }
            }
        };

        const show_loader = this.state.isSearching ? <img className="search-loader" src={require(`../../../assets/images/loader-usm.svg`)} alt="" /> : '';
        const is_agent = this.current_user_role === 'Agent';
        const not_allowed_cursor = is_agent ? 'disable_not_allowed_cursor' : '';
        const disableClass = this.current_user_role === 'Agent' ? 'disableClass' : '';
        const restrict_representation_enddate  = get_current_user_role() !== 'Admin' && this.state.is_editing && this.state.fields.restrict_representation_enddate;
        return (


            <div id={ this._player_id && this._player_id > 0 && !isNaN( this._player_id ) ? '' : 'mainContainer' }>

                {this.state.is_editing || this.state.lockscreen ? '' :

                    <div className="heading-section height84 d-flex align-items-center border-bottom-trans">
                        <h2>Add Player</h2>
                    </div>
                }
                <div className={`content-section form-modal ${disableClass} `}>
                    {loading}
                    {responseMsg}
                    {lockErrorMsg}
                    <form action="" className={`usm-form form-border ${not_allowed_cursor}`} onSubmit={e => { e.preventDefault(); }}>
                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <div className={this.state.fieldsValidityError.last_name === '' ? `form-group` : `form-group form-error`}>
                                    <label htmlFor="">Last Name</label>
                                    <input onChange={this.handleFieldsChanges} value={this.state.fields.last_name} disabled={is_agent} name="last_name" type="text" className="form-control" placeholder="Last Name" autoComplete="off" />
                                    {this.state.fieldsValidityError.last_name === '' || <div className="display-error">{this.state.fieldsValidityError.last_name}</div>}
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-12">
                                <div className={this.state.fieldsValidityError.first_name === '' ? `form-group` : `form-group form-error`}>
                                    <label htmlFor="">First Name</label>
                                    <input onChange={this.handleFieldsChanges} value={this.state.fields.first_name} disabled={is_agent} name="first_name" type="text" className="form-control" placeholder="First Name" autoComplete="off" />
                                    {this.state.fieldsValidityError.first_name === '' || <div className="display-error">{this.state.fieldsValidityError.first_name}</div>}
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-12">
                                <div className={this.state.fieldsValidityError.birthday === '' ? `form-group` : `form-group form-error`}>
                                    <label htmlFor="">Date of Birth</label>
                                    <div className="input-group w-100">
                                        <DatePicker
                                            className="form-control"
                                            selected={this.state.fields.birthday}
                                            onChange={this.handleBirthdayChanges}
                                            dateFormat="dd/MM/yyyy"
                                            maxDate={new Date(today.getFullYear() - 11, today.getMonth(), today.getDate() - 1)}
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={30}
                                            disabled={is_agent}
                                        />
                                    </div>
                                    {this.state.fieldsValidityError.birthday === '' || <div className="display-error">{this.state.fieldsValidityError.birthday}</div>}
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className={this.state.fieldsValidityError.age === '' ? `form-group` : `form-group form-error`}>
                                    <label htmlFor="">Age</label>
                                    <input onChange={this.handleFieldsChanges} value={this.state.fields.age} name="age" disabled={is_agent} type="number" className="form-control" placeholder="Age" autoComplete="off" />
                                    {this.state.fieldsValidityError.age === '' || <div className="display-error">{this.state.fieldsValidityError.age}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Nationality</label>
                                    <Select name="nationality"
                                        value={this.state.fields.nationality_id}
                                        onChange={selectedOption => this.handleChange(selectedOption, 'nationality_id')}
                                        options={this.state.nationalityOptions}
                                        styles={customerStyles}
                                        placeholder={``}
                                        isDisabled={is_agent}
                                    />
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Passport(s)</label>
                                    <Select name="passport"
                                        value={this.state.fields.passport}
                                        onChange={selectedOption => this.handleChange(selectedOption, 'passport')}
                                        options={this.state.passportOptions}
                                        isMulti={true}
                                        styles={customerStyles}
                                        placeholder={``}
                                        isDisabled={is_agent}
                                    />
                                </div>
                            </div>
                        </div>
                        {this.state.is_editing ? '' :
                            <div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="">Phone</label>
                                            <div className="d-flex bd-highlight">
                                                <div className="w-100">
                                                    <div className={this.state.fieldsValidityError.phone === '' ? `input-group` : `input-group form-error`}>
                                                        <IntlTelInput
                                                            onPhoneNumberChange={this.handlePhoneChange}
                                                            defaultCountry={'gb'}
                                                            css={['intl-tel-input w-100', 'form-control']}
                                                            placeholder="Phone"
                                                            value={this.state.fields.phone}
                                                            separateDialCode="true"
                                                            onSelectFlag={this.handleCountryCodeChange}
                                                        />
                                                    </div>
                                                    {this.state.fieldsValidityError.phone === '' || <div className="display-error">{this.state.fieldsValidityError.phone}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-12">
                                        <div className={this.state.fieldsValidityError.email === '' ? `form-group` : `form-group form-error`}>
                                            <label htmlFor="">Email</label>
                                            <input onChange={this.handleFieldsChanges} value={this.state.fields.email} name="email" type="text" className="form-control" placeholder="Email" autoComplete="off" />
                                            {this.state.fieldsValidityError.email === '' || <div className="display-error">{this.state.fieldsValidityError.email}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group usm-check marginT30">
                                            <input onChange={this.handleFieldsChanges} value={this.state.fields.is_restricted} checked={this.state.fields.is_restricted} name="is_restricted" type="checkbox" id="adminview" />
                                            <label htmlFor="adminview">Restrict Personal Contact Details to Admin View Only</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group usm-check marginT15">
                                            <input onChange={this.handleFieldsChanges} value={this.state.fields.show_birthday_notification} checked={this.state.fields.show_birthday_notification} name="show_birthday_notification" type="checkbox" id="bdayNotification" />
                                            <label htmlFor="bdayNotification">Show Birthday Notification</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="border-bottom-trans marginTB34"></div>

                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <div className={this.state.fieldsValidityError.height === '' ? `form-group` : `form-group form-error`}>
                                    <label htmlFor="">Height(cm)</label>
                                    <input onChange={this.handleFieldsChanges} value={this.state.fields.height} disabled={is_agent} name="height" type="number" className="form-control" placeholder="" autoComplete="off" />
                                    {this.state.fieldsValidityError.height === '' || <div className="display-error">{this.state.fieldsValidityError.height}</div>}
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className={this.state.fieldsValidityError.weight === '' ? `form-group` : `form-group form-error`}>
                                    <label htmlFor="">Weight(Kg)</label>
                                    <input onChange={this.handleFieldsChanges} value={this.state.fields.weight} disabled={is_agent} name="weight" type="number" className="form-control" placeholder="" autoComplete="off" />
                                    {this.state.fieldsValidityError.weight === '' || <div className="display-error">{this.state.fieldsValidityError.weight}</div>}
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Preferred Foot</label>
                                    <Select name="preferred_foot"
                                        value={this.state.fields.preferred_foot}
                                        onChange={selectedOption => this.handleChange(selectedOption, 'preferred_foot')}
                                        options={this.state.preferredFootOptions}
                                        styles={customerStyles}
                                        placeholder={``}
                                        isDisabled={is_agent}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Professional Level</label>
                                    <Select nameName="professional_level"
                                        value={this.state.fields.master_professional_level_id}
                                        onChange={selectedOption => this.handleChange(selectedOption, 'master_professional_level_id')}
                                        options={this.state.professionalLevelOptions}
                                        styles={customerStyles}
                                        placeholder={``}
                                        isDisabled={is_agent}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Primary Position</label>
                                    <Select name="primary_position"
                                        value={this.state.fields.primary_position_id}
                                        onChange={selectedOption => this.handleChange(selectedOption, 'primary_position_id')}
                                        options={this.state.primaryPositionOptions}
                                        styles={customerStyles}
                                        placeholder={``}
                                        isDisabled={is_agent}
                                    />
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-12">
                                <div className={this.state.fieldsValidityError.additional_position_id === '' ? `form-group` : `form-group form-error`}>
                                    <label htmlFor="">Additional Positions</label>
                                    <Select name="additional_position"
                                        value={this.state.fields.additional_position_id}
                                        onChange={selectedOption => this.handleChange(selectedOption, 'additional_position_id')}
                                        options={this.state.additionalPositionOptions}
                                        isMulti={true}
                                        styles={customerStyles}
                                        placeholder={``}
                                        isDisabled={is_agent}
                                    />
                                    {this.state.fieldsValidityError.additional_position_id === '' || <div className="display-error">{this.state.fieldsValidityError.additional_position_id}</div>}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Opportunity Type</label>
                                    <Select name="primary_position"
                                        value={this.state.fields.master_opportunity_type_id}
                                        onChange={selectedOption => this.handleChange(selectedOption, 'master_opportunity_type_id')}
                                        options={this.state.opportunityTypeOptions}
                                        styles={customerStyles}
                                        placeholder={``}
                                        isDisabled={is_agent}
                                    />
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Outfitter (s)</label>
                                    <Select name="additional_position"
                                        value={this.state.fields.outfiter}
                                        onChange={selectedOption => this.handleChange(selectedOption, 'outfiter')}
                                        options={this.state.outfitterOptions}
                                        isMulti={true}
                                        styles={customerStyles}
                                        placeholder={``}
                                        isDisabled={is_agent}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Unique SM Agent</label>
                                    <Select name="unique_sm_agent"
                                        value={this.state.fields.usm_agent_id}
                                        onChange={selectedOption => this.handleChange(selectedOption, 'usm_agent_id')}
                                        options={this.state.agentOptions}
                                        styles={customerStyles}
                                        placeholder={``}
                                        isDisabled={this.state.disableAgent || is_agent}
                                    />
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-12">
                                <div className={this.state.fieldsValidityError.additional_usm_agent_id === '' ? `form-group` : `form-group form-error`}>
                                    <label htmlFor="">Additional UniqueSM Agents</label>
                                    <Select name="additional_unique_sm_agent"
                                        value={this.state.fields.additional_usm_agent_id}
                                        onChange={selectedOption => this.handleChange(selectedOption, 'additional_usm_agent_id')}
                                        options={this.state.additionalAgentOptions}
                                        isMulti={true}
                                        styles={customerStyles}
                                        placeholder={``}
                                        isDisabled={this.state.disableAgent || is_agent}
                                    />
                                    {this.state.fieldsValidityError.additional_usm_agent_id === '' || <div className="display-error">{this.state.fieldsValidityError.additional_usm_agent_id}</div>}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Current Club</label>
                                    <input onChange={this.handleClubNameChanges} value={this.state.fields.club_name} disabled={is_agent} name="club_name" type="text" className="form-control" autoComplete="off" />
                                    {
                                        !this.state.fields.predictive_club_list || this.state.fields.predictive_club_list.length <= 0 ? '' :
                                            <ul className="pre-search">{this.state.fields.predictive_club_list.map((club, index) => {
                                                return <li key={club.value} onClick={this.selectClub.bind(this, club.label, club.value)}>{club.label}</li>
                                            })}</ul>
                                    }
                                    {show_loader}
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">League</label>
                                    <Select name="master_league_id"
                                        value={this.state.fields.master_league_id}
                                        onChange={selectedOption => this.handleChange(selectedOption, 'master_league_id')}
                                        options={this.state.leagueOptions}
                                        styles={customerStyles}
                                        placeholder={``}
                                        isDisabled={is_agent}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Contract Type</label>
                                    <Select name="contract_type"
                                        value={this.state.fields.master_contract_type_id}
                                        onChange={selectedOption => this.handleChange(selectedOption, 'master_contract_type_id')}
                                        options={this.state.contractTypeOptions}
                                        styles={customerStyles}
                                        placeholder={``}
                                        isDisabled={is_agent}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className={this.state.fieldsValidityError.last_sigining_date === '' ? `form-group datepicker-wrap` : `form-group datepicker-wrap form-error`}>
                                    <label htmlFor="">Last Signing Date</label>
                                    <DatePicker
                                        className="form-control"
                                        selected={this.state.fields.last_sigining_date}
                                        onChange={selectedOption => this.handleChange(selectedOption, 'last_sigining_date')}
                                        dateFormat="dd/MM/yyyy"
                                        maxDate={new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)}
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={30}
                                        disabled={is_agent}
                                    />
                                    {this.state.fieldsValidityError.last_sigining_date === '' || <div className="display-error">{this.state.fieldsValidityError.last_sigining_date}</div>}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Current Representation Agent</label>
                                    <input type="text" onChange={this.handleFieldsChanges} value={this.state.fields.agent_representation} disabled={is_agent} className="form-control" id="" placeholder="" name="agent_representation" autoComplete="off" />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Current Representation Agency</label>
                                    <input type="text" onChange={this.handleFieldsChanges} value={this.state.fields.agency_representation} disabled={is_agent} className="form-control" id="" placeholder="" name="agency_representation" autoComplete="off" />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className={this.state.fieldsValidityError.contract_enddate === '' ? `form-group datepicker-wrap` : `form-group datepicker-wrap form-error`}>
                                    <label htmlFor="">Contract End Date</label>
                                    <DatePicker
                                        className="form-control"
                                        selected={this.state.fields.contract_enddate}
                                        onChange={selectedOption => this.handleChange(selectedOption, 'contract_enddate')}
                                        dateFormat="dd/MM/yyyy"
                                        // maxDate={new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)}
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={30}
                                        disabled={is_agent}
                                    />
                                    {this.state.fieldsValidityError.contract_enddate === '' || <div className="display-error">{this.state.fieldsValidityError.contract_enddate}</div>}
                                </div>
                            </div>
                            
                        </div>

                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                {restrict_representation_enddate ? <div className="form-group">
                                    <label htmlFor="">Representation End Date</label>
                                    <p className="text-danger font-size13">{ __x( `Restricted, please contact the administrator.` ) }</p>
                                </div> :

                                    <div className={this.state.fieldsValidityError.agent_representation_enddate === '' ? `form-group datepicker-wrap` : `form-group datepicker-wrap form-error`}>
                                        <label htmlFor="">Representation End Date</label>
                                        <DatePicker
                                            className="form-control"
                                            selected={this.state.fields.agent_representation_enddate}
                                            onChange={selectedOption => this.handleChange(selectedOption, 'agent_representation_enddate')}
                                            dateFormat="dd/MM/yyyy"
                                            minDate={new Date(today.getFullYear(), today.getMonth(), today.getDate())}
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={30}
                                            disabled={is_agent}
                                        />
                                        {this.state.fieldsValidityError.agent_representation_enddate === '' || <div className="display-error">{this.state.fieldsValidityError.agent_representation_enddate}</div>}
                                    </div>
                                }
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group usm-check marginT25">
                                    <input onChange={this.handleFieldsChanges} value={this.state.fields.show_ending_notification} checked={this.state.fields.show_ending_notification} disabled={is_agent} name="show_ending_notification" type="checkbox" id="endingnotification" />
                                    <label htmlFor="endingnotification">Show Representation Ending Notification</label>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                            { restrict_representation_enddate ? <div className="form-group usm-check marginT25">
                                                    <input checked={ true } name="restrict_representation_enddate" className="" type="checkbox" id="restrict_representation_enddate" />
                                                    <label htmlFor="restrict_representation_enddate" className="text-danger disabled">{ __x( `Restricted, please contact the administrator.` ) }</label>
                                                </div> : 
                                <div className="form-group usm-check marginT25">
                                    <input onChange={this.handleFieldsChanges} value={this.state.fields.restrict_representation_enddate} checked={this.state.fields.restrict_representation_enddate} disabled={is_agent} name="restrict_representation_enddate" type="checkbox" id="restrict_representation_enddate" />
                                    <label htmlFor="restrict_representation_enddate">Restrict Representation End Date to Admin View Only</label>
                                </div>
                                }
                            </div>
                        </div>

                        {this.state.is_editing ?
                            <div className="row">
                                <div className="col-md-3 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="">Transfer Market</label>
                                        <input type="text" onChange={this.handleFieldsChanges} value={this.state.fields.transfer_market} className="form-control" id="" placeholder="" disabled={is_agent} name="transfer_market" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="">Who Scored</label>
                                        <input type="text" onChange={this.handleFieldsChanges} value={this.state.fields.who_scored} className="form-control" id="" placeholder="" disabled={is_agent} name="who_scored" autoComplete="off" />
                                    </div>
                                </div>
                            </div>
                            : ''}

                        <div className="border-bottom-trans marginTB34"></div>

                        {this.state.is_editing ? '' :
                            <div>
                                <div className="row form-modal">
                                    <div className="col-md-5 col-sm-12 usm-form">
                                        <div className="d-flex">
                                            <div className="form-group align-self-center">
                                                <label htmlFor="">Add Photo</label>
                                                <div className={this.state.fieldsValidityError.file === '' ? `form-group paddingT0` : `form-group paddingT0 form-error`}>
                                                    <input onChange={this.handleFieldsChanges} type="file" name="file" className="custom-file-input" id="playerPic" />
                                                    <label className="custom-file-label" htmlFor="customFile">{this.state.fileName}</label>
                                                </div>
                                                {this.state.fieldsValidityError.file && this.state.fieldsValidityError.file !== '' ? <div className="display-error">{this.state.fieldsValidityError.file}</div> : ''}
                                            </div>
                                            <div className="upload-image ml-auto align-self-center">
                                                <img src={this.state.imgPreview} alt="" height="100px" />
                                                {/*<img src={ require( '../../assets/images/add-photo.png' ) } alt="" height="" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="border-bottom-trans marginTB34"></div>
                            </div>
                        }

                        <div className="row">
                            <div className="col-md-12 col-sm-12 marginB20">
                                <div className={this.state.fieldsValidityError.tags === '' ? `form-group tags-chip player-tags` : `form-group tags-chip player-tags form-error`}>
                                    <label htmlFor="">Tag (s)</label>
                                    {
                                        is_agent ?
                                            <ul className="usm-tags-list">{this.list_tags()}</ul>
                                            :
                                            <ReactTags
                                                classNames={{ searchInput: 'players-considered form-control' }}
                                                tags={this.state.fields.tags}
                                                handleDelete={this.onTagDelete}
                                                handleAddition={this.onTagSelection}
                                                placeholder={``}
                                                delimiters={[43, 13]}
                                                allowNew={true}
                                                autofocus={false}
                                                delimiterChars={['+', ';']}
                                                allowBackspace={false}
                                                tagComponent={ReactTagsComponent}
                                            />
                                    }
                                    {this.state.fieldsValidityError.tags === '' || <div className="display-error">{this.state.fieldsValidityError.tags}</div>}
                                </div>
                            </div>
                        </div>
                        {
                            is_agent ? '' :
                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        <Link className="default-btn btn-danger width-auto" to={`/admin/players`}>Cancel</Link>
                                        <button type="button" onClick={ e => this.addPlayer(e) } disabled={disableSubmit} className="default-btn btn-success width-auto">Save</button>
                                    </div>
                                </div>
                        }

                    </form>
                    { !this.state.lockscreen || <div className="modal-backdrop fade show"></div> }
                </div>
            </div>
        );
    }

    list_tags = () => {
        const tags = this.state.fields.tags;
        return tags && tags.length > 0 ? tags.map((tag, idx) => {
            return <li key={idx}>{tag.name}</li>
        }) : [];
    }
}
