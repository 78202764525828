import React from 'react';
import { schemize_url, get_current_user_role, get_display_format_date, __x, get_embedded_url } from '../../../helpers/GeneralHelper';

const Video = props => {
    const videos = props.videos;
    
    
    return videos && videos.length > 0 ? videos.map( video => {
        const analysis_links = video.player_video_analysis_links ? video.player_video_analysis_links : [],
        current_user_role   = get_current_user_role(),
        is_restricted       = video.is_restricted && current_user_role !== 'Admin',
        video_url           = get_embedded_url( video.video_url );
        
        /**
         * If current note is restricted for current user.
         */
        if ( is_restricted ) {
            return (
                <li key={ video.id } className="media marginB15">
                    <div className="media-body clearfix">
                        <div className="note-add-modify">
                            <div className="row">
                                <div className="col-md-1 text-center">
                                    <img src={ require("../../../assets/images/Restricted.png") } alt="" />
                                </div>
                                <div className="col-md-11 paddingL0">
                                    <p className="fontBold text-danger font-size14">{ __x( `Restricted` ) }
                                    <span className="fontBold text-white d-block">{__x( `Please contact administrator.` ) }</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            );
        }

        return (
            <li key={video.id} className="media paddingB40 marginB15">
                {
                    current_user_role === 'Agent' ? '' : 
                    <button type="button" onClick={ e => props.openPopup( e, video.id )} className="transparent-btn align-self-start mr-3">
                        <i className="material-icons text-opt-four font-size20">edit</i>
                    </button>
                }
    
                <div className="media-body clearfix">
                    <div className="row">
                        <div className="col-md-7 col-sm-12">
                            <div className="nc-nb-heading">{video.video_title} [{ get_display_format_date( video.updated_at ) }]</div>
                            <p>
                                {video.video_desc}
                            </p>
                            <div className="note-add-modify">
                                <div className="row marginB5">
                                    <div className="col-md-12">
                                        {
                                            analysis_links && analysis_links.length > 0 ? analysis_links.map( analysis_link => {
                                                return <a key={ analysis_link.id } href={analysis_link.link} className="d-block" rel="noopener noreferrer" target="_blank">
                                                    Link to external analysis 
                                                    <i className="material-icons text-success font-size14 align-text-bottom">call_made</i>
                                                </a>
                                            }) : []
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12">
                            <div className="video-block">
                                {
                                    !video_url || video_url === '' ? '' : 
                                    <iframe 
                                        className="usm-player-video"
                                        src={ schemize_url( video_url ) }
                                        frameBorder="0" 
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                                        allowFullScreen
                                        webkitallowfullscreen="true"
                                        mozallowfullscreen="true"
                                        title={ video_url }>
                                    </iframe>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        );
    }) : [];
}
export default Video;