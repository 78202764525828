const APIRoutes = {
    login                       : "/login/",
    tnc                         : "/tnc/",
    forgot_password             : "/forgot-password/",
    reset_password              : "/reset-password/",
    activate_user               : "/activate-user/",
    dashboard                   : "/dashboard/",
    all_users                   : '/all-users/',
    user                        : "/user/",
    all_agents                  : "/all-agents/",
    agent                       : "/agent/",
    clubs                       : "/all-clubs/",
    club                        : "/club/",
    league_list                 : "/league-list/",
    club_badge                  : "/club-badge/",
    update_league               : "/update-club-league/",
    add_club_contact            : "/add-club-contact/",
    club_contact_status         : "/club-contact-status/",
    club_contact                : "/club-contact/",
    players                     : "/all-players/",
    player_positions            : "/get-position",
    player_professional_level   : "/get-professional-level",
    player_contract_type        : "/get-contract-list",
    filter_players              : "/filter-player",
    getcasewidget               : "/getcasewidget/",
    get_nationality             : "/get-country/nationality",
    get_passport                : "/get-country",
    get_opportunity_list        : "/get-opportunity-list",
    get_outfiter                : "/get-outfiter",
    get_club_list               : "/get-club-list/",
    get_position                : "/get-position/",
    get_agent_list              : "/get-agent-list/",
    player                      : "/player/",
    case                        : "/case/",
    get_player_list             : "/get-player-list/",
    add_player_note             : "/add-player-note/",
    get_player_notes            : "/get-player-notes/",
    get_player_concierges       : "/get-player-concierges/",
    get_player_notes_filters    : "/get-playernotes-addedby/",
    get_player_concierge_filters: "/get-playerconcierges-addedby/",
    add_player_concierge        : "/add-player-concierge/",
    get_player_notes_concierges : "/get-player-notes-concierges/" ,
    player_profile_image        : "/player-profile-image/",
    update_player_note          : "/update-player-note/",
    update_player_concierge     : "/update-player-concierge/",
    player_case                 : "/player-case/",
    renew_lock_session          : "/renew-lock-session/",

    // Required param :player_id for GET Request.
    player_hightlight           : "/player-hightlight/",
    
    // Required param :note_id for DELETE Request.
    delete_player_note          : "/delete-player-note/",

    // Required param :note_id for DELETE Request.
    delete_player_concierge     : "/delete-player-concierge/",

    // Required param :player_id for GET Request.
    get_player_reports          : "/get-player-reports/",
    
    // Required param :player_id for POST Request.
    add_player_report           : "/add-player-report/",
    
    // Required param :player_id for PUT Request.
    update_player_report        : "/update-player-report/",
    all_cases                   : "/all-cases/",
    
    // Required param :player_id for GET request.
    player_all_videos           : "/player-all-video/",

    // Required param :player_id for POST(add video) and GET(get video) both requests.
    player_video                : "/player-video/",

    // Path for add case note , Required param : case_id for ADD Case Note
    case_note                   : "/case-note/",
    
    //Path to get all notes of a paerticular case, Required param : case_id
    case_all_note               : "/case-all-note/",
    //Path to get outcome list in club case edit
    get_outcome_list            : "/get-outcome-list/",

    //Path to delete player report, Required param : report_id for GET and DELETE both.
    player_report                : "/player-report/",
    
    //Path to get/add/update/delete player contact, Required param :player_id for POST/PUT and :contact_id for GET/DELETE.
    player_contact                : "/player-contact/",

    // Path to get all players' contacts, Required param :player_id for GET request.
    player_all_contacts           : "/player-all-contact/",

    // Path to get particular player cases (active/closed) , Required param : player_id
    get_player_case_widget       : "/get-player-case-widget/",

    //Path to get active case list , Required param : player_id , searchText
    active_case_list             : "/active-case-list/",

    //Path to assign a case to a player for POST request
    add_player_existing_case     : "/add-player-existing-case/",

    //Path to get agent cases , Required param : agent_id
    agent_detail                 : "/agent-detail/",

    // No parameters required.
    agent_dashboard              : "/agent-dashboard/",

    //path to change agent profile image
    agent_profile_image          : "/agent-profile-image/",

    //path to add agent intel notes
    add_agent_note               : "/add-agent-note/",

    //path for player global search
    search                       : "/search/",

    //path for staff dashboard content
    staff_dashboard              : "/staff/dashboard/",

    //path to get single player note by note id
    player_note                  : "/player-note/",

    //path to get intel note by note id
    agent_note                   : "/agent-note/",

    //path for admin dashboard
    admin_dashboard              : "/admin/dashboard",

    //path to get push notification data
    notification                 : "/notification/",

    //path to get all organisation data
    all_organization             : "/all-organization/",

    //path to add organisation data
    organization                 : "/organization/",

    //path to get agents with active cases
    active_case_agents           : "/active-case-agents/",

    //path to add/edit/get organisation contact
    organization_contact         : "/organization-contact/",

    //path to get all organisation contact of a particular organisation
    organization_all_contacts    : "/organization-all-contacts/",
    
    // Logout path with get method.
    logout                       : "/logout/",
    
    // Players history path with GET method and :player_id param.
    players_history              : "/players-history/",

    //login history
    users_login_history          : "/users-login-history/",

    //path to add organisation note , required param organisation_id
    organization_note            : "/organization-note/",

    //path to send alert from admin dashboard
    send_notification            : "/send-notification/",

    //path to send alert of agent from admin dashboard
    send_agent_reminder          : "/send-agent-reminder/",

    //path to get users name who added organisation notes
    get_orgnotes_addedby         : "/get-orgnotes-addedby/",
    
    // Path to get intel notes list. Accepts :agent_id as a parameter.
    intel_notes                  : "/intel-notes/",
    // Path to view/edit user profile
    user_profile                 : "/user-profile/",
    
    // Path to get all/single changelogs. Also needed :log_id as a parameter for get the single log.
    change_logs                 : "/change-logs/",

    //path to change password
    change_password              : "/change-password/",

    //path to send report
    send_report                  : "/send-report/",

    //path to get club details of a particular club, Param id (club id)
    club_detail                  : "/club-detail/",
    
    // Path to check token va;idation.
    validate_token               : "/validate-token/",

    //path to change user role
    change_role                  : "/change-role/",
}
export default APIRoutes;