import React from 'react';
import { get_days_by_date, get_date_timestamp } from '../../helpers/GeneralHelper';
import { Link } from 'react-router-dom';
import CaseAgentRollOver from '../admin-staff/case/CaseAgentRollOver';


const PlayerCase = ({ active_player_case, active_player_widgets, idx, case_type, handle_alert }) => {

    let visible = '',
        activeCases = [],
        disableAlert = active_player_case.show_button && active_player_case.show_button === 1 ? '' : 'disabled';

    if (active_player_widgets.length > 0) {
        for (let i in active_player_widgets) {
            if (active_player_widgets[i].id === active_player_case.id) {
                visible = `visible`;
            }
        }
    } else {
        visible = idx > 3 ? '' : `visible`;
    }

    activeCases.push(<div key={active_player_case.id} className={`col-md-3 col-sm-6 col-xs-12 ${visible}`}>
        <div className="card">
            <div className="card-body">
            <Link to={ case_type === 'player_case' ? `/admin/edit-player-case/` + active_player_case.id : `/admin/edit-club-case/` + active_player_case.id }>
                <div className="cardImg-section">
                    <div className="card-image">
                        <img className="club" src={active_player_case.image_path ? active_player_case.image_path : require('../../assets/images/dummy-image.png')} alt=" " />
                    </div>
                    <div className="cardTitle-section" title={`${active_player_case.name ? active_player_case.name : ''} (${active_player_case.position ?  active_player_case.position : ' '})`}>
                        <div className="card-title">{active_player_case.name ? active_player_case.name : ''}</div>
                        <div className="card-subtitle">{active_player_case.position ? active_player_case.position : ' '}</div>
                    </div>
                </div>
                </Link>

                <div className="cardContent-section">
                <Link to={ case_type === 'player_case' ? `/admin/edit-player-case/` + active_player_case.id : `/admin/edit-club-case/` + active_player_case.id }>
                    <div className="d-flex card-text marginB18">
                        <div className="mr-auto text-success fontBold font-size14">No of Agents</div>
                        <div className="fontBold text-success font-size14 show-rollover position-relative">{active_player_case.agents ? active_player_case.agents.length : 0}
                            {active_player_case.agents.length > 0 ? <CaseAgentRollOver case_id={active_player_case.agents.id} agents={active_player_case.agents} /> : ''}
                        </div>
                    </div>
                    
                    <div className="d-flex card-text marginB20">
                        <div className="mr-auto font-size14">Last Update</div>
                        <div className="fontBold font-size14">{active_player_case.updated_at ? get_days_by_date(active_player_case.updated_at) : 0} days</div>
                    </div>
                    </Link>
                    <button type="button" disabled={disableAlert} onClick={ () => handle_alert(active_player_case,'case') }  className="btn btn-usmPrimary">Alert</button>
                    <div className="card-alert">{ active_player_case.alert_send_at ? `Alert last sent ${get_date_timestamp(active_player_case.alert_send_at, false)}` : '' }</div>
                </div>
            </div>
        </div>
    </div>);
    return activeCases;
}
export default PlayerCase;

