import React from 'react';
import { is_valid_db_date, get_display_format_date, get_current_user_role, __x } from '../../../helpers/GeneralHelper';
import { Link } from 'react-router-dom';
import CaseAgentRollOver from './CaseAgentRollOver';

const Case = ({ cases, is_fetched, user_role }) => {
    let casesList = [];
    if ( !cases || !cases.length || cases === 'undefined' ) {
        if ( is_fetched ) {
            casesList.push(
                <tr className="text-center" key={1}>
                    <td colSpan="8">{ __x( `No record found.` ) }</td>
                </tr>
            );
        }
        
        return casesList;
    }

    let current_user_role = get_current_user_role() === 'Agent' ? 'agent' : 'admin';

    if (!cases || cases === 'undefined' || cases.length <= 0) {
        return casesList;
    }

    const manage_icon = user_role === 'Agent' ? <i className="material-icons text-offWhite font-size20">remove_red_eye</i> : <i className="material-icons text-offWhite font-size20">edit</i>;

    for ( let i in cases ) {
        let case_data   = cases[i];
        const case_type = case_data.master_requirement_id === 1 ? 'player' : 'club';
        casesList.push(<tr key={case_data.id}>
            <td className="fontBold font-size16">
                {case_data.case_number !== '' ? case_data.case_number : ''}
            </td>
            <td>
            <div className="d-flex align-items-center">
                {
                    case_type === 'player' ? 
                        <span className="table-playerImg">
                            <img src={ require( `../../../assets/images/add-player-white.png` ) } alt="" /> 
                        </span>
                    : 
                        <span className="table-clubImg">
                            <img src={ case_data.club && case_data.club.image_path ? case_data.club.image_path : require( `../../../assets/images/dummy-image.png` ) } alt="" />
                        </span> 
                }
                {case_data.club_id && case_data.club ? case_data.club.club_name : case_data.player_id && case_data.player ? case_data.player.first_name + " "+ case_data.player.last_name : ''}
                </div>
            </td>
            <td>
                {case_data.master_position_id && case_data.master_position_id !== '' && case_data.master_position ? case_data.master_position.title : ''}
            </td>
            
            <td className="fontBold text-offWhite">
                {case_data.fulfillment_date && case_data.fulfillment_date !== '' && is_valid_db_date(case_data.fulfillment_date) ? get_display_format_date(case_data.fulfillment_date) : ''}
            </td>
            <td>
                <button data-toggle="popover" data-container="body" data-trigger="focus" data-placement="left" data-html="true" to="#" email="toggle-one" className="table-btn btn-transWhite show-rollover position-relative">
                    <span>{case_data.case_agents && case_data.case_agents.length > 0 ? case_data.case_agents.length : 0}</span>
                    {case_data.case_agents.length > 0 ? <CaseAgentRollOver case_id={case_data.id} agents={case_data.case_agents} /> : ''}
                </button>
            </td>
            <td className="fontBold text-offWhite">{case_data.updated_at && case_data.updated_at !== '' && is_valid_db_date(case_data.updated_at) ? get_display_format_date(case_data.updated_at) : ''}</td>
            <td>
                <div className="marginB3"><button type="button" className={case_data.status === true ? `table-btn btn-danger` : `table-btn btn-success`}>{case_data.status === true ? 'Open' : 'Closed'}</button></div>

            </td>

            <td>{case_data.master_requirement_id !== '' && case_data.master_requirement_id === 1 ?
                case_data.status === false ?
                    <Link to={`/${current_user_role}/view-player-case/` + case_data.id}>{manage_icon}</Link> :
                    current_user_role === 'admin' ?
                    <Link to={`/admin/edit-player-case/` + case_data.id}>{manage_icon}</Link> :
                    <Link to={`/${current_user_role}/view-player-case/` + case_data.id}>{manage_icon}</Link>
                     :
                case_data.status === false ?
                    <Link to={`/${current_user_role}/club-case/` + case_data.id}>{manage_icon}</Link> :
                    current_user_role === 'admin' ?
                    <Link to={`/admin/edit-club-case/` + case_data.id}>{manage_icon}</Link> :
                    <Link to={`/${current_user_role}/club-case/` + case_data.id}>{manage_icon}</Link> 
                    
            }
            </td>
        </tr>);
    }

    return casesList;

}

export default Case;