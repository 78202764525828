import React from 'react';
import { __x } from '../../helpers/GeneralHelper';

const IntelNotesConfirmationPopup = props => {
    const showModal = props.modal ? 'show' : '';
    return (
        <div>
            <div className={`modal form-modal z-index99999 fade modal-active ${showModal}`} style={{ transform: props.modal ? `translate(0, 0)` : 'translate(-125%, -125%)' }}>
                <div className="modal-dialog modal-dialog-centered maxWidth400">
                    <div className="modal-content">
                        <div className="modal-header form-modal-header text-center d-flex align-items-center">
                            <h5 className="usm-note-sent-title form-modal-title">Note Sent</h5>
                        </div>
                        <div className="modal-body form-modal-body">
                            <h5 className="form-modal-content text-white fontBold">
                                { __x( `Thank you, your note has been submitted` ) }
                            </h5>
                        </div>
                        <div className="modal-footer form-modal-footer d-flex">
                            <button type="button" className="default-btn btn-success" onClick={ props.onConfirm }>Ok</button>
                        </div>
                    </div>
                </div>
            </div>
            { !props.modal || <div className="modal-backdrop fade show z-index9999"></div> }
        </div>
    );
}
export default IntelNotesConfirmationPopup;