import React from 'react';
import { Link } from 'react-router-dom';

const Case = ({ current_case }) => {
    if ( !current_case || Object.keys( current_case ).length <= 0 ) {
        return '';
    }

    const case_type = current_case.club_id !== null ? 'club' : current_case.player_id !== null ? 'player' : '',
    case_name       = case_type === 'club' ? current_case.club_name : `${current_case.player_first_name} ${current_case.player_last_name}`,
    position        = current_case.master_position && current_case.master_position.title ? current_case.master_position.title : '';

    return (
        <Link to={ case_type === 'club' ? `/agent/club-case/${current_case.id}` : `/agent/view-player-case/${current_case.id}`} className="col-md-3 col-xs-12">
            <div className="card">
                <div className="card-body">
                    <div className="cardImg-section">
                        <div className="card-image paddingT5">
                            <img className="club" src={ current_case.image_path ? current_case.image_path : require(`../../assets/images/dummy-image.png`) } alt="" />
                        </div>

                        <div className="cardTitle-section">
                            <div className="card-title">{ case_name }</div>
                            <div className="card-subtitle">{ position }</div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}
export default Case;