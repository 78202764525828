import React, { Component } from 'react';
import UpdateContact from './UpdateContact';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import { withRouter } from 'react-router-dom';
import { setContacts, setContact } from '../../../actions/PlayersAction';
import { connect } from 'react-redux';
import { get_current_user_role, get_display_format_date, get_authorization_token, __x, is_valid_db_date } from '../../../helpers/GeneralHelper';



class Contacts extends Component {

    _page_title = 'Contacts - USM';

    constructor(props) {
        super(props);

        this._player_id = props.match.params.player_id;
        this.current_user_role = get_current_user_role();

        this._fetched = {
            contact: false,
            contacts: false
        };

        this.state = {
            shouldPopupOpen: false,
            contacts: [],
            contact: {},
            is_editing: false
        }
    }

    componentWillReceiveProps = (props) => {
        this.setState({
            contacts: props.players.contacts
        });
    }

    openPopup = ( contact_id = 0, is_player_contact = 'false') => {
        if (contact_id > 0) {
            this.getContact(contact_id, is_player_contact);
        }

        this.setState({
            shouldPopupOpen: !this.state.shouldPopupOpen
        }, () => {
            if ( this.state.shouldPopupOpen ) {
                document.body.classList.add( 'modal-open' );
            } else {
                document.body.classList.remove( 'modal-open' );
            }
        });
    }

    closePopup = () => {
        this.setState({
            contact: {},
            is_editing: false,
            shouldPopupOpen: !this.state.shouldPopupOpen
        }, () => {
            if ( this.state.shouldPopupOpen ) {
                document.body.classList.add( 'modal-open' );
            } else {
                document.body.classList.remove( 'modal-open' );
            }
        });
    }

    /**
     * Get single contact by contact_id from API.
     */
    getContact = (contact_id, is_player_contact = 'false') => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x(`Session has been expired. Please reload the page to login!`)
            });
            return false;
        }

        const contacts_path = APIRoutes.player_contact + contact_id + '/' + is_player_contact;
        const request = new Request(contacts_path, {}, 'GET', headers).send();
        request.then(response => {
            if ( response.success ) {
                const contact = response.data;

                this.setState({
                    contact,
                    is_editing: true
                });

                this.props.setContact(contact);
                this._fetched.contact = true;
            }
        }, error => {
            console.log(error);
        });
    }

    /**
     * Get all contacts from API.
     */
    getContacts = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x(`Session has been expired. Please reload the page to login!`)
            });
            return false;
        }

        const contacts_path = APIRoutes.player_all_contacts + this._player_id;
        const request = new Request(contacts_path, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                /**
                 * Dispatching action to set clubs to redux store.
                 */
                this.props.setContacts(response.data);
                this._fetched.contacts = true;
            }
        }, error => {
            console.log(error);
        });
    }

    componentDidMount = () => {
        document.title = this._page_title;

        if ( !this._fetched.contacts ) {
            this.getContacts();
        }
    }

    render() {
        return (
            <div>
                <div className="closed-cases">
                    {
                        this.current_user_role === 'Agent' ? '' :
                            <div className="heading-section d-flex align-items-center auto-height fontBold font-size14">
                                <button onClick={this.openPopup} type="button" className="transparent-btn text-white float-left text-uppercase">
                                    <img src={require('../../../assets/images/add-icon.png')} className="marginR10" alt="" />
                                    Add New Contact
                                </button>
                            </div>
                    }

                    <div className="usm-table club-cases-table table-responsive">
                        <table className="table table-hover lastOne-center">
                            <thead className="usm-thead">
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Relationship</th>
                                    <th scope="col">Phone</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Birthday</th>
                                    {this.current_user_role === 'Agent' || <th scope="col">Manage</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {this.listContacts()}
                            </tbody>
                        </table>
                    </div>
                </div>
                <UpdateContact
                    shouldPopupOpen={this.state.shouldPopupOpen}
                    closePopup={this.closePopup}
                    refresh={this.getContacts}
                    contact={this.state.contact}
                    is_editing={this.state.is_editing}
                />
            </div>
        );
    }

    /**
     * List contact details.
     */
    listContacts = () => {
        const contacts = this.state.contacts;
        return contacts && contacts.length > 0 ? contacts.map(contact => {
            const is_restricted = get_current_user_role() !== 'Admin' && contact.is_restricted;
            return <tr key={contact.id}>
                <td className="fontBold font-size16">{contact.first_name ? contact.first_name : ''} {contact.last_name ? contact.last_name : ''}</td>
                <td>{contact.relation ? contact.relation : ''}</td>
                {
                    is_restricted ? <td colSpan="2">
                        <div className="row">
                        <div className="col-md-12">
                                <img src={require("../../../assets/images/Restricted.png")} alt="" />
                            
                                <p className="fontBold text-danger font-size14 restricted-css">Restricted
                                <span className="fontBold text-white d-block">Please contact administrator.</span>
                                </p>
                            </div>
                        </div>
                    </td> : <td>{contact.country_code && contact.phone ? `+${contact.country_code}` : ''}{contact.phone ? contact.phone : ''}</td>
                }
                {
                    is_restricted || <td>{contact.email ? contact.email : ''}</td>
                }
                <td>
                    {contact.show_birthday_notification ? <i className="align-bottom material-icons text-success font-size18 marginR5">notifications</i> : ''}
                    {is_valid_db_date(contact.birthday) ? get_display_format_date(contact.birthday) : '' }
                </td>
                {
                    this.current_user_role === 'Agent' ||
                    <td>
                        <button onClick={ () => this.openPopup( contact.id, contact.is_player_contact ? 'true' : 'false')} type="button" className="transparent-btn">
                            <i className="material-icons text-offWhite font-size20">edit</i>
                        </button>
                    </td>
                }
            </tr>
        }) : [];
    }
}

const mapStateToProps = state => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setContact: contact => dispatch(setContact(contact)),
    setContacts: contacts => dispatch(setContacts(contacts))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contacts));