export const setNotes = ( notes ) => dispatch => {
    dispatch({
        type: 'SET_NOTES',
        payload: {
            notes
        }
    });
}

export const setConcierges = ( concierges ) => dispatch => {
    dispatch({
        type: 'SET_CONCIERGES',
        payload: {
            concierges
        }
    });
}

export const setCurrentPlayer = player => dispatch => {
    dispatch({
        type: 'SET_CURRENT_PLAYER',
        payload: {
            player
        }
    });
}