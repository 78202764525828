import React from 'react';
import { is_valid_db_date, get_authorization_token, get_current_user_role, get_display_format_date, __x } from '../../helpers/GeneralHelper';
import APIRoutes from '../../API/Routes';
import Request from '../../helpers/Request';
import { withRouter } from 'react-router-dom';
import UpdateNote from '../admin-staff/common/UpdateNote';
import IntelNotesConfirmationPopup from './IntelNotesConfirmationPopup';
import LoadingSpinner from '../admin-staff/common/LoadingSpinner';

class PlayerCaseView extends React.Component {
    constructor(props) {
        super(props);
        this._case_id = props.match.params.case_id;
        this._current_user_role = get_current_user_role();
        this._notes = React.createRef();
        this.state = {
            cases: [],
            _fetched: {
                closed_player_case: false
            },
            fields: {
                case_number: '',
                last_name: '',
                first_name: '',
                case_agents: [],
                agent_representation: '',
                agency_representation: '',
                agent_representation_enddate: null,
                current_club: '',
                master_position_id: '',
                master_professional_level: '',
                detail: '',
                case_note: [],
                status: true,
                is_contract_signed: false,
                case_addition_positions: [],
                master_requirement_id: '',
            }
        }
    }

    getClosedPlayerCase = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x(`Session has been expired. Please reload the page to login!`)
            });
            return false;
        }

        const closed_player_case_path = APIRoutes.case + this._case_id;
        const request = new Request(closed_player_case_path, {}, 'GET', headers).send();
        const fields = this.state.fields;
        request.then(response => {
            if (response.success) {
                let closed_case_data = response.data[0];
                fields['master_requirement_id'] = closed_case_data.master_requirement_id ? closed_case_data.master_requirement_id : '';
                fields['case_number'] = closed_case_data.case_number && closed_case_data.case_number !== '' ? closed_case_data.case_number : '';
                fields['last_name'] = closed_case_data.player && Object.keys(closed_case_data.player).length > 0 ? closed_case_data.player.last_name : '';
                fields['first_name'] = closed_case_data.player && Object.keys(closed_case_data.player).length > 0 ? closed_case_data.player.first_name : '';
                fields['case_agents'] = closed_case_data.case_agents && closed_case_data.case_agents.length > 0 ? closed_case_data.case_agents : [];
                fields['agent_representation'] = closed_case_data.agent_representation && closed_case_data.agent_representation !== '' ? closed_case_data.agent_representation : '';
                fields['agency_representation'] = closed_case_data.agency_representation && closed_case_data.agency_representation !== '' ? closed_case_data.agency_representation : '';
                fields['agent_representation_enddate'] = closed_case_data.agent_representation_enddate && closed_case_data.agent_representation_enddate !== '' ? new Date(closed_case_data.agent_representation_enddate) : null;
                fields['current_club'] = closed_case_data.current_club && closed_case_data.current_club !== '' ? closed_case_data.current_club : '';
                fields['master_position_id'] = closed_case_data.master_position_id && closed_case_data.master_position_id !== null && closed_case_data.master_position && Object.keys(closed_case_data.master_position).length > 0 ? closed_case_data.master_position.title : '';
                fields['detail'] = closed_case_data.detail && closed_case_data.detail !== '' ? closed_case_data.detail : '';
                fields['case_note'] = closed_case_data.case_notes && closed_case_data.case_notes.length > 0 ? closed_case_data.case_notes : [];
                fields['status'] = closed_case_data.status;
                fields['is_contract_signed'] = closed_case_data.is_contract_signed;
                fields['master_professional_level'] = closed_case_data.master_professional_level && Object.keys(closed_case_data.master_professional_level).length > 0 ? closed_case_data.master_professional_level.name : '';
                fields['case_addition_positions'] = closed_case_data.case_addition_positions && Object.keys(closed_case_data.case_addition_positions).length > 0 ? closed_case_data.case_addition_positions : [];

                this.setState({
                    _fetched: {
                        closed_player_case: true
                    },
                    fields
                })
            }
        }, error => {
            console.log(error);
        });
    }

    componentDidMount = () => {
        if (!this.state._fetched.closed_player_case) {
            this.getClosedPlayerCase();
        }
    }

    render = () => {
        const loading = this.state.isProcessing ? <LoadingSpinner /> : '',
            case_agents = this.state.fields.case_agents,
            case_agents_name = case_agents && case_agents.length > 0 ? case_agents.map(case_agent => {
                return case_agent.user.first_name + " " + case_agent.user.last_name;
            }).join(', ') : '';

        const is_contract_signed = this.state.fields.is_contract_signed === true ? 'Yes' : 'No';

        /**
         * case addition positions name
         */
        const case_addition_positions = this.state.fields.case_addition_positions && Object.keys(this.state.fields.case_addition_positions).length > 0 ? this.state.fields.case_addition_positions.map(case_addition_position => {
            return case_addition_position.master_position.title;
        }).join(', ') : '';

        /* Cases listing */
        let cases_list = this.state.fields.case_note && this.state.fields.case_note.length > 0 ? this.state.fields.case_note.map((note, n_idx) => {
            const attachments = note.case_note_files;
            const is_restricted = note.is_restricted && get_current_user_role() !== 'Admin';

            /**
                 * If current note is restricted for current user.
                 */
            if (is_restricted) {
                return (
                    <li key={note.id} className="media">
                        <div className="media-body clearfix">
                            <div className="nc-nb-heading marginB10">Note</div>
                            <div className="note-add-modify">
                                <div class="row">

                                    <div class="col">
                                        <div class="d-inline-block marginR10 align-top">
                                            <img src={require(`../../assets/images/Restricted.png`)} alt="" />
                                        </div>
                                        <div class="d-inline-block">
                                            <p class="fontBold text-danger font-size14">{__x(`Restricted`)}
                                                <span class="fontBold text-white d-block marginT5">{__x(`Please contact administrator.`)}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                );
            }

            /**
             * List for non restricted notes
             */
            return (
                <li key={note.id} className="media">
                    <div className="media-body clearfix">
                        <div className="nc-nb-heading">Note: </div>
                        <p>{note.note}</p>
                        <div className="note-add-modify">
                            <div className="row marginB5">
                                <div className="col-md-2 width10 col-sm-12">Added by: </div>
                                <div className="col-md-10 col-sm-12 fontBold">{note.added_by}</div>
                            </div>
                            <div className="row marginB5">
                                <div className="col-md-2 width10 col-sm-12">Last Modified: </div>
                                <div className="col-md-10 col-sm-12 fontBold">{ note.updated_at && is_valid_db_date(note.updated_at) ? get_display_format_date(note.updated_at) : '' }</div>
                            </div>
                            <div className="row">
                                <div className="col-md-2 width10 col-sm-12">Attachments: </div>
                                <div className="col-md-10 col-sm-12">
                                    {
                                        attachments && attachments.length > 0 ? attachments.map(attachment => {
                                            return (<div key={attachment.id} className="text-success font-size14 word-break-all marginB10">
                                                <a href={attachment.filename} target="_blank" rel="noopener noreferrer">{attachment.original_filename}</a>
                                            </div>)
                                        }) : ''
                                    }
                                    {/* <div className="text-success font-size14">Filename02.pdf</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            );
        }) : '';

        /**
         * add text if case is closed
         */
        let status = this.state.fields.status === true ? 'View Case' : 'Closed Case';


        return (
            <div id="mainContainer">
                {loading}
                <div className="heading-section height84 d-flex border-bottom-trans align-items-center">
                    <h2>{status}</h2>
                </div>

                <div className="content-section">
                    <form action="" className="usm-form form-border">
                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group form-filled">
                                    <label htmlFor="">Case Number</label>
                                    <p className="font-size24 fontBold">{this.state.fields.case_number}</p>
                                </div>
                            </div>
                        </div>
                        <div className="border-bottom-trans marginB35"></div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Last Name</label>
                                    <p>{this.state.fields.last_name}</p>

                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">First Name</label>
                                    <p>{this.state.fields.first_name}</p>

                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Unique SM Agent:</label>
                                    <p>{case_agents_name}</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Representation Agent During Case:</label>
                                    <p>{this.state.fields.agent_representation}</p>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Representation Agency During Case:</label>
                                    <p>{this.state.fields.agency_representation}</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Representation End Date</label>
                                    <p>{ is_valid_db_date(this.state.fields.agent_representation_enddate) ? get_display_format_date(this.state.fields.agent_representation_enddate) : '' }</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Professional Level</label>
                                    <p>{this.state.fields.master_professional_level}</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Current Club</label>
                                    <p>{this.state.fields.current_club}</p>
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom-trans marginB25 marginT20"></div>

                        <div className="row">

                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Primary Position</label>
                                    <p>{this.state.fields.master_position_id}</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Additional Positions</label>
                                    <p>{case_addition_positions}</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="">Details</label>
                                    <p className="fontNormal line-height-normal">{this.state.fields.detail}</p>
                                </div>
                            </div>

                        </div>


                        <div className="border-bottom-trans marginTB35"></div>

                        <div className="player-detail-block">
                            <div className="player-detail-heading d-flex align-items-center">Notes
                                    {this.state.fields.status === false ? '' :
                                    <button onClick={this._notes.toggleNotesPopup} type="button" className="transparent-btn float-right ml-auto">
                                        <img src={require('../../assets/images/add-icon.png')} alt="" />
                                    </button>
                                }
                            </div>
                            <div className="player-detail-content">
                                <div className="notes-block">
                                    <ul className="list-unstyled">
                                        {cases_list}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom-trans marginB25 marginT20"></div>

                        {this.state.fields.status === true ? '' :
                            <div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group font-white">
                                            <div className="font-size24 marginT10">{status}</div>
                                            <div className="font-size16 marginT10">Contract Signed: {is_contract_signed} </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="border-bottom-trans marginB35 marginT20"></div>
                            </div>
                        }

                        <div className="row">
                            <div className="col">
                                <button type="button" onClick={() => this.props.history.go(-1)} className="default-btn btn-danger width-auto">Back</button>
                            </div>
                        </div>
                    </form>

                </div>
                <UpdateNote

                    ref={elt => this._notes = elt}
                    onSubmit={this.saveNotesGlobally}
                    note={this.state.edit_note}
                    files="case_note_files"
                    userRole={this._current_user_role}
                />
                <IntelNotesConfirmationPopup
                    modal={this.state.openIntelNotesConfirmationPoppup}
                    onConfirm={this.closeConfirmationPopUp}
                />
            </div>
        );
    }

    /**
    * Save notes globally and list them.
    */
    saveNotesGlobally = (note_fields) => {

        const case_note_path = APIRoutes.case_note + this._case_id;

        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess       : false
            });
            return false;
        }

        this.setState({
            isProcessing: true
        });

        let formData = new FormData(),
            new_state = {};

        formData.append('note', note_fields.note);
        for (let i in note_fields.files) {
            formData.append('files', note_fields.files[i]);
        }
        /**
         * append master_requirement_id with post data in cases
         */
        formData.append('master_requirement_id', this.state.fields.master_requirement_id);
        const request = new Request(case_note_path, formData, 'POST', headers, true).send();
        request.then(response => {
            if (response.success) {
                this._notes.toggleNotesPopup(true);
                new_state = {
                    isProcessing: false,
                    isSubmitted: true,
                    isSuccess: response.success,
                    responseMsg: response.message,
                    openIntelNotesConfirmationPoppup: true
                }
                this.setState(new_state);
            }
        }, error => {
            console.log(error);
            new_state = {
                isProcessing: false,
                isSubmitted: true,
                isSuccess: error.success,
                responseMsg: error.message,
            }
            this.setState(new_state);
        });
    }

    closeConfirmationPopUp = () => {
        this.setState({
            openIntelNotesConfirmationPoppup: false
        });
        this.clearFormFields();
        this.getClosedPlayerCase();
    }

    /**
     * clear form fields
     */
    clearFormFields = () => {
        this.setState({
            cases: [],
            _fetched: {
                closed_player_case: false
            },
            fields: {
                case_number: '',
                last_name: '',
                first_name: '',
                case_agents: [],
                agent_representation: '',
                agency_representation: '',
                agent_representation_enddate: null,
                current_club: '',
                master_position_id: '',
                master_professional_level: '',
                detail: '',
                case_note: [],
                status: true,
                is_contract_signed: false,
                case_addition_positions: [],
                master_requirement_id: '',
            }
        })
    }

}

export default withRouter(PlayerCaseView);