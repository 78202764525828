import React from 'react';
import OtherClubContact from './OtherClubContact';
import UpdateClubContacts from './UpdateClubContacts';
import * as EmailValidator from 'email-validator';
import { is_bday_valid, get_authorization_token, get_current_user_role, __x, get_db_date, is_valid_db_date } from '../../../helpers/GeneralHelper';
import Request from '../../../helpers/Request';
import APIRoutes from '../../../API/Routes';
import Alert from '../../../helpers/Alert';
import { setClub, setClubContact } from '../../../actions/ClubsAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class OtherClubContacts extends React.Component {
    constructor( props ) {
        super( props );

        this._club_id           = props.match.params.id;
        this._country           = React.createRef();
        this.slides             = React.createRef();
        this.current_user_role  = get_current_user_role();

        this._isFetching   =  false;
        
        this.state = {
            contact_id      : 0,
            club            : {},
            visibleContacts : [],
            contact         : [],
            should_add_popup_open : false,
            country_iso     : 'gb',
            is_editing      : false,
            fields: {
                title           : '',
                status          : '',
                first_name      : '',
                last_name       : '',
                email           : '',
                country_code    : '44',
                phone           : '',
                birthday        : null,
                contact_note    : '',
                show_birthday_notification: false,
                is_restricted   : false,
            },
            isValid : {
                title           : true,
                status          : true,
                first_name      : true,
                last_name       : true,
                email           : true,
                country_code    : true,
                phone           : true,
                birthday        : true,
                show_birthday_notification: true,
                is_restricted   : true,
            },
            error: {
                title           : '',
                status          : '',
                first_name      : '',
                last_name       : '',
                email           : '',
                phone           : '',
                birthday        : ''
            },
            fields_label    : {
                title           : 'Title',
                status          : 'Status',
                first_name      : 'First Name',
                last_name       : 'Last Name',
                email           : 'Email',
                phone           : 'Phone',
                birthday        : 'Birthday'
            },
            isProcessing    : false,
            isSubmitted     : false,
            isSuccess       : false,
            responseMsg     : '',
            openStatus      : false,
            active_widgets  : [],
            minIndex        : 0,
            maxIndex        : 3,
            animatedClass   : '',
            openDeleteContactConfirmationPopup : false
        }
    }

    componentWillReceiveProps = new_props => {
        this.setState({
            club: new_props.clubs.club ? new_props.clubs.club : {},
            visibleContacts : new_props.clubs.club ? new_props.clubs.club.club_contacts : [],
        });
    }

    handleOtherClubContactPopup = () => {
        this.setState({
            should_add_popup_open: !this.state.should_add_popup_open
        }, () => {
            if ( this.state.should_add_popup_open ) {
                document.body.classList.add( 'modal-open' );
            }
        });
    }

    editClubContact = contact_id => event => {
        if ( !contact_id || contact_id <= 0 ) {
            return false;
        }

        this.getClubContact( contact_id );
    }

    closePopup = () => {
        this.setState({
            should_add_popup_open: false
        }, () => {
            if ( !this.state.should_add_popup_open ) {
                document.body.classList.remove( 'modal-open' );
            }
        });
        this.clearFormFields();
    }

    contactFieldsChanges = ( event ) => {
        const elt   = event.target,
        elt_name    = elt.name,
        value       = elt.type === 'checkbox' ? elt.checked : elt.value,
        fields      = this.state.fields,
        isValid     = this.state.isValid,
        error       = this.state.error,
        fieldsLabel = this.state.fields_label;

        /**
         * Handles title, first_name and last_name fields' values and validation.
         */
        if (  [ 'title', 'first_name', 'last_name', 'status' ].indexOf( elt_name ) >= 0 ) {
            const elt_label      = fieldsLabel[ elt_name ];
            fields[ elt_name ]   = value;
            isValid[ elt_name ]  = value === '' ? true: value.length > 0 && value.length <= 255;
            error[ elt_name ]    = !isValid[ elt_name ] ? __x( `{{label}} is invalid.`, `{{label}}`, elt_label ) : '';
        } 
        
        /**
         * Handles email field's value and validation.
         */
        if ( elt_name === 'email' ) {
            fields[ elt_name ]   = value;
            isValid[ elt_name ]  = value === '' ? true: value.length > 0 && value.length <= 255 && EmailValidator.validate( value );
            error[ elt_name ]    = !isValid[ elt_name ] ? __x( `Please enter a valid email.` ) : '';
        }

        /**
         * Handles show_birthday_notification and is_restricted field's value.
         */
        if ( [ 'show_birthday_notification', 'is_restricted', 'contact_note' ].indexOf( elt_name ) >= 0 ) {
            fields[ elt_name ]   = value;
        }

        this.setState({ fields, isValid, error });
    }

    /**
     * Handles country changes.
     */
    onCountryChange = ( value, countryData ) => {
        const fields    = this.state.fields,
        isValid         = this.state.isValid,
        country_code    = countryData ? countryData.dialCode : '44',
        country_iso     = countryData ? countryData.iso2 : 'gb';
        
        fields[ 'country_code' ]    = country_code;
        isValid[ 'country_code' ]   = fields.country_code !== '' ? fields.country_code.toString().length >= 0 && fields.country_code.toString().length <= 255 : true;
        
        this.setState({ fields, isValid, country_iso });
    }

    /**
     * Handles phone changes.
     */
    onPhoneChange = ( status, value ) => {
        const fields    = this.state.fields,
        isValid         = this.state.isValid,
        error           = this.state.error,
        phone           = value ? value : '';
        
        fields[ 'phone' ]   = phone;
        isValid[ 'phone' ]  = fields.phone !== '' ? fields.phone.length >= 0 && fields.phone.length <= 255 && !isNaN( fields.phone ) : true;
        error[ 'phone' ]    = !isValid.phone ? `Phone is invalid.` : '';
        
        this.setState({ fields, isValid, error });
    }

    /**
     * Handles birthday field changes.
     */
    onBirthdayChanges = ( date ) => {
        const fields    = this.state.fields,
        isValid         = this.state.isValid,
        error           = this.state.error;
        
        fields[ 'birthday' ]    = date;
        isValid[ 'birthday' ]   = date === '' || date === null || !date ? true : is_bday_valid( date );
        error[ 'birthday' ]     = !isValid[ 'birthday' ] ? __x( `Birthday is invalid.` ) : '';
        
        this.setState({ fields, isValid, error });
    }

    updateContact = ( event ) => {
        event.preventDefault();
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( !this.isFormValid() ) {
            return false;
        }

        this.setState({
            isProcessing: true
        });

        const is_editing= this.state.is_editing;
        const club_path = is_editing ? APIRoutes.club_contact + this.state.contact.id: APIRoutes.add_club_contact + this._club_id;
        const method    = is_editing ? "PUT" : "POST";

        const fields    =  this.state.fields;
        let new_state   = {},
        formData        = new FormData();
        for ( let i in fields ) {
            if ( i === 'birthday' ) {
                if ( fields[i] !== '' && is_bday_valid( fields[i] ) ) {
                    formData.append(i, get_db_date( fields[i]));
                    // formData.append( i, `${fields[i].getDate()}/${fields[i].getMonth() + 1}/${fields[i].getFullYear()}` );
                } else {
                    formData.append( i, `` );
                }

                continue;
            }
            
            formData.append( i, fields[i] );
        }
        formData.append( 'country_iso', this.state.country_iso );
        
        /**
         * Sending request to add/update clubs' other contacts.
         */
        const request = new Request( club_path, formData, method, headers, true ).send();
        request.then( response => {
            if ( response.success ) {
                this.closePopup( true );
                this.getClub( true );
            }
            
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg : response.message
            }

            this.setState( new_state );
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMsg : error.message
            };
            this.setState( new_state );
        });
    }

    isFormValid = () => {
        let validityErr = [];
        const fields    = this.state.fields,
        isValid         = this.state.isValid;

        const toExclude  = [ 'is_restricted', 'show_birthday_notification', 'contact_note' ];
        
        /**
         * Exclude email and phone from validation if `is_restricted` is checked and current user is not admin.
         */
        if ( this.current_user_role !== 'Admin' && this.state.fields.is_restricted ) {
            toExclude.push( 'email' );
            toExclude.push( 'phone' );
        }
        
        for ( let elt in fields ) {
            if ( toExclude.indexOf( elt ) >= 0 ) {
                continue;
            }

            if ( elt === 'birthday' || elt === 'phone' || elt === 'email' ) {
                if ( isValid[ elt ] || fields[ elt ] === '' ) {
                    continue;
                }
            }
            
            if ( fields[ elt ] === '' || !isValid[ elt ] ) {
                validityErr.push( elt );
            }
        }
        return validityErr.length <= 0;
    }

    resetAlert = () => {
        this.setState({
            isSubmitted     : false,
            responseMessage : ''
        });
    }

    render = () => {
        const responseMsg   = this.state.responseMsg !== '' && this.state.isSubmitted ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} floating="true" onClose={this.resetAlert} /> : '';
        const contacts      = this.state.visibleContacts && Object.keys( this.state.visibleContacts ).length > 0 ? this.state.visibleContacts : [];
        const contact_notes    = this.state.club && Object.keys( this.state.club ).length > 0 ? this.state.club.contact_notes : [];
        const active_widgets = this.state.active_widgets;
        const showDeletePopup = this.state.openDeleteContactConfirmationPopup ? 'show' : '';
        
        return (
            <div className="players-section">
                { responseMsg }
                <div className="heading-section d-flex align-items-center auto-height">
                    Other Club Contacts  <span className="badge badge-pill badge-success">{ contacts.length > 0 ? contacts[0] === null ? 0 : contacts.length : 0 }</span> 
                    {
                        this.current_user_role === 'Agent' ? '' : 
                        <button onClick={ this.handleOtherClubContactPopup } className="ml-auto transparent-btn padding0" data-toggle="modal" data-target="#addContactModal">
                            <img src={ require( '../../../assets/images/add-icon.png' ) } alt="" />
                        </button>
                    }
                </div>
                <div className="position-relative">
                <div className="row usm-cards club-slider-block widget-slider marginB35">
                    {
                        contacts.length > 0 ? contacts.map( (contact, idx) => {
                            return <OtherClubContact 
                                key={contact.id} 
                                contact_id={contact.id}
                                contact={contact}
                                chooseStatus={ this.handleStatusDropdown } 
                                openStatus={ this.state.openStatus }
                                active_widgets={ active_widgets }
                                idx={idx}
                                openPopup={ this.editClubContact }
                                refresh={ this.getClub }
                                animatedClass={ this.state.animatedClass }
                            />
                        }) : ''
                    }                                    
                </div>
                <div className="widget-arrow">
                        
                        { contacts.length <= 4 ? '' : 
                            <button onClick={ this.slidePrevWidget } className="club-slider-btn float-left" data-toggle="offcanvas">
                                <i className="material-icons text-success">keyboard_arrow_left</i>
                            </button>
                        }
                        { contacts.length <= 4 ? '' : 
                            <button onClick={ this.slideNextWidget } className="club-slider-btn float-right" data-toggle="offcanvas">
                                <i className="material-icons text-success">keyboard_arrow_right</i>
                            </button>
                        }
                   
                </div> 
                </div>
                
                <UpdateClubContacts 
                    modal={ this.state.should_add_popup_open }
                    fields={ this.state.fields }
                    isValid={ this.state.isValid }
                    error={ this.state.error }
                    onClose={ this.closePopup }
                    onChange={ this.contactFieldsChanges }
                    onUpdate={ this.updateContact }
                    _country={ this._country }
                    set_country={ elt => this._country = elt }
                    onCountryChange={ this.onCountryChange }
                    onPhoneChange={ this.onPhoneChange }
                    onBirthdayChanges={ this.onBirthdayChanges }
                    isFormValid={ this.isFormValid }
                    notes={ contact_notes }
                    is_editing={ this.state.is_editing }
                    contact={ this.state.contact }
                    onDeleteClubContact={ this.confirmDelete }
                    isProcessing={ this.state.isProcessing }
                />
                <div className={`modal form-modal modal-active fade ${showDeletePopup}`} style={{ transform: this.state.openDeleteContactConfirmationPopup ? `translate(0, 0)` : 'translate(-125%, -125%)' }}>
                    <div className="modal-dialog modal-dialog-centered maxWidth400">
                        <div className="modal-content">
                            <div className="modal-body form-modal-body">
                                <h5 className="form-modal-content text-white fontBold text-center">{ __x( `Deleting a {{type}} is permanent, and cannot be undone.`, `{{type}}`, `club contact` ) }</h5>
                                <h5 className="form-modal-content text-white fontBold text-center">{ __x( `Do you wish to proceed with deleting?` ) }</h5>
                            </div>
                            <div className="modal-footer form-modal-footer d-flex justify-content-between">
                                <button type="button" className="default-btn btn-danger" onClick={ this.closeDeletePopup }>{ __x( `No, Cancel` ) }</button>
                                <button type="button" className="default-btn btn-success" onClick={ this.deleteClubContact }>{ __x( `Yes, Delete` ) }</button>
                            </div>
                        </div>
                    </div>
                </div>
                { !this.state.openDeleteContactConfirmationPopup || <div className="modal-backdrop fade show"></div> }
            </div>
        );
    }

    confirmDelete = contact_id => event => {
        this.setState({
            contact_id: contact_id,
            should_add_popup_open: false,
            openDeleteContactConfirmationPopup : true
        });
    }

    closeDeletePopup = () => {
        this.setState({
            openDeleteContactConfirmationPopup : false
        }, () => {
            if ( this.state.openDeleteContactConfirmationPopup ) {
                document.body.classList.add( 'modal-open' );
            } else {
                document.body.classList.remove( 'modal-open' );
            }
        });
    }
    
    /**
     * Delete club conatct by given id.
     */
    deleteClubContact = () => {
        const contact_id    = this.state.contact_id;
        const is_editing    = this.state.is_editing;
        if ( !contact_id || contact_id === '' || !is_editing ) {
            return false;
        }
        
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }
        
        this.setState({
            isProcessing: true
        });

        const delete_contact_path = APIRoutes.club_contact + this.state.contact.id;
        const method    = "DELETE";

        let new_state   = {};

        /**
         * Sending request to delete club's other contacts.
         */
        const request = new Request( delete_contact_path, {}, method, headers, ).send();
        request.then( response => {
            if ( response.success ) {
                this.closeDeletePopup( true );
                this.getClub( true );
            }
            
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message
            }

            this.setState( new_state );
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMsg : error.message
            };
            this.setState( new_state );
        });
    }

    /**
     * Navigate to previous slide.
     */
    slidePrevWidget = () => {
        const contacts      = this.state.visibleContacts;
        if ( !contacts || contacts.length <= 0 ) {
            return false;
        }

        let minIndex = this.state.minIndex,
        maxIndex = this.state.maxIndex;

        if ( minIndex <= 0 ) {
            return false;
        }

        minIndex -= 1;
        maxIndex -= 1;
        
        const new_contacts = contacts.filter( ( contact, idx ) => {
            return idx >= minIndex && idx <= maxIndex;
        });
        
        this.setState({
            active_widgets: new_contacts,
            minIndex,
            maxIndex,
            animatedClass : `animated bounceInLeft`
        });
    }
    
    /**
     * Navigate to nex slide.
     */
    slideNextWidget = () => {
        const contacts      = this.state.visibleContacts;
        if ( !contacts || contacts.length <= 0 ) {
            return false;
        }
        
        let minIndex = this.state.minIndex,
        maxIndex = this.state.maxIndex;
        
        if ( maxIndex > ( contacts.length - 2 ) ) {
            return false;
        }
        
        minIndex = minIndex + 1;
        maxIndex = maxIndex + 1;
        const new_contacts = contacts.filter( ( contact, idx ) => {
            return idx >= minIndex && idx <= maxIndex;
        });
        
        this.setState({
            active_widgets: new_contacts,
            minIndex,
            maxIndex,
            animatedClass : `animated bounceInRight`
        });
    }
    
    clearFormFields = () => {
        this.setState({
            country_iso  : 'gb',
            contact      : [],
            fields: {
                title           : '',
                status          : '',
                first_name      : '',
                last_name       : '',
                email           : '',
                country_code    : '44',
                phone           : '',
                birthday        : null,
                contact_note       : '',
                show_birthday_notification: false,
                is_restricted   : false,
            },
            isValid : {
                title           : true,
                status          : true,
                first_name      : true,
                last_name       : true,
                email           : true,
                country_code    : true,
                phone           : true,
                birthday        : true,
                show_birthday_notification: true,
                is_restricted   : true,
            },
            error: {
                title           : '',
                status          : '',
                first_name      : '',
                last_name       : '',
                email           : '',
                phone           : '',
                birthday        : ''
            },
            isProcessing    : false,
            isSubmitted     : false,
            isSuccess       : false,
            responseMsg     : '',
            is_editing      : false
        });
        
        /**
         * Either current user is admin or is_restricted is not checked, set default flag.
         */
        if (  this.current_user_role === 'Admin' || !this.state.fields.is_restricted ) {
            this._country.setFlag( 'gb' );
        }
    }

    /**
     * This fucntion is being called only in this component and its children components.
     * There's a filter inside this function which is mapped throught current compoenent's state so 
     * this fucntion can't be commonly called.
     * 
     * This function will be invoked only after updating and deleting the club contact to update the listing.
     */
    getClub = ( reset_active_widgets = false ) => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( this._isFetching ) {
            return false;
        }

        this._isFetching = true;
        const path = APIRoutes.club_detail + this._club_id;
        const request = new Request( path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                const club = response.data.length > 0 ? response.data[0] : response.data;
                /**
                 * Dispatching action to set club details to redux store.
                 */
                this.props.setClub( club );
                
                let active_widgets = this.state.active_widgets;
                if ( reset_active_widgets ) {
                    active_widgets = club ? club.club_contacts.filter( ( contact, idx ) => {
                        return idx <= 3;
                    }) : [];
                }

                this.setState({
                    active_widgets,
                    minIndex : 0,
                    maxIndex : 3,
                    visibleContacts: club ? club.club_contacts : []
                });
                this._isFetching = false;
            }
        }, error => {
            console.log( error );
        });
    }

    /**
     * Fetchs club contact details by id and set field to open the edit popup.
     */
    getClubContact = contact_id => {
        if ( !contact_id || contact_id === '' || contact_id <= 0 )
            return false;

        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( this._isFetching ) {
            return false;
        }

        this._isFetching = true;
        const path = APIRoutes.club_contact + contact_id;
        const request = new Request( path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                const contact = response.data ? response.data : {};
                let fields  = this.state.fields;
                fields = {
                    title           : contact ? contact.title : '',
                    status          : contact ? ( contact.status ? '1' : '0' ) : '',
                    first_name      : contact ? contact.first_name : '',
                    last_name       : contact ? contact.last_name : '',
                    email           : contact ? contact.email : '',
                    country_code    : contact ? contact.country_code : '44',
                    phone           : contact ? contact.phone : '',
                    birthday        : contact && is_valid_db_date(contact.birthday) ? new Date( contact.birthday ) : null,
                    contact_note       : '',
                    show_birthday_notification: contact ? contact.show_birthday_notification : false,
                    is_restricted   : contact ? contact.is_restricted : false,
                }
                
                this.setState({
                    contact,
                    fields,
                    is_editing: contact_id && contact_id !== 0, 
                }, () => {
                    /**
                     * Set country flag only when `is_restricted` is checked and current user is not admin.
                     */
                    return this.current_user_role !== 'Admin' && this.state.fields.is_restricted ? '' : this._country.setFlag( contact.country_iso ) ;
                });

                /**
                 * Open popup with editable details.
                 */
                this.handleOtherClubContactPopup();
                this._isFetching = false;
            }
        }, error => {
            console.log( error );
        });
    }
}

const mapStateToProps = ( state ) => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setClubContact  : ( leagues ) => dispatch( setClubContact( leagues ) ),
    setClub         : ( club ) => dispatch( setClub( club ) )
});

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( OtherClubContacts ) );