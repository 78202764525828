import React from 'react';
import { is_valid_db_date, get_days_by_date, get_display_format_date } from '../../../helpers/GeneralHelper';
import { Link } from 'react-router-dom';
import CaseAgentRollOver from '../case/CaseAgentRollOver';


const ActiveCases = ({ active_case, active_case_widgets, idx }) => {

    let visible = '',
        activeCases = [];

    if (active_case_widgets.length > 0) {
        for (let i in active_case_widgets) {
            if (active_case_widgets[i].id === active_case.id) {
                visible = `visible`;
            }
        }
    } else {
        visible = idx > 3 ? '' : `visible`;
    }

    if(active_case.player_id !== null) {
        activeCases.push(<div key={active_case.id} className={`col-md-3 col-sm-6 col-xs-12 ${visible}`}>
        <div className="card">
            <div className="card-body">
                <div className="cardImg-section">
                    <div className="card-image minHeight177">
                        <img className="club" src={active_case.player && active_case.player.image_path ? active_case.player.image_path : require('../../../assets/images/dummy-image.png')} alt=" " />
                    </div>
                    <div className="cardTitle-section" title={`${active_case.player ? active_case.player.first_name + " " + active_case.player.last_name : ''} (${active_case.master_position ? active_case.master_position.title : ''})`}>
                        <div className="card-title">{active_case.player ? active_case.player.first_name + " " + active_case.player.last_name : ''}</div>
                        <div className="card-subtitle">{active_case.master_position ? active_case.master_position.title : ''}</div>
                    </div>
                </div>
                <div className="cardContent-section minHeight145">
                    <div className="d-flex card-text marginB18">
                        <div className="mr-auto text-success fontBold font-size14">No of Agents</div>
                        <div className="fontBold text-success font-size14 show-rollover position-relative">{active_case.case_agents && active_case.case_agents.length > 0 ? active_case.case_agents.length : ''}
                        {active_case.case_agents.length > 0 ? <CaseAgentRollOver case_id={active_case.id} agents={active_case.case_agents} /> : ''}</div>
                    </div>
                    <div className="d-flex card-text marginB20">
                        <div className="mr-auto font-size14">Last Update</div>
                        <div className="fontBold font-size14">{active_case.updated_at ? get_days_by_date(active_case.updated_at) : 0 } days</div>
                    </div>
                </div>
                <div className="card-footer text-center">
                    <Link to={`/admin/edit-player-case/${active_case.id}`} className="btn btn-usmPrimary">View Case</Link>
                </div>
            </div>
            </div>
    </div>);
    } else {
          activeCases.push(<div key={active_case.id}  className={`col-md-3 col-sm-6 col-xs-12 ${visible}`}>
          <div className="card">
                <div className="card-body">
                    <div className="cardImg-section">
                        <div className="card-data">
                            <div className="card-data-label">Fulfilment Date</div>
                            <div className="card-data-content">{ active_case.fulfillment_date && is_valid_db_date(active_case.fulfillment_date) ? get_display_format_date(active_case.fulfillment_date) : '' }</div>
                            <div className="card-data-label">Requirement</div>
                            <div className="card-data-content">{ active_case.requirement_type ? active_case.requirement_type : '' }</div>
                            <div className="card-data-label">Case ID</div>
                            <div className="card-data-content">{ active_case.case_number ? active_case.case_number : '' }</div>
                        </div>
                        <div className="cardTitle-section" title={`${active_case.club && active_case.club.club_name ? active_case.club.club_name : ''} (${ active_case.master_position ? active_case.master_position.title : '' })`}>
                            <div className="card-title">{active_case.club && active_case.club.club_name ? active_case.club.club_name : ''}</div>
                            <div className="card-subtitle">{ active_case.master_position ? active_case.master_position.title : '' }</div>
                        </div>
                    </div>
                    <div className="cardContent-section minHeight145">
                        <div className="d-flex card-text marginB18">
                            <div className="mr-auto text-success fontBold font-size14">No of Agents</div>
                            <div className="fontBold text-success font-size14 show-rollover position-relative">
                                { active_case.case_agents && active_case.case_agents.length > 0 ? active_case.case_agents.length : '' }
                                {active_case.case_agents.length > 0 ? <CaseAgentRollOver case_id={active_case.id} agents={active_case.case_agents} /> : ''}
                            </div>
                        </div>
                        <div className="d-flex card-text marginB20">
                            <div className="mr-auto font-size14">Last Update</div>
                            <div className="fontBold font-size14">{ active_case.updated_at ? get_days_by_date(active_case.updated_at) : 0 } Days</div>
                        </div>
                        <div className="d-flex card-text marginB20">
                            <div className="mr-auto font-size14">Players Considered</div>
                            <div className="fontBold font-size14">{ active_case.case_player_considereds && active_case.case_player_considereds.length > 0 ? active_case.case_player_considereds.length : 0 }</div>
                        </div>
                    </div>
                    <div className="card-footer text-center">
                        <Link to={`/admin/edit-club-case/${active_case.id}`} className="btn btn-usmPrimary">View Case</Link>
                    </div>
                </div>
            </div>
      </div>)
    }

    return activeCases;

}
export default ActiveCases;

