import React from 'react';
import { get_display_format_date } from '../../../helpers/GeneralHelper';
import { Link } from 'react-router-dom';

const ClosedCase = props => {
    const closed_case = props.case;
    return (
        <tr>
            <td className="fontBold font-size16"><Link to={`/admin/club-case/${closed_case.id}`}>{ closed_case.id }</Link></td>
            <td>{ closed_case.master_position ? closed_case.master_position.title : '' }</td>
            <td className="fontBold text-opt-four">{ get_display_format_date( closed_case.fulfillment_date ) }</td>
            <td>
                { 
                    closed_case.case_agents && closed_case.case_agents.length > 0 ? closed_case.case_agents.map( agent => {
                        return agent.user ? `${agent.user.first_name} ${agent.user.last_name}` : ``;
                    }).join( ', ' )
                    : ''                    
                }
            </td>
            <td><button className="table-btn btn-transWhite">{ closed_case.case_player_considereds ? closed_case.case_player_considereds.length : 0 }</button></td>
        </tr>
    );
}
export default ClosedCase;