import React from 'react';

const ReactTagsComponent = ({ tag, classNames, onDelete }) => {
    return (
        <button type="button" className={ `position-relative ${classNames.selectedTag}` }>
            <span className={ classNames.selectedTagName }>{ tag.name }</span>
            <span className="usm-tag-remove position-absolute" onClick={ onDelete }>&times;</span>
        </button>
    );
}

export default ReactTagsComponent;