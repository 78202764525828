import React from 'react';
import { is_valid_db_date, get_display_format_date } from '../../../helpers/GeneralHelper';

const NotesHistory = props => {
    const notes = props.notes ? props.notes : [];
    if ( notes.length <= 0 ) {
        return '';
    }

    return (
        <ul className="list-unstyled">
            {
                notes.length > 0 ? notes.map( note => {
                    return (
                        <li key={ note.id } className="media">
                            <div className="media-body clearfix">
                                <p>{ note.note }</p>
                                <div className="row note-add-modify">
                                    <div className="col">
                                        Added by: <span className="fontBold marginL10">{ note.added_by }</span>
                                    </div>
                                    <div className="col text-right">
                                        Last modified: <span className="fontBold marginL10">{ note.updated_at && is_valid_db_date(note.updated_at) ? get_display_format_date( note.updated_at ) : '' }</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    )
                }) : ''
            }
        </ul>
    );
}
export default NotesHistory;