const initialState = {
    clubs       : [],
    club        : {},
    leagues     : [],
    activeCases : [],
    closedCases : [],
    clubContact : []
}

export default ( state = initialState, action ) => {
	switch ( action.type ) {
        case 'SET_CLUBS':
            return {
                ...state,
                clubs: action.payload.clubs
            };
        case 'APPEND_CLUBS':
            return {
                ...state,
                clubs: action.payload.clubs
            };    
        case 'SET_CLUB':
            return {
                ...state,
                club: action.payload.club.length > 0 ? action.payload.club[0] : action.payload.club
            };
        case 'SET_LEAGUES':
            return {
                ...state,
                leagues: action.payload.leagues
            };
        case 'SET_ACTIVE_CASES':
            return {
                ...state,
                activeCases: action.payload.cases
            };
        case 'SET_CLOSED_CASES':
            return {
                ...state,
                closedCases: action.payload.cases
            };
        case 'SET_CLUB_CONTACT':
            return {
                ...state,
                clubContact: action.payload.contact
            };
		default:
			return state;
	}
}