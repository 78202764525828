import React from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { get_parameter_by_name, get_authorization_token, __x } from '../../../helpers/GeneralHelper';
import Request from '../../../helpers/Request';
import APIRoutes from '../../../API/Routes';
import Select from 'react-select';
import { withRouter, Redirect } from 'react-router-dom';
import { setSearchResult, setFilterOption } from '../../../actions/SearchActions';
import { connect } from 'react-redux';

class Search extends React.Component {

    /**
     * Time in miliseconds to wait until typing has done.
     */
    wait_for_search = 800;

    constructor( props ) {
        super( props );

        this.searchInput    = React.createRef();
        this.searchIcon     = React.createRef();
        this.searchWrapper  = React.createRef();

        this._is_fetched = {
            league              : false,
            passport            : false,
            nationality         : false,
            opportunity_type    : false,
            primary_position    : false,
            professional_level  : false,
        };

        this.apiFilters = {
            league_id               : 'leagueOptions',
            nationality_id          : 'nationalityOptions',
            player_passport_id      : 'passportOptions',
            opportunity_type_id     : 'opportunityTypeOptions',
            primary_position_id     : 'primaryPositionOptions',
            secondary_position_id   : 'additionalPositionOptions',
            professional_level_id   : 'professionalLevelOptions',
        }

        const search = {
            age_min     : get_parameter_by_name( 'age_min' ),
            age_max     : get_parameter_by_name( 'age_max' ),
            height_min  : get_parameter_by_name( 'height_min' ),
            height_max  : get_parameter_by_name( 'height_max' ),
            tag         : get_parameter_by_name( 'tag' ),
        }

        this.state = {
            fields: {
                age                     : { 
                    min: search.age_min !== null && search.age_min !== '' ? parseInt( search.age_min ) : 0, 
                    max: search.age_max !== null && search.age_max !== '' ? parseInt( search.age_max ) : 42 
                },
                height                  : { 
                    min: search.height_min !== null && search.height_min !== '' ? parseInt( search.height_min ) : 0, 
                    max: search.height_max !== null && search.height_max !== '' ? parseInt( search.height_max ) : 210 
                },
                league_id               : '',
                nationality_id          : '',
                player_passport_id      : '',
                opportunity_type_id     : '',
                primary_position_id     : '',
                secondary_position_id   : '',
                professional_level_id   : '',
            },
            tag                         : search.tag !== null && search.tag !== '' ? search.tag : '',
            searchFormOpen              : false,
            primaryPositionOptions      : [],
            additionalPositionOptions   : [],
            nationalityOptions          : [],
            passportOptions             : [],
            opportunityTypeOptions      : [],
            professionalLevelOptions    : [],
            leagueOptions               : [],
            isError                     : false,
            responseMessage             : '',
            isSuccess                   : true,
            search                      : '',
            redirectTo                  : '',
            predictiveSearchList        : []
        };
    }

    /**
     * Setting local states from url and global store.
     */
    componentWillReceiveProps = props => {
        const searchString = props.location.search;
        if ( searchString && searchString !== this.state.search ) {
            let new_search  = searchString,
            redirection     = `/admin/search-dashboard${searchString}`;
            if ( this.props.location.pathname !== '/admin/search-dashboard' ) {
                new_search = '';
                redirection = '';
            }
            
            this.setState({
                search      : new_search,
                redirectTo  : redirection
            });
        }

        const searchFilters = props.search;
        
        let new_state   = {};
        let fields      = this.state.fields;
        for ( let i in this.apiFilters ) {
            const filter_name = this.apiFilters[i];
            if ( searchFilters[ filter_name ] && Object.keys( searchFilters[ filter_name ] ).length > 0 ) {
                new_state[ filter_name ] = searchFilters[ filter_name ];
                const fetched_name = i.replace( '_id', '' );

                /**
                 * Set all the fetching flags to true if data already exists, to avaoid unnecessary api calls.
                 */
                this._is_fetched[ fetched_name ] = true;
            }

            const searched = get_parameter_by_name( i );
            if ( !searched ) {
                continue;
            }

            /**
             * For multi-select values.
             */
            if ( i === 'secondary_position_id' || i === 'player_passport_id' ) {
                fields[i] = searchFilters[ filter_name ] && searchFilters[ filter_name ].length > 0 ? searchFilters[ filter_name ].filter( option => {
                    const match = Array.isArray( searched ) ? searched.indexOf( option.value.toString() ) >= 0 : searched === option.value.toString();
                    return option.value !== '' && match;
                }) : [];
                continue;
            }
            
            /**
             * For single value select.
             */
            const data  = searchFilters[ filter_name ] && searchFilters[ filter_name ].length > 0 ? searchFilters[ filter_name ].filter( option => (option.value.toString() === searched) ) : [];
            fields[i]   = data.length > 0 ? data[0] : '';
        }
        
        new_state = {
            ...new_state,
            fields
        };

        if ( new_state && Object.keys( new_state ).length > 0 ) {
            this.setState( new_state );
        }
    }

    toggleSearchForm = ( checkInput = false ) => {
        if ( !this.state.searchFormOpen && this.state.tag !== '' ) {
            if ( checkInput ) {
                this.searchForKeyword();
            }
            return false;
        }
        
        this.setState({
            searchFormOpen: !this.state.searchFormOpen
        });
    }

    /**
     * Get all the filter options' data from API.
     */
    getFilterOptions = ( route = 'player_positions', state = 'primaryPositionOptions' ) => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess       : false
            });
            return false;
        }

        const request = new Request( APIRoutes[ route ], {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                let data = response.data && response.data.length > 0 ? response.data : [];
                if ( state === 'leagueOptions' ) {
                    const new_league = data.length > 0 ? data.map( league => {
                        return { label: league.title, value: league.id };
                    }) : [];
                    data = new_league;
                }
                
                data.unshift({ value: '', label: '' });
                const new_state = {
                    isError         : false,
                    responseMessage : '',
                    isSuccess       : true,
                };

                if ( state === 'primaryPositionOptions' ) {
                    this.props.setFilter( 'additionalPositionOptions', data );
                }
                
                this.setState( new_state );
                this.props.setFilter( state, data );
            }
        }, error => {
            console.log( error );
        });
    }

    /**
     * Handles filters' changes
     */
    handleChanges = ( value, field ) => {
        let fields      = this.state.fields;
        fields[ field ] = value;
        
        this.setState({ fields });
    }

    /**
     * Handle typed keywords.
     */
    handleKeywords = event => {
        const target    = event.target,
            name        = target.name,
            value       = target.value;

        if ( value && value.length > 255 ) {
            return false;
        }
        
        this.setState({
            [name] : value
        });

        if ( value === '' ) {
            this.setState({
                predictiveSearchList: []
            });
        }
        
        if ( value.length < 3 ) {
            return false;
        }

        if ( this.state.searchFormOpen ) {
            this.toggleSearchForm();
        }

        clearTimeout( this.countdown );
        this.countdown = setTimeout( this.predictiveSearchByKeyword, this.wait_for_search );
    }

    /**
     * Go for predictive search by keyword.
     */
    predictiveSearchByKeyword = () => {
        const tag   = this.state.tag;
        if ( tag < 3 ) {
            return false;
        }

        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError         : true,
                responseMessage : __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess       : false
            });
            return false;
        }

        const search_path = `${APIRoutes.get_player_list}${tag}/tag`;

        const request = new Request( search_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                this.setState({
                    predictiveSearchList : response.data
                });
            }
        }, error => {
            console.log( error );
        });
    }

    /**
     * Reset Search Chices
     */
    resetFilters = () => {
        this.setState({
            fields: {
                age     : { 
                    min: 0, 
                    max: 42 
                },
                height  : { 
                    min: 0, 
                    max: 210 
                },
                league_id               : '',
                nationality_id          : '',
                player_passport_id      : '',
                opportunity_type_id     : '',
                primary_position_id     : '',
                secondary_position_id   : '',
                professional_level_id   : '',
            },
            search      : '',
            redirectTo  : '',
        });
    }

    /**
     * Hanldes search submission.
     */
    handleFormSubmission = event => {
        event.preventDefault();

        const fields    = this.state.fields;
        let queryString = '';
        
        for ( let i in fields ) {
            if ( !i || !fields[i] ) {
                continue;
            }
            
            const field = fields[i];
            
            if ( i === 'age' || i === 'height' ) {
                queryString += queryString === '' ? `${i}_min=${field.min}` : `&${i}_min=${field.min}`;
                queryString += queryString === '' ? `${i}_max=${field.max}` : `&${i}_max=${field.max}`;
            } else if ( i === 'secondary_position_id' || i === 'player_passport_id' ) {
                const data = field.map( obj => {
                    return obj.value !== '' ? `${i}=${obj.value}` : '';
                }).join( '&' );
                
                queryString += queryString === '' ? `${data}` : `&${data}`;
            } else {
                queryString += field.value !== '' ? queryString === '' ? `${i}=${field.value}` : `&${i}=${field.value}` : '';
            }
        }
        
        this.setState({
            redirectTo  : `/admin/search-dashboard?${queryString}`
        });
        this.toggleSearchForm();
    }

    /**
     * Handles search fields submission for keywords
     */
    handleKeywordSearch = ( event ) => {
        event.preventDefault();
        
        this.searchForKeyword();
    }

    /**
     * Search for keyword.
     */
    searchForKeyword = () => {
        const tag    = this.state.tag;
        if ( tag === '' ) {
            return false;
        }

        const queryString = tag !== '' ? `tag=${tag}` : '';
        
        this.setState({
            redirectTo  : `/admin/search-dashboard?${queryString}`
        });
    }
    
    componentDidMount = () => {
        document.addEventListener( 'mousedown', this.handleClickOutside );

        if ( !this._is_fetched.primary_position ) {
            this.getFilterOptions();
        }

        if ( !this._is_fetched.nationality ) {
            this.getFilterOptions( 'get_nationality', 'nationalityOptions' );
        }

        if ( !this._is_fetched.opportunity_type ) {
            this.getFilterOptions( 'get_opportunity_list', 'opportunityTypeOptions' );
        }

        if ( !this._is_fetched.professional_level ) {
            this.getFilterOptions( 'player_professional_level', 'professionalLevelOptions' );
        }

        if ( !this._is_fetched.league ) {
            this.getFilterOptions( 'league_list', 'leagueOptions' );
        }
        
        if ( !this._is_fetched.passport ) {
            this.getFilterOptions( 'get_passport', 'passportOptions' );
        }
    }

     /**
     * Close search popup if open and clicked outside.
     */
    handleClickOutside = ( event ) => {
        /**
         * If popup is closed do nothing.
         */
        if ( event.target.tagName === 'HTML' || !this.state.searchFormOpen ) {
            return false;
        }

        /**
         * Checks if clicked outside of the popup.
         */
        if ( this.searchWrapper && !this.searchWrapper.contains( event.target ) && !this.searchInput.contains( event.target ) && !this.searchIcon.contains( event.target ) ) {
            this.toggleSearchForm();
        }
    }

    /**
     * Removing Event listener for outside search popup.
     */
    componentWillUnmount = () => {
        document.removeEventListener( 'mousedown', this.handleClickOutside );
        clearTimeout( this.countdown );
    }

    searchByName = player => {
        if ( !player || player === '' ) {
            return false;
        }

        this.setState({
            tag: player,
            predictiveSearchList : []
        }, () => this.searchForKeyword() );
    }

    listPredictiveSearchData = () => {
        const predictiveSearchList = this.state.predictiveSearchList;
        if ( !predictiveSearchList.length ) {
            return '';
        }

        const list = predictiveSearchList.map( player => <li onClick={ () => this.searchByName( player.name ) } key={player.id}>{ player.name }</li> );
        return list;
    }

    render = () => {
        const search = this.state.search;
        if ( this.state.redirectTo !== '' && this.state.redirectTo !== `/admin/search-dashboard${search}` ) {
            return <Redirect to={this.state.redirectTo} />
        }

        const openForm = this.state.searchFormOpen ? 'show' : '';
        const customerStyles = {
            option: (styles, { isFocused }) => {
                return {
                    ...styles,
                    backgroundColor: isFocused ? 'rgba(0, 0, 0, 0.2)' : styles.backgroundColor,
                }
            }
        };
        return (
            <div className={`usm-form form-border my-2 my-lg-0 position-relative global-search-block ${openForm}`}>
                <form onSubmit={ this.handleKeywordSearch } action="/admin/search-dashboard" className={`usm-form form-border my-2 my-lg-0`}>
                    <input onChange={ this.handleKeywords } value={ this.state.tag } onClick={ () => this.toggleSearchForm() } ref={ node => this.searchInput = node } className="form-control mr-sm-2 usm-search" name="tag" type="search" placeholder="Enter Search Term" autoComplete="off" />
                    <button onClick={ () => this.toggleSearchForm( true ) } ref={ node => this.searchIcon = node } type="button" className="transparent-btn search-btn"></button>
                </form>
                <ul className="pre-search">{ this.listPredictiveSearchData() }</ul>
                {
                    !this.state.searchFormOpen ? '' : 
                    <div className={`dropdown-menu dropdown-menu-right global-search-result ${openForm}`} ref={ node => this.searchWrapper = node } onClick={ e => e.stopPropagation() }>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 border-right-trans paddingB20 paddingR30">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="">Primary Position</label>
                                            <Select 
                                                name="primary_position"
                                                value={this.state.fields.primary_position_id}
                                                onChange={(e) => this.handleChanges(e, 'primary_position_id')}
                                                options={this.state.primaryPositionOptions}
                                                styles={customerStyles}
                                                placeholder={``}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group search-chips">
                                            <label htmlFor="">Additional Positions</label>
                                            <Select 
                                                name="additional_position"
                                                value={this.state.fields.secondary_position_id}
                                                onChange={(e) => this.handleChanges(e, 'secondary_position_id')}
                                                options={this.state.additionalPositionOptions}
                                                isMulti={true}
                                                styles={customerStyles}
                                                placeholder={``}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12 paddingB20 paddingL30">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group marginB20 paddingB20">
                                            <label htmlFor="customRange1">Age Range</label>
                                            <div className="paddingT20">
                                                <InputRange
                                                    allowSameValues={true}
                                                    formatLabel={ age => `${age}Y` }
                                                    draggableTrack
                                                    minValue={0}
                                                    maxValue={42}
                                                    onChange={ e => this.handleChanges( e, 'age' )}
                                                    value={this.state.fields.age}
                                                    name="age"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group marginB20 paddingB20">
                                            <label htmlFor="customRange2">Height Range (cm)</label>
                                            <div className="paddingT20 height-ranger">
                                                <InputRange
                                                    allowSameValues={true}
                                                    formatLabel={ height => `${height} cm` }
                                                    draggableTrack
                                                    minValue={0}
                                                    maxValue={210}
                                                    onChange={ e => this.handleChanges( e, 'height' )}
                                                    value={this.state.fields.height}
                                                    name="height"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="">Nationality</label>
                                            <Select name="nationality"
                                                value={this.state.fields.nationality_id}
                                                onChange={(e) => this.handleChanges(e, 'nationality_id')}
                                                options={this.state.nationalityOptions}
                                                styles={customerStyles}
                                                placeholder={``}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="">Opportunity Type</label>
                                            <Select name="primary_position"
                                                value={this.state.fields.opportunity_type_id}
                                                onChange={(e) => this.handleChanges(e, 'opportunity_type_id')}
                                                options={this.state.opportunityTypeOptions}
                                                styles={customerStyles}
                                                placeholder={``}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="">Professional Level</label>
                                            <Select nameName="professional_level"
                                                value={this.state.fields.professional_level_id}
                                                onChange={(e) => this.handleChanges(e, 'professional_level_id')}
                                                options={this.state.professionalLevelOptions}
                                                styles={customerStyles}
                                                placeholder={``}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="">League</label>
                                            <Select name="league_id"
                                                value={this.state.fields.league_id}
                                                onChange={(e) => this.handleChanges(e, 'league_id')}
                                                options={this.state.leagueOptions}
                                                styles={customerStyles}
                                                placeholder={``}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="">Passport</label>
                                            <Select name="passport"
                                                value={this.state.fields.player_passport_id}
                                                onChange={(e) => this.handleChanges(e, 'player_passport_id')}
                                                options={this.state.passportOptions}
                                                isMulti={true}
                                                styles={customerStyles}
                                                placeholder={``}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col d-flex justify-content-between border-top-trans paddingT35">
                                <button onClick={ this.resetFilters } type="button" className="default-btn btn-danger">Reset Choice</button>
                                <button onClick={ this.handleFormSubmission } type="submit" className="default-btn btn-success">Search</button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setResult : result => dispatch( setSearchResult( result ) ),
    setFilter : ( option, data ) => dispatch( setFilterOption( option, data ) )
});

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( Search ) );