import React, { Component } from 'react';
import { get_authorization_token, __x, is_mobile, get_current_user_full_name } from '../../helpers/GeneralHelper';
import APIRoutes from '../../API/Routes';
import Request from '../../helpers/Request';
import Case from './Case';
import AgentCase from './AgentCase';
import RightDrawer from './RightDrawer';
import LoadingSpinner from '../admin-staff/common/LoadingSpinner';
import Alert from '../../helpers/Alert';


export default class AdminDashboard extends Component {

    _page_title = 'Admin Dashboard - USM';

    constructor(props) {
        super(props);

        this.state = {
            case_type: '',
            player_case: [],
            active_player_widgets: [],
            club_case: [],
            active_club_widgets: [],
            agent_cases: [],
            active_agent_widgets: [],
            birthday: {},
            representation_date: {},
            index: {
                active_player_case: {
                    minIndex: 0,
                    maxIndex: 3,
                },
                active_club_case: {
                    minIndex: 0,
                    maxIndex: 3,
                },
                active_agent_case: {
                    minIndex: 0,
                    maxIndex: 3,
                }
            },
            isProcessing: false,
            isSubmitted: false,
            isSuccess: false,
            responseMsg: '',
            sidebarToggled: !is_mobile()
        }
    }

    componentDidMount = () => {
        document.title = this._page_title;
        this.getDashboardData();
        this.getActiveCaseAgents();
    }

    /**
     * open right sidebar 
     */
    openSidebar = () => {
        const sidebarToggled = this.state.sidebarToggled
        this.setState({
            sidebarToggled: !sidebarToggled
        });
    }

    /**
     * get all cases of an agent
     */
    getDashboardData = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        let new_state = {},
            player_case = this.state.player_case,
            active_player_widgets = this.state.active_player_widgets,
            club_case = this.state.club_case,
            active_club_widgets = this.state.active_club_widgets,
            // agent_cases = this.state.agent_cases,
            // active_agent_widgets = this.state.active_agent_widgets,
            birthday = this.state.birthday,
            representation_date = this.state.representation_date;
        const admin_path = APIRoutes.admin_dashboard;
        const request = new Request(admin_path, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                player_case = response.data.player_case && response.data.player_case.length > 0 ? response.data.player_case : [];
                club_case = response.data.club_case && response.data.club_case.length > 0 ? response.data.club_case : [];
                // agent_cases = response.data.agent_cases && response.data.agent_cases.length > 0 ? response.data.agent_cases : [];
                birthday = response.data.birthday && Object.keys(response.data.birthday).length > 0 ? response.data.birthday : {};
                representation_date = response.data.representation_date && Object.keys(response.data.representation_date).length > 0 ? response.data.representation_date : {};

                active_player_widgets = player_case ? player_case.filter((active_case, idx) => {
                    return idx <= 3;
                }) : [];

                active_club_widgets = club_case ? club_case.filter((active_case, idx) => {
                    return idx <= 3;
                }) : [];

                // active_agent_widgets = agent_cases ? agent_cases.filter((active_case, idx) => {
                //     return idx <= 3;
                // }) : [];
            }

            new_state = {
                player_case,
                active_player_widgets,
                club_case,
                active_club_widgets,
                // agent_cases,
                // active_agent_widgets,
                birthday,
                representation_date,
            }
            this.setState(
                new_state,
             );
        }, error => {
            console.log(error);
        });
    }

    /**
     * get agents with active case data
     */
    getActiveCaseAgents = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        let new_state = {},
            agent_cases = this.state.agent_cases,
            active_agent_widgets = this.state.active_agent_widgets;
        const agent_path = APIRoutes.active_case_agents;
        const request = new Request(agent_path, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                agent_cases = response.data && response.data.length > 0 ? response.data : [];

                active_agent_widgets = agent_cases ? agent_cases.filter((active_case, idx) => {
                    return idx <= 3;
                }) : [];
            }

            new_state = {
                agent_cases,
                active_agent_widgets,
            }
            this.setState(
                new_state,
             );
        }, error => {
            console.log(error);
        });
    }

    /**
     * Navigate to previous slide.
     */
    slidePrevWidget = (case_name) => {
        const player_case = case_name === 'player' ? this.state.player_case : case_name === 'club' ? this.state.club_case : this.state.agent_cases;
        if (!player_case || player_case.length <= 0) {
            return false;
        }

        const case_index_name = case_name === 'player' ? `active_player_case` : case_name === 'club' ? `active_club_case` : `active_agent_case`;
        let minIndex = case_name === 'player' ? this.state.index.active_player_case.minIndex : case_name === 'club' ? this.state.index.active_club_case.minIndex : this.state.index.active_agent_case.minIndex,
            maxIndex = case_name === 'player' ? this.state.index.active_player_case.maxIndex : case_name === 'club' ? this.state.index.active_club_case.maxIndex : this.state.index.active_agent_case.maxIndex;

        if (minIndex <= 0) {
            return false;
        }

        minIndex -= 1;
        maxIndex -= 1;

        const new_player_case = player_case.filter((contact, idx) => {
            return idx >= minIndex && idx <= maxIndex;
        });

        const active_widgets = case_name === 'player' ? 'active_player_widgets' : case_name === 'club' ? 'active_club_widgets' : 'active_agent_widgets';

        this.setState({
            [active_widgets]: new_player_case,
            index: {
                ...this.state.index,
                [case_index_name]: {
                    minIndex,
                    maxIndex,
                }
            },
            animatedClass: `animated bounceInRight`
        });
    }

    /**
     * Navigate to nex slide.
     */
    slideNextWidget = (case_name) => {
        const player_case = case_name === 'player' ? this.state.player_case : case_name === 'club' ? this.state.club_case : this.state.agent_cases;
        if (!player_case || player_case.length <= 0) {
            return false;
        }
        const case_index_name = case_name === 'player' ? `active_player_case` : case_name === 'club' ? `active_club_case` : `active_agent_case`;
        let minIndex = case_name === 'player' ? this.state.index.active_player_case.minIndex : case_name === 'club' ? this.state.index.active_club_case.minIndex : this.state.index.active_agent_case.minIndex,
            maxIndex = case_name === 'player' ? this.state.index.active_player_case.maxIndex : case_name === 'club' ? this.state.index.active_club_case.maxIndex : this.state.index.active_agent_case.maxIndex;

        if (maxIndex > (player_case.length - 2)) {
            return false;
        }

        minIndex = minIndex + 1;
        maxIndex = maxIndex + 1;
        const new_player_case = player_case.filter((contact, idx) => {
            return idx >= minIndex && idx <= maxIndex;
        });

        const active_widgets = case_name === 'player' ? 'active_player_widgets' : case_name === 'club' ? 'active_club_widgets' : 'active_agent_widgets';
        this.setState({
            [active_widgets]: new_player_case,
            index: {
                ...this.state.index,
                [case_index_name]: {
                    minIndex,
                    maxIndex,
                }
            },
            animatedClass: `animated bounceInRight`
        });
    }

    /**
     * handle field change for applying filter
     */

    handleFieldsChanges = (event) => {
        const target = event.target,
            value = target.value;

        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        let new_state = {},
            agent_cases = this.state.agent_cases,
            active_agent_widgets = this.state.active_agent_widgets,
            index = this.state.index;
        const agent_case_path = APIRoutes.active_case_agents;
        const request = new Request(agent_case_path + value, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                agent_cases = response.data && response.data.length > 0 ? response.data : [];
                active_agent_widgets = agent_cases ? agent_cases.filter((active_case, idx) => {
                    return idx <= 3;
                }) : [];
            }

            new_state = {
                agent_cases,
                active_agent_widgets,
                index : {
                    ...index,
                    active_agent_case: {
                        minIndex: 0,
                        maxIndex: 3,
                    }
                }
            }
            this.setState(
                new_state
                );
        }, error => {
            console.log(error);
        });


        this.setState({
            case_type: value
        });

    }

    /**
     * handle alert button click
     */
    handleAlert = (case_details,case_type) => {
        console.log(case_details);
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        this.setState({
            isProcessing: true
        })

        let new_state = {},
            formdata = new FormData();
        
        if (case_type === 'case') {
            //append agents' id who are associated with the case    
            formdata.append('user_id', case_details.agents && case_details.agents.length > 0 ? case_details.agents.map(case_detail => {
                return case_detail.id;
            }) : '');

            //append case_id
            formdata.append('case_id', case_details.id ? case_details.id : '');

            //append case type ( 1 for player recruitment and 2 for club requirement)
            formdata.append('case_type', case_details.master_requirement_id ? case_details.master_requirement_id : '');
        }
        else if (case_type === 'agent') {
            //append agent id 
            formdata.append('user_id', case_details.id ? case_details.id : '');
        }

        const agent_path = case_type === 'case' ? APIRoutes.send_notification : APIRoutes.send_agent_reminder;
        const request = new Request(agent_path, formdata, 'POST', headers, true).send();
        request.then(response => {
            if (response.success) {
                this.getDashboardData();
                this.getActiveCaseAgents();
            }

            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message
            }
            this.setState( new_state );
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMsg : error.message
            };
            this.setState( new_state );
        });
    }

    resetMessage = () => {
        this.setState({
            responseMsg: ''
        });
    }

    render() {
        const toggledClass = this.state.sidebarToggled ? 'toggled' : '',
              loading      = this.state.isProcessing ? <LoadingSpinner/> : '',
              responseMessage = this.state.responseMsg === '' && !this.state.isSubmitted ? '' : <Alert message={this.state.responseMsg} success={this.state.isSuccess} floating="true" onClose={this.resetMessage} />;
        return (
            <div id="mainContainer" className={toggledClass}>
            {loading}
            {responseMessage}
                <div className="heading-section d-flex align-items-center">
                <h2>Welcome back, {get_current_user_full_name()}</h2>
                    <button onClick={this.openSidebar} to="" className="transparent-btn ml-auto usmDrawer" data-toggle="offcanvas">
                        <img src={require('../../assets/images/drawer.png')} alt="" />
                    </button>
                </div>

                <RightDrawer
                    birthday={this.state.birthday}
                    representation_date={this.state.representation_date}
                />

                <div className="content-section">
                    <div className="players-section case-icon-player">
                        <div className="heading-section d-flex align-items-center auto-height">
                            Ageing Player Recruitment Cases <span className="badge badge-pill badge-success">{this.state.player_case ? this.state.player_case.length : 0}</span>
                        </div>
                        <div className="position-relative">
                            <div className="row usm-cards club-slider-block widget-slider marginB35">
                                {this.state.player_case && this.state.player_case.length > 0 ? this.state.player_case.map((active_player_case, idx) => {
                                    return <Case key={active_player_case.id}
                                        active_player_case={active_player_case}
                                        active_player_widgets={this.state.active_player_widgets}
                                        idx={idx}
                                        case_type="player_case"
                                        handle_alert={this.handleAlert} />
                                }) : ''
                                }
                                
                            </div>
                            <div className="widget-arrow">
                                    
                                {this.state.player_case.length <= 4 ? '' :
                                    <button onClick={() => this.slidePrevWidget('player')} className="club-slider-btn float-left" data-toggle="offcanvas">
                                        <i className="material-icons text-success">keyboard_arrow_left</i>
                                    </button>
                                }
                                {this.state.player_case.length <= 4 ? '' :
                                    <button onClick={() => this.slideNextWidget('player')} className="club-slider-btn float-right" data-toggle="offcanvas">
                                        <i className="material-icons text-success">keyboard_arrow_right</i>
                                    </button>
                                }
                            
                            </div>
                        </div>

                        
                    </div>
                    

                    <div className="players-section case-icon">
                        <div className="heading-section d-flex align-items-center auto-height">
                            Ageing Club Requirement Cases <span className="badge badge-pill badge-success">{this.state.club_case ? this.state.club_case.length : 0}</span>
                        </div>
                        <div className="position-relative">
                            <div className="row usm-cards club-slider-block widget-slider marginB35">
                                {this.state.club_case && this.state.club_case.length > 0 ? this.state.club_case.map((active_club_case, idx) => {
                                    return <Case key={active_club_case.id}
                                        active_player_case={active_club_case}
                                        active_player_widgets={this.state.active_club_widgets}
                                        idx={idx}
                                        case_type="club_case"
                                        handle_alert={this.handleAlert} />
                                }) : ''
                                }   
                            </div>
                            <div className="widget-arrow">
                                {this.state.club_case.length <= 4 ? '' :
                                    <button onClick={() => this.slidePrevWidget('club')} className="club-slider-btn float-left" data-toggle="offcanvas">
                                        <i className="material-icons text-success">keyboard_arrow_left</i>
                                    </button>
                                }
                                {this.state.club_case.length <= 4 ? '' :
                                    <button onClick={() => this.slideNextWidget('club')} className="club-slider-btn float-right" data-toggle="offcanvas">
                                        <i className="material-icons text-success">keyboard_arrow_right</i>
                                    </button>
                                }                           
                            </div>
                        </div>
                    </div>
                    

                    <div className="players-section agent-section">
                        <div className="heading-section d-flex align-items-center auto-height">
                            Agents with Active Cases <span className="badge badge-pill badge-success">{this.state.agent_cases ? this.state.agent_cases.length : 0}</span>
                            <div className="caseTypeSelect">
                                <select name="case_type" onChange={this.handleFieldsChanges} className="form-control ml-auto" value={this.state.case_type}>
                                    <option value="">All</option>
                                    <option value="1">Player Recruitment Cases</option>
                                    <option value="2">Club Requirement Cases</option>
                                </select>
                            </div>
                        </div>
                        <div className="position-relative">
                            <div className="row usm-cards club-slider-block widget-slider marginB35">
                                {this.state.agent_cases && this.state.agent_cases.length > 0 ? this.state.agent_cases.map((active_agent_case, idx) => {
                                    return <AgentCase key={active_agent_case.id}
                                        active_agent_case={active_agent_case}
                                        active_agent_widgets={this.state.active_agent_widgets}
                                        idx={idx}
                                        handle_alert={this.handleAlert} />
                                }) : ''
                                }
                                
                            </div>
                            <div className="widget-arrow">
                                
                                {this.state.agent_cases.length <= 4 ? '' :
                                    <button onClick={() => this.slidePrevWidget('agent')} className="club-slider-btn float-left" data-toggle="offcanvas">
                                        <i className="material-icons text-success">keyboard_arrow_left</i>
                                    </button>
                                }
                                {this.state.agent_cases.length <= 4 ? '' :
                                    <button onClick={() => this.slideNextWidget('agent')} className="club-slider-btn float-right" data-toggle="offcanvas">
                                        <i className="material-icons text-success">keyboard_arrow_right</i>
                                    </button>
                                }
                                
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        );
    }
}
