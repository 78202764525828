import React from 'react';
import { Link } from 'react-router-dom';
import { get_display_format_date, is_valid_db_date, __x, get_current_user_role } from '../../../helpers/GeneralHelper';
import PlayerListCaseRollOver from './PlayerListCaseRollOver';


const Player = ({ players, user_role, is_fetched, ...rest }) => {
    let playersList = [];

    if (!players || players === 'undefined' || players.length <= 0) {
        if ( is_fetched ) {
            playersList.push(
                <tr key={1}>
                    <td colSpan="8">{ __x( `No record found.` ) }</td>
                </tr>
            );
        }

        return playersList;
    }

    const manage_icon = user_role === 'Agent' ? <i className="material-icons text-offWhite font-size20">remove_red_eye</i> : <i className="material-icons text-offWhite font-size20">edit</i>;
    for ( let i in players ) {
        let player = players[i];
        let agent_representation_enddate =  player.agent_representation_enddate;
        let contract_enddate = player.contract_enddate;
        const is_restricted = get_current_user_role() !== 'Admin' && player.restrict_representation_enddate;
        
        playersList.push(
            <tr key={player.id}>
                <td>
                    {/*{ rest.file_path + player.image_path }*/}
                    <div className="d-flex align-items-center">
                        <span className="table-clubImg align-top"><img src={player.image_path && player.image_path !== '' ? player.image_path : require('../../../assets/images/dummy-image.png')} alt="" /></span>
                        <div className="d-inline-block">
                            <div className="fontBold">{player.first_name ? player.first_name : ''} {player.last_name ? player.last_name : ''}
                            </div>
                            {player.master_position && player.master_position.title ? player.master_position.title : ''}
                        </div>
                    </div>
                </td>
                <td>{player.master_professional_level != null ? player.master_professional_level.name : ''}</td>
                <td>
                    <div className="d-flex align-items-center">
                        <span className="table-clubImg"><img src={player.current_club > 0 && player.club !== null ? player.club.image_path : require('../../../assets/images/dummy-image.png')} alt="" /></span>
                        {(player.club !== null && player.current_club > 0) ? player.club.club_name : player.other_club}
                    </div>
                </td>
                <td>
                    {player.master_contract_type && player.master_contract_type.name ? player.master_contract_type.name : ''}
                    <div className="fontBold">{/*Full Time*/} <span className="text-danger">{ contract_enddate && is_valid_db_date(contract_enddate) ? `(Exp: `+ get_display_format_date(contract_enddate) + `)` : '' }</span></div>

                    {/* <div className="fontBold"> <span className="text-danger">({"Exp: " + contract_enddate && is_valid_db_date(contract_enddate) ? get_display_format_date(contract_enddate) : '' })</span></div> */}
                </td>
                <td>
                    {player.agent_representation ? player.agent_representation : ''}
                    <div>{player.agency_representation ? player.agency_representation : ''}</div>
                </td>
                { is_restricted ? 
                <td className="text-danger">{ __x( `Restricted, please contact the administrator.` ) }</td>
                 :
                <td className="fontBold text-danger">{agent_representation_enddate && is_valid_db_date(agent_representation_enddate) ? get_display_format_date(agent_representation_enddate) : ''}</td>
                }
                <td>
                    <div className="marginB3"><button type="button" className="table-btn btn-success">{player.close_case ? player.close_case.length : 0} Closed</button></div>
                    <div className="">
                        <button type="button" className="position-relative table-btn btn-danger show-rollover">{player.active_case ? player.active_case.length : 0} Active
                        { player.active_case.length > 0 ? <PlayerListCaseRollOver player_id={player.id} activeCases = {player.active_case} filtered={ rest.filtered } /> : '' }
                        </button>
                    </div>

                </td>
                <td>
                    <Link to={`/players-details/${player.id}`}>{manage_icon}</Link>
                </td>
            </tr>
        );
    }
    return playersList;
}
export default Player;

