import React, { Component } from 'react';
import { Link } from "react-router-dom";
import APIRoutes from '../../API/Routes';

import * as EmailValidator from "email-validator";
import Request from "../../helpers/Request";
import { loginSuccess, loginFalied } from '../../actions/LoginAction';
import { connect } from 'react-redux';
import { set_user_data, get_user_dashboard_url, __x } from '../../helpers/GeneralHelper';
import Alert from '../../helpers/Alert';


class Login extends Component {

    _page_title     = "Login - USM";

    _userDataKeys   = [ 'user', 'token' ];

    _userDataKeysTnc = ['temp_user', 'temp_token'];

    constructor( props ) {
        super( props );

        this.state = {
            fields          : {
                username    : '',
                password    : '',
                rememberme  : false
            },
            isSubmitted         : false,
            isSuccess           : false,
            responseMsg         : '',
            usernameValid       : true,
            passValid           : true,
            redirectTo          : '',
            setLocalStorageForTnc : false,
        };
        
        this.handleSubmit           = this.handleSubmit.bind( this );
        this.handleChange           = this.handleChange.bind( this );
        this.isFormValid            = this.isFormValid.bind( this );
        this.setUserDataToStore     = this.setUserDataToStore.bind( this );
        this.dispatchSuccessToStore = this.dispatchSuccessToStore.bind( this );
        this.resetAlert             = this.resetAlert.bind( this );
    }
    
    /**
     * Handles login form submission
     * @param {*} event 
     */
    handleSubmit( event ) {
        event.preventDefault();

        const postUrl       = APIRoutes.login;

        let fields          = this.state.fields;
        
        if( !this.isFormValid() ) {
            return;
        }

        /**
         * Creating new request instance to call api
         */
        const request = new Request( postUrl, fields, "POST" ).send();
        request.then( 
            ( result ) => {
                let new_state = {
                    isSubmitted  : true,
                    isSuccess    : result.success
                };

                if ( result.success ) {

                    const setLocalStorageForTnc = !result.user.tnc_accepted ? true : false;
                    this.setState({
                        setLocalStorageForTnc
                    });
                    this.setUserDataToStore( result );

                    const redirectTo = result.user.tnc_accepted ? get_user_dashboard_url() : '/terms-and-conditions'; 
                    

                    new_state = {
                        ...new_state,
                        redirectTo
                    };
                    
                    // Commented for later use
                    /* if( fields.rememberme ) {
                        set_user_data( 'rememberme', true );
                    } */
                } else {
                    new_state = {
                        ...new_state,
                        responseMsg  : result.error
                    };
                }

                /**
                 * Set new states to the real state
                 */
                this.setState( new_state, () => {
                    this.props.history.push( new_state.redirectTo );
                });
            },
            ( error ) => {
                this.setState({
                    isSubmitted : true,
                    isSuccess   : error.success,
                    responseMsg : error.message
                });
            },
        );
    }

    /**
     * Handles login fields change event
     * @param {*} event 
     */
    handleChange( event ) {
        const target    = event.target,
        value           = target.type === 'checkbox' ? target.checked : target.value,
        id              = target.id;
        
        let fields      = this.state.fields;
        
        fields[ id ]    = value;

        this.setState({
            fields,
            usernameValid           : fields.username === '' ? true : EmailValidator.validate( fields.username )
        });
    }

    /**
     * Checks if form is valid or not
     */
    isFormValid() {
        return this.state.fields.username !== '' && this.state.fields.password !== '';
    }
    
    /**
     * Sets userdata to local storage and redux store
     */
    setUserDataToStore = ( userData ) => {
        const keys = this.state.setLocalStorageForTnc ? this._userDataKeysTnc : this._userDataKeys;
        
        for( let i in keys ) {
            
            let key = keys[i];            
            if ( key === "" || key === 'undefined' || typeof key === 'undefined' )
                continue;
            if( key === 'user' || key === 'temp_user') {
                set_user_data( key, userData[ 'user' ] );
            }

            if( key === 'token' || key === 'temp_token') {
                set_user_data( key, userData[ 'token' ] );
            }
            
        }
        
        this.dispatchSuccessToStore( userData );
    }

    /**
     * Set user data to global redux store before rendign the component
     */
    dispatchSuccessToStore = ( userData ) => {
        this.props.loginSuccess( userData.token, userData.user );
    }

    resetAlert = () => {
        this.setState({
            responseMsg: ''
        });
    }

    render() {
        
        /**
         * Required dynamic fields data
         */
        const username_classes  = ( this.state.usernameValid ) ? '' : 'form-error',
        pass_classes            = ( this.state.passValid ) ? '' : 'form-error',
        submitDisabled          = !this.isFormValid(),
        responseMsg             = ( this.state.isSubmitted && this.state.responseMsg !== '' ) ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} onClose={this.resetAlert} /> : '',
        flashMessage            = !this.props.location.state || this.props.location.state.message === '' || this.state.isSubmitted ? '' : <Alert message={this.props.location.state.message} success={this.props.location.state.success} />;

        return (
            <div className="modal-body usm-modal-body Login">
                { flashMessage }
                { responseMsg }

                <h2>Login</h2>
                <div className="width-container">
                    <form action="" className="usm-form" onSubmit={ event => this.handleSubmit( event ) }>
                        <div className={`form-group ${username_classes}`}>
                            <input 
                                type="text" className={`form-control ${username_classes}`} id="username" placeholder="Email" 
                                value={ this.state.fields.username } onChange={ event => this.handleChange( event )} 
                            />
                            { username_classes === '' ||
                                <div className="display-error">{ __x( `Please enter a vaild email.` ) }</div>
                            }
                        </div>
                        <div className={`form-group ${pass_classes}`}> 
                            <input 
                                type="password" className={`form-control ${pass_classes}`} id="password" 
                                value={ this.state.fields.password } onChange={ event => this.handleChange( event )} placeholder="Enter Password" 
                            />
                        </div>
                        <div className="form-group usm-check marginT25">
                            <input 
                                type="checkbox" id="rememberme" value={ this.state.fields.rememberme } 
                                onChange={ event => this.handleChange( event )} 
                            />
                            <label htmlFor="rememberme">Remember me</label>
                        </div>
                        <button type="submit" className="btn btn-success btn-block marginLR0" disabled={submitDisabled}>Sign In</button>
                        <div className="usm-form-subtext text-transform-uppercase marginT25">
                            <Link to="/forgot-password/">Forgot Password?</Link>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        document.title = this._page_title;
    }

    componentDidUpdate = () => {
        window.scrollTo(0,0);
    }
}

const mapStateToProps = ( state ) => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    loginSuccess    : ( token, user ) => dispatch( loginSuccess( token, user ) ),
    loginFalied     : () => dispatch( loginFalied() ),
});

export default connect( mapStateToProps, mapDispatchToProps )( Login );