export const loginSuccess = ( token, user ) => dispatch => {
    dispatch({
        type: 'LOGIN_SUCCESS',
        payload: {
            token   : token,
            user    : user
        }
    });
}

export const loginFalied = () => dispatch => {
    dispatch({
        type: 'LOGIN_FAILED'
    });
}
