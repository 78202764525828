import React from 'react';
import Alert from '../../../helpers/Alert';
import '../../../../node_modules/react-intl-tel-input/dist/main.css';
import { __x } from '../../../helpers/GeneralHelper';

const UpdatePushNotification = ( props ) => {
    const modalActiveClass  = props.modal ? 'show' : '',
    message_err_classes  = props.isValid.message ? '' : 'form-error',
    disableSubmit           = !props.isFormValid() || props.isProcessing ? true : false,
    responseMsg             = props.responseMessage !== '' && props.isSubmitted ? <Alert message={props.responseMessage} success={props.isSuccess} floating="true" onClose={props.resetAlert} /> : '',
     reminder_day = props.fields.reminder_day ? props.fields.reminder_day.split(',') : '' ;
    const automatedReminderArr = props.automatedReminderArr && props.automatedReminderArr.length > 0 ? props.automatedReminderArr.map( (value,index) => {
        const checked = reminder_day.indexOf( value.toString() ) >= 0;
        return <div key={index} className="row">
                    <div className="col">
                        <div className="form-group usm-check">
                            <input onChange={ props.handleFieldsChanges } value={value} name={`reminder_day_${value}`} id={`reminder_day_${value}`} type="checkbox" checked={checked} />
                            <label htmlFor={`reminder_day_${value}`}>{value} Days</label>
                        </div>
                    </div>
                </div>
    }) : '';
    
    return (
        <div>
            { responseMsg }
            <div className={`modal form-modal fade modal-active ${modalActiveClass}`} style={{ transform: props.modal ? `translate(0, 0)` : 'translate(-125%, -125%)' }}>
                <div className="modal-dialog modal-dialog-centered maxWidth600">
                    <div className="modal-content">
                        <form action="" className="usm-form form-border" onSubmit={ props.updatePushNotification } encType="multipart/form-data">
                            <div className="modal-header form-modal-header d-flex align-items-center">
                                <img src={ require( '../../../assets/images/form-usm-logo.png' ) } alt="" />
                                <h5 className="form-modal-title">Manage Push Notification</h5>
                                <button type="button" className="modal-close" aria-label="Close" onClick={ props.onPopupClose }>
                                    <img src={ require( '../../../assets/images/close-modal.png' ) } alt="Close" />
                                </button>
                            </div>
                            <div className="modal-body form-modal-body">
                                <div className="row">
                                    <div className="col-md-12 col-xs-12">
                                        <div className={`form-group ${message_err_classes}`}>
                                            <label htmlFor="">Message</label>
                                            <input value={ props.fields.message } onChange={ props.handleFieldsChanges } className="form-control" name="message" type="text" placeholder="Message" />
                                            { message_err_classes === '' || <div className="display-error">{ __x( `Message is invalid.` ) }</div> }
                                        </div>
                                    </div>
                                </div>
                                { props.fields.status === true ? 
                                <div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group usm-check">
                                            <input onChange={ props.handleFieldsChanges } value={props.fields.manual} checked={props.fields.manual ? true : false} name="manual" type="checkbox" id="manual" />
                                            <label htmlFor="manual">Enable Manual Sending of Notification</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="reminder_day">Automated Reminders Sent:</label>
                                        </div>
                                    </div>
                                </div>

                                {automatedReminderArr}
                                </div>
                                : ''
                            }
                            
                            </div>
                            
                            <div className="modal-footer form-modal-footer d-flex justify-content-between">
                                <button type="button" className="default-btn btn-danger" data-dismiss="modal" onClick={ props.onPopupClose }>Cancel</button>
                                <button type="submit" className="default-btn btn-success" disabled={disableSubmit}>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            { !props.modal || <div className="modal-backdrop fade show"></div> }
        </div>
    );
}
export default UpdatePushNotification;