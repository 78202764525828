import React from 'react';
import { Link, withRouter } from "react-router-dom";
import Logo from './Logo';
import Nav from './Nav';
import Logout from './login/Logout';
import { get_user_data, get_current_user_role, get_date, get_authorization_token, __x, set_user_data, get_user_dashboard_url } from '../helpers/GeneralHelper';
import Search from './admin-staff/search/Search';
import APIRoutes from '../API/Routes';
import Request from '../helpers/Request';
import { loginSuccess, loginFalied } from '../actions/LoginAction';
import { connect } from 'react-redux';

class Header extends React.Component {

    profileNavPopper;
    profileNavWrapper;

    _userDataKeys   = [ 'user', 'token', 'original_role' ];

    constructor( props ) {
        super( props );

        this.settingsBtn        = React.createRef();
        this.settingsNavWrapper = React.createRef();

        this.state = {
            settingsDropdownExpanded    : false,
            accountDropdownExpanded     : false,
            confirmationModal           : false,
            openMobileMenu              : false,
            redirectTo                  : '',
        };

        this._current_user_role = get_current_user_role();
    }

    /**
     * Toggle profile actions dropdown.
     */
    toggleAccountDropdown = () => {
        this.setState({
            accountDropdownExpanded : !this.state.accountDropdownExpanded
        });
    }

    toggleSettingsDropdown = () => {
        this.setState({
            settingsDropdownExpanded : !this.state.settingsDropdownExpanded
        });
    }

    /**
     * Toggles logout comfirmation modal.
     */
    handleConfirmationModal = ( event ) => {
        this.setState({
            confirmationModal: !this.state.confirmationModal
        });
    }

    toggleMobileMenu = () => {
        this.setState({
            openMobileMenu : !this.state.openMobileMenu
        })
    }

    render() {
        const accountClasses    = this.state.accountDropdownExpanded ? 'show' : '',
            settingsClasses     = this.state.settingsDropdownExpanded ? 'show' : '',
            user                = get_user_data( 'user' ),
            // user_id             = user ? user.id : '',
            show_mobile_menu    = this.state.openMobileMenu ? 'show' : '',
            profile_pic         = user.imagepath ? user.imagepath : require( '../assets/images/dummy-image.png' ),
            last_signin         = user.last_login ? get_date( user.last_login ) : '' ,
            original_role       = user.original_role ? user.original_role : '';
        return (
            <div>
                <div className="welcome-header">
                    <ul className="nav justify-content-end">
                        <li className="nav-item">
                            <Link className="nav-link text-white fontBold d-flex align-items-center" to="">Welcome Back, { user.first_name } { user.last_name }</Link>
                        </li>
                        { this._current_user_role !== 'Admin' ? '' : 
                            <li className="nav-item dropdown">
                                <button onClick={ this.toggleSettingsDropdown } ref={ elt => this.settingsBtn = elt } type="button" className="transparent-btn nav-link d-flex align-items-center" to="">
                                    <i className="material-icons text-purpleLight font-size20">settings</i>
                                </button>
                                <div className={`dropdown-menu dropdown-menu-right usm-dropdown-style ${settingsClasses}`} onClick={ e => e.stopPropagation() } ref={ elt => this.settingsNavWrapper = elt }>
                                    <Link className="dropdown-item" to="/admin/change-logs/">Change Log</Link>
                                    <Link className="dropdown-item" to="/admin/push-notifications/">Push Notification</Link>
                                    <Link className="dropdown-item" to="/admin/login-history">Login History</Link>
                                </div>
                            </li>
                        }
                        <li className="nav-item dropdown">
                            <button  onClick={ this.toggleAccountDropdown } ref={ node => this.profileNavPopper = node } className="nav-link d-flex align-items-center dropdown-toggle text-purpleLight user-dropdown-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img src={ profile_pic } alt="" />
                            </button>
                            <div className={`dropdown-menu dropdown-menu-right usm-dropdown-style ${accountClasses}`} onClick={ e => e.stopPropagation() } ref={ node => this.profileNavWrapper = node }>
                                <Link className="dropdown-item" to={`/user/profile/`}>My Profile</Link>
                                <button className="dropdown-item logout-btn" onClick={this.handleConfirmationModal}>Log Out</button>
                                <button className="dropdown-item logout-btn">
                                    <small>
                                        Last Sign in:
                                        <span className="d-block">{last_signin}</span>
                                    </small>
                                </button>
                                { this._current_user_role === 'Agent' && original_role === 'Admin' ? <button className="dropdown-item logout-btn" onClick={this.switchAdminToAgent}>Switch to Admin Role</button> : this._current_user_role === 'Admin' ? <button className="dropdown-item logout-btn" onClick={this.switchAdminToAgent}>Switch to Agent Role</button> : '' }
                            </div>
                            <Logout 
                                modal={ this.state.confirmationModal }
                                onClose={ () => this.handleConfirmationModal() }
                            />
                        </li>
                    </ul>
                </div>
                <header className="header">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <Link className="navbar-brand" to="/">
                            <Logo siteLogo={true} />
                        </Link>
                        <button onClick={ this.toggleMobileMenu } className="navbar-toggler text-white " type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon">
                                <i className="material-icons">menu</i>
                            </span>
                        </button>
                        <div className={`collapse navbar-collapse ${show_mobile_menu}`} id="navbarText">
                            <Nav />
                            <Search />
                        </div>
                    </nav>
                </header>
            </div>
        );
    }

    /**
     * switch admin to agent
     */
    switchAdminToAgent = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x( `Session has been expired. Please reload the page to login!` ),
                isSuccess: false
            });
            return false;
        }

        const postUrl       = APIRoutes.change_role;

        /**
         * Creating new request instance to call api
         */
        const request = new Request( postUrl, {}, "GET", headers ).send();
        request.then( 
            ( result ) => {
                let new_state = {
                    isSubmitted  : true,
                    isSuccess    : result.success
                };

                if ( result.success ) {
                    this.setUserDataToStore( result );

                    const redirectTo = get_user_dashboard_url() ; 

                    new_state = {
                        ...new_state,
                        redirectTo
                    };
                    
                    // Commented for later use
                    /* if( fields.rememberme ) {
                        set_user_data( 'rememberme', true );
                    } */
                } else {
                    new_state = {
                        ...new_state,
                        responseMsg  : result.error
                    };
                }

                /**
                 * Set new states to the real state
                 */
                this.setState( new_state, () => {
                    this.props.history.push( new_state.redirectTo );
                });
            },
            ( error ) => {
                // this.setState({
                //     isSubmitted : true,
                //     isSuccess   : error.success,
                //     responseMsg : error.message
                // });

                console.log(error);
            },
        );
    }

     /**
     * Sets userdata to local storage and redux store
     */
    setUserDataToStore = ( userData ) => {
        const keys = this._userDataKeys;
        
        for( let i in keys ) {
            
            let key = keys[i];            
            if ( key === "" || key === 'undefined' || typeof key === 'undefined' )
                continue;

            set_user_data( key, userData[ key ] );
        }
        
        this.dispatchSuccessToStore( userData );
    }

    /**
     * Set user data to global redux store before rendign the component
     */
    dispatchSuccessToStore = ( userData ) => {
        this.props.loginSuccess( userData.token, userData.user );
    }

    /**
     * Close profile navigation dropdown popup when clicking outside of that wrapper.
     */
    handleClickOutside = ( event ) => {
        /**
         * Checks if the profile navigator and dropdown wrapper is not the clicked element, close the dropdown.
         */
        if ( this.state.accountDropdownExpanded && this.profileNavWrapper && !this.profileNavWrapper.contains( event.target ) && !this.profileNavPopper.contains( event.target ) ) {
            this.toggleAccountDropdown();
        }
        
        /**
         * Checks if the settings navigator and dropdown wrapper is not the clicked element, close the dropdown.
         */
        if ( this.state.settingsDropdownExpanded && this.settingsNavWrapper && !this.settingsNavWrapper.contains( event.target ) && !this.settingsBtn.contains( event.target ) ) {
            this.toggleSettingsDropdown();
        }
    }

    /**
     * Adding Event listener for outside div of profile navigation wrapper
     */
    componentDidMount = () => {
        document.addEventListener( 'mousedown', this.handleClickOutside );
    }

    /**
     * Removing Event listener for outside div of profile navigation wrapper
     */
    componentWillUnmount = () => {
        document.removeEventListener( 'mousedown', this.handleClickOutside );
    }

}

const mapStateToProps = ( state ) => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    loginSuccess    : ( token, user ) => dispatch( loginSuccess( token, user ) ),
    loginFalied     : () => dispatch( loginFalied() ),
});


export default withRouter(connect( mapStateToProps, mapDispatchToProps )( Header ));