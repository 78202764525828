import React, { Component } from 'react';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import Notes from './Notes';
import Concierges from './Concierges';
import { withRouter } from 'react-router-dom';
import { setCurrentPlayer } from '../../../actions/NotesAndConciergesAction';
import { connect } from 'react-redux';
import { get_authorization_token, __x } from '../../../helpers/GeneralHelper';

class NotesAndConcierge extends Component {
    
    _page_title = `Notes & Concierges - USM`;

    constructor( props ) {
        super( props );

        this._fetched = {
            player   : false
        }

        this._player_id = props.match.params.player_id;
        this.state      = {
            player: {}
        }
    }

    componentWillReceiveProps = new_props => {
        this.setState({
            player: new_props.notesandconcierges.player
        });
    }

    render() {
        const player = this.state.player;
        return (
            <div>
                <div id="mainContainer">
                    <div className="heading-section height84 d-flex align-items-center marginB0">
                        <h2>{ player ? `${player.first_name} ${player.last_name}` : '' } - Notes & Concierge</h2>
                    </div>
                    <div className="content-section">
                        <div className="row">
                            <Notes />
                            <Concierges />
                        </div>
                        <div className="border-bottom-trans marginB35 marginT20"></div>
                        <div className="row">
                            <div className="col">
                                <button type="button" onClick={() => this.props.history.go(-1)} className="default-btn btn-danger width-auto">Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    fetchPlayer = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        const player_path = APIRoutes.player + this._player_id;
        const request = new Request( player_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                this._fetched = {
                    player: true
                }

                this.props.setCurrentPlayer( response.data );
            }
        }, error => {
            console.log( error );
        });
    }

    componentDidMount = () => {
        document.title = this._page_title;

        if ( !this._fetched.player ) {
            this.fetchPlayer();
        }
    }
}

const mapStateToProps = ( state ) => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setCurrentPlayer  : notes => dispatch( setCurrentPlayer( notes ) )
});

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( NotesAndConcierge ) );
