import React, { Component } from 'react';
import Reports from './Reports';
import Videos from './Videos';

class VideosAndReports extends Component {
    
    _page_title = 'Videos & Reports - USM';

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-8 col-sm-12">
                        <Videos />
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <Reports />
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        document.title = this._page_title;
    }
}

export default VideosAndReports;