const initialState = {
    changelogs : []
}

export default ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'ADD_CHANGELOGS':
            return {
                ...state,
                changelogs : action.payload.changelogs
            };
		default:
			return state;
	}
}