import React, { Component } from 'react';
import { get_authorization_token, __x } from '../../../helpers/GeneralHelper';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import PushNotification from './PushNotification';
import UpdatePushNotification from './UpdatePushNotification';
import LoadingSpinner from '../../admin-staff/common/LoadingSpinner';

export default class PushNotifications extends Component {

    constructor(props) {
        super(props);

        this._is_fetched = {
            get_push_notification_data : false,
        }

        this.state = {
            updatingNotificationId : 0,
            notifications        : [],
            editPushNotification : false,
            reminder_days : [],
            fields : {
                message          : '',
                reminder_day     : '',
                manual           : false,
                status           : false,
            },
            automated_reminder_arr : [7,14,21,28],
            isValid : {
                message  : true
            },
            isProcessing        : false,
            isSubmitted         : false,
            isSuccess           : false,
            responseMsg         : ''
        }
    }
  
    /**
     * handle fields changes
     */
    handleFieldsChanges = event => {
        const target = event.target,
        name = target.name,
        value = target.type === 'checkbox' ? target.checked : target.value,
        fields = this.state.fields,
        isValid = this.state.isValid,
        reminder_days = this.state.reminder_days;

        if ( name.includes( 'reminder_day_' ) ) {
            
            if ( value ) {
                reminder_days.push( target.value );
            } else {
                const index = reminder_days.indexOf( target.value );
                if ( index >= 0 ) {
                    reminder_days.splice( index, 1 );
                }
            }

            fields.reminder_day = reminder_days.join( ',' );
        } else {
            fields[ name ] = value;
        }

        if ( name === 'message') {
            fields[ name ] = value;
            isValid[ name ] = fields[ name ] !== '' ? fields[ name ].length >= 1 && fields[ name ].length <= 255  : true;
        }
        this.setState({
            fields,
            isValid,
        });

    }

    componentDidMount = () => {
        if(!this._is_fetched.get_push_notification_data)
        {
            this.getPushNotificationData();
        }
    }

    getPushNotificationData = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }
        
        let notifications         = this.state.notifications;
        const notification_path   = APIRoutes.notification;
        const request = new Request( notification_path, {}, 'GET', headers ).send();
        request.then( response => {
            if ( response.success ) {
                notifications = response.data && response.data.length > 0 ? response.data : [];
            }
            this.setState( {
                notifications
            } );

            this._is_fetched.get_push_notification_data = true;
        }, error => {
            console.log( error );
        });
    }

    /**
     * handle edit push notification popup
     */
    handleEditPushNotification = ( fetchUsers = false, notification_id = 0 ) => {
        let new_state = {
            editPushNotification    : !this.state.editPushNotification,
        }
        
        /**
         * If notification id is given, set notification's details as object in state
         */
        if ( notification_id > 0 ) {

            const headers = get_authorization_token();
            if (!headers) {
                this.setState({
                    isError: true,
                    responseMsg: __x( `Session has been expired. Please reload the page to login!` )
                });
                return false;
            }
            const notification_path = APIRoutes.notification;
            const request = new Request(notification_path + notification_id, {}, 'GET', headers).send();
            let  notification_data = [];
            request.then(response => {
                if (response.success) {
                    notification_data = response.data && response.data.length > 0 ? response.data[0] : '' ;
                    new_state = {
                        ...new_state,
                        fields: {
                            ...this.state.fields,
                            message       : notification_data.message ? notification_data.message : '',
                            reminder_day  : notification_data.reminder_day ? notification_data.reminder_day : '',
                            manual        : notification_data.manual ? notification_data.manual : false,
                            status        : notification_data.status ? notification_data.status : false,
                        },
                        reminder_days     : notification_data.reminder_day ? notification_data.reminder_day.split(',') : [],
                        updatingNotificationId : notification_id
                    }
                }
                this.setState( new_state );
            }, error => {
                console.log(error);
            });
        }
        
        this.setState( new_state, () => {
            if ( this.state.editPushNotification ) {
                document.body.classList.add( 'modal-open' );
            } else {
                document.body.classList.remove( 'modal-open' );
            }
        });
        
        if ( fetchUsers ) {
            this.getPushNotificationData();
        }
    }

    /**
     * Check is form is valid or not to submit
     */
    isFormValid = () => {
        let validityErr = [];
        const fields    = this.state.fields,
        isValid         = this.state.isValid;

        for ( let elt in fields ) {
            if ( elt === 'manual' || elt === 'reminder_day' || elt === 'status' )
                continue;
            
            if ( fields[ elt ] === '' || !isValid[ elt ] ) {
                validityErr.push( elt );
            }
        }
        return validityErr.length <= 0;
    }

    /**
     * close edit popup
     */
    onPopupClose = () => {
        this.clearFormFields();
        this.handleEditPushNotification( true );
    }

    /**
     * clear form fields
     */
    clearFormFields = () => {
        this.setState({
            updatingNotificationId : 0,
            notifications        : [],
            fields : {
                message          : '',
                reminder_day     : '',
                manual           : false,
            },
            reminder_days        : [],
            automated_reminder_arr : [7,14,21,28],
            isValid : {
                message  : true
            },
            isProcessing        : false,
            isSubmitted         : false,
            isSuccess           :false,
            responseMsg         : ''
        })
    }

    /**
     * update push notification
     */
    updatePushNotification = ( event ) => {
        event.preventDefault();
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( !this.isFormValid() ) {
            return false;
        }

        this.setState({
            isProcessing: true
        });
        
        const update_notification_path   = APIRoutes.notification,
              fields                     =  this.state.fields,
              updatingNotificationId     = this.state.updatingNotificationId;
        let   new_state                  = {},
              formData                   = new FormData();

              formData.append('id', updatingNotificationId)
        
        for ( let i in fields ) {
            formData.append( i, fields[i] );
        }
        const request = new Request( update_notification_path, formData , 'PUT', headers, true ).send();
        request.then( response => {
            if ( response.success ) {
                this.clearFormFields();
                this.handleEditPushNotification( true );
            }

            console.log(response.message);

            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message
            }
            this.setState( new_state );

        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMsg     : error.message
            }
            this.setState( new_state );
            console.log( error );
        });

    }

    /**
     * reset success/fail message
     */
    resetAlert = () => {
        this.setState({
            responseMsg : ''
        });
    }

    render() {
        const loading = this.state.isProcessing ? <LoadingSpinner /> : '';
        return (
            <div id="mainContainer">
            {loading}
            {/* {responseMsg} */}
                <div className="heading-section d-flex align-items-center">
                    <h2>Push Notifications</h2>
                </div>

                <div className="content-section">

                    <div className="usm-table table-responsive">
                        <table className="table table-hover">
                            <thead className="usm-thead">
                                <tr>
                                    <th scope="col">Push Notification Message</th>
                                    <th scope="col">Manual Sending Enabled</th>
                                    <th scope="col">Automated Reminders Sent</th>
                                    <th scope="col">Manage</th>
                                </tr>
                            </thead>
                            <tbody>
                                <PushNotification
                                    notifications={this.state.notifications}
                                    handleEditPushNotification={ ( fetchUsers, id ) => this.handleEditPushNotification( fetchUsers, id ) }
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
                <UpdatePushNotification
                    fields              = { this.state.fields }
                    isValid             = { this.state.isValid }
                    isFormValid         = { this.isFormValid }
                    modal               = { this.state.editPushNotification }
                    onPopupClose        = { this.onPopupClose }
                    automatedReminderArr = { this.state.automated_reminder_arr } 
                    handleFieldsChanges = { this.handleFieldsChanges } 
                    updatePushNotification = { this.updatePushNotification }
                    isSubmitted         = { this.state.isSubmitted }
                    isSuccess           = { this.state.isSuccess }
                    responseMessage     = { this.state.responseMsg }
                    resetAlert          = { this.resetAlert }
                />
            </div>
        )
    }

}