import React from 'react';
import { get_display_format_date, __x, is_valid_db_date } from '../../../helpers/GeneralHelper';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import Alert from '../../../helpers/Alert';
import { get_authorization_token, get_current_user_role } from '../../../helpers/GeneralHelper';
import { withRouter } from 'react-router-dom';

class OtherClubContact extends React.Component {

    _statusDropdownToggler;
    _statusDropdownWraper;

    constructor( props ) {
        super( props );
        this._club_id           = props.match.params.id;
        this._isFetching        = false;
        this.current_user_role  = get_current_user_role();
        
        this.state       = {
            contact         : props.contact ? props.contact : {},
            contact_id      : props.contact ? props.contact.id : 0,
            openStatus      : false,
            isProcessing    : false,
            isSubmitted     : true,
            isSuccess       : false,
            responseMsg     : '',
        }
    }

    /**
     * Updates status for current club contact.
     */
    updateStatus = ( event ) => {
        const elt   = event.target,
        new_status = elt.getAttribute( 'data-status' );

        if ( !new_status || new_status === '' ) {
            return false;
        }

        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        this.setState({
            isProcessing: true
        });
        
        const status_path = APIRoutes.club_contact_status;
        let new_state   = {},
        formData        = new FormData();
        formData.append( 'id', this.state.contact_id );
        formData.append( 'status', new_status );
        
        /**
         * Sending request to add/update clubs.
         */
        const request = new Request( status_path, formData, 'POST', headers, true ).send();
        request.then( response => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message
            };
            
            if ( response.success ) {
                /**
                 * Updated new status
                 */
                const status = {
                    ...this.state.contact,
                    contact: {
                        ...this.state.contact,
                        status: new_status === '0' ? false : true
                    }
                };

                this.toggleStatusDropdown();
                this.setState( status );
                this.props.refresh();
            }
            
            this.setState( new_state );
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMsg     : error.message
            };
            this.setState( new_state );
        });
    }

    /**
     * Toggle status dropdown
     */
    toggleStatusDropdown = ( event ) => {
        this.setState({
            openStatus: !this.state.openStatus
        });
    }

    /**
     * Rerender component to update the states according to new props.
     */
    /* componentWillReceiveProps = new_props => {
        console.log( new_props );
        this.setState({
            contact         : new_props.contact ? new_props.contact : {},
            contact_id      : new_props.contact ? new_props.contact.id : 0,
        });
    } */

    /**
     * Rerender component to update the states according to new props.
     */
    UNSAFE_componentWillReceiveProps = new_props => {
        this.setState({
            contact         : new_props.contact ? new_props.contact : {},
            contact_id      : new_props.contact ? new_props.contact.id : 0,
        });
    }

    render = () => {
        const contact   = this.state.contact,
        bday            = is_valid_db_date(contact.birthday) ? get_display_format_date( contact.birthday ) : '',
        inactiveWrapper = contact.status ? '' : 'disbaled',
        activeElt       = contact.status ? 'active' : '',
        inactiveElt     = !contact.status ? 'active' : '',
        openStatus      = this.state.openStatus ? 'show' : '',
        responseMsg     = this.state.responseMsg !== '' && this.state.isSubmitted ? <Alert message={this.state.responseMsg} success={this.state.isSuccess} floating="true" onClose={this.resetAlert} /> : '';
        const active_widgets = this.props.active_widgets ? this.props.active_widgets : [],
        email_phone_restrict = this.current_user_role !== 'Admin' && contact.is_restricted && contact.email === '' && contact.phone === '';
        let visible   = '';

        if ( active_widgets.length > 0 ) {
            for ( let i in active_widgets ) {
                if ( active_widgets[i].id === contact.id ) {
                    visible = `visible`;
                    // visible = `visible ${this.props.animatedClass}`;
                }
            }
        } else {
            visible = this.props.idx > 3 ? '' : `visible`;
            // visible = this.props.idx > 3 ? '' : `visible ${this.props.animatedClass}`;
        }

        return (
            <div className={`col-md-3 col-xs-12 ${visible}`}>
                {responseMsg}
                <div className="card">
                    <div className="card-body">
                        <div className={`card-head-dropdown ${inactiveWrapper}`}>
                            <div className="dropdown d-inline">
                                <button title={ `${contact.first_name} ${contact.last_name}` } ref={ elt => this._statusDropdownToggler = elt } onClick={ this.toggleStatusDropdown } className="btn card-dropdown boundary-90" type="button" id={`dropdownMenuCard-${contact.id}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    { `${contact.first_name} ${contact.last_name}` } 
                                    { this.current_user_role === 'Agent' ? '' : <i className="material-icons contact-caret">arrow_drop_down</i> }
                                </button>
                                {
                                    this.current_user_role === 'Agent' ? '' : 
                                    <div ref={ elt => this._statusDropdownWraper = elt } className={`status-dropdown dropdown-menu purpleBg-dropdown ai-dropdown purpleBg-top ${openStatus}`} aria-labelledby="dropdownMenuCard" onClick={e => e.stopPropagation()}>
                                        <button onClick={ this.updateStatus } className={`transparent-btn dropdown-item ${activeElt}`} data-status="1">Active</button>
                                        <button onClick={ this.updateStatus } className={`transparent-btn dropdown-item ${inactiveElt}`} data-status="0">Inactive</button>
                                    </div>
                                }
                                {
                                    this.current_user_role === 'Agent' ? '' :
                                    <div className="btn-group dropright d-inline float-right marginT5 show">
                                        <button onClick={ this.props.openPopup( contact.id ) } className="transparent-btn padding0" type="button">
                                            <i className="material-icons text-offWhite font-size20">edit</i>
                                        </button>                                    
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="cardContent-section">
                            <div className="card-data padding0">
                                <div className="card-data-label">Title in Club</div>
                                <div className="card-data-content fontNormal">{contact.title}</div>
                                <div className="card-data-label">Phone</div>
                                { email_phone_restrict ? <div className="label-content text-danger">{ __x( `Restricted, please contact the administrator.` ) }</div> : <div className="card-data-content fontNormal">{contact.country_code && contact.phone ? `+${contact.country_code}` : ''}{contact.phone ? contact.phone : ''}</div> }
                                <div className="card-data-label">Email</div>
                                { email_phone_restrict ? <div className="label-content text-danger">{ __x( `Restricted, please contact the administrator.` ) }</div> : <div className="card-data-content fontNormal">{contact.email}</div> }
                                <div className="card-data-label">Birthday</div>
                                <div className="card-data-content fontNormal">{ bday }{ contact.show_birthday_notification && contact.show_birthday_notification === true ? <i className="align-bottom material-icons text-success">notifications</i> : '' }</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    resetAlert = () => {
        this.setState({
            responseMsg: ''
        });
    }

    /**
     * Close status dropdown popup when clicking outside of that wrapper and button.
     */
    handleClickOutside = ( event ) => {
        /**
         * Dropdown is collapsed return false.
         */
        if ( !this.state.openStatus ) {
            return false;
        }

        /**
         * Checks if the profile navigator and dropdown wrapper is not the clicked element, close the dropdown.
         */
        if ( this._statusDropdownWraper && !this._statusDropdownWraper.contains( event.target ) && !this._statusDropdownToggler.contains( event.target ) ) {
            this.toggleStatusDropdown();
        }
    }

    /**
     * Add event lsitener on mousedown for status dropdown when component mounts to DOM.
     */
    componentDidMount = () => {
        document.addEventListener( 'mousedown', this.handleClickOutside );
    }
    
    /**
     * Remove event lsitener on mousedown for status dropdown when component unmounts from DOM.
     */
    componentWillUnmount = () => {
        document.removeEventListener( 'mousedown', this.handleClickOutside );
    }
}

export default withRouter( OtherClubContact );