import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { tokenAuthentication, get_user_data, isAuthenticated } from '../helpers/GeneralHelper';

/**
 * Class Component for Protected route.
 */
export default class ProtectedRoute extends React.Component {

    constructor( props ) {
        super( props );
        
        this.isProcessing   = false;
        this.route          = props.path;

        this.state = {
            isAuthenticated     : isAuthenticated(),
            redirectBackToLogin : !isAuthenticated() ? true : false,
            redirectMessage     : !isAuthenticated() ? 'Please signin first.' : ''
        }
        
        this.authenticateToken();
    }

    componentWillReceiveProps = ( props ) => {
        /**
         * Matches if the previous route is not same as current one.
         */
        if ( this.route !== props.path && !this.isProcessing ) {
            this.route = props.path;
            this.authenticateToken();
        }
    }
    
    authenticateToken = () => {
        this.isProcessing = true;

        /**
         * Authenticate token validity
         */
        tokenAuthentication( response => {
            this.isProcessing = false;
            let new_state = {
                isAuthenticated     : response.success,
                redirectBackToLogin : !response.success,
                redirectMessage     : !response.success ? response.message : ''
            }

            /**
             * Checks if current user has access to current page.
             */
            if ( response.success && this.props.accessibleTo ) {
                const current_user = get_user_data( 'user' );
                if ( current_user.users_role !== this.props.accessibleTo ) {
                    new_state = {
                        isAuthenticated     : false,
                        redirectBackToLogin : true,
                        redirectMessage     : ''
                    } 
                }
            }

            this.setState( new_state );
        });
    }

    render() {
        if ( this.state.redirectBackToLogin || !this.state.isAuthenticated ) {
            return (<Redirect to={ { pathname: '/login', state: { message: this.state.redirectMessage, success: false } }} />);
        }

        return (
            <Route {...this.props} />
        );
    }
}
