import React from 'react';
import { get_display_format_date, __x } from '../../helpers/GeneralHelper';


class Notes extends React.Component {

    listAllNotes = () => {
        const notes = this.props.notes;
        let notesList = [];

        if (!notes || notes === 'undefined' || notes.length <= 0) {
            if ( this.props.is_fetched ) {
                notesList.push(
                    <tr key={1}>
                        <td colSpan="7">{ __x( `No record found.` ) }</td>
                    </tr>
                );
            }

            return notesList;
        }
    
        for ( let i in notes ) {
            let note = notes[i];
            notesList.push(
                <tr key={i}>
                    <td>
                        <div className="d-flex">
                            <div className="d-inline-block">
                                <div className="fontBold">{note.agent ? note.agent : ''} 
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>{note.case_number ? note.case_number : ''}</td>
                    <td>
                        <div className="d-flex">
                            {note.note_type ? note.note_type : ''}
                        </div>
                    </td>
                    <td>
                        {note.fulfillment_date ? get_display_format_date(note.fulfillment_date) : ''}
                    </td>
                    <td>
                    <button data-toggle="popover" data-container="body" data-trigger="focus" data-placement="left" data-html="true" to="#" email="toggle-one" className="table-btn btn-transWhite">
                        <span>{note.attachments ? note.attachments : 0}</span>
                    </button>
                    </td>
                    <td>
                       {note.created_at ? get_display_format_date(note.created_at) : ''}
    
                    </td>
                    <td>
                        <button onClick={ e => this.props.openUpdateNote( e, note.id, note.note_type ) } type="button" className="transparent-btn"><i className="material-icons text-offWhite font-size20">edit</i></button>
                    </td>
                </tr>
            );
        }
        return notesList;
    }
    
    render() {        
        return  this.listAllNotes();
    }
}

 
export default Notes;

