import React from 'react';
import { is_valid_db_date, get_display_format_date } from '../../../helpers/GeneralHelper';
import { Scrollbars } from 'react-custom-scrollbars';


const PlayerListCaseRollOver = props => {
    
    let active_cases  = [];

    for ( let i in props.activeCases ) {
        let active_case = props.activeCases[i];
        
        const club_name =  active_case.club && active_case.club.club_name ? active_case.club.club_name : '';
        active_cases.push(<tr key={i}>
            <td className="text-left paddingL0 usm-roll-name" title={ club_name }>
                {club_name}
                <span className="d-block">{ active_case.fulfillment_date && is_valid_db_date(active_case.fulfillment_date) ? get_display_format_date(active_case.fulfillment_date) : '' }</span></td>
            <td className="text-center">{ active_case.master_position && active_case.master_position.abv ? active_case.master_position.abv : '' }</td>
            <td className="text-right paddingR0">{ active_case.status && active_case.status === true ? 'active' : 'inactive'}</td>
        </tr>);
    }

    const id = `popover-content-toggle-one-${props.club_id}`;
    return (
        <div id={id} className="popover-content-toggle-one player-rollover">

            <div className="arrow-right"></div>
            <div className="usm-table-popover table-responsive">
                <div className="table-popover-heading"><span>{props.activeCases ? props.activeCases.length : 0}</span> Cases</div>
                <Scrollbars autoHeightMin={100} autoHeightMax={270} autoHide autoHeight hideTracksWhenNotNeeded>
                    <div className="usm-table-popover-scroll">
                        <table className="">
                            <thead>
                                <tr>
                                    <th>Club</th>
                                    <th className="text-center">Position</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {active_cases}
                            </tbody>
                        </table>
                    </div>
                </Scrollbars>
            </div>
        </div>
    )
}

export default PlayerListCaseRollOver;

