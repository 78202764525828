import React from 'react';
import { is_valid_db_date, get_display_format_date } from '../../../helpers/GeneralHelper';
import { Link } from 'react-router-dom';


const ActivePlayerCases = ({ active_player_case, active_player_widgets, idx }) => {

    let visible = '';

    if (active_player_widgets.length > 0) {
        for (let i in active_player_widgets) {
            if (active_player_widgets[i].id === active_player_case.id) {
                visible = `visible`;
            }
        }
    } else {
        visible = idx > 3 ? '' : `visible`;
    }

    return (
        <Link to={`/players-details/${active_player_case.id}`} className={`col-md-3 col-sm-6 col-xs-12 ${visible}`}>
            <div className="card">
                <div className="card-body">
                    <div className="cardImg-section">
                        <div className="card-image">
                            <img className="club" src={active_player_case.image_path && active_player_case.image_path !== '' ? active_player_case.image_path : require('../../../assets/images/dummy-image.png')} alt=" " />
                        </div>

                        <div className="cardTitle-section" title={`${ active_player_case.first_name ? active_player_case.first_name : '' } ${ active_player_case.last_name ? active_player_case.last_name : '' } (${active_player_case.master_position && active_player_case.master_position.title ? active_player_case.master_position.title : ''})`}>
                            <div className="card-title">{ active_player_case.first_name ? active_player_case.first_name : '' } { active_player_case.last_name ? active_player_case.last_name : '' }</div>
                            <div className="card-subtitle">{active_player_case.master_position && active_player_case.master_position.title ? active_player_case.master_position.title : ''}</div>
                        </div>
                    </div>
                    <div className="cardContent-section">
                    <div className="d-flex card-text marginB20">
                            <div className="mr-auto">Represented By</div>
                            <div className="fontBold">{active_player_case.agency_representation && active_player_case.agency_representation !== '' ? active_player_case.agency_representation : ''}</div>
                        </div>
                        <div className="d-flex card-text marginB20">
                            <div className="mr-auto">Rep. End Date</div>
                            <div className="fontBold">{active_player_case.agent_representation_enddate && is_valid_db_date(active_player_case.agent_representation_enddate) ? get_display_format_date(active_player_case.agent_representation_enddate) : ''}</div>
                        </div>

                    </div>
                </div>
            </div>
        </Link>
    );

}
export default ActivePlayerCases;

