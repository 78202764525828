import React from 'react';

const PushNotification = ({ notifications, ...rest }) => {
    let notificationsList = [];
        
    if ( !notifications || notifications === 'undefined' ) {
        return notificationsList;
    }

    for ( let i in notifications ) {
        let notification = notifications[i];
        let reminder_days = notification.reminder_day ?  notification.reminder_day.split( ',' ) : '';
        reminder_days = reminder_days !== '' ? reminder_days.map( data => `${data} Days` ).join( ', ' ) : '-';
        notificationsList.push(
            <tr key={notification.id}>
                <td>{ notification.message ? notification.message : '' }</td>
                {/* <td>{ notification.manual && notification.manual === true ? 'Yes' : 'No' }</td> */}
                <td>{ notification.status && notification.status === true ? notification.manual && notification.manual === true ? 'Yes' : 'No' : '-' }</td>
                <td>{ reminder_days }</td>
                <td><button className="transparent-btn"  onClick={ ( e ) => rest.handleEditPushNotification( false, notification.id ) }><i className="material-icons text-offWhite font-size20">edit</i></button></td>
            </tr>
        );
    }

    return notificationsList;
}
export default PushNotification;