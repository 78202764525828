import React from 'react';
import RightDrawer from './RightDrawer';
import { get_authorization_token, __x, is_mobile } from '../../../helpers/GeneralHelper';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import ClosedPlayerCases from './ClosedPlayerCases';
import ClosedClubCases from './ClosedClubCases';
import ActivePlayerCases from './ActivePlayerCases';
import ActiveCases from './ActiveCases';

export default class AgentDetails extends React.Component {

    _page_title = 'Agent Details - USM';

    constructor(props) {
        super(props);

        this._agent_id = props.match.params.agent_id;

        this.state = {
            _is_fetched: {
                get_cases: false
            },
            shouldDrwaerOpen: !is_mobile(),
            closedCasesOpen: false,
            closed_player_cases: {},
            closed_club_cases: {},
            active_player_cases: [],
            active_player_widgets: [],
            active_cases: [],
            active_case_widgets: [],
            index: {
                active_player: {
                    minIndex: 0,
                    maxIndex: 3,
                },
                active_case: {
                    minIndex: 0,
                    maxIndex: 3,
                }
            }
        }
    }

    toggleDrawer = () => {
        this.setState({
            shouldDrwaerOpen: !this.state.shouldDrwaerOpen
        });
    }

    componentDidMount = () => {
        document.title = this._page_title;

        if (!this.state._is_fetched.get_cases) {
            this.getAllCases();
        }
    }

    /**
     * get all cases of an agent
     */
    getAllCases = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x(`Session has been expired. Please reload the page to login!`)
            });
            return false;
        }

        let new_state = {},
            closed_player_cases = this.state.closed_player_cases,
            closed_club_cases = this.state.closed_club_cases,
            active_player_cases = this.state.active_player_cases,
            active_player_widgets = this.state.active_player_widgets,
            active_cases = this.state.active_cases,
            active_case_widgets = this.state.active_case_widgets;
        const agent_id = this._agent_id;
        const agent_path = APIRoutes.agent_detail + agent_id;
        const request = new Request(agent_path, {}, 'GET', headers).send();
        request.then(response => {
            if (response.success) {
                closed_player_cases = response.data.closed_player_case && response.data.closed_player_case.length > 0 ? response.data.closed_player_case : {};
                closed_club_cases = response.data.closed_club_case && response.data.closed_club_case.length > 0 ? response.data.closed_club_case : {};
                active_player_cases = response.data.player && response.data.player.length > 0 ? response.data.player : [];
                active_cases = response.data.active_case && response.data.active_case.length > 0 ? response.data.active_case : [];

                active_player_widgets = active_player_cases ? active_player_cases.filter((active_player_case, idx) => {
                    return idx <= 3;
                }) : [];

                active_case_widgets = active_cases ? active_cases.filter((active_case, idx) => {
                    return idx <= 3;
                }) : [];
            }

            new_state = {
                isProcessing: false,
                isSubmitted: true,
                isSuccess: response.success,
                responseMsg: response.message
            }
            this.setState({
                new_state,
                closed_player_cases,
                closed_club_cases,
                active_player_cases,
                active_player_widgets,
                active_cases,
                active_case_widgets,
            });
        }, error => {
            console.log(error);
        });
    }


    /**
        * Navigate to previous slide.
        */
    slidePrevWidget = (case_name) => {
        const active_player_cases = case_name === 'player' ? this.state.active_player_cases : this.state.active_cases;
        if (!active_player_cases || active_player_cases.length <= 0) {
            return false;
        }

        const case_index_name = case_name === 'player' ? `active_player` : `active_case`;
        let minIndex = case_name === 'player' ? this.state.index.active_player.minIndex : this.state.index.active_case.minIndex,
            maxIndex = case_name === 'player' ? this.state.index.active_player.maxIndex : this.state.index.active_case.maxIndex;

        if (minIndex <= 0) {
            return false;
        }

        minIndex -= 1;
        maxIndex -= 1;

        const new_active_player_cases = active_player_cases.filter((contact, idx) => {
            return idx >= minIndex && idx <= maxIndex;
        });

        const active_widgets = case_name === 'player' ? 'active_player_widgets' : 'active_case_widgets';

        this.setState({
            [active_widgets]: new_active_player_cases,
            index: {
                ...this.state.index,
                [case_index_name]: {
                    minIndex,
                    maxIndex,
                }
            },
            animatedClass: `animated bounceInRight`
        });
    }

    /**
     * Navigate to nex slide.
     */
    slideNextWidget = (case_name) => {
        const active_player_cases = case_name === 'player' ? this.state.active_player_cases : this.state.active_cases;
        if (!active_player_cases || active_player_cases.length <= 0) {
            return false;
        }
        const case_index_name = case_name === 'player' ? `active_player` : `active_case`;
        let minIndex = case_name === 'player' ? this.state.index.active_player.minIndex : this.state.index.active_case.minIndex,
            maxIndex = case_name === 'player' ? this.state.index.active_player.maxIndex : this.state.index.active_case.maxIndex;

        if (maxIndex > (active_player_cases.length - 2)) {
            return false;
        }

        minIndex = minIndex + 1;
        maxIndex = maxIndex + 1;
        const new_active_player_cases = active_player_cases.filter((contact, idx) => {
            return idx >= minIndex && idx <= maxIndex;
        });

        const active_widgets = case_name === 'player' ? 'active_player_widgets' : 'active_case_widgets';
        this.setState({
            [active_widgets]: new_active_player_cases,
            index: {
                ...this.state.index,
                [case_index_name]: {
                    minIndex,
                    maxIndex,
                }
            },
            animatedClass: `animated bounceInRight`
        });
    }

    /**
     * toggle closed case table
     */
    toggleClosedCases = () => {
        this.setState({
            closedCasesOpen: !this.state.closedCasesOpen
        });
    }

    render() {
        const drawer_toggle_class = this.state.shouldDrwaerOpen ? 'toggled' : '',
            closed_club_cases_count = this.state.closed_club_cases && this.state.closed_club_cases.length > 0 ? this.state.closed_club_cases.length : 0,
            closed_player_cases_count = this.state.closed_player_cases && this.state.closed_player_cases.length > 0 ? this.state.closed_player_cases.length : 0,
            closed_player_contract_sign_count = this.state.closed_player_cases && this.state.closed_player_cases.length > 0 ? this.state.closed_player_cases.filter((closed_player_case, idx) => {
                return closed_player_case.is_contract_signed === true ? closed_player_case : '';
            }) : 0,
            closed_club_contract_sign_count = this.state.closed_club_cases && this.state.closed_club_cases.length > 0 ? this.state.closed_club_cases.filter((closed_club_case, idx) => {
                return closed_club_case.is_contract_signed === true ? closed_club_case : '';
            }) : 0;

        return (
            <div id="mainContainer" className={drawer_toggle_class}>
                <div className="heading-section d-flex align-items-center">
                    <h2>Agent Detail</h2>
                    <button onClick={this.toggleDrawer} type="button" className="transparent-btn ml-auto usmDrawer" data-toggle="offcanvas">
                        <img src={require('../../../assets/images/drawer.png')} alt="" />
                    </button>
                </div>

                <RightDrawer />

                <div className="content-section">
                    <div className="players-section">
                        <div className="heading-section d-flex align-items-center auto-height">
                            Players <span className="badge badge-pill badge-success">{this.state.active_player_cases ? this.state.active_player_cases.length : 0}</span>
                        </div>
                        <div className="position-relative">
                        <div className="row usm-cards club-slider-block widget-slider marginB35">
                            {this.state.active_player_cases && this.state.active_player_cases.length > 0 ? this.state.active_player_cases.map((active_player_case, idx) => {
                                return <ActivePlayerCases key={active_player_case.id}
                                    active_player_case={active_player_case}
                                    active_player_widgets={this.state.active_player_widgets}
                                    idx={idx} />
                            }) : ''
                            }
                            
                        </div>
                        <div className="widget-arrow">  
                                    {this.state.active_player_cases.length <= 4 ? '' :
                                        <button onClick={() => this.slidePrevWidget('player')} className="club-slider-btn float-left" data-toggle="offcanvas">
                                            <i className="material-icons text-success">keyboard_arrow_left</i>
                                        </button>
                                    }
                                    {this.state.active_player_cases.length <= 4 ? '' :
                                        <button onClick={() => this.slideNextWidget('player')} className="club-slider-btn float-right" data-toggle="offcanvas">
                                            <i className="material-icons text-success">keyboard_arrow_right</i>
                                        </button>
                                    }   
                            </div>
                        </div>
                        
                    </div>
                    <div className="active-cases img-same-height">
                        <div className="heading-section d-flex align-items-center auto-height">
                            Active Cases <span className="badge badge-pill badge-success">{this.state.active_cases ? this.state.active_cases.length : 0}</span>
                        </div>
                        <div className="position-relative">
                        <div className="row usm-cards club-slider-block widget-slider marginB35">
                            {this.state.active_cases && this.state.active_cases.length > 0 ? this.state.active_cases.map((active_case, idx) => {
                                return <ActiveCases key={active_case.id}
                                    active_case={active_case}
                                    active_case_widgets={this.state.active_case_widgets}
                                    idx={idx} />
                            }) : ''
                            }
                             
                        </div>
                        <div className="widget-arrow">
                               
                                    {this.state.active_cases.length <= 4 ? '' :
                                        <button onClick={() => this.slidePrevWidget('case')} className="club-slider-btn float-left" data-toggle="offcanvas">
                                            <i className="material-icons text-success">keyboard_arrow_left</i>
                                        </button>
                                    }
                                    {this.state.active_cases.length <= 4 ? '' :
                                        <button onClick={() => this.slideNextWidget('case')} className="club-slider-btn float-right" data-toggle="offcanvas">
                                            <i className="material-icons text-success">keyboard_arrow_right</i>
                                        </button>
                                    }
                                
                            </div>
                        </div>
                    </div>
                   
                </div>

                <div className="transDivider marginB35"></div>

                <div className="closed-cases">
                    <div className="heading-section d-flex align-items-center auto-height">
                        Closed Cases <span className="badge badge-pill badge-success">{closed_club_cases_count + closed_player_cases_count}</span>
                        <button onClick={this.toggleClosedCases} className="transparent-btn ml-auto" type="button">
                            {
                                this.state.closedCasesOpen ?
                                    <img src={require('../../../assets/images/arrow-up.png')} alt="" />
                                    :
                                    <img src={require('../../../assets/images/arrow-down.png')} alt="" />
                            }
                        </button>
                    </div>

                    {!this.state.closedCasesOpen ? '' :
                        <div>
                            <div className="player-cases-table usm-table table-responsive">
                                <div className="table-heading">Player Cases</div>
                                <table className="table table-hover lastOne-center">
                                    <thead className="usm-thead">
                                        <tr>
                                            <th scope="col">Case No</th>
                                            <th scope="col">Player</th>
                                            <th scope="col">Position</th>
                                            <th scope="col">Current Club</th>
                                            <th scope="col">Contract Signed({closed_player_contract_sign_count.length})</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ClosedPlayerCases
                                            closed_player_cases={this.state.closed_player_cases}
                                        />
                                    </tbody>
                                </table>
                            </div>

                            <div className="usm-table club-cases-table table-responsive">
                                <div className="table-heading marginT15">Club Cases </div>
                                <table className="table table-hover lastTwo-center">
                                    <thead className="usm-thead">
                                        <tr>
                                            <th scope="col">Case No</th>
                                            <th scope="col">Position</th>
                                            <th scope="col">Fulfilment Date</th>
                                            <th scope="col">Club</th>
                                            <th scope="col">Players Considered</th>
                                            <th scope="col">Contract Signed ({closed_club_contract_sign_count.length})</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ClosedClubCases
                                            closed_club_cases={this.state.closed_club_cases}
                                        />
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}