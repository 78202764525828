import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import Logo from '../Logo';
import Login from './Login';
import AccountSetup from './AccountSetup';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import TermsNConditions from './TermsNConditions';
import { isAuthenticated, is_tnc_accepted, get_user_dashboard_url } from '../../helpers/GeneralHelper';

export default class LoginWrap extends Component {

    render() {
        const { ...rest } = this.props;
        const maxWidthModal = this.props.match.path === '/terms-and-conditions/' ? 'maxWidth800' : 'maxWidth420';
        const dashboard_url = !get_user_dashboard_url() ? "/admin/dashboard" : get_user_dashboard_url();
        const tnc_path = this.props.location.pathname === '/terms-and-conditions';

        return (
            <div className="usm-modal usm-modal-active" tabIndex="-1" role="dialog">
                <div className={`modal-dialog modal-dialog-centered ${maxWidthModal}`} role="document">
                    <div className="modal-content">
                        <Logo tnc={tnc_path} />
                        <Switch>
                            <Route exact path="/account-setup/" 
                                render={ () => ( isAuthenticated() ? ( <Redirect  to={dashboard_url} /> ) : ( <AccountSetup {...rest} /> ) )} 
                            />
                            <Route 
                                exact path="/login" 
                                render={ () => ( !isAuthenticated() ? ( <Login {...rest} /> ) : ( <Redirect  to={dashboard_url} /> ) )} 
                            />
                            <Route path="/forgot-password/" render={ () => ( isAuthenticated() ? <Redirect to={dashboard_url} /> : <ForgotPassword {...rest} /> ) } />
                            <Route path="/reset-password/" render={ () => ( isAuthenticated() ? <Redirect to={dashboard_url} /> : <ResetPassword {...rest} /> ) }/>
                            <Route 
                                path="/terms-and-conditions/" 
                                render={ () => ( !isAuthenticated( true ) ? ( <Redirect to="/login" /> ) : ( is_tnc_accepted() ? <Redirect  to={dashboard_url} /> : <TermsNConditions {...rest} /> ) )} 
                            />
                            <Route exact path="/" render={ () => <Redirect to="/login" /> } />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}
