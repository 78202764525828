import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setOrganisation } from '../../../actions/OrganisationsAction';
import IntlTelInput from 'react-intl-tel-input';
import { get_authorization_token, __x, is_url_valid, is_protocol_exists } from '../../../helpers/GeneralHelper';
import Request from '../../../helpers/Request';
import APIRoutes from '../../../API/Routes';
import DeletePlayerInfoConfirm from '../players/DeletePlayerInfoConfirm';
import Contacts from './Contacts';
import * as EmailValidator from 'email-validator';
import LoadingSpinner from '../common/LoadingSpinner';
import Alert from '../../../helpers/Alert';

class UpdateOrganisation extends React.Component {

    constructor(props) {
        super(props);

        this._country = React.createRef();

        this.state = {
            fields: {
                organization_name: '',
                web_address: '',
                organization_type: '',
                country_code: '44',
                phone: '',
                email: '',
            },
            isValid: {
                organization_name: true,
                web_address: true,
                organization_type: true,
                country_code: true,
                phone: true,
                email: true,
            },
            fieldsValidityError: {
                organization_name: '',
                web_address: '',
                organization_type: '',
                country_code: '',
                phone: '',
                email: '',
            },
            country_iso: 'gb',
            isProcessing: false,
            isSubmitted: false,
            isSuccess: false,
            responseMessage: '',
            openDeleteConfirmationPoppup: false,
        }
    }

    componentWillReceiveProps = props => {
        let fields = this.state.fields;
        const organisation_data = props.organisation.organisation;
        fields['organization_name'] = organisation_data && organisation_data.organization_name ? organisation_data.organization_name : '';
        fields['web_address'] = organisation_data && organisation_data.web_address ? organisation_data.web_address : '';
        fields['organization_type'] = organisation_data && organisation_data.organization_type ? organisation_data.organization_type : '';
        fields['phone'] = organisation_data && organisation_data.phone ? organisation_data.phone : '';
        fields['country_code'] = organisation_data && organisation_data.country_code ? organisation_data.country_code : '44';
        fields['email'] = organisation_data && organisation_data.email ? organisation_data.email : '';
        this._country.setFlag(organisation_data && organisation_data.country_iso ? organisation_data.country_iso : 'gb');
        this.setState({
            ...fields,
            fields,
            country_iso: organisation_data && organisation_data.country_iso ? organisation_data.country_iso : 'gb'
        });
    }

    /**
     * handle input fields changes
     */
    handleFieldsChanges = (event) => {

        const elt = event.target,
            name = elt.name,
            value = elt.value,
            fields = this.state.fields,
            isValid = this.state.isValid,
            fieldsValidityError = this.state.fieldsValidityError;


        /**
         * When the current field is `video_url`
         */
        if (name === 'web_address') {
            fields[name] = value;
            isValid[name] = fields[name] === '' ? true : fields[name].length > 0 && fields[name].length <= 255 && is_url_valid(fields[name]) && is_protocol_exists(fields[name]);
            fieldsValidityError[name] = isValid[name] ? '' : (is_protocol_exists(fields[name]) ? __x(`Incorrect URL.`) : __x(`Please include http or https in url.`));
        }

        else if (name === 'email') {
            console.log(name);
            fields[name] = value;
            isValid[name] = fields[name] === '' ? true : fields.email.length >= 1 && fields.email.length <= 255 && EmailValidator.validate(fields.email);
            fieldsValidityError[name] = isValid[name] ? '' : __x(`Please enter a vaild email.`);
        }

        /**
         * For all rest of the `fields`.
         */
        else {
            fields[name] = value;
            isValid[name] = fields[name] === '' ? true : fields[name].length > 0 && fields[name].length <= 255;
            fieldsValidityError[name] = isValid[name] ? '' : __x(`Maximum 255 characters allowed.`);
        }

        this.setState({ fields, isValid, fieldsValidityError });
    }

    /**
     * Handles country code changes
     */
    handleCountryCodeChange = (value, countryData) => {
        const fields = this.state.fields,
            isValid = this.state.isValid,
            country_code = countryData ? countryData.dialCode : '44';

        fields['country_code'] = country_code;

        isValid['country_code'] = fields.country_code !== '' ? fields.country_code.toString().length >= 0 && fields.country_code.toString().length <= 255 : true;

        this.setState({
            fields,
            isValid,
            country_iso: countryData.iso2
        });
    }

    /**
     * Handles phone changes
     */
    handlePhoneChange = (status, value, countryData) => {
        const fields = this.state.fields,
            isValid = this.state.isValid,
            country_code = countryData ? countryData.dialCode : '44',
            phone = value ? value : '',
            fieldsValidityError = this.state.fieldsValidityError;

        fields['country_code'] = country_code;
        fields['phone'] = phone;

        isValid['country_code'] = fields.country_code !== '' ? fields.country_code.toString().length >= 0 && fields.country_code.toString().length <= 255 : true;
        isValid['phone'] = fields.phone !== '' ? fields.phone.length >= 0 && fields.phone.length <= 255 && !isNaN(fields.phone) : true;
        fieldsValidityError['phone'] = isValid['phone'] ? '' : __x(`Phone is invalid.`);

        this.setState({
            fields,
            isValid
        })
    }

    /**
     * Check if form is valid or not to submit
     */
    isFormValid = () => {
        let validityErr = [];
        const fields = this.state.fields,
            isValid = this.state.isValid;

        for (let elt in fields) {

            if (fields[elt] === '' || !isValid[elt]) {
                validityErr.push(elt);
            }
        }

        return validityErr.length <= 0;
    }

    /**
     * update organisation data
     */
    updateOrganisation = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMessage: __x(`Session has been expired. Please reload the page to login!`)
            });
            return false;
        }

        if (!this.isFormValid()) {
            return false;
        }

        this.setState({
            isProcessing: true
        });

        const organisation_path = APIRoutes.organization + this.props.organisation_id,
            fields = this.state.fields;
        let new_state = {},
            formData = new FormData();
        for (let i in fields) {
            formData.append(i, fields[i]);
        }

        formData.append('country_iso', this.state.country_iso);

        /**
         * Sending request to update organisation.
         */
        const request = new Request(organisation_path, formData, 'PUT', headers, true).send();
        request.then(response => {
            if (response.success) {
                this.clearFields();
                this.props.history.push('/organisations', { message: response.message, success: true });
            }
            else {
                new_state = {
                    isProcessing: false,
                    isSubmitted: true,
                    isSuccess: response.success,
                    responseMessage: response.message,
                }
                this.setState(new_state);
            }

        }, error => {
            new_state = {
                isProcessing: false,
                isSubmitted: true,
                isSuccess: error.success,
                responseMessage: error.message
            };
            this.setState(new_state);
        });
    }

    /**
     * clear all form fields
     */
    clearFields = () => {
        this.setState({
            fields: {
                organization_name: '',
                web_address: '',
                organization_type: '',
                country_code: '44',
                phone: '',
                email: '',
            },
            isValid: {
                organization_name: true,
                web_address: true,
                organization_type: true,
                country_code: true,
                phone: true,
                email: true,
            },
            fieldsValidityError: {
                organization_name: '',
                web_address: '',
                organization_type: '',
                country_code: '',
                phone: '',
                email: '',
            },
            country_iso: 'gb',
            isProcessing: false,
            isSubmitted: false,
            isSuccess: false,
            responseMessage: '',
            openDeleteConfirmationPoppup: false,
        });
    }

    /**
     * reset alert message after success and failure
     */
    resetMessage = () => {
        this.setState({
            responseMessage: '',
        })
    }

    render() {
        const disableSubmit = !this.isFormValid() || this.state.isProcessing ? true : false,
            loading = this.state.isProcessing ? <LoadingSpinner /> : '',
            responseMessage = this.state.responseMessage === '' && !this.state.isSubmitted ? '' : <Alert message={this.state.responseMessage} success={this.state.isSuccess} floating="true" onClose={this.resetMessage} />;
        return (
            <div className="col-md-6 col-sm-12">
                {loading}
                {responseMessage}
                <div className="player-detail-block">
                    <div className="player-detail-heading d-flex align-items-center">Organisation Details</div>
                    <div className="player-detail-search filtter-section paddingB40 marginB20">
                        <form action="" className="usm-form form-border form-modal" onSubmit={ e => {e.preventDefault(); }}>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className={this.state.fieldsValidityError.organization_name === '' ? `form-group` : `form-group form-error`}>
                                        <label htmlFor="">Organisation Name</label>
                                        <input value={this.state.fields.organization_name} onChange={this.handleFieldsChanges} className="form-control" name="organization_name" type="text" placeholder="" autoComplete="off" />
                                        {this.state.fieldsValidityError.organization_name !== '' ? <div className="display-error text-white">{this.state.fieldsValidityError.organization_name}</div> : ''}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className={this.state.fieldsValidityError.organization_type === '' ? `form-group` : `form-group form-error`}>
                                        <label htmlFor="">Entity Type</label>
                                        <select name="organization_type" value={this.state.fields.organization_type} onChange={this.handleFieldsChanges} id="" className="form-control">
                                            <option value=""></option>
                                            <option value="Agency">Agency</option>
                                            <option value="National Team">National Team</option>
                                            <option value="Supplier">Supplier</option>
                                        </select>
                                        {this.state.fieldsValidityError.organization_type !== '' ? <div className="display-error text-white">{this.state.fieldsValidityError.organization_type}</div> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className={this.state.fieldsValidityError.web_address === '' ? `form-group` : `form-group form-error`}>
                                        <label htmlFor="">Web Address</label>
                                        <input type="text" value={this.state.fields.web_address} onChange={this.handleFieldsChanges} className="form-control" name="web_address" id="" placeholder="" autoComplete="off" />
                                        {this.state.fieldsValidityError.web_address !== '' ? <div className="display-error text-white">{this.state.fieldsValidityError.web_address}</div> : ''}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className={this.state.fieldsValidityError.phone === '' ? `form-group` : `form-group form-error`}>
                                        <label htmlFor="phone">Phone</label>
                                        <div className={this.state.fieldsValidityError.web_address === '' ? `input-group` : `input-group form-error`}>
                                            <IntlTelInput
                                                ref={elt => this._country = elt}
                                                onPhoneNumberChange={this.handlePhoneChange}
                                                defaultCountry={'gb'}
                                                css={['intl-tel-input w-100', 'form-control']}
                                                placeholder="Phone"
                                                value={this.state.fields.phone}
                                                separateDialCode="true"
                                                onSelectFlag={this.handleCountryCodeChange}
                                            />
                                        </div>
                                        {this.state.fieldsValidityError.phone !== '' ? <div className="display-error text-white">{this.state.fieldsValidityError.phone}</div> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className={this.state.fieldsValidityError.email === '' ? `form-group` : `form-group form-error`}>
                                        <label htmlFor="">Email</label>
                                        <input type="email" value={this.state.fields.email} className="form-control" name="email" onChange={this.handleFieldsChanges} id="" placeholder="" autoComplete="off" />
                                        {this.state.fieldsValidityError.email !== '' ? <div className="display-error text-white">{this.state.fieldsValidityError.email}</div> : ''}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="player-detail-content">

                        <div className="players-section">
                            <Contacts
                                organisation_id={this.props.organisation_id} />

                            <div className="row">
                                <div className="col d-flex justify-content-between border-top-trans paddingT35 paddingB20">
                                    <Link to="/organisations" className="default-btn btn-danger min-width157">Cancel</Link>
                                    <button type="button" className="default-btn btn-danger min-width157" onClick={this.toggleDeleteConfirmation}>Delete</button>
                                    <button type="button" onClick={this.updateOrganisation} disabled={disableSubmit} className="default-btn btn-success min-width157">Save</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <DeletePlayerInfoConfirm
                    modal={this.state.openDeleteConfirmationPoppup}
                    onClose={this.onCancelDelete}
                    onConfirm={this.deleteOrganisation}
                    type="organisation"
                />
            </div>
        )
    }
    /**
     * Open confirmation poppup.
     */
    toggleDeleteConfirmation = () => {
        this.setState({
            openDeleteConfirmationPoppup: !this.state.openDeleteConfirmationPoppup
        });
    }

    /**
     * on cancel delete confirmation
     */
    onCancelDelete = () => {
        this.toggleDeleteConfirmation();
    }

    /**
     * delete an organisation
     */
    deleteOrganisation = () => {
        const headers = get_authorization_token();
        if (!headers) {
            this.setState({
                isError: true,
                responseMsg: __x(`Session has been expired. Please reload the page to login!`)
            });
            return false;
        }

        let new_state = {};
        const organisation_path = APIRoutes.organization + this.props.organisation_id;
        const request = new Request(organisation_path, {}, 'DELETE', headers).send();
        request.then(response => {
            if (response.success) {
                this.toggleDeleteConfirmation();
                this.props.history.push('/organisations', {
                    message: response.message,
                    success: response.success
                });
            } else {
                new_state = {
                    isProcessing: false,
                    isSubmitted: true,
                    isSuccess: response.success,
                    responseMsg: response.message
                }

                this.setState(new_state);
            }
        }, error => {
            new_state = {
                isProcessing: false,
                isSubmitted: true,
                isSuccess: error.success,
                responseMsg: error.message
            };
            this.setState(new_state);
        });
    }
}

const mapStateToProps = (state) => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setOrganisation: (organisation) => dispatch(setOrganisation(organisation))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateOrganisation));