import React from 'react';
import { is_protocol_exists, schemize_url, is_url_valid, get_authorization_token, __x } from '../../../helpers/GeneralHelper';
import APIRoutes from '../../../API/Routes';
import Request from '../../../helpers/Request';
import Alert from '../../../helpers/Alert';
import LoadingSpinner from '../common/LoadingSpinner';
import DeletePlayerInfoConfirm from './DeletePlayerInfoConfirm';


class UpdateVideo extends React.Component {
    constructor(props) {
        super(props);

        this._player_id = props.player_id;
        this.state = {
            analysis_links  : [ 'link-0' ],
            fields          : {
                video_url       : '',
                video_title     : '',
                video_desc      : '',
                analysis_title  : '',
                is_restricted   : false,
                analysis_links  : {
                    'link-0' : ''
                }
            },
            isValid : {
                video_url       : true,
                video_title     : true,
                video_desc      : true,
                analysis_title  : true,
                is_restricted   : true,
                analysis_links  : {
                    'link-0' : true
                }
            },
            error   : {
                video_url       : '',
                video_title     : '',
                video_desc      : '',
                analysis_title  : '',
                analysis_links  : {
                    'link-0' : ''
                }
            },
            video_id        : 0,
            is_editing      : false,
            isProcessing    : false,
            isSubmitted     : false,
            isSuccess       : false,
            responseMsg     : '',
            isValidatingUrl : false,
            openDeleteConfirmationPoppup : false
        }
    }

    /**
     * video data into local fields
     */
    componentWillReceiveProps = props => {
        if ( !props.video || Object.keys( props.video ).length <=0 ) {
            return false;
        }
        
        let fields              = this.state.fields;
        const chosen_video      = props.video,
        isValid                 = this.state.isValid,
        error                   = this.state.error,
        analysis_links          = this.state.analysis_links,        
        video_analysis_links    = chosen_video && chosen_video.player_video_analysis_links ? chosen_video.player_video_analysis_links : [],
        new_analysis_links      = {
            'link-0' : ''
        };

        /**
         * Fetch current editing video's analysis links and set into local analysis links' fields
         */
        for ( let i in video_analysis_links ) {
            if ( i > 0 ) {
                analysis_links.push( `link-${i}` );
            }

            new_analysis_links[ `link-${i}` ]       = video_analysis_links[i].link;
            isValid.analysis_links[ `link-${i}` ]   = '';
            error.analysis_links[ `link-${i}` ]     = '';
        }

        fields = {
            video_url       : chosen_video && chosen_video.video_url ? chosen_video.video_url : '',
            video_title     : chosen_video && chosen_video.video_title ? chosen_video.video_title : '',
            video_desc      : chosen_video && chosen_video.video_desc ? chosen_video.video_desc : '',
            analysis_title  : chosen_video && chosen_video.analysis_title ? chosen_video.analysis_title : '',
            is_restricted   : chosen_video && chosen_video.is_restricted ? chosen_video.is_restricted : false,
            analysis_links  : new_analysis_links,
        };
        
        this.setState({ 
            fields, 
            analysis_links, 
            isValid, 
            error,
            is_editing  : props.is_editing,
            video_id    : chosen_video && chosen_video.id ? chosen_video.id : 0
        });
    }

    /**
     * Adds ne analysis link field in popup.
     */
    addNewLink = () => {
        const fields    = this.state.fields,
        isValid         = this.state.isValid,
        error           = this.state.error,
        analysis_links  = this.state.analysis_links,
        new_link        = `link-${analysis_links.length}`;

        analysis_links.push( new_link );
        
        fields.analysis_links[ new_link ]   = '';
        isValid.analysis_links[ new_link ]  = true;
        error.analysis_links[ new_link ]    = '';

        this.setState({ analysis_links, fields, isValid, error });
    }

    /**
     * Handles changes.
     */
    handleChanges = event => {
        const elt   = event.target,
        type        = elt.type,
        name        = elt.name,
        value       = type === 'checkbox' ? elt.checked : elt.value,
        fileNames   = this.state.fileNames,
        fields      = this.state.fields,
        isValid     = this.state.isValid,
        error       = this.state.error;

        /**
         * When the current field is `analysis_links`
         */
        if ( name.includes( 'link-' ) ) {
            fields.analysis_links[ name ]  = value;
            isValid.analysis_links[ name ] = fields.analysis_links[ name ] === '' ? true : fields.analysis_links[ name ].length > 0 && fields.analysis_links[ name ].length <= 255;
            error.analysis_links[ name ]   = isValid.analysis_links[ name ] ? '' : __x( `Maximum 255 characters allowed.` );
        } 
        
        /**
         * When the current field is `is_restricted`
         */
        else if ( name === 'is_restricted' ) {
            fields[ name ]  = value;
        } 
        
        /**
         * When the current field is `video_url`
         */
        else if ( name === 'video_url' ) {
            fields[ name ]  = value;
            isValid[ name ] = fields[ name ] === '' ? true : fields[ name ].length > 0 && fields[ name ].length <= 255 && is_url_valid( fields[ name ] ) && is_protocol_exists( fields[ name ] );
            error[ name ]   = isValid[ name ] ? '' : ( is_protocol_exists( fields[ name ] ) ? __x( `Incorrect URL.` ) : __x( `Please include http or https in url.` ) );
        } 
        
        /**
         * For all rest of the `fields`.
         */
        else {
            fields[ name ]  = value;
            isValid[ name ] = fields[ name ] === '' ? true : fields[ name ].length > 0 && fields[ name ].length <= 255;
            error[ name ]   = isValid[ name ] ? '' : __x( `Maximum 255 characters allowed.` );
        }

        this.setState({ fileNames, fields, isValid, error });
    }

    /**
     * Checks if domain exists or not.
     */
    checkDomain = event => {
        const target    = event.target,
            name        = target.name,
            value       = schemize_url( target.value ),
            isValid     = this.state.isValid,
            error       = this.state.error;

        if ( !isValid[ name ] ) {
            return false;
        }

        this.setState({
            isValidatingUrl: true
        });
        const request = fetch( `https://cors-anywhere.herokuapp.com/${value}`, {
            method      : "GET",
            mode        : 'cors',
            credentials : 'same-origin',
        });
        request.then( response => {
            if ( !response.ok ) {
                isValid[ name ] = false;
                error[ name ]   = __x( `URL doesn't exist.` );
            }
            this.setState({ 
                isValid, 
                error,
                isValidatingUrl: false
            });
        }).catch( (err) => {
            isValid[ name ] = false;
            error[ name ]   = __x( `URL doesn't exist.` );
            this.setState({ 
                isValid, 
                error,
                isValidatingUrl: false
            });
        });
    }

    /**
     * Handles add/update videos.
     */
    updateVideo = event => {
        event.preventDefault();

        /**
         * Check if user is logged in.
         */
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }

        if ( !this.isFormValid() ) {
            return false;
        }

        this.setState({
            isProcessing: true
        });

        const fields        = this.state.fields,
        player_note_path    = this.state.is_editing ? APIRoutes.player_video + this.state.video_id : APIRoutes.player_video + this._player_id,
        mothod              = this.state.is_editing ? "PUT" : "POST";

        let new_state   = {},
        body = {
            video_url       : fields.video_url,
            video_title     : fields.video_title,
            video_desc      : fields.video_desc,
            analysis_title  : fields.analysis_title,
            is_restricted   : fields.is_restricted,
            video_analysis_link : Object.values( fields.analysis_links ).length > 0 ? Object.values( fields.analysis_links ).filter( link => link !== '' && link !== null ) : []
        };

        /**
         * Sending request to add plyaer notes.
         */
        const request = new Request( player_note_path, body, mothod, headers ).send();
        request.then( response => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message
            }
            this.setState( new_state );

            if ( response.success ) {
                this.closePopup();

                if ( this.props.refresh ) {
                    this.props.refresh();
                }
            }
        }, error => {
            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : error.success,
                responseMsg     : error.message
            };
            this.setState( new_state );
        });
    }

    /**
     * Checks if form is va;id or not.
     */
    isFormValid = () => {
        const error = [],
        fields      = this.state.fields,
        isValid     = this.state.isValid;

        for ( let elt in fields ) {
            /**
             * Skip these fields to validate for blank.
             */
            if ( ['video_url', 'analysis_title', 'analysis_links', 'video_desc', 'is_restricted' ].indexOf( elt ) >= 0 ) {
                if ( fields[ elt ] === '' || !fields[ elt ] ) {
                    continue;
                }
            }

            /**
             * Checks validation of rest of the fields.
             */
            if ( fields[ elt ] === '' || !isValid[ elt ] ) {
                error.push( elt );
            }
        }
        return error.length <= 0;
    }

    /**
     * Popup closing handler.
     */
    closePopup = () => {
        this.props.closePopup();
        this.clearFields();
    }

    resetAlert = () => {
        this.setState({
            responseMsg: ''
        });
    }

    /**
     * Open confirmation poppup.
     */
    toggleDeleteConfirmation = () => {
        this.setState({
            openDeleteConfirmationPoppup : !this.state.openDeleteConfirmationPoppup
        });
    }

    /**
     * Delete videos.
     */
    deleteVideo = () => {
        const headers = get_authorization_token();
        if ( !headers ) {
            this.setState({
                isError     : true,
                responseMsg : __x( `Session has been expired. Please reload the page to login!` )
            });
            return false;
        }
        
        let new_state       = {};
        const video_id      = this.state.video_id;
        const video_path    = APIRoutes.player_video + video_id;
        const request       = new Request( video_path, {}, 'DELETE', headers ).send();
        request.then( response => {
            if ( response.success ) {
                this.toggleDeleteConfirmation();
                this.closePopup();
                
                if ( this.props.refresh ) {
                    this.props.refresh();
                }
            }

            new_state = {
                isProcessing    : false,
                isSubmitted     : true,
                isSuccess       : response.success,
                responseMsg     : response.message
            }
            this.setState( new_state );
        }, error => {
            console.log( error );
        });
    }

    onCancelDelete = () => {
        this.toggleDeleteConfirmation();
        this.closePopup();
    }

    /**
     * List all analysis link field.
     */
    listAnalysisLinks = () => {
        const links = this.state.analysis_links;
        return links && links.length > 0 ? links.map( link => (
            <div key={link} className={ this.state.error.analysis_links[ link ] === '' ? `form-group` : `form-group form-error` }>
                <label htmlFor="">Analysis Link</label>
                <input onChange={ this.handleChanges } value={ this.state.fields.analysis_links[ link ] } name={ link } type="text" className="form-control" placeholder="" />
                { this.state.error.analysis_links[ link ] !== '' ? <div className="display-error text-white">{ this.state.error.analysis_links[ link ] }</div> : '' }
            </div>
        )) : [];
    }

    render() {
        const showModal = this.props.shouldPopupOpen ? 'show' : '',
        disabled_submit = ! this.isFormValid(),
        alert_message   = this.state.responseMsg !== '' ? <Alert message={ this.state.responseMsg } success={ this.state.isSuccess } floating="true" onClose={ this.resetAlert } /> : '',
        btnswidth       = this.state.is_editing ? 'min-width157' : '',
        loading         = this.state.isProcessing ? <LoadingSpinner/> : '',
        validating      = this.state.isValidatingUrl ? <img className="search-loader" src={require(`../../../assets/images/loader-usm.svg`)} alt="" /> : '';
        
        return (
            <div>
                {alert_message}
                {loading}
                <div className={`modal form-modal fade modal-active ${showModal}`} style={{ transform: this.props.shouldPopupOpen ? 'translate(0,0)' : 'translate(-125%, -125%)' }} >
                    <div className="modal-dialog modal-dialog-centered maxWidth600">
                        <div className="modal-content">
                            <div className="modal-header form-modal-header d-flex align-items-center">
                                <img src={require("../../../assets/images/form-usm-logo.png")} alt="" />
                                <h5 className="form-modal-title">{ this.state.is_editing ? `Edit` : `Add` } Video</h5>
                                <button onClick={ this.closePopup } type="button" className="modal-close transparent-btn">
                                    <img src={require("../../../assets/images/close-modal.png")} alt="" />
                                </button>

                            </div>
                            <div className="modal-body form-modal-body">

                                <form onSubmit={ this.updateVideo } action="" className="usm-form form-border">
                                    <div className="row">
                                        <div className="col">
                                            <div className={ this.state.error.video_url === '' ? `form-group` : `form-group form-error`}>
                                                <label htmlFor="">Video URL</label>
                                                <input onChange={ this.handleChanges } /* onBlur={ this.checkDomain } */ value={ this.state.fields.video_url } name="video_url" type="text" className="form-control" placeholder="" />
                                                <small className="text-white-70">( Youtube and Vimeo's video urls are accepted. )</small>
                                                { this.state.error.video_url !== '' ? <div className="display-error text-white">{ this.state.error.video_url }</div> : '' }
                                                {validating}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className={ this.state.error.video_title === '' ? `form-group` : `form-group form-error`}>
                                                <label htmlFor="">Video Title</label>
                                                <input onChange={ this.handleChanges } value={ this.state.fields.video_title } name="video_title" type="text" className="form-control" placeholder="" />
                                                { this.state.error.video_title !== '' ? <div className="display-error text-white">{ this.state.error.video_title }</div> : '' }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className={ this.state.error.video_desc === '' ? `form-group` : `form-group form-error`}>
                                                <label htmlFor="">Video Description</label>
                                                <textarea onChange={ this.handleChanges } value={ this.state.fields.video_desc } name="video_desc" className="form-control textarea76" cols="" rows=""></textarea>
                                                { this.state.error.video_desc !== '' ? <div className="display-error custome-error-top108 text-white">{ this.state.error.video_desc }</div> : '' }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className={ this.state.error.analysis_title === '' ? `form-group` : `form-group form-error`}>
                                                <label htmlFor="">Analysis Title</label>
                                                <input onChange={ this.handleChanges } value={ this.state.fields.analysis_title } name="analysis_title" type="text" className="form-control" placeholder="" />
                                                { this.state.error.analysis_title !== '' ? <div className="display-error text-white">{ this.state.error.analysis_title }</div> : '' }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <button onClick={ this.addNewLink } type="button" className="transparent-btn float-right top-browse-btn add-video-link-in-popup">
                                                <img src={require("../../../assets/images/add-analysis-icon.png")} alt="" />
                                                ADD Analysis Link
                                            </button>
                                            { this.listAnalysisLinks() }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group usm-check">
                                                <input onChange={ this.handleChanges } checked={ this.state.fields.is_restricted } name="is_restricted" type="checkbox" id="restrictVideo" />
                                                <label htmlFor="restrictVideo"> Restrict Video & Analysis to Admin View Only.</label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer form-modal-footer d-flex justify-content-between">
                                <button onClick={ this.closePopup } type="button" className={`default-btn btn-danger ${btnswidth}`}>Cancel</button>
                                { this.state.is_editing ? <button type="button" className="default-btn btn-danger min-width157" onClick={ this.toggleDeleteConfirmation }>Delete</button> : '' }
                                <button onClick={ this.updateVideo } type="submit" className={`default-btn btn-success ${btnswidth}`} disabled={disabled_submit}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>

                {!this.props.shouldPopupOpen || <div className="modal-backdrop fade show"></div>}
                <DeletePlayerInfoConfirm 
                    modal={ this.state.openDeleteConfirmationPoppup }
                    onClose={ this.onCancelDelete }
                    onConfirm={ this.deleteVideo }
                    type="video"
                />
            </div>
        );
    }

    /**
     * Clear required fields after closing the popup.
     */
    clearFields = () => {
        this.setState({
            analysis_links  : [ 'link-0' ],
            fields          : {
                video_url       : '',
                video_title     : '',
                video_desc      : '',
                analysis_title  : '',
                is_restricted   : false,
                analysis_links  : {
                    'link-0' : ''
                }
            },
            isValid : {
                video_url       : true,
                video_title     : true,
                video_desc      : true,
                analysis_title  : true,
                is_restricted   : true,
                analysis_links  : {
                    'link-0' : true
                }
            },
            error   : {
                video_url       : '',
                video_title     : '',
                video_desc      : '',
                analysis_title  : '',
                analysis_links  : {
                    'link-0' : ''
                }
            },
            video_id        : 0,
            is_editing      : false
        });
    }
}
export default UpdateVideo;